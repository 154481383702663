import { SpanS } from './Form.style';

interface Props {
  id?: string;
  label?: string;
  required?: boolean;
}
export const FormLabel = ({ id, label, required }: Props) => {
  return (
    <label htmlFor={id}>
      {label}
      {required && <SpanS> *</SpanS>}
    </label>
  );
};
