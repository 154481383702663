import { useTranslation } from 'react-i18next';

import { PageHeader } from 'components/Shared/CustomMui';

import { Grid, Typography } from '@mui/material';

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader
        headerText={t('error_404')}
        subHeaderText={t('cant_find_page')}
      />
      <Grid>
        <Typography color="textSecondary" variant="body1">
          {t('try_link_on_top')}
        </Typography>
      </Grid>
    </>
  );
};
