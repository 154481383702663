import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { tkCampaignState } from 'i18n/translationKeyMaps';
import { xor } from 'lodash';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { definitions } from 'types/api';

import { useGetCampaignsQuery } from 'store/api/endpoints/campaign';
import { getRelatedCampaignStates } from 'store/modules/campaign/helpers';
import { selectUser } from 'store/modules/user/selectors';

import { CampaignListActionBar } from 'components/Campaigns/CampaignListing/CampaignListActionbar/CampaignListActionbar';
import styles from 'components/Shared/AddProductsDialog/AddProductsDialog.module.scss';
import { CampaignsSelectionTable } from 'components/Shared/CampaignsSelectionTable/CampaignsSelectionTable';
import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';
import { EntitySelectedFilterType } from 'components/Shared/ListActionBar/components';

import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface AddCampaignToGroupDialogProps {
  countryCode: string;
  onCancel: () => void;
  onConfirm: (campaignsToAssign: string[]) => void;
}

export const AddCampaignToGroupDialog: FC<AddCampaignToGroupDialogProps> = (
  props: AddCampaignToGroupDialogProps
): ReactElement => {
  const { onCancel, onConfirm, countryCode } = props;

  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const [page, setPage] = useState<number>(1);
  const [campaignsToAssign, setCampaignsToAssign] = useState<string[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const [activeSortValues, setActiveSortValues] = useState<{
    field: definitions['CampaignSortField'];
    order: definitions['SortOrder'];
  }>({ field: 'CAMPAIGN_SORT_NAME', order: 'SORT_ORDER_ASC' });
  const [stateFilterValues, setStateFilterValues] = useState<string[]>([]);
  const [typeFilterValues, setTypeFilterValues] = useState<string[]>([]);

  const { data: campaignResponse } = useGetCampaignsQuery(
    {
      page,
      per_page: DEFAULT_API_PARAMETERS.per_page,
      sortBy: activeSortValues.field,
      order: activeSortValues.order,
      filter: {
        search: searchTerm,
        country_code: countryCode,
        group_assignment: 'UNASSIGNED',
        advertiser_id: user?.advertiser_id,
        state: stateFilterValues?.length
          ? stateFilterValues
          : ['CAMPAIGN_STATE_REJECTED', 'CAMPAIGN_STATE_DRAFT'],
        type: typeFilterValues,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const resetListingItems = () => {
    setPage(1);
  };

  const handleFilterChange = (filters: EntitySelectedFilterType[]): void => {
    const typeFilters =
      filters
        ?.find((filter) => filter.field?.value === 'type')
        ?.activeFilterValues?.map((x) => x.value) || [];

    let stateFilters =
      filters
        ?.find((filter) => filter.field?.value === 'state')
        ?.activeFilterValues?.map((x) => x.value) || [];
    let extendedStateFilters: string[] = [];
    stateFilters.forEach(
      (state) =>
        (extendedStateFilters = stateFilters.concat(
          getRelatedCampaignStates(state as definitions['CampaignState'])
        ))
    );

    if (xor(extendedStateFilters, stateFilterValues).length) {
      resetListingItems();
      setStateFilterValues(extendedStateFilters);
    } else if (xor(typeFilters, typeFilterValues).length) {
      resetListingItems();
      setTypeFilterValues(typeFilters);
    }
  };

  return (
    <DialogWrapper
      width={1000}
      headerText={t('add_campaign_to_group')}
      subHeaderText={t('subheader_add_campaign_to_group')}
      handleClose={onCancel}
      dialogContent={
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12} className={styles.filters}>
            <CampaignListActionBar
              activeSearchTerm={searchTerm}
              activeSort={{
                field: activeSortValues.field,
                order: activeSortValues.order,
              }}
              supportedStateFilterValues={[
                {
                  label: tkCampaignState('CAMPAIGN_STATE_DRAFT'),
                  value: 'CAMPAIGN_STATE_DRAFT',
                },
                {
                  label: tkCampaignState('CAMPAIGN_STATE_REJECTED'),
                  value: 'CAMPAIGN_STATE_REJECTED',
                },
              ]}
              initialFilters={{
                state: stateFilterValues,
                type: typeFilterValues,
              }}
              onSearch={(term) => {
                resetListingItems();
                setSearchTerm(term);
              }}
              onSort={(sortValue, sortOrder) => {
                resetListingItems();
                setActiveSortValues({
                  field: sortValue,
                  order: sortOrder,
                });
              }}
              onFiltersChange={handleFilterChange}
            ></CampaignListActionBar>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={styles.entries}>
              <div className={styles.result_count}>
                <Typography variant="h3">{t('results')}</Typography>
                <Typography variant="h3" color="textSecondary">
                  (
                  {campaignResponse?.results.length
                    ? `${campaignResponse?.results?.length}/${campaignResponse?.query.results}`
                    : 0}
                  )
                </Typography>
              </div>
            </Grid>
          </Grid>
          <CampaignsSelectionTable
            page={page}
            setPage={setPage}
            campaignResponse={campaignResponse}
            onSelectionChange={(newSelection: string[]) => {
              setCampaignsToAssign(newSelection);
            }}
          />
        </Grid>
      }
      dialogActions={
        <>
          <Button onClick={onCancel} variant="outlined">
            {t('cancel')}
          </Button>
          <Button
            disabled={!campaignsToAssign?.length}
            onClick={() => onConfirm(campaignsToAssign)}
            variant="contained"
          >
            {t('confirm')}
          </Button>
        </>
      }
    />
  );
};
