import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';

import { Routes } from 'routes/Routes';

import { useKeycloak } from 'hooks/usekeycloak';

import { LogoS, PaperS, RootS } from 'components/Login/Login.style';

import { Alert, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';

const Login: FC = () => {
  const auth = useAuth();
  const { serviceStatus } = useKeycloak();
  const { t } = useTranslation();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const redirectUri = searchParams.get('redirect_uri');

  return (
    <RootS>
      <PaperS alignItems="center" container wrap="nowrap">
        <>
          <Grid
            alignItems="center"
            container
            direction="column"
            gap={3}
            sx={{
              width: '420px',
            }}
          >
            <LogoS>
              <img src="/img/SMP-logo-color.png" alt="SMP-logo" />
            </LogoS>
            <Typography
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
              textAlign="center"
              variant="h2"
            >
              {t('login_title')}
            </Typography>
            <Typography align="center" color="textSecondary" variant="body1">
              {t('login_subtitle')}
            </Typography>
            {serviceStatus === 'DOWN' && (
              <Alert severity="error" sx={{ whiteSpace: 'pre-line' }}>
                {t('keyCloak_unavailable')}
              </Alert>
            )}
            <Button
              color="primary"
              fullWidth
              data-testid="application-login-button"
              onClick={() =>
                auth.signinRedirect({
                  redirect_uri:
                    origin +
                    Routes.AUTHENTICATION_CALLBACK +
                    `?redirect_uri=${redirectUri}`,
                })
              }
              variant="contained"
              disabled={serviceStatus === 'DOWN'}
            >
              {t('login')}
            </Button>
          </Grid>
        </>
      </PaperS>
    </RootS>
  );
};

export default Login;
