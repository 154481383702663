import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down-small.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/circle-help.svg';
import { definitions } from 'types/api';

import { useStoreDispatch } from 'store';
import { updateDraft } from 'store/modules/campaign/slice';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  SvgIcon,
  Tooltip,
} from '@mui/material';

import styles from './BidModifier.module.scss';

type Props = {
  state: definitions['CampaignState'];
  bidModifier: definitions['CampaignBidModifier'];
};

export const CampaignOverviewBidModifier = ({ bidModifier, state }: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  return (
    <Accordion disableGutters={true} className={styles.bid_modifier}>
      <AccordionSummary
        className={styles.bid_modifier_header}
        expandIcon={
          <SvgIcon component={ChevronDownIcon} viewBox={'0 0 32 32'} />
        }
      >
        <span className={styles.bid_modifier_title}>
          {t('bid_modifier_title')}
        </span>
        <Tooltip
          className={styles.bid_modifier_tooltip}
          title={
            <div style={{ whiteSpace: 'pre-line' }}>
              {t('bid_modifier_tooltip')}
            </div>
          }
        >
          <SvgIcon component={InfoIcon} viewBox="0 0 32 32" />
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails>
        <ButtonGroup disabled={state === 'CAMPAIGN_STATE_STOPPED'}>
          {(
            [
              'CAMPAIGN_BID_MODIFIER_DECREASE',
              'CAMPAIGN_BID_MODIFIER_NEUTRAL',
              'CAMPAIGN_BID_MODIFIER_BOOST',
            ] as const
          ).map((modifier) => (
            <Button
              key={modifier}
              className={styles.bid_modifier_button}
              color={bidModifier === modifier ? 'primary' : 'secondary'}
              onClick={() =>
                dispatch(
                  updateDraft({
                    bid_modifier: modifier,
                  })
                )
              }
              variant={bidModifier === modifier ? 'contained' : 'outlined'}
              disableRipple
            >
              {t(modifier.toLocaleLowerCase())}
            </Button>
          ))}
        </ButtonGroup>
      </AccordionDetails>
    </Accordion>
  );
};
