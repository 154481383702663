import { InputS } from './Form.style';
import { FormLabel } from './FormLabel';
import { TextInputProps } from './TextInput';

export const ValueLabel: React.FC<TextInputProps> = (props) => {
  const { label, className, value, id, width, children, sx, ...rest } = props;
  return (
    <InputS className={className} width={width} sx={sx}>
      <FormLabel id={id} label={label} required={rest.required} />
      <span data-testid={id} id={id}>
        {children}
      </span>
    </InputS>
  );
};
