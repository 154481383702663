import { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { tkPrimaryKpiFieldLabelForChangeLogRecord } from 'i18n/translationKeyMaps';
import { COLLAPSED_VIEW_MAX_ITEM_COUNT } from 'shared/constants';
import { definitions } from 'types/api';

import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import { CampaignSectionWrapper } from '../CampaignSectionWrapper';
import styles from './ChangeLog.module.scss';
import { ChangeLogRow } from './ChangeLogRow/ChangeLogRow';
import { useChangeLogRequest } from './hooks/useChangeLogRequest';

interface ChangeLogProps {
  campaignId: string;
  campaignType: definitions['CampaignType'];
}

export const ChangeLog: FC<ChangeLogProps> = (
  props: ChangeLogProps
): ReactElement => {
  const { t } = useTranslation();
  const { campaignId, campaignType } = props;
  const [showAllRows, setShowAllRows] = useState<boolean>(false);
  const [displayedRows, setDisplayedRows] = useState<
    definitions['ChangeLogRow'][]
  >([]);

  const changeLogs = useChangeLogRequest(campaignId);

  useEffect(() => {
    if (changeLogs) {
      setDisplayedRows(
        (showAllRows
          ? changeLogs
          : changeLogs?.slice(0, COLLAPSED_VIEW_MAX_ITEM_COUNT)) || []
      );
    }
  }, [showAllRows, changeLogs]);

  return (
    <>
      {displayedRows?.length > 0 && (
        <CampaignSectionWrapper
          header={t('changelog')}
          subHeader={t('subheader_campaign_changelog')}
        >
          <Grid container>
            <Grid item xs={12} className={styles.result_count}>
              <Typography variant="h3">{t('entries')}</Typography>
              <Typography variant="h3" color="textSecondary">
                ({changeLogs?.length || 0})
              </Typography>
            </Grid>

            <Grid item xs={12} className={styles.changelog_rows_wrapper}>
              {displayedRows?.map(
                (changeLogRow: definitions['ChangeLogRow']) => (
                  <div
                    className={styles.change_log_row_item}
                    key={changeLogRow?.timestamp}
                  >
                    <ChangeLogRow
                      changeLogRow={changeLogRow}
                      primaryKpiFieldLabel={tkPrimaryKpiFieldLabelForChangeLogRecord(
                        campaignType
                      )}
                    />
                  </div>
                )
              )}
            </Grid>
            {(changeLogs?.length || 0) > COLLAPSED_VIEW_MAX_ITEM_COUNT && (
              <Grid
                item
                xs={12}
                className={styles.items_limiter_toggle_wrapper}
              >
                <span
                  className={styles.items_limiter_toggle}
                  onClick={() =>
                    setShowAllRows((prevState: boolean) => !prevState)
                  }
                >
                  {showAllRows ? t('show_less') : t('show_more')}
                </span>
              </Grid>
            )}
          </Grid>
        </CampaignSectionWrapper>
      )}
    </>
  );
};
