import { Endpoints } from 'types';
import { definitions } from 'types/api';

import { baseApi } from '../baseApi';

/**
 * Enhance baseApi with tags that we use only in user management
 */
const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['UserList'],
});

// inject user management endpoints to the enhancedBaseApiWithTags
export const userManagementApi = enhancedBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllUser: builder.query<definitions['User'][], string>({
      query: (advertiserId: string) => ({
        url: Endpoints.users,
        params: {
          advertiser_id: advertiserId,
        },
      }),
      transformResponse: (response: definitions['GetAllUsersResponse']) => {
        return response.users;
      },
      providesTags: ['UserList'],
    }),
    addUser: builder.mutation<definitions['User'], definitions['User']>({
      query: (addUserPayload: definitions['User']) => ({
        url: Endpoints.users,
        method: 'POST',
        body: addUserPayload,
      }),
      transformResponse: (response: definitions['CreateUserResponse']) => {
        return response.user;
      },
      invalidatesTags: ['UserList'],
    }),
    deleteUser: builder.mutation<string, string>({
      query: (userId: string) => ({
        url: `${Endpoints.users}/${userId}`,
        method: 'DELETE',
      }),
      transformResponse: (_response: string, _meta, args) => {
        return args;
      },
      invalidatesTags: ['UserList'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllUserQuery, useAddUserMutation, useDeleteUserMutation } =
  userManagementApi;
