import { Grid, Typography } from '@mui/material';

import { ImgS, PaperS, TypographyS } from './SelectCampaignType.style';

type CampaignTypeTileProps = {
  campaignType: string;
  description: string;
  isSelected: boolean;
  imgPath: string;
  handleClick: () => void;
};

const CampaignTypeTile = (props: CampaignTypeTileProps) => {
  const { campaignType, description, isSelected, handleClick, imgPath } = props;
  return (
    <Grid item xs={11}>
      <PaperS selected={isSelected} onClick={handleClick}>
        <Typography variant="h3">{campaignType}</Typography>
        <ImgS src={imgPath} alt={campaignType} />
        <TypographyS variant="body1">{description}</TypographyS>
      </PaperS>
    </Grid>
  );
};

export default CampaignTypeTile;
