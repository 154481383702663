import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as RightChevronIcon } from 'assets/icons/chevron-right.svg';
import { definitions } from 'types/api';
import { toDateTime } from 'utils/time';

import { Routes } from 'routes/Routes';

import { ActionStateType } from 'hooks/useCampaignGroupActionState';

import { Card, Divider, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import Link from '@mui/material/Link';

import styles from './CampaignGroupOverview.module.scss';

type Props = {
  campaignGroup?: definitions['CampaignGroup'];
  formActionsState: ActionStateType;
} & HTMLAttributes<HTMLDivElement>;

export const CampaignGroupOverview = ({
  campaignGroup,
  children,
  formActionsState,
}: Props) => {
  const { t } = useTranslation();

  const hasChildren =
    React.Children.toArray(children)?.filter(Boolean).length > 0;

  return (
    <Card className={styles.overview_card}>
      <Grid container className={styles.overview_card__title}>
        <Grid item xs={12}>
          <Typography variant="h3">
            {t('campaign_group_overview_title')}
          </Typography>
        </Grid>
        {
          <Grid item xs={12}>
            <Typography variant="body2" color={'textSecondary'}>
              {`${t('last_modified')}:  ${
                campaignGroup?.updated_at
                  ? toDateTime(campaignGroup?.updated_at)
                  : '-'
              }`}
            </Typography>
            <Typography variant="body2" color={'textSecondary'}>
              {`${t('by')}: ${campaignGroup?.updated_by || '-'}`}
            </Typography>
          </Grid>
        }
        {formActionsState['ReportingLink'] === 'Enabled' && (
          <Grid item xs={12} className={styles.overview_card__reporting}>
            <Link
              data-testid="campaign-group-reporting-link"
              component={RouterLink}
              to={`${Routes.REPORTING}?campaign_group=${campaignGroup?.group_id}`}
            >
              <span>{t('reporting')}</span>
              <SvgIcon component={RightChevronIcon} viewBox="0 0 32 32" />
            </Link>
          </Grid>
        )}
        <Grid item xs={12} className={styles.overview_card__info}>
          <Typography color={'textSecondary'}>
            {formActionsState['Delete'] !== 'Hidden' &&
              t('campaign_group_overview_hint_for_deleting')}
            {formActionsState['Save'] !== 'Hidden' &&
              t('campaign_group_overview_hint_for_saving')}
          </Typography>
        </Grid>
      </Grid>
      {hasChildren && (
        <>
          <Divider />
          <Stack
            className={styles.action_bar}
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            {/* Bottom action buttons will be passed as children based on the state/condition from campaign group page */}
            {children}
          </Stack>
        </>
      )}
    </Card>
  );
};
