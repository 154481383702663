import { styled } from 'theme';

import Grid from '@mui/material/Grid';

export const CHART_HEIGHT = 480;
export const RANGE_FILTER_HEIGHT = 55;

export const ContainerS = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
}));

export const ControlBarS = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  '& .MuiButton-outlined, & .MuiButton-contained': {
    fontSize: 12,
    height: 30,
    minWidth: 30,
    marginRight: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    width: 30,
  },
  '& .MuiIconButton-root': {
    marginLeft: theme.spacing(0.25),
  },
}));

export const ChartWrapperS = styled('div')(({ theme }) => ({
  position: 'relative',
  height: CHART_HEIGHT + RANGE_FILTER_HEIGHT + 24,
  '& .overlay': {
    alignItems: 'center',
    background: 'rgba(255,255,255,0.9)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}));

export const ChartS = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginBottom: 24,
}));

export const RangeFilterS = styled('div')(({ theme }) => ({
  height: RANGE_FILTER_HEIGHT,
}));
