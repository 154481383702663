import { ReactNode } from 'react';

import { ExtendedCampaignGroup } from 'shared/interfaces';

import { ReportDataType } from 'components/Reporting/utils/utils';

import { Accordion, AccordionDetails } from '@mui/material';

import { CampaignGroupAccordionHeader } from '../CampaignGroupAccordionHeader/CampaignGroupAccordionHeader';
import styles from './CampaignGroupAccordion.module.scss';
import { CampaignGroupAccordionProvider } from './context/CampaignGroupAccordionContext';

export type SelectionType = {
  selected: boolean;
  onSelect: (reportData: ReportDataType) => void;
};

type Props = {
  campaignGroup: ExtendedCampaignGroup;
  expanded: boolean;
  children?: ReactNode;
  selection?: SelectionType;
  lazyLoadCount?: boolean;
  headerExpansionHandler: () => void;
};

export const CampaignGroupAccordion = ({
  campaignGroup,
  headerExpansionHandler,
  expanded = false,
  lazyLoadCount,
  selection,
  children,
}: Props) => {
  return (
    <CampaignGroupAccordionProvider>
      <Accordion
        className={styles.group_box}
        expanded={expanded}
        onChange={headerExpansionHandler}
        TransitionProps={{ unmountOnExit: true }}
      >
        <CampaignGroupAccordionHeader
          campaignGroup={campaignGroup}
          expanded={expanded}
          selection={selection}
          {...(!lazyLoadCount && {
            numberOfAllCampaigns: campaignGroup.number_of_campaigns || 0,
          })}
        />
        {campaignGroup.group_id && (
          <AccordionDetails className={styles.table_wrapper}>
            {children}
          </AccordionDetails>
        )}
      </Accordion>
    </CampaignGroupAccordionProvider>
  );
};
