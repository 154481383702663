import { AnyAction, combineReducers, Reducer } from 'redux';

import { RootState } from './';
import { baseApi } from './api/baseApi';
import campaigns from './modules/campaign/slice';
import notifications from './modules/notifications/slice';
import settings from './modules/settings/slice';
import user from './modules/user/slice';

export const combinedReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  notifications,
  campaigns,
  settings,
  user,
});

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  return combinedReducer(state, action);
};
