import { styled } from 'theme';

const AdvertiserS = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginRight: theme.spacing(2),
  textAlign: 'right',
  '& .MuiTypography-root': {
    fontSize: 13,
    lineHeight: '16px',
    '&:first-of-type': {
      fontWeight: 500,
    },
    '& .CreditLimit-root': {
      color: theme.palette.grey[700],
    },
    '& .CreditLimitAvailable-root': {
      color: theme.palette.success.main,
    },
  },
}));

export default AdvertiserS;
