import { ChangeEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from 'assets/icons/circle-information.svg';
import i18n from 'i18n';
import { ExtendedCampaign } from 'shared/interfaces';

import { useStoreDispatch } from 'store';
import { usePatchCampaignFieldsMutation } from 'store/api/endpoints/campaign';
import { MINIMUM_CAMPAIGN_BUDGET } from 'store/modules/campaign/validations';
import { addNotification } from 'store/modules/notifications/actions';

import { ErrorTooltipS, NumberInput } from 'components/Shared';

import { CircularProgress, IconButton, SvgIcon } from '@mui/material';

import styles from './InlineBudgetInput.module.scss';

interface Props {
  campaignTouched: boolean;
  campaign: ExtendedCampaign;
  onBlur: () => void;
}

export const InlineBudgetInput = ({
  onBlur,
  campaign,
  campaignTouched,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [budget, setBudget] = useState(campaign.budget);

  const budgetLimit =
    Number(campaign?.used_budget_total_cumulative || 0) +
    MINIMUM_CAMPAIGN_BUDGET;

  const isBudgetValid =
    Number(budget) >=
    MINIMUM_CAMPAIGN_BUDGET +
      Number(campaign?.used_budget_total_cumulative || 0);

  const isCampaignStopped = campaign?.state === 'CAMPAIGN_STATE_STOPPED';

  const showError = campaignTouched && !isCampaignStopped && !isBudgetValid;

  const [patchCampaignFields, { isLoading }] = usePatchCampaignFieldsMutation();

  const updateBudget = async (): Promise<void> => {
    const patchCampaignResult = await patchCampaignFields({
      id: campaign.id,
      budget: budget,
    }).unwrap();
    if (patchCampaignResult) {
      dispatch(
        addNotification({
          text: t('budget_successfully_saved'),
          type: 'success',
        })
      );
    }
  };

  return (
    <div
      className={styles.budget_input_wrapper}
      onClick={(event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
      }}
    >
      <NumberInput
        width={130}
        id="budget"
        disabled={isCampaignStopped}
        sx={{
          marginBottom: (theme) => theme.spacing(0.5),
        }}
        error={showError}
        prefix={campaign?.currency?.symbol}
        value={budget}
        onBlur={() => {
          onBlur();
          if (isBudgetValid && budget !== campaign.budget) updateBudget();
        }}
        decimalScale={2}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setBudget(event.target.value);
        }}
        suffixIcon={
          showError ? (
            <IconButton edge="end">
              <ErrorTooltipS
                color={'error'}
                open={true}
                placement="right"
                title={
                  <div
                    style={{
                      whiteSpace: 'pre-line',
                      // Reduce font size when number reaches millions or more than 6 digits
                      fontSize: budgetLimit.toString().length > 6 ? 11 : 12,
                    }}
                  >
                    {i18n.t('V MIN_BUDGET', {
                      currencySymbol: campaign?.currency?.symbol,
                      budget: budgetLimit.toFixed(2),
                    })}
                  </div>
                }
              >
                <SvgIcon
                  color={'error'}
                  component={InfoIcon}
                  viewBox={'0 0 32 32'}
                />
              </ErrorTooltipS>
            </IconButton>
          ) : isLoading ? (
            <CircularProgress size={12} />
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};
