import { styled } from 'theme';

import { Box } from '@mui/system';

export const GreyS = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  background: theme.palette.grey[100],
  alignItems: 'flex-start',
  borderRadius: theme.shape.borderRadius,
  border: `1px dashed ${theme.palette.grey[200]}`,

  '& .MuiButton-root': {
    alignSelf: 'center',
  },
}));
