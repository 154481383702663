import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { DraftUniverse } from 'shared/interfaces';

import { Routes } from 'routes/Routes';

import { PageHeader } from 'components/Shared';

interface Props {
  universe: DraftUniverse;
}

export const UniversePageHeader = ({ universe }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const getHeaderText = (): string => {
    if (universe?.build?.type === 'UNIVERSE_BUILD_TYPE_BUILDER')
      return t(universe.id ? 'edit_universe' : 'create_universe');
    else if (universe?.build?.type === 'UNIVERSE_BUILD_TYPE_IMPORT')
      return t('upload_universe');
    return '';
  };

  const isBuilderType = universe?.build?.type === 'UNIVERSE_BUILD_TYPE_BUILDER';

  return (
    <>
      <PageHeader
        headerText={getHeaderText()}
        subHeaderText={t(
          isBuilderType
            ? 'subheader_edit_universe'
            : 'subheader_import_universe'
        )}
        parentLinkText={t('back').toUpperCase()}
        parentLink={location.key === 'default' ? Routes.UNIVERSES : -1}
      />
    </>
  );
};
