// references:
// https://docs.google.com/document/d/1xDRb_pTxwTkhvw42iPrH3900EBx6xBtwaI6fqGKLFOw/edit#
// https://www.figma.com/file/7vTNjVwNHoAmSl0gYYNWjr/SMP-%E2%80%93-UX?node-id=2834%3A45504
import { UserRole } from 'types';
import { definitions } from 'types/api';

export type CampaignFormActionType =
  | 'save'
  | 'delete'
  | 'submit'
  | 'reject'
  | 'approve'
  | 'pause'
  | 'resume'
  | 'stop'
  | 'clone'
  | 'withdraw';

const campaignActionsMappings: {
  [key in UserRole]: {
    [key in Partial<definitions['CampaignState']>]?: CampaignFormActionType[];
  };
} = {
  [UserRole.ADVERTISER_ADMIN]: {
    CAMPAIGN_STATE_UNKNOWN: [],
    CAMPAIGN_STATE_STOPPED: ['clone'],
    CAMPAIGN_STATE_DELETED: [],
    CAMPAIGN_STATE_SUBMITTED: ['clone', 'save', 'withdraw'],
    CAMPAIGN_STATE_APPROVED: ['pause', 'clone'],
    CAMPAIGN_STATE_RUNNING: ['save', 'pause', 'clone'],
    CAMPAIGN_STATE_DRAFT: ['delete', 'save', 'submit', 'clone'],
    CAMPAIGN_STATE_REJECTED: ['delete', 'save', 'submit', 'clone'],
    CAMPAIGN_STATE_PAUSED_MANUAL: ['save', 'resume', 'clone', 'stop'],
    CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED: ['pause', 'save', 'clone'],
    CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE: ['pause', 'save', 'clone'],
  },
  [UserRole.ADVERTISER_CAMPAIGN_MANAGER]: {
    CAMPAIGN_STATE_UNKNOWN: [],
    CAMPAIGN_STATE_STOPPED: ['clone'],
    CAMPAIGN_STATE_DELETED: [],
    CAMPAIGN_STATE_SUBMITTED: ['clone', 'save', 'withdraw'],
    CAMPAIGN_STATE_APPROVED: ['pause', 'clone'],
    CAMPAIGN_STATE_RUNNING: ['save', 'pause', 'clone'],
    CAMPAIGN_STATE_DRAFT: ['delete', 'save', 'submit', 'clone'],
    CAMPAIGN_STATE_REJECTED: ['delete', 'save', 'submit', 'clone'],
    CAMPAIGN_STATE_PAUSED_MANUAL: ['save', 'resume', 'clone', 'stop'],
    CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED: ['pause', 'save', 'clone'],
    CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE: ['pause', 'save', 'clone'],
  },
  [UserRole.KEY_ACCOUNT_MANAGER]: {
    CAMPAIGN_STATE_UNKNOWN: [],
    CAMPAIGN_STATE_STOPPED: ['clone'],
    CAMPAIGN_STATE_DELETED: [],
    CAMPAIGN_STATE_SUBMITTED: ['clone', 'save', 'withdraw'],
    CAMPAIGN_STATE_APPROVED: ['pause', 'clone'],
    CAMPAIGN_STATE_RUNNING: ['save', 'pause', 'clone'],
    CAMPAIGN_STATE_DRAFT: ['delete', 'save', 'submit', 'clone'],
    CAMPAIGN_STATE_REJECTED: ['delete', 'save', 'submit', 'clone'],
    CAMPAIGN_STATE_PAUSED_MANUAL: ['save', 'resume', 'clone', 'stop'],
    CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED: ['pause', 'save', 'clone'],
    CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE: ['pause', 'save', 'clone'],
  },
  [UserRole.APPROVAL_MANAGER]: {
    CAMPAIGN_STATE_SUBMITTED: ['approve', 'reject'],
    CAMPAIGN_STATE_APPROVED: ['save'],
    CAMPAIGN_STATE_RUNNING: ['save'],
    CAMPAIGN_STATE_PAUSED_MANUAL: ['save'],
    CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED: ['save'],
    CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE: ['save'],
  },
  [UserRole.SALESFORCE_MANAGER]: {
    CAMPAIGN_STATE_SUBMITTED: ['save'],
    CAMPAIGN_STATE_APPROVED: ['save'],
    CAMPAIGN_STATE_RUNNING: ['save'],
    CAMPAIGN_STATE_PAUSED_MANUAL: ['save'],
    CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED: ['save'],
    CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE: ['save'],
  },
  [UserRole.STAFF]: {},
};

export const getFormActions = (
  userRole: UserRole,
  campaignState: definitions['CampaignState']
): CampaignFormActionType[] => {
  return campaignActionsMappings[userRole][campaignState] || [];
};
