import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FrequencyCapLimitTimeUnit } from 'types';
import { definitions } from 'types/api';

import { useStoreDispatch } from 'store';
import {
  setDraftTouchedFields,
  updateDraft,
} from 'store/modules/campaign/slice';

import { CampaignSectionProps } from 'components/Campaigns/CampaignDetails/CampaignDetails';
import { NumberInput, SelectInput } from 'components/Shared/CustomMui';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { CampaignSectionWrapper } from '../CampaignSectionWrapper';
import styles from './FrequencyCap.module.scss';

interface Props extends CampaignSectionProps {
  businessRuleLimit: definitions['BusinessRuleLimit'][];
}

const FrequencyCap: FC<Props> = (props: Props) => {
  const { businessRuleLimit, formState } = props;
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const isCappingInputDisabled =
    !businessRuleLimit.length ||
    formState?.readonlyFields?.includes('FrequencyCapValue');

  const isFrequencyCapModeSelectionDisabled =
    formState.readonlyFields.includes('FrequencyCap');

  const isFrequencyCapTimeUnitDisabled =
    formState.readonlyFields.includes('FrequencyCapTimeUnit') ||
    isCappingInputDisabled;

  const [views, setViews] = useState<number>(
    businessRuleLimit.length ? businessRuleLimit[0].value : 1
  );

  /**
   * Frequency cap (business rule limit) can accept multiple entries in an array as a future-proof,
   * but only one entry is considered at the moment as we have one frequency cap per Campaign.
   */
  const [frequencyCap, setFrequencyCap] =
    useState<definitions['BusinessRuleLimit'][]>(businessRuleLimit);

  useEffect(() => {
    dispatch(setDraftTouchedFields('business_rules_limit'));
    dispatch(
      updateDraft({
        business_rules_limit: frequencyCap,
      })
    );
  }, [frequencyCap]);

  /**
   * Update frequency cap views per number of DAYS/WEEKS/MONTHS or default Campaign duration.
   */

  useEffect(() => {
    if (frequencyCap.length) {
      setFrequencyCap([
        {
          ...frequencyCap[0],
          value: views,
        },
      ]);
    }
  }, [views]);

  /**
   * Toggle between uncapped and capped frequency.
   * Empty array represents uncapped frequency in BE.
   */
  const handleEnableCappingInput = (event: ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value === 'true') {
      setFrequencyCap([
        {
          value: views,
          limit_time_unit: 'BR_LIMIT_TIME_UNIT_CAMPAIGN_DURATION',
        },
      ]);
    } else {
      setFrequencyCap([]);
      setViews(businessRuleLimit.length ? businessRuleLimit[0].value : 1);
    }
  };

  return (
    <CampaignSectionWrapper
      className={styles.frequency_cap}
      header={t('frequency_cap')}
      subHeader={t('subheader_frequency_cap')}
    >
      <div className={styles.frequency_cap__toggle}>
        <FormControl
          data-testid={'fc-capped'}
          disabled={isFrequencyCapModeSelectionDisabled}
        >
          <RadioGroup
            value={!!businessRuleLimit?.length}
            onChange={handleEnableCappingInput}
          >
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={t<string>('frequency_cap_default')}
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={t<string>('set_frequency_cap')}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className={styles.frequency_cap__settings}>
        <Typography
          variant="body1"
          sx={{
            fontSize: (theme) => theme.typography.body1.fontSize,
          }}
        >
          {t('frequency_cap_views')}
        </Typography>
        <NumberInput
          id="views"
          data-testid={'fc-views'}
          value={views}
          decimalScale={0}
          width={80}
          onChange={(e: SelectChangeEvent<string>) => setViews(+e.target.value)}
          disabled={isCappingInputDisabled}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: (theme) => theme.typography.body1.fontSize,
          }}
        >
          {t('frequency_cap_time_unit')}
        </Typography>
        <SelectInput
          data-testid={'fc-time-unit'}
          width={'100%'}
          label={''}
          value={
            frequencyCap.length
              ? frequencyCap[0].limit_time_unit
              : FrequencyCapLimitTimeUnit['Campaign Duration']
          }
          items={Object.keys(FrequencyCapLimitTimeUnit).map(
            (limitTimeUnit) => ({
              label: limitTimeUnit,
              value:
                FrequencyCapLimitTimeUnit[
                  limitTimeUnit as keyof typeof FrequencyCapLimitTimeUnit
                ],
              disabled: false,
            })
          )}
          onChange={(e: SelectChangeEvent<string>) =>
            setFrequencyCap([
              {
                ...frequencyCap[0],
                limit_time_unit: e.target.value as FrequencyCapLimitTimeUnit,
              },
            ])
          }
          required={false}
          disabled={isFrequencyCapTimeUnitDisabled}
        />
      </div>
    </CampaignSectionWrapper>
  );
};

export default FrequencyCap;
