import { useCallback, useEffect, useState } from 'react';

interface Props {
  checkWithExternalService: boolean;
}

interface Results {
  detected: boolean;
  check: () => Promise<void>;
}

export const useAdBlockDetector = ({
  checkWithExternalService = false,
}: Props): Results => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  const checkAdBlocker = useCallback(async () => {
    // first check the online status of the browser.
    const isOnline = typeof navigator !== 'undefined' && navigator.onLine;

    // create a dummy request using a dummy advertiser id
    const internal_ad_url = '/advertisers/0';
    const external_ad_url =
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

    if (!isOnline) {
      return;
    }

    try {
      await fetch(
        checkWithExternalService ? external_ad_url : internal_ad_url,
        {
          method: 'HEAD',
          mode: 'no-cors',
        }
      );
      setAdBlockDetected(false);
    } catch (e) {
      setAdBlockDetected(true);
    }
  }, [checkWithExternalService]);

  useEffect(() => {
    checkAdBlocker();
  }, [checkAdBlocker]);

  return {
    detected: adBlockDetected,
    check: checkAdBlocker,
  };
};
