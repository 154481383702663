import { FormHelperText } from '@mui/material';

export const FormError: React.FC = ({ children }) => (
  <FormHelperText
    className="FormError-root"
    error
    sx={{
      fontSize: 12,
      marginTop: (theme) => theme.spacing(0),
      marginBottom: (theme) => theme.spacing(0.5),
    }}
  >
    {children}
  </FormHelperText>
);
