import { ExtendedCampaign, ExtendedCampaignGroup } from 'shared/interfaces';
import { UserRole } from 'types';
import { definitions } from 'types/api';

export type ReportObjectType = 'Campaign' | 'CampaignGroup';

export type ReportDataType = {
  id: string;
  name: string;
  objectType: ReportObjectType;
  areMultipleCurrenciesInvolved: boolean;
} & Partial<ExtendedCampaign> &
  ExtendedCampaignGroup;

export const GroupMetrics = [
  'group_impressions',
  'group_views',
  'group_clicks',
  'group_shoppers_reached',
  'group_units_purchased',
  'group_revenue',
  'group_used_budget',
] as const;

export const CampaignMetrics = [
  'impressions',
  'views',
  'clicks',
  'shoppers_reached',
  'units_purchased',
  'revenue',
  'average_cpm',
  'average_cpa',
  'average_cpc',
  'used_budget',
] as const;

export const AllMetrics = [...CampaignMetrics, ...GroupMetrics];

export const ALL_PRICE_METRICS = [
  'revenue',
  'used_budget',
  'average_cpm',
  'average_cpa',
  'average_cpc',
  'group_revenue',
  'group_used_budget',
];

export type MetricType = typeof AllMetrics[number];

export const metricTypeToFieldNameMapping: {
  [key in MetricType]: {
    metricField: string;
    cumulativeField:
      | keyof Omit<
          definitions['CumulativeMetric'],
          'campaign_id' | 'campaign_group_id'
        >
      | 'average_cpm'
      | 'average_cpa'
      | 'average_cpc';
    operatorForCumulativeCalculation: 'sum' | 'average' | 'static_total';
    translationKey: `reporting_${Lowercase<key>}_description`;
  };
} = {
  impressions: {
    metricField: 'impressions_by_day',
    cumulativeField: 'number_of_impressions_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_impressions_description',
  },
  used_budget: {
    metricField: 'budget_spent_by_day',
    cumulativeField: 'used_budget_total_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_used_budget_description',
  },
  revenue: {
    metricField: 'revenue_by_day',
    cumulativeField: 'revenue_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_revenue_description',
  },
  shoppers_reached: {
    metricField: 'shoppers_reached_by_day',
    cumulativeField: 'shoppers_reached_cumulative',
    operatorForCumulativeCalculation: 'static_total', // PACMAN-1437
    translationKey: 'reporting_shoppers_reached_description',
  },
  units_purchased: {
    metricField: 'units_purchased_by_day',
    cumulativeField: 'units_purchased_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_units_purchased_description',
  },
  average_cpa: {
    metricField: 'average_cpa_by_day',
    cumulativeField: 'average_cpa',
    operatorForCumulativeCalculation: 'average',
    translationKey: 'reporting_average_cpa_description',
  },
  average_cpm: {
    metricField: 'average_cpm_by_day',
    cumulativeField: 'average_cpm',
    operatorForCumulativeCalculation: 'average',
    translationKey: 'reporting_average_cpm_description',
  },
  views: {
    metricField: 'views_by_day',
    cumulativeField: 'number_of_views_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_views_description',
  },
  average_cpc: {
    metricField: 'average_cpc_by_day',
    cumulativeField: 'average_cpc',
    operatorForCumulativeCalculation: 'average',
    translationKey: 'reporting_average_cpc_description',
  },
  clicks: {
    metricField: 'clicks_by_day',
    cumulativeField: 'number_of_clicks_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_clicks_description',
  },
  group_revenue: {
    metricField: 'revenue_by_day',
    cumulativeField: 'revenue_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_group_revenue_description',
  },
  group_shoppers_reached: {
    metricField: 'shoppers_reached_by_day',
    cumulativeField: 'shoppers_reached_cumulative',
    operatorForCumulativeCalculation: 'static_total', // PACMAN-1437
    translationKey: 'reporting_group_shoppers_reached_description',
  },
  group_units_purchased: {
    metricField: 'units_purchased_by_day',
    cumulativeField: 'units_purchased_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_group_units_purchased_description',
  },
  group_used_budget: {
    metricField: 'budget_spent_by_day',
    cumulativeField: 'used_budget_total_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_group_used_budget_description',
  },
  group_impressions: {
    metricField: 'impressions_by_day',
    cumulativeField: 'number_of_impressions_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_group_impressions_description',
  },
  group_views: {
    metricField: 'views_by_day',
    cumulativeField: 'number_of_views_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_group_views_description',
  },
  group_clicks: {
    metricField: 'clicks_by_day',
    cumulativeField: 'number_of_clicks_cumulative',
    operatorForCumulativeCalculation: 'sum',
    translationKey: 'reporting_group_clicks_description',
  },
};

export const selectMetricTimeSeriesData = (
  timeSeries: definitions['ByDayTimeSeries'] | undefined,
  selectedMetric: MetricType
) => {
  if (!timeSeries) return [];

  switch (selectedMetric) {
    case 'impressions':
    case 'group_impressions':
      return timeSeries.impressions_by_day || [];
    case 'revenue':
    case 'group_revenue':
      return timeSeries.revenue_by_day || [];
    case 'used_budget':
    case 'group_used_budget':
      return timeSeries.budget_spent_by_day || [];
    case 'average_cpm':
      return timeSeries.average_cpm_by_day || [];
    case 'average_cpa':
      return timeSeries.average_cpa_by_day || [];
    case 'average_cpc':
      return timeSeries.average_cpc_by_day || [];
    case 'units_purchased':
    case 'group_units_purchased':
      return timeSeries.units_purchased_by_day || [];
    case 'shoppers_reached':
    case 'group_shoppers_reached':
      return timeSeries.shoppers_reached_by_day || [];
    case 'views':
    case 'group_views':
      return timeSeries.views_by_day || [];
    case 'clicks':
    case 'group_clicks':
      return timeSeries.clicks_by_day || [];
  }
};

export const selectAverageValue = (
  data: definitions['ByDayTimeSeries'] | undefined,
  selectedMetric: 'average_cpm' | 'average_cpa'
): number => {
  const selectedData = selectMetricTimeSeriesData(data, selectedMetric);

  if (selectedData?.length) {
    const sum = selectedData
      .map((d) => +(d.value || 0))
      .reduce((a, b) => a + b, 0);

    return sum / selectedData.length || 0;
  } else {
    return 0;
  }
};

export const filterOutEmptyMetrics = (
  campaignMetrics: definitions['ByDayTimeSeries']
): definitions['ByDayTimeSeries'] => {
  const transformedObject: definitions['ByDayTimeSeries'] = {
    ...campaignMetrics,
  };
  if (Array.isArray(transformedObject.average_cpa_by_day)) {
    transformedObject.average_cpa_by_day =
      campaignMetrics?.average_cpa_by_day?.filter(
        (timeSeriesValue) => !!Number(timeSeriesValue.value)
      );
  }
  if (Array.isArray(transformedObject.average_cpm_by_day)) {
    transformedObject.average_cpm_by_day =
      campaignMetrics?.average_cpm_by_day?.filter(
        (timeSeriesValue) => !!Number(timeSeriesValue.value)
      );
  }
  if (Array.isArray(transformedObject.average_cpc_by_day)) {
    transformedObject.average_cpc_by_day =
      campaignMetrics?.average_cpc_by_day?.filter(
        (timeSeriesValue) => !!Number(timeSeriesValue.value)
      );
  }

  return transformedObject;
};

// https://schwarz-media-platform.atlassian.net/browse/PACMAN-1187
const canDisplayGroupTotalMetricsForUser = (userRole: UserRole): boolean => {
  return ![
    UserRole.ADVERTISER_ADMIN,
    UserRole.ADVERTISER_CAMPAIGN_MANAGER,
  ].includes(userRole);
};

// https://schwarz-media-platform.atlassian.net/browse/PACMAN-1187
export const filterGroupTotalMetricsBasedOnRole = (
  campaignGroupMetrics: ExtendedCampaignGroup,
  userRole: UserRole
): ExtendedCampaignGroup => {
  return {
    ...campaignGroupMetrics,
    number_of_views_cumulative: canDisplayGroupTotalMetricsForUser(userRole)
      ? campaignGroupMetrics?.number_of_views_cumulative
      : '-',
    number_of_clicks_cumulative: canDisplayGroupTotalMetricsForUser(userRole)
      ? campaignGroupMetrics?.number_of_clicks_cumulative
      : '-',
    number_of_impressions_cumulative: canDisplayGroupTotalMetricsForUser(
      userRole
    )
      ? campaignGroupMetrics?.number_of_impressions_cumulative
      : '-',
  };
};
