import { createSlice } from '@reduxjs/toolkit';
import { AuthConfig } from 'shared/interfaces';
import { definitions } from 'types/api';

import { settingsApi } from 'store/api/endpoints/settings';

import { setAuthConfig } from './actions';

interface State {
  advertiser: null | definitions['Advertiser'];
  channels: definitions['Channel'][];
  countries: definitions['Country'][];
  loaded: boolean;
  authConfig: AuthConfig;
}

const initialState: State = {
  advertiser: null,
  channels: [],
  countries: [],
  loaded: false,
  authConfig: { client_id: '', authority: '' },
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAuthConfig, (state, action) => {
        state.authConfig = action.payload;
      })
      .addMatcher(settingsApi.endpoints.getAdvertiser.matchPending, (state) => {
        state.loaded = false;
      })
      .addMatcher(
        settingsApi.endpoints.getAdvertiser.matchRejected,
        (state) => {
          state.loaded = true;
        }
      )
      .addMatcher(
        settingsApi.endpoints.getAdvertiser.matchFulfilled,
        (state, { payload }) => {
          state.advertiser = payload.advertiser;
          state.loaded = true;
        }
      )
      .addMatcher(settingsApi.endpoints.getChannels.matchPending, (state) => {
        state.loaded = false;
      })
      .addMatcher(settingsApi.endpoints.getChannels.matchRejected, (state) => {
        state.loaded = true;
      })
      .addMatcher(
        settingsApi.endpoints.getChannels.matchFulfilled,
        (state, { payload }) => {
          state.channels = payload.channels;
          state.loaded = true;
        }
      )
      .addMatcher(
        settingsApi.endpoints.getAllCountries.matchPending,
        (state) => {
          state.loaded = false;
        }
      )
      .addMatcher(
        settingsApi.endpoints.getAllCountries.matchRejected,
        (state) => {
          state.loaded = true;
        }
      )
      .addMatcher(
        settingsApi.endpoints.getAllCountries.matchFulfilled,
        (state, { payload }) => {
          state.countries = payload.countries;
        }
      );
  },
});

export default slice.reducer;
