import React, { MutableRefObject } from 'react';

import classnames from 'classnames';

import {
  CellS,
  HeaderRowS,
  RootS,
  RowS,
} from 'components/Shared/CustomMui/Table/Table.style';

import { Radio, TableFooter } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import { Theme } from '@mui/system';

import { Checkboxes, Column, Pagination, Row } from './types';

const HEADER_CELL_HEIGHT = 46;
const CELL_HEIGHT = 42;

interface Props {
  checkboxes?: Checkboxes;
  className?: string;
  columns: Column[];
  groupMetricHeaderColumns?: Column[];
  groupMetricHeaderRow?: Row;
  rows: Row[];
  onRowClick?: (id: string) => void;
  minHeight?: number; // number of rows
  pagination?: null | false | Pagination;
  emptyState: React.ReactNode;
  footerRef?: MutableRefObject<null>;
  selectionType?: 'Radio' | 'RowClick';
}

export const CustomTable: React.FC<Props> = (props) => {
  const {
    checkboxes,
    className,
    columns,
    groupMetricHeaderColumns,
    groupMetricHeaderRow,
    rows,
    minHeight,
    pagination,
    onRowClick,
    emptyState,
    footerRef,
    selectionType = 'RowClick',
  } = props;
  const noneSelected = rows.every((r) => !r.selected);
  const allSelected = rows.every((r) => r.selected);
  return (
    <RootS
      className={classnames(className, { 'no-content': !rows?.length })}
      minHeight={minHeight}
      paginationDisabled={(!!pagination && pagination?.disabled) || false}
    >
      <TableContainer>
        <Table component="div">
          <TableHead component="div">
            <HeaderRowS
              // @ts-ignore
              component="div"
            >
              {selectionType === 'Radio' && (
                <CellS
                  component="div"
                  height={HEADER_CELL_HEIGHT}
                  width={54}
                  scope="row"
                  className="sticky-left"
                />
              )}
              {!!checkboxes && (
                <CellS
                  component="div"
                  height={HEADER_CELL_HEIGHT}
                  scope="row"
                  width={54}
                  className="sticky-left"
                >
                  <Checkbox
                    checked={allSelected}
                    indeterminate={!noneSelected && !allSelected}
                    onClick={(e) => {
                      e.stopPropagation();
                      checkboxes.onRootClick(
                        noneSelected,
                        rows.map((r) => r.id)
                      );
                    }}
                  />
                </CellS>
              )}
              {columns.map(
                ({ field, headerName, justify, width, key, sticky }) => (
                  <CellS
                    component="div"
                    height={HEADER_CELL_HEIGHT}
                    key={key || field}
                    sx={{
                      color: (theme: Theme) => theme.palette.text.secondary,
                      justifyContent: justify ? justify : 'flex-start',
                    }}
                    width={width}
                    className={
                      !!sticky
                        ? sticky === 'left'
                          ? 'sticky-left'
                          : 'sticky-right'
                        : ''
                    }
                  >
                    {headerName}
                  </CellS>
                )
              )}
            </HeaderRowS>
            {groupMetricHeaderRow && (
              <HeaderRowS
                // @ts-ignore
                component="div"
              >
                {selectionType === 'Radio' && (
                  <CellS
                    component="div"
                    height={HEADER_CELL_HEIGHT}
                    width={54}
                    scope="row"
                    className="sticky-left"
                  />
                )}
                {groupMetricHeaderColumns?.map(
                  ({
                    field,
                    headerName,
                    justify,
                    width,
                    key,
                    sticky,
                    renderCell,
                    useRootObject,
                  }) => (
                    <CellS
                      component="div"
                      height={HEADER_CELL_HEIGHT}
                      key={key || field}
                      sx={{
                        color: (theme: Theme) => theme.palette.secondary,
                        backgroundColor: (theme: Theme) =>
                          theme.palette.grey[50],
                        justifyContent: justify ? justify : 'flex-start',
                      }}
                      width={width}
                      className={
                        !!sticky
                          ? sticky === 'left'
                            ? 'sticky-left'
                            : 'sticky-right'
                          : ''
                      }
                    >
                      {groupMetricHeaderRow && renderCell ? (
                        renderCell(
                          useRootObject
                            ? groupMetricHeaderRow
                            : groupMetricHeaderRow[field]
                        )
                      ) : (
                        <span>{headerName}</span>
                      )}
                    </CellS>
                  )
                )}
              </HeaderRowS>
            )}
          </TableHead>
          <TableBody component="div">
            {!rows.length && (
              <div className="no-content-message">{emptyState}</div>
            )}
            {rows.map((row) => {
              const { id, selected = false } = row;
              return (
                <RowS
                  // @ts-ignore
                  component="div"
                  key={id}
                  onClick={() => !!onRowClick && onRowClick(id)}
                  rowClick={!!onRowClick}
                  selected={selected}
                >
                  {selectionType === 'Radio' && (
                    <CellS
                      component="div"
                      height={CELL_HEIGHT}
                      scope="row"
                      className="sticky-left"
                      width={54}
                    >
                      <Radio checked={selected} />
                    </CellS>
                  )}
                  {!!checkboxes && (
                    <CellS
                      component="div"
                      height={CELL_HEIGHT}
                      scope="row"
                      width={54}
                      className="sticky-left"
                    >
                      <Checkbox
                        checked={selected}
                        onClick={(e) => {
                          e.stopPropagation();
                          checkboxes.onClick(id);
                        }}
                      />
                    </CellS>
                  )}
                  {columns.map(
                    ({
                      field,
                      justify,
                      renderCell,
                      width,
                      key,
                      sticky,
                      useRootObject,
                    }) => (
                      <CellS
                        component="div"
                        height={CELL_HEIGHT}
                        key={key || field}
                        scope="row"
                        sx={{
                          color: (theme: Theme) => theme.palette.text.primary,
                          justifyContent: justify ? justify : 'flex-start',
                        }}
                        width={width}
                        className={
                          !!sticky
                            ? sticky === 'left'
                              ? 'sticky-left'
                              : 'sticky-right'
                            : ''
                        }
                      >
                        {renderCell ? (
                          renderCell(useRootObject ? row : row[field])
                        ) : (
                          <span>{row[field]}</span>
                        )}
                      </CellS>
                    )
                  )}
                </RowS>
              );
            })}
          </TableBody>
          <TableFooter component="div" ref={footerRef}></TableFooter>
        </Table>
      </TableContainer>
      {!!pagination && (
        <TablePagination
          component="div"
          count={pagination.results}
          onPageChange={(e, page) => pagination.onPageChange(e, page + 1)}
          page={rows.length ? pagination.page - 1 : 0}
          rowsPerPage={pagination.per_page}
          rowsPerPageOptions={[]}
        />
      )}
    </RootS>
  );
};
