import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down-small.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/chevron-up-small.svg';
import { ReactComponent as ViewIcon } from 'assets/icons/eye.svg';
import classNames from 'classnames';
import { definitions } from 'types/api';

import { PopperStep, SelectionBoxProps } from 'components/Universes/Rule/Rule';
import styles from 'components/Universes/Rule/Rule.module.scss';

import { Button } from '@mui/material';

interface Props extends SelectionBoxProps {
  selectedAttribute?: definitions['ShopperMetadata'];
  selectedMetadataIds?: string[];
  setPopperStep: (popperStep: PopperStep | null) => void;
}
export const SelectRuleAttributeValue = ({
  disabled,
  selected,
  readonly,
  hasError,
  setPopperStep,
  selectedAttribute,
  selectedMetadataIds,
}: Props) => {
  const { t } = useTranslation();

  const selectedMetadata =
    selectedMetadataIds?.map((selectedMetadataId: string) =>
      selectedAttribute?.shopper_metadata_values.find(
        (value) => value.metadata_id === selectedMetadataId
      )
    ) || [];

  const getAttributeValueText = (): ReactNode => {
    if (
      selectedMetadata?.length ===
      selectedAttribute?.shopper_metadata_values.length
    )
      return t('all');

    if (selectedMetadata?.length === 1)
      return selectedMetadata[0]?.attribute_value?.trim();

    if (selectedMetadata && selectedMetadata?.length > 1)
      return (
        <>
          {selectedMetadata[0]?.attribute_value?.trim()}
          <span className={styles.divider}> {t('or')} </span>
          {selectedMetadata.length - 1} {t('other_values')}
        </>
      );

    return t('select');
  };

  return (
    <Button
      id={PopperStep.ATTRIBUTE_VALUE}
      className={classNames(styles.block, {
        [styles.disabled]: disabled,
        [styles.selected]: selected,
        [styles.error]: hasError,
      })}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        setPopperStep(selected ? null : PopperStep.ATTRIBUTE_VALUE);
      }}
      endIcon={
        readonly ? (
          <ViewIcon className={styles.icon} />
        ) : selected ? (
          <ArrowUpIcon className={styles.icon} />
        ) : (
          <ArrowDownIcon className={styles.icon} />
        )
      }
    >
      <span data-testid="selected-text">{getAttributeValueText()}</span>
    </Button>
  );
};
