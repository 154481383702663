import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down-small.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import classnames from 'classnames';
import { upsertObjectInArray } from 'utils';

import { useListActionBar } from 'components/Shared/ListActionBar/hooks/useListActionBar';

import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
} from '@mui/material';

import styles from './Filter.module.scss';
import { EntityFilterType } from './types';

export interface SingleSelectFilterProps {
  showFilterIcon?: boolean;
  filterItem: EntityFilterType;
  onFilter: (values: string) => void;
}

export const SingleSelectFilter = ({
  onFilter,
  filterItem,
  showFilterIcon,
}: SingleSelectFilterProps) => {
  const { t } = useTranslation();
  const { selectedFilters, setSelectedFilters, setUpdatedFilters } =
    useListActionBar();
  const [open, setOpen] = useState<boolean>(false);

  const selectedFilterValue =
    selectedFilters
      .filter((x) => x.field.value === filterItem?.field.value)[0]
      ?.activeFilterValues?.map((x) => x.value)[0] || '';

  useEffect(() => {
    onFilter(selectedFilterValue);
  }, [selectedFilterValue]);

  const onChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;

    const selectedValuesWithLabel = {
      label: filterItem.filterValues.filter(
        (fv) => fv.value === selectedValue
      )[0].label,
      value: selectedValue,
    };

    const filters = upsertObjectInArray(
      [...selectedFilters],
      {
        field: filterItem.field,
        activeFilterValues: [selectedValuesWithLabel],
      },
      'field'
    );

    setSelectedFilters(filters);
    setUpdatedFilters(filters);
  };

  return (
    <Stack direction="row" spacing={2} className={styles.filtering_wrapper}>
      {showFilterIcon && (
        <SvgIcon
          component={FilterIcon}
          className={styles.icon}
          viewBox={'0 0 32 32'}
        />
      )}
      <Stack direction="row" spacing={2}>
        <Stack
          key={filterItem?.field.value}
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Select
            displayEmpty
            IconComponent={ArrowDownIcon}
            value={selectedFilterValue}
            onChange={onChange}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            className={classnames(styles.filter_select_field, {
              [styles.opened]: open,
            })}
            renderValue={() => t(filterItem?.field.label)}
            MenuProps={{
              classes: {
                list: styles.filter_list,
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            <MenuItem value={''} disabled={true}>
              {t('filter_by')}
            </MenuItem>
            {filterItem?.filterValues?.map((item) => (
              <MenuItem key={item.value} value={item.value} disableRipple>
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </Stack>
  );
};
