import { FeatureFlags } from 'utils/FeatureFlags';

import dev from './dev.json';
import local from './local.json';
import prod from './prod.json';
import stage from './stage.json';

enum ENVIRONMENTS {
  local = 'local',
  dev = 'dev',
  stage = 'stage',
  prod = 'prod',
}

export function getDisabledFeatureList(environment: string): FeatureFlags {
  switch (environment) {
    case ENVIRONMENTS.local:
      return local;
    case ENVIRONMENTS.dev:
      return dev;
    case ENVIRONMENTS.stage:
      return stage;
    case ENVIRONMENTS.prod:
      return prod;
    default:
      return {};
  }
}
