import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DEFAULT_COUNTRY } from 'shared/constants';
import { definitions } from 'types/api';

import { selectCountries } from 'store/modules/settings/selectors';

import { CountryNameWithFlag } from 'components/Shared/CountryNameWithFlag/CountryNameWithFlag';
import { SelectInput } from 'components/Shared/CustomMui';

import { Box, FormControl, SelectChangeEvent } from '@mui/material';

import styles from './Country.module.scss';

type CountryProps = {
  disabled?: boolean;
  required?: boolean;
  countryCode: string;
  width?: string | number | undefined;
  onCountryCodeChange: (countryCode: string) => void;
};

const Country: FC<CountryProps> = (props: CountryProps) => {
  const {
    countryCode,
    onCountryCodeChange,
    width,
    required = false,
    disabled = false,
  } = props;
  const { t } = useTranslation();
  const countries = useSelector(selectCountries);

  return (
    <>
      <FormControl fullWidth>
        {countries && (
          <SelectInput
            htmlFor="Country"
            label={t('country')}
            width={width}
            value={countryCode}
            disabled={disabled}
            className={styles.countrySelect}
            onChange={(event: SelectChangeEvent) => {
              onCountryCodeChange(event.target.value);
            }}
            items={
              countries?.length
                ? countries.map((country: definitions['Country']) => ({
                    label: (
                      <Box className={styles.item}>
                        <CountryNameWithFlag
                          countryCode={country.code}
                          countryName={country.name}
                        ></CountryNameWithFlag>
                      </Box>
                    ),
                    value: country.code,
                  }))
                : [DEFAULT_COUNTRY]
            }
            required={required}
            data-testid="country-select"
          />
        )}
      </FormControl>
    </>
  );
};

export default memo(Country);
