import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as XSmallIcon } from 'assets/icons/x-small.svg';
import classNames from 'classnames';
import { definitions } from 'types/api';

import {
  Checkbox,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
} from '@mui/material';

import styles from './RuleAttributeValue.module.scss';

interface Props {
  readonly?: boolean;
  selectedMetadataIds?: string[];
  attribute?: definitions['ShopperMetadata'];
  condition: definitions['BuildUniverseUserCondition'];
  onChange: (condition: definitions['BuildUniverseUserCondition']) => void;
}

export const RuleAttributeValuePopper = ({
  onChange,
  readonly,
  attribute,
  condition,
  selectedMetadataIds,
}: Props) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const onMetadataItemClick = (id: string) => {
    const selectedIds: string[] = selectedMetadataIds?.includes(id)
      ? selectedMetadataIds.filter((metadataId) => metadataId !== id)
      : [...(selectedMetadataIds || []), id];

    onChange({
      ...condition,
      metadata_ids: selectedIds,
    });
  };

  let filteredValues = attribute?.shopper_metadata_values?.filter(
    (shopperMetadataValue) =>
      shopperMetadataValue?.attribute_value
        .toLowerCase()
        ?.includes(searchTerm.toLowerCase())
  );

  if (readonly) {
    filteredValues = attribute?.shopper_metadata_values?.filter(
      (shopperMetadataValue) =>
        selectedMetadataIds?.includes(shopperMetadataValue.metadata_id)
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="center" flexGrow={1}>
        {!readonly && (
          <TextField
            autoFocus
            type="text"
            size="small"
            variant="standard"
            value={searchTerm}
            className={styles.search_bar}
            placeholder={(t('search') || 'Search').toUpperCase()}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(event.target.value)
            }
            InputProps={{
              'aria-label': (t('search') || 'Search').toUpperCase(),
              startAdornment: (
                <InputAdornment position="start">
                  {searchTerm ? (
                    <SvgIcon
                      component={XSmallIcon}
                      viewBox={'0 0 32 32'}
                      className={styles.clear_icon}
                      onClick={() => {
                        setSearchTerm('');
                      }}
                    />
                  ) : (
                    <SvgIcon
                      component={SearchIcon}
                      viewBox={'0 0 32 32'}
                      className={styles.search_icon}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>
      <ul className={styles.list}>
        {filteredValues?.length === 0 && (
          <li className={`${styles.empty_result} ${styles.item}`}>
            {t('no_results')}
          </li>
        )}

        {filteredValues?.map((metadata) => {
          return (
            <li
              className={classNames(styles.item, {
                [styles.readonly]: readonly,
              })}
              key={metadata.metadata_id}
              onClick={() => onMetadataItemClick(metadata.metadata_id)}
            >
              <Stack direction="row" alignItems="center">
                <Checkbox
                  onChange={() => onMetadataItemClick(metadata.metadata_id)}
                  checked={selectedMetadataIds?.includes(metadata.metadata_id)}
                  disabled={readonly}
                />
                <span>{metadata.attribute_value?.trim()}</span>
              </Stack>
            </li>
          );
        })}
      </ul>
    </>
  );
};
