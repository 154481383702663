import { NumberFormat } from 'components/Shared';

import { LinearProgress, Typography } from '@mui/material';

type Props = { budget: number; usedBudget: number; currency: string };

export const CampaignOverviewUsedBudgetBar = ({
  budget,
  currency,
  usedBudget,
}: Props) => {
  const progress = Math.min((usedBudget * 100) / budget, 100);
  return (
    <>
      <LinearProgress
        value={progress}
        variant="determinate"
        sx={{
          '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: (theme) => theme.palette.primary.main,
          },
          borderRadius: 5,
          height: 6,
          marginBottom: (theme) => theme.spacing(1),
          backgroundColor: (theme) => theme.palette.grey[300],
        }}
      />
      <Typography
        variant="body2"
        sx={{ color: (theme) => theme.palette.primary.main }}
      >
        <NumberFormat
          data-testid="usedBudget"
          thousandSeparator={'.'}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          displayType="text"
          prefix={currency}
          value={usedBudget}
        />
      </Typography>
    </>
  );
};
