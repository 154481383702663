import { useTranslation } from 'react-i18next';

import { ReactComponent as SchwarzMediaIcon } from 'assets/icons/Schwarz-Media.svg';
import { ReactComponent as TriangleWarningIcon } from 'assets/icons/triangle-warning.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import classNames from 'classnames';
import { definitions } from 'types/api';

import { useBuildConditionText } from 'components/Universes/hooks/useBuildConditionText';

import { Grid, SvgIcon, Typography } from '@mui/material';

import styles from '../UniverseItem.module.scss';

interface Props {
  universe: definitions['Universe'];
  universeItemType:
    | definitions['UniverseBuildType']
    | Extract<
        definitions['BuildUniverseWorkflowStatus'],
        'UNIVERSE_BUILDER_STATUS_FAIL'
      >;
}

export const UniverseItemFooter = ({ universeItemType, universe }: Props) => {
  const { t } = useTranslation();
  const { universeRuleText, numberOfUniverseRules } =
    useBuildConditionText(universe);

  switch (universeItemType) {
    case 'UNIVERSE_BUILDER_STATUS_FAIL':
      return (
        <Grid
          item
          className={classNames(styles.static_icon_indication, 'error')}
        >
          <SvgIcon
            className={styles.test_icon}
            component={TriangleWarningIcon}
            viewBox={'0 0 32 32'}
          />
          <span>{t('universe_failed_creation')}</span>
        </Grid>
      );
    case 'UNIVERSE_BUILD_TYPE_IMPORT':
      return (
        <Grid item className={styles.static_icon_indication}>
          <SvgIcon
            className={styles.test_icon}
            component={UploadIcon}
            viewBox={'0 0 28 22'}
          />
          <span>{t('uploaded_universe')}</span>
        </Grid>
      );
    case 'UNIVERSE_BUILD_TYPE_EXTERNAL':
      return (
        <Grid item className={styles.static_icon_indication}>
          <SvgIcon
            className={styles.schwarz_media_icon}
            component={SchwarzMediaIcon}
            viewBox={'0 0 50 24'}
          />
          <span>{t('imported_universe')}</span>
        </Grid>
      );
    case 'UNIVERSE_BUILD_TYPE_BUILDER':
      return (
        <>
          <Grid item xs={12}>
            <Typography
              className={`${styles.condition} ${styles.display_value}`}
              variant="body2"
            >
              {universeRuleText}
            </Typography>
            {numberOfUniverseRules > 1 && (
              <Typography
                color="primary"
                variant="h3"
                className={styles.display_label__link}
              >
                {`+${numberOfUniverseRules - 1} ${t(
                  'more'
                ).toLocaleUpperCase()}`}
              </Typography>
            )}
          </Grid>
        </>
      );

    default:
      return <></>;
  }
};
