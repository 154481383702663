import {
  tkCampaignSortField,
  tkCampaignState,
  tkCampaignType,
} from 'i18n/translationKeyMaps';
import { definitions } from 'types/api';

export const campaignSortFields: {
  [key in Exclude<
    definitions['CampaignSortField'],
    'CAMPAIGN_SORT_UNKNOWN' | 'CAMPAIGN_SORT_STATE'
  >]: string;
} = {
  CAMPAIGN_SORT_CAMPAIGN_START: tkCampaignSortField(
    'CAMPAIGN_SORT_CAMPAIGN_START'
  ),
  CAMPAIGN_SORT_CAMPAIGN_END: tkCampaignSortField('CAMPAIGN_SORT_CAMPAIGN_END'),
  CAMPAIGN_SORT_NAME: tkCampaignSortField('CAMPAIGN_SORT_NAME'),
  CAMPAIGN_SORT_BUDGET: tkCampaignSortField('CAMPAIGN_SORT_BUDGET'),
  CAMPAIGN_SORT_UPDATED_AT: tkCampaignSortField('CAMPAIGN_SORT_UPDATED_AT'),
};

export const campaignStatusValues: {
  label: string;
  value: definitions['CampaignState'];
}[] = [
  {
    label: tkCampaignState('CAMPAIGN_STATE_RUNNING'),
    value: 'CAMPAIGN_STATE_RUNNING',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_STOPPED'),
    value: 'CAMPAIGN_STATE_STOPPED',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_PAUSED_MANUAL'),
    value: 'CAMPAIGN_STATE_PAUSED_MANUAL',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_DRAFT'),
    value: 'CAMPAIGN_STATE_DRAFT',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_SUBMITTED'),
    value: 'CAMPAIGN_STATE_SUBMITTED',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_APPROVED'),
    value: 'CAMPAIGN_STATE_APPROVED',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_REJECTED'),
    value: 'CAMPAIGN_STATE_REJECTED',
  },
];

export const activeCampaignStatusValues: {
  label: string;
  value: definitions['CampaignState'];
}[] = [
  {
    label: tkCampaignState('CAMPAIGN_STATE_RUNNING'),
    value: 'CAMPAIGN_STATE_RUNNING',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_STOPPED'),
    value: 'CAMPAIGN_STATE_STOPPED',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_PAUSED_MANUAL'),
    value: 'CAMPAIGN_STATE_PAUSED_MANUAL',
  },
];

export const campaignTypeValues: {
  label: string;
  value: definitions['CampaignType'];
}[] = [
  {
    label: tkCampaignType('CAMPAIGN_TYPE_CONVERSION'),
    value: 'CAMPAIGN_TYPE_CONVERSION',
  },
  {
    label: tkCampaignType('CAMPAIGN_TYPE_IMPRESSION'),
    value: 'CAMPAIGN_TYPE_IMPRESSION',
  },
  {
    label: tkCampaignType('CAMPAIGN_TYPE_CLICK'),
    value: 'CAMPAIGN_TYPE_CLICK',
  },
];
