import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { DraftBuildUniverseUserConditionType } from 'shared/interfaces';
import { definitions } from 'types/api';
import { useFeature } from 'utils/FeatureFlags';

import {
  validateAttributeAndSearchQueryError,
  validatePeriodError,
  validateProductError,
} from 'store/modules/universes/validations';

import { AddProductsDialog } from 'components/Shared/AddProductsDialog/AddProductsDialog';
import CloseIcon from 'components/Shared/svg/CloseHeavy';

import { Popper } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import styles from './Rule.module.scss';
import {
  RuleAttributeTypePopper,
  RuleAttributeValuePopper,
  RuleInclusivenessPopper,
  RuleTimeSpanPopper,
  SelectProducts,
  SelectRuleAttributeType,
  SelectRuleAttributeValue,
  SelectRuleInclusiveness,
  SelectRuleTimeSpan,
} from './components';

export enum PopperStep {
  INCLUSIVENESS = 'inclusiveness',
  TIME_SPAN = 'time_span',
  ATTRIBUTE_TYPE = 'attribute_type',
  ATTRIBUTE_VALUE = 'attribute_value',
  PRODUCTS = 'products',
}

export interface SelectionBoxProps {
  disabled?: boolean;
  selected?: boolean;
  hasError?: boolean;
  readonly?: boolean;
}

interface RuleProps {
  condition: DraftBuildUniverseUserConditionType;
  disabled?: boolean;
  readonly?: boolean;
  index: number;
  advertiserId?: string;
  retailerId?: string;
  countryCode?: string;
  taxonomyId: string;
  attribute?: definitions['ShopperMetadata'];
  onDelete: () => void;
  onUpdate: (condition: DraftBuildUniverseUserConditionType) => void;
  onPopperOpen: (value: boolean) => void;
  enableRuleSelection: boolean;
}

const Rule: FC<RuleProps> = (props: RuleProps): ReactElement => {
  const {
    condition,
    index,
    retailerId,
    countryCode,
    taxonomyId,
    onDelete,
    onUpdate,
    readonly,
    attribute,
    disabled = false,
    onPopperOpen,
    enableRuleSelection,
  } = props;
  const {
    gtins,
    logic,
    isNew,
    period,
    attributes,
    period_type,
    metadata_ids,
    search_query,
  } = condition;

  const anchorEl = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const isShopperMetadataEnabled = useFeature('isShopperMetadataEnabled');

  const productGtins = gtins ?? [];
  const productAttributes = attributes ?? [];

  const [popperStep, setPopperStep] = useState<null | PopperStep>();
  const [productsModalOpen, setProductsModalOpen] = useState<boolean>(false);

  const [selectedAttribute, setSelectedAttribute] = useState<
    definitions['ShopperMetadata'] | 'Products' | undefined
  >(isNew ? undefined : attribute ?? 'Products');

  const productsError = validateProductError(productGtins);
  const attributesError = validateAttributeAndSearchQueryError(
    productAttributes,
    search_query
  );
  const periodError = validatePeriodError(period, period_type);

  const notAvailableAttributeErrorMessage =
    !selectedAttribute && !isNew && t('not_available_attribute_message'); //PACMAN-1383 when attribute can't be found, we can assume that it was deleted or changed in DPR side

  useEffect(() => {
    const areBoughtProductsSelected =
      !!gtins?.length || !!attributes?.length || !!search_query;
    setSelectedAttribute(
      attribute ? attribute : areBoughtProductsSelected ? 'Products' : undefined
    );
  }, [attribute, gtins, attributes, search_query]);

  const currentStep = popperStep
    ? {
        inclusiveness: (
          <RuleInclusivenessPopper
            logic={logic}
            condition={condition}
            onChange={onUpdate}
          />
        ),
        attribute_value: (
          <RuleAttributeValuePopper
            onChange={onUpdate}
            condition={condition}
            selectedMetadataIds={metadata_ids}
            attribute={selectedAttribute as definitions['ShopperMetadata']}
            readonly={readonly}
          />
        ),
        products: null,
        attribute_type: (
          <RuleAttributeTypePopper
            countryCode={countryCode}
            retailerId={retailerId}
            onAttributeTypeChange={(attribute) => {
              setSelectedAttribute(attribute);

              if (attribute === 'Products') {
                onUpdate({
                  ...condition,
                  attribute_id: undefined,
                  metadata_ids: [],
                });
                setPopperStep(PopperStep.PRODUCTS);
                onPopperOpen(false);
              } else {
                onUpdate({
                  ...condition,
                  attributes: [],
                  gtins: [],
                  attribute_id: attribute.attribute_id,
                  metadata_ids: [],
                  search_query: '',
                });
                setPopperStep(undefined);
                onPopperOpen(false);
              }
            }}
          />
        ),
        time_span: (
          <RuleTimeSpanPopper
            condition={condition}
            onChange={onUpdate}
            periodError={periodError}
          />
        ),
      }[popperStep]
    : null;

  return (
    <Grid container className={styles.rule}>
      <Grid item xs={0.5} className={styles.rule_connector_text_wrapper}>
        {index > 0 && (
          <Typography
            className={classNames(styles.rule_connector_text, 'uppercase')}
            variant="body1"
            color="textSecondary"
          >
            {t('and')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={11}>
        <Grid alignItems="center" container ref={anchorEl}>
          <SelectRuleInclusiveness
            logic={logic}
            disabled={disabled || !enableRuleSelection}
            selected={popperStep === PopperStep.INCLUSIVENESS}
            setPopperStep={(value) => {
              if (readonly) return;
              setPopperStep(value);
              onPopperOpen(!!value);
            }}
            readonly={readonly}
          />
          {isShopperMetadataEnabled && (
            <SelectRuleAttributeType
              disabled={disabled || !enableRuleSelection}
              setPopperStep={(value) => {
                if (readonly) return;
                setPopperStep(value);
                onPopperOpen(!!value);
              }}
              hasError={!selectedAttribute}
              selectedAttribute={selectedAttribute}
              selected={popperStep === PopperStep.ATTRIBUTE_TYPE}
              readonly={readonly}
            />
          )}
          {!isShopperMetadataEnabled || selectedAttribute === 'Products' ? (
            <>
              <SelectProducts
                disabled={disabled || !enableRuleSelection}
                taxonomyId={taxonomyId}
                productGtins={productGtins}
                search_query={search_query}
                hasError={
                  (productGtins.length && productsError) ||
                  (!productGtins.length && attributesError)
                }
                productAttributes={productAttributes}
                setProductsModalOpen={() => {
                  setPopperStep(null);
                  onPopperOpen(false);
                  setProductsModalOpen(true);
                }}
                selected={popperStep === PopperStep.PRODUCTS}
                readonly={readonly}
              />
              <SelectRuleTimeSpan
                period={period}
                disabled={disabled || !enableRuleSelection}
                hasError={periodError}
                periodType={period_type}
                setPopperStep={(value) => {
                  if (readonly) return;
                  setPopperStep(value);
                  onPopperOpen(!!value);
                }}
                selected={popperStep === PopperStep.TIME_SPAN}
                readonly={readonly}
              />
            </>
          ) : (
            selectedAttribute && (
              <SelectRuleAttributeValue
                disabled={disabled || !enableRuleSelection}
                hasError={!metadata_ids?.length}
                setPopperStep={(value) => {
                  setPopperStep(value);
                  onPopperOpen(!!value);
                }}
                selectedAttribute={selectedAttribute}
                selectedMetadataIds={metadata_ids}
                selected={popperStep === PopperStep.ATTRIBUTE_VALUE}
                readonly={readonly}
              />
            )
          )}
          <Typography variant="body2">
            {notAvailableAttributeErrorMessage}
          </Typography>
        </Grid>
      </Grid>
      {!disabled && !readonly && (
        <Grid item xs={0.5} display="flex" justifyContent="flex-end">
          <CloseIcon
            onClick={() => {
              onDelete();
              onPopperOpen(false);
            }}
            className={styles.close_icon}
          />
        </Grid>
      )}
      {!!popperStep && popperStep !== PopperStep.PRODUCTS && (
        <ClickAwayListener
          onClickAway={() => {
            setPopperStep(null);
            onPopperOpen(false);
          }}
        >
          <Popper
            className={classNames(styles.popper, {
              [styles.with_space]:
                popperStep !== PopperStep.ATTRIBUTE_TYPE &&
                popperStep !== PopperStep.ATTRIBUTE_VALUE,
              [styles.attribute_type]: popperStep === PopperStep.ATTRIBUTE_TYPE,
            })}
            anchorEl={anchorEl.current?.querySelector(`#${popperStep}`)}
            open={true}
            placement="bottom-start"
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ]}
          >
            {currentStep}
          </Popper>
        </ClickAwayListener>
      )}
      {productsModalOpen && (
        <AddProductsDialog
          readonly={readonly}
          onCancel={() => setProductsModalOpen(false)}
          alreadySelectedProductGTINs={productGtins}
          alreadyAppliedSearchTerm={search_query}
          alreadySelectedProductAttributes={productAttributes}
          typeUniverse={true}
          backButtonText={t('back_to_universe')}
          retailer_ids={retailerId ? [retailerId] : []}
          taxonomyId={taxonomyId}
          onConfirm={(ids, { attributes, searchTerm }) => {
            onUpdate({
              ...condition,
              gtins: ids,
              product_taxonomy_id: taxonomyId,
              attributes: attributes,
              search_query: searchTerm,
            });
            setProductsModalOpen(false);
          }}
        />
      )}
    </Grid>
  );
};

export default Rule;
