import {
  Action,
  AnyAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { original } from 'immer';
import { isEqualWith, omit } from 'lodash';
import { ExtendedCampaign, Query } from 'shared/interfaces/Campaign';
import { definitions } from 'types/api';

import { campaignApi } from 'store/api/endpoints/campaign';

import { AlertProps } from 'components/Shared/CustomMui/Alert/Alert';

import {
  baseCampaign,
  campaignComparisonCustomizer,
  DraftCampaignType,
} from './helpers';
import {
  CampaignFieldValidations,
  validateCampaign,
  validators,
} from './validations';

type Campaign = DraftCampaignType;

export type TouchedFields = {
  [key in keyof Partial<ExtendedCampaign>]: boolean;
};
interface State {
  query: Query;
  results: { [key: string]: DraftCampaignType };
  originalCampaign: Campaign;
  draft: null | ExtendedCampaign;
  draftValidations: CampaignFieldValidations;
  draftTouchedFields: TouchedFields;
  draftHasChanges: boolean;
  alerts: AlertProps[];
}

const initialQuery: Query = {
  page: 1,
  per_page: 50,
  results: 0,
  sortBy: 'CAMPAIGN_SORT_UNKNOWN',
  order: 'SORT_ORDER_UNKNOWN',
};

const initialState: State = {
  query: initialQuery,
  results: {},
  draft: null,
  draftValidations: {},
  draftTouchedFields: {},
  draftHasChanges: false,
  originalCampaign: baseCampaign(),
  alerts: [],
};

const isCampaignsAction = (action: AnyAction): action is Action => {
  return action.type.startsWith('campaigns');
};

const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setDraft(
      state,
      action: PayloadAction<null | {
        id: string;
        type: definitions['CampaignType'];
        extended_channels?: definitions['Channel'][];
        countries?: definitions['Country'][];
        universe?: definitions['Universe'];
      }>
    ) {
      state.draftHasChanges = false;
      if (!action.payload) {
        state.draft = null;
        state.draftValidations = {};

        return;
      }
      const { id, type, extended_channels, countries, universe } =
        action.payload;

      state.draftTouchedFields = {};
      if (id === 'create') {
        const draft = { ...baseCampaign(), type, extended_channels };
        state.draft = state.originalCampaign = draft;
        state.draftValidations = validateCampaign(draft);
        return;
      }

      if (state.results[id]) {
        const draft = {
          ...state.results[id],
          country: countries?.find(
            (country) => country.code === state.results[id].country_code
          ),
          extended_channels,
          universe,
        };
        state.draft = state.originalCampaign = draft;
        state.draftValidations = validateCampaign(draft);

        return;
      }
    },
    updateDraft(
      state: State,
      action: PayloadAction<Partial<DraftCampaignType>>
    ) {
      if (!state.draft) return;
      const newDraft = {
        ...state.draft,
        ...action.payload,
      };
      state.draft = newDraft;
      Object.keys(action.payload).forEach((k) => {
        const fieldValidationResult =
          validators[k as keyof Campaign]?.(newDraft);
        if (!fieldValidationResult) return;
        state.draftValidations[k as keyof Campaign] = fieldValidationResult;
      });
    },
    setDraftTouchedFields(state: State, action: PayloadAction<keyof Campaign>) {
      state.draftTouchedFields[action.payload] = true;
      state.draftValidations[action.payload] = validators[action.payload]?.(
        state.draft as ExtendedCampaign
      );
    },
    addCampaignAlert(state: State, action: PayloadAction<AlertProps>) {
      const index = state.alerts.findIndex(
        (alert: AlertProps) =>
          alert.text === action.payload.text &&
          alert.severity === action.payload.severity
      );
      if (index < 0) {
        state.alerts = [...state.alerts, action.payload];
      }
    },
    removeCampaignAlert(state: State, action: PayloadAction<AlertProps>) {
      const index = state.alerts.findIndex(
        (alert: AlertProps) =>
          alert.text === action.payload.text &&
          alert.severity === action.payload.severity
      );
      if (index >= 0) {
        state.alerts = [
          ...state.alerts.slice(0, index),
          ...state.alerts.slice(index + 1),
        ];
      }
    },
    removeAllCampaignAlerts(state: State) {
      state.alerts = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isCampaignsAction, (state) => {
        if (!state.draft) return;
        const draft = original(state.draft) as DraftCampaignType;

        if (!draft.id) {
          // on create mode, if user set a name then we can set draftHasChanges to true and enable the save button
          state.draftHasChanges = !!draft.name;
          return;
        }
        if (!state.results[state.draft.id]) return;
        state.draftHasChanges = !isEqualWith(
          original(state.results[state.draft.id]),
          omit(draft, [
            'used_budget_total_cumulative',
            'extended_channels',
            'country',
            'universe',
          ]), // omit 'used_budget_total_cumulative', 'extended_channels', 'country', 'universe' since they are not part of the original campaign object
          campaignComparisonCustomizer
        );
      })
      .addMatcher(
        campaignApi.endpoints.getCampaignById.matchFulfilled,
        (state, { payload }) => {
          state.results[payload.id] = payload;
        }
      )
      .addMatcher(
        campaignApi.endpoints.updateCampaign.matchFulfilled,
        (state, { payload }) => {
          state.results[payload.id] = payload;
        }
      )
      .addMatcher(
        campaignApi.endpoints.deleteCampaign.matchFulfilled,
        (state, { payload }) => {
          delete state.results[payload];
        }
      );
  },
});

export const {
  setDraft,
  updateDraft,
  setDraftTouchedFields,
  addCampaignAlert,
  removeCampaignAlert,
  removeAllCampaignAlerts,
} = slice.actions;

export default slice.reducer;
