import { useCallback, useEffect, useRef, useState } from 'react';

export const useElementOnScreen = (
  options: IntersectionObserverInit,
  areAllItemsLoaded: boolean
) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      setIsVisible(entries[0].isIntersecting);
    },
    []
  );

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const unsubscribe = () => {
      if (containerRef.current && !!observer?.unobserve) {
        observer.unobserve(containerRef.current);
      }
    };
    if (containerRef.current && !!observer?.observe) {
      observer.observe(containerRef.current);
    }

    if (areAllItemsLoaded) {
      unsubscribe();
    }
    return unsubscribe;
  }, [containerRef, options]);

  return [containerRef, isVisible];
};
