import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { Endpoints } from 'types';
import { definitions } from 'types/api';

import { RootState } from 'store/index';

import { baseApi } from '../baseApi';

const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['RetailerList'],
});

export const retailerApi = enhancedBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getRetailers: builder.query<definitions['Retailer'][], void>({
      async queryFn(_args, { getState }, _extraOptions, fetchWithBQ) {
        try {
          // advertiser_id might be missing for some roles, it's an optional parameter
          const params = {} as { advertiser_id?: string };
          const user = (getState() as RootState).user?.user;

          const advertiserId = user?.advertiser_id as string;
          if (advertiserId) {
            params['advertiser_id'] = advertiserId;
          }

          const networkId =
            user?.network_id ||
            (getState() as RootState).settings.advertiser?.network_id;
          if (!networkId) {
            throw new Error('No network_id');
          }
          const response = await fetchWithBQ({
            url: `${Endpoints.networks}/${networkId}/retailers`,
            params,
          });
          const getRetailersResponse =
            response.data as definitions['GetNetworkRetailersResponse'];
          return response.data
            ? { data: getRetailersResponse.retailers }
            : { error: response.error as FetchBaseQueryError };
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
      providesTags: ['RetailerList'],
    }),
    getRetailerById: builder.query<definitions['Retailer'], string>({
      query: (retailerId: string) => `${Endpoints.retailers}/${retailerId}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetRetailersQuery, useGetRetailerByIdQuery } = retailerApi;
