import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';
import { getUserFromLocalStorage } from 'utils/getUserFromLocalStorage';

import { AUTHORIZATION_HEADER } from 'store/modules/user/helpers';

import { RootState } from '../index';

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || '/',
    prepareHeaders: async (
      headers: Headers,
      { getState }: Pick<BaseQueryApi, 'getState'>
    ) => {
      const authConfig = (getState() as RootState).settings.authConfig;
      const token = getUserFromLocalStorage(authConfig)?.access_token;
      if (token) {
        headers.set(AUTHORIZATION_HEADER, `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
      }
      return headers;
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {
        allowDots: true,
        arrayFormat: 'repeat',
      });
    },
  }),
  endpoints: () => ({}),
});
