import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FormikValues, useFormik } from 'formik';
import { EMAIL_VALIDATION_REGEX } from 'shared/constants';
import { ErrorCode } from 'types';
import * as yup from 'yup';

import { useCreateAdvertiserMutation } from 'store/api/endpoints/settings';
import { addNotification } from 'store/modules/notifications/actions';

import { TextInput } from 'components/Shared/CustomMui';
import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';

interface AddAdvertiserDialogProps {
  existingAdvertiserIds: string[];
  onClose: () => void;
}

export const AddAdvertiserDialog: FC<AddAdvertiserDialogProps> = ({
  existingAdvertiserIds,
  onClose,
}: AddAdvertiserDialogProps): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [createAdvertiser, { isLoading: isCreatingAdvertiser }] =
    useCreateAdvertiserMutation();

  const advertiserCreationSchema = yup.object().shape({
    advertiser_name: yup
      .string()
      .required(t('error_required', { entity: t('advertiser_name') })),
    advertiser_id: yup
      .string()
      .required(t('error_required', { entity: t('advertiser_id') })),
    admin_name: yup
      .string()
      .required(t('error_required', { entity: t('admin_name') })),
    admin_email: yup
      .string()
      .required(t('error_required', { entity: t('admin_email') }))
      .test(
        'is_valid_admin_email',
        t(ErrorCode.AUTH_INVALID_EMAIL),
        (value: string) => EMAIL_VALIDATION_REGEX.test(value)
      ),
    advertiser_contact_email: yup.string().when({
      is: (exists: string) => !!exists,
      then: (rule) =>
        rule
          .required()
          .test(
            'is_valid_advertiser_contact_email',
            t(ErrorCode.AUTH_INVALID_EMAIL),
            (value: string) => EMAIL_VALIDATION_REGEX.test(value)
          ),
    }),
  });

  const handleCreateAdvertiser = async (data: FormikValues): Promise<void> => {
    if (
      !!data.advertiser_id &&
      existingAdvertiserIds?.includes(data.advertiser_id)
    ) {
      dispatch(
        addNotification({
          text: t('advertiser_id_exists_error'),
          type: 'error',
        })
      );
      return;
    }
    const payload = {
      advertiser: {
        id: data.advertiser_id,
        display_name: data.advertiser_name,
        advertiser_contact_email: data.advertiser_contact_email,
      },
      advertiserAdminUser: {
        email: data.admin_email,
        name: data.admin_name,
      },
    };
    const createAdvertiserResponse = await createAdvertiser(payload).unwrap();
    if (createAdvertiserResponse?.id) {
      dispatch(
        addNotification({
          text: t('advertiser_created_message'),
          type: 'success',
        })
      );
      onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      advertiser_name: '',
      advertiser_id: '',
      admin_name: '',
      admin_email: '',
      advertiser_contact_email: '',
    },
    validateOnMount: true,
    onSubmit: handleCreateAdvertiser,
    validationSchema: advertiserCreationSchema,
  });

  return (
    <>
      <DialogWrapper
        width={1000}
        headerText={t('add_new_advertiser')}
        subHeaderText={t('add_new_advertiser_subheader')}
        dialogActions={
          <>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              {t('cancel')}
            </Button>
            <LoadingButton
              loading={isCreatingAdvertiser}
              disabled={!formik.isValid}
              variant="contained"
              color="primary"
              type="submit"
              form="advertiser-creation-form"
              data-testid="confirm-create-button"
            >
              {t('create')}
            </LoadingButton>
          </>
        }
        dialogContent={
          <form id="advertiser-creation-form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextInput
                  required
                  width={'100%'}
                  label={t('advertiser_name')}
                  name="advertiser_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.advertiser_name}
                  error={
                    formik.touched.advertiser_name &&
                    (formik.errors.advertiser_name ?? '')
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextInput
                  required
                  width={'100%'}
                  label={t('advertiser_id')}
                  hint={t('advertiser_id_allowed_pattern_hint')}
                  name="advertiser_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.advertiser_id}
                  error={
                    formik.touched.advertiser_id &&
                    (formik.errors.advertiser_id ?? '')
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextInput
                  required
                  width={'100%'}
                  label={t('admin_name')}
                  name="admin_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.admin_name}
                  error={
                    formik.touched.admin_name &&
                    (formik.errors.admin_name ?? '')
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextInput
                  required
                  width={'100%'}
                  label={t('admin_email')}
                  name="admin_email"
                  onChange={formik.handleChange}
                  value={formik.values.admin_email}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.admin_email &&
                    (formik.errors.admin_email ?? '')
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextInput
                  width={'100%'}
                  label={t('advertiser_contact_email')}
                  name="advertiser_contact_email"
                  value={formik.values.advertiser_contact_email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.advertiser_contact_email &&
                    (formik.errors.advertiser_contact_email ?? '')
                  }
                />
              </Grid>
            </Grid>
          </form>
        }
        handleClose={onClose}
      />
    </>
  );
};
