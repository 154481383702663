import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as XSmallIcon } from 'assets/icons/x-small.svg';

import {
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
} from '@mui/material';

import styles from './Search.module.scss';

export interface SearchProps {
  activeSearchTerm?: string;
  onSearch: (search: string) => void;
}

export const Search = ({ activeSearchTerm, onSearch }: SearchProps) => {
  const { t } = useTranslation();
  const [term, setTerm] = useState('');

  useEffect(() => {
    if (activeSearchTerm && activeSearchTerm !== term) {
      setTerm(activeSearchTerm);
    }
  }, [activeSearchTerm]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch(term);
    }
  };

  return (
    <Stack direction="row" alignItems="center" flexGrow={1}>
      <TextField
        data-testid="list-action-search"
        value={term}
        type="text"
        variant="standard"
        size="small"
        onKeyDown={handleKeyDown}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setTerm(event.target.value)
        }
        className={styles.search_bar}
        placeholder={(t('search') || 'Search').toUpperCase()}
        InputProps={{
          'aria-label': (t('search') || 'Search').toUpperCase(),
          startAdornment: (
            <InputAdornment position="start" onClick={() => onSearch(term)}>
              <SvgIcon
                component={SearchIcon}
                viewBox={'0 0 28 28'}
                className={styles.search_icon}
              />
            </InputAdornment>
          ),
          endAdornment: term && (
            <IconButton
              data-testid="list-action-clear-search"
              disableRipple
              onClick={() => {
                setTerm('');
                onSearch('');
              }}
            >
              <SvgIcon component={XSmallIcon} viewBox={'0 0 32 32'} />
            </IconButton>
          ),
        }}
      />
    </Stack>
  );
};
