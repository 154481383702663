import { GetAdvertiserProductsUnavailabilityQuery } from 'shared/interfaces/ProductTaxonomy';
import { Endpoints } from 'types';
import { definitions } from 'types/api';

import { baseApi } from '../baseApi';

/**
 * Enhance baseApi with tags that we use only in products
 */
const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['ProductsUnavailability'],
});

// inject products-unavailability endpoints to the enhancedBaseApiWithTags
export const ProductUnavailabilityApi = enhancedBaseApiWithTags.injectEndpoints(
  {
    endpoints: (builder) => ({
      getProductsUnavailability: builder.query<
        definitions['ProductUnavailabilityDuration'][],
        GetAdvertiserProductsUnavailabilityQuery
      >({
        query: ({
          retailer_id,
          gtins = [],
          ...rest
        }: GetAdvertiserProductsUnavailabilityQuery) => ({
          url: `${Endpoints.productUnavailability}/${retailer_id}/${gtins
            .slice(0, 99)
            .join('%2C')}`,
          params: {
            ...rest,
          },
        }),
        transformResponse: (
          response: definitions['GetProductsUnavailabilityResponse']
        ) => {
          return response.unavailability_durations || [];
        },
        providesTags: ['ProductsUnavailability'],
      }),
    }),
    overrideExisting: false,
  }
);

export const {
  useGetProductsUnavailabilityQuery,
  useLazyGetProductsUnavailabilityQuery,
} = ProductUnavailabilityApi;
