import { useTranslation } from 'react-i18next';

import { useGetEnvironment } from 'hooks';
import { LocationState } from 'types';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { PageHeader } from '../CustomMui';

interface ErrorGeneralProps {
  locationState?: LocationState;
}

const ErrorGeneral = ({ locationState }: ErrorGeneralProps) => {
  const { t } = useTranslation();
  const { environment } = useGetEnvironment();
  const error = locationState?.error;
  const isDevelopmentEnvironment =
    environment === 'development' || environment === 'local';

  return (
    <>
      <PageHeader headerText={t('error_general')} subHeaderText="" />
      <Grid>
        {error?.message && isDevelopmentEnvironment && (
          <Typography
            color="error"
            sx={{
              marginBottom: (theme) => theme.spacing(2),
            }}
            variant="body1"
          >
            {error?.message ? error.message : ''}
          </Typography>
        )}
        <Button
          onClick={() => (window.location.href = '/')}
          variant="contained"
          sx={{
            marginTop: (theme) => theme.spacing(2),
          }}
        >
          {t('reload')}
        </Button>
      </Grid>
    </>
  );
};

export default ErrorGeneral;
