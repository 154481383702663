import { FC, ReactElement, useEffect } from 'react';

import { CircularProgress } from '@mui/material';

import { LoadingIndicatorWrapper } from './LoadingIndicator.style';

interface Props {
  isAppLoading: boolean;
}

const LoadingIndicator: FC<Props> = (props): ReactElement => {
  const { isAppLoading } = props;

  useEffect(() => {
    document.body.style.overflow = isAppLoading ? 'hidden' : 'auto';
  }, [isAppLoading]);

  return (
    <>
      {isAppLoading && (
        <LoadingIndicatorWrapper data-testid="app-loader">
          <CircularProgress />
        </LoadingIndicatorWrapper>
      )}
    </>
  );
};

export default LoadingIndicator;
