import {
  ChangeEvent,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/chevron-up.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as XSmallIcon } from 'assets/icons/x-small.svg';
import { ReactComponent as XIcon } from 'assets/icons/x.svg';
import classnames from 'classnames';
import { useElementOnScreen } from 'hooks';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { Campaign } from 'shared/interfaces';
import { User } from 'types';
import { definitions } from 'types/api';

import { useGetCampaignsQuery } from 'store/api/endpoints/campaign';
import { selectCountries } from 'store/modules/settings/selectors';

import {
  Button,
  ClickAwayListener,
  InputAdornment,
  MenuItem,
  Popper,
  SvgIcon,
  TextField,
} from '@mui/material';

import styles from './CompareCampaignMenu.module.scss';

export interface Props {
  user: User;
  onCampaignSelect: (campaignId: Campaign | undefined) => void;
}

export const CompareCampaignMenu = (props: Props) => {
  const { user, onCampaignSelect } = props;
  const { t } = useTranslation();
  const countries = useSelector(selectCountries);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedCampaignName, setSelectedCampaignName] = useState<string>('');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handlePopperTrigger = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  const [allCampaigns, setAllCampaigns] = useState<Campaign[]>([]);
  const [page, setPage] = useState(DEFAULT_API_PARAMETERS.page);

  const {
    data: newlyFetchedCampaigns,
    isLoading: isGetCampaignsLoading,
    isFetching: isGetCampaignsFetching,
  } = useGetCampaignsQuery(
    {
      page,
      sortBy: 'CAMPAIGN_SORT_NAME',
      order: 'SORT_ORDER_ASC',
      filter: {
        advertiser_id: user?.advertiser_id,
        search: searchTerm,
      },
    },
    {
      skip: !user?.advertiser_id,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (newlyFetchedCampaigns?.results?.length) {
      const campaigns = newlyFetchedCampaigns.results.map((campaign) => ({
        ...campaign,
        currency: countries?.find(
          (country: definitions['Country']) =>
            country.code === campaign?.country_code
        )?.currency,
      }));
      setAllCampaigns((previousCampaigns: Campaign[]) =>
        previousCampaigns.concat(campaigns)
      );
    }
  }, [newlyFetchedCampaigns]);

  const resetAllCampaignList = () => {
    setAllCampaigns([]);
    setPage(1);
  };

  const [containerRef, isVisible] = useElementOnScreen(
    {},
    newlyFetchedCampaigns?.query?.results === allCampaigns?.length
  );

  useEffect(() => {
    if (isGetCampaignsLoading || isGetCampaignsFetching) {
      return;
    }
    if (
      isVisible &&
      newlyFetchedCampaigns?.query?.results !== 0 &&
      newlyFetchedCampaigns?.query?.results !== allCampaigns?.length
    ) {
      setPage((previousPage: number) => previousPage + 1);
    }
  }, [isVisible]);

  const handleClose = (): void => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className={classnames(styles.trigger_button, {
          [styles.active]: open,
        })}
        disableRipple
        size="small"
        onClick={handlePopperTrigger}
      >
        <div>
          {t('compare_with')}
          {selectedCampaignName ? (
            <>
              {': '}
              <span>{`- ${selectedCampaignName}`}</span>
            </>
          ) : (
            ''
          )}
        </div>
        {selectedCampaignName ? (
          <SvgIcon
            component={XIcon}
            viewBox={'0 0 32 32'}
            onClick={(e: any) => {
              e.stopPropagation();
              onCampaignSelect(undefined);
              setSelectedCampaignName('');
            }}
          />
        ) : null}
        <SvgIcon
          component={open ? ArrowUpIcon : ArrowDownIcon}
          viewBox={'0 0 32 32'}
        />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        className={styles.popper}
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <TextField
              autoFocus
              value={searchTerm}
              type="text"
              sx={{
                '& input::placeholder': {
                  fontSize: '16px',
                  fontWeight: 400,
                },
              }}
              variant="standard"
              size="small"
              className={styles.popper__search}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                resetAllCampaignList();
                setSearchTerm(event.target.value);
              }}
              placeholder={t('search_for_campaign')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {searchTerm.length ? (
                      <SvgIcon
                        component={XSmallIcon}
                        viewBox={'0 0 32 32'}
                        className={styles.remove_icon}
                        onClick={() => {
                          resetAllCampaignList();
                          setSearchTerm('');
                        }}
                      />
                    ) : (
                      <SvgIcon
                        component={SearchIcon}
                        viewBox={'0 0 28 28'}
                        className={styles.search_icon}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.popper__items}>
              <MenuItem disabled={true}>{t('compare_with')}</MenuItem>
              {allCampaigns.map((campaign: Campaign) => (
                <MenuItem
                  key={campaign.id}
                  value={campaign.name}
                  disableRipple
                  onClick={() => {
                    setSelectedCampaignName(campaign.name);
                    onCampaignSelect(campaign);
                    handleClose();
                  }}
                >
                  {campaign.name}
                </MenuItem>
              ))}
              <MenuItem ref={containerRef as MutableRefObject<null>}></MenuItem>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
