import { FormType } from '../types';
import { definitions } from '../types/api';

type FieldState = 'Hidden' | 'Readonly' | 'Enabled';
const formFields = ['Name', 'Country', 'SumOfBudgets'] as const;

const formActions = [
  'Save',
  'Delete',
  'AddCampaign',
  'CreateCampaign',
  'ReportingLink',
] as const;

export type FormFieldStateType = {
  [key in typeof formFields[number]]: FieldState;
};
export type ActionStateType = {
  [key in typeof formActions[number]]: FieldState;
};

interface Props {
  groupName?: string;
  formType: FormType;
  campaignGroup?: definitions['CampaignGroup'];
}

export interface CampaignGroupFormState {
  fields: FormFieldStateType;
  actions: ActionStateType;
}

export const useCampaignGroupActionState = ({
  campaignGroup,
  formType,
  groupName,
}: Props): CampaignGroupFormState => {
  return {
    fields: {
      Name: formType === 'create' ? 'Enabled' : 'Readonly',
      Country: formType === 'create' ? 'Enabled' : 'Readonly',
      SumOfBudgets: formType === 'create' ? 'Hidden' : 'Readonly',
    },
    actions: {
      Save:
        formType === 'create'
          ? groupName && groupName.trim() !== campaignGroup?.name?.trim()
            ? 'Enabled'
            : 'Readonly'
          : 'Hidden',
      Delete:
        formType === 'edit' && campaignGroup?.deletable ? 'Enabled' : 'Hidden',
      AddCampaign: formType === 'create' ? 'Hidden' : 'Enabled',
      CreateCampaign: 'Enabled',
      ReportingLink: formType === 'create' ? 'Hidden' : 'Enabled',
    },
  };
};
