import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { definitions } from 'types/api';

import { CampaignGroupAccordion } from 'components/CampaignGroups/CampaignGroupListing/CampaignGroupAccordion/CampaignGroupAccordion';
import { CampaignGroupListActionBar } from 'components/CampaignGroups/CampaignGroupListing/CampaignGroupListActionBar/CampaignGroupListActionBar';
import LoadingIndicator from 'components/Shared/LoadingIndicator/LoadingIndicator';

import { Stack, TablePagination, Typography } from '@mui/material';

import { ReportingCampaignTable } from '../ReportingCampaignTable/ReportingCampaignTable';
import { useCampaignGroupListingData } from '../hooks/useCampaignGroupListingData';
import { ReportDataType } from '../utils/utils';
import styles from './ReportingCampaignGroupListing.module.scss';

interface Props {
  singleCampaignGroupId?: string;
  isCampaignGroupReportingEnabled: boolean;
  onSelect: (reportData: ReportDataType) => void;
}

export const ReportingCampaignGroupListing = ({
  onSelect,
  singleCampaignGroupId,
  isCampaignGroupReportingEnabled,
}: Props) => {
  const { t } = useTranslation();

  const [groupsPage, setGroupsPage] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [searchField, setSearchField] = useState<string>('');
  const [activeSortValues, setActiveSortValues] = useState<{
    field: definitions['CampaignGroupSortField'];
    order: definitions['SortOrder'];
  }>({ field: 'CAMPAIGN_GROUP_SORT_LAST_MODIFIED', order: 'SORT_ORDER_DESC' });

  const [selectedCampaignGroupId, setSelectedCampaignGroupId] = useState<
    string | undefined
  >(singleCampaignGroupId);

  const { campaignGroups, isLoading, pagination } = useCampaignGroupListingData(
    {
      page: groupsPage,
      sortField: activeSortValues?.field,
      sortOrder: activeSortValues?.order,
      search: searchField,
      singleCampaignGroupId,
      filterByCampaignStates: [],
    }
  );

  const onCampaignGroupSelect = (campaignGroup: ReportDataType) => {
    setSelectedCampaignGroupId(campaignGroup.id);
    onSelect(campaignGroup);
  };

  // Select campaign group item on page load when user reach the page via campaign group details page
  useEffect(() => {
    if (campaignGroups && isCampaignGroupReportingEnabled) {
      onCampaignGroupSelect({
        ...campaignGroups[0],
        id: campaignGroups[0]?.group_id || '',
        name: campaignGroups[0]?.name || '',
        objectType: 'CampaignGroup',
        areMultipleCurrenciesInvolved:
          (campaignGroups[0]?.campaign_group_currencies?.length ?? 0) > 1,
      });
    }
  }, [isLoading]);

  return (
    <>
      <LoadingIndicator isAppLoading={isLoading} />
      {!singleCampaignGroupId && (
        <>
          <CampaignGroupListActionBar
            activeSearchTerm={searchField}
            activeSort={{
              field: activeSortValues.field,
              order: activeSortValues.order,
            }}
            initialFilters={[]}
            onSearch={(term) => {
              setGroupsPage(0);
              setSearchField(term);
            }}
            onSort={(sortValue, sortOrder) => {
              setActiveSortValues({
                field: sortValue,
                order: sortOrder,
              });
            }}
          />
          <Stack direction="row" spacing={1} className={styles.title}>
            <Typography variant="h3">{t('campaign_groups')}</Typography>
            <Typography variant="h3" color="textSecondary">
              ({pagination?.results})
            </Typography>
          </Stack>
        </>
      )}
      <>
        {!isLoading &&
          campaignGroups &&
          campaignGroups?.map((campaignGroup, index) => (
            <div key={campaignGroup?.group_id}>
              {campaignGroup && (
                <CampaignGroupAccordion
                  key={campaignGroup.group_id}
                  campaignGroup={campaignGroup}
                  headerExpansionHandler={() =>
                    setExpandedIndex(index === expandedIndex ? -1 : index)
                  }
                  lazyLoadCount
                  expanded={index === expandedIndex}
                  {...(isCampaignGroupReportingEnabled && {
                    selection: {
                      selected:
                        selectedCampaignGroupId === campaignGroup.group_id,
                      onSelect: onCampaignGroupSelect,
                    },
                  })}
                >
                  {campaignGroup.group_id && (
                    <ReportingCampaignTable
                      sortValue={{
                        field:
                          activeSortValues.field ===
                          'CAMPAIGN_GROUP_SORT_LAST_MODIFIED'
                            ? 'CAMPAIGN_SORT_UPDATED_AT'
                            : 'CAMPAIGN_SORT_UNKNOWN',
                        order: activeSortValues.order,
                      }}
                      selectedId={selectedCampaignGroupId}
                      campaignGroup={campaignGroup}
                      onSelect={(reportData) => {
                        setSelectedCampaignGroupId(reportData.id);
                        onSelect(reportData);
                      }}
                      emptyStateMessage={t('no_linked_campaign')}
                      showActionBar={false}
                      userPreferencesKey="reportingPageSettings_campaignGroupsTab"
                    />
                  )}
                </CampaignGroupAccordion>
              )}
            </div>
          ))}
      </>
      {pagination && (
        <TablePagination
          component="div"
          page={groupsPage}
          rowsPerPage={DEFAULT_API_PARAMETERS.per_page_small}
          onPageChange={(event, page: number) => {
            setExpandedIndex(0);
            setGroupsPage(page);
          }}
          rowsPerPageOptions={[]}
          count={pagination?.results || -1}
        />
      )}
    </>
  );
};
