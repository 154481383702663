import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { addMonths, startOfMonth, subMonths } from 'date-fns';
import { MONTH_RANGE_TO_FETCH_UNAVAILABILITY } from 'shared/constants';
import { definitions } from 'types/api';
import { formattedISOString } from 'utils/time';

import { useStoreDispatch } from 'store';
import { useLazyGetProductsUnavailabilityQuery } from 'store/api/endpoints/productUnavailability';
import {
  setDraftTouchedFields,
  updateDraft,
} from 'store/modules/campaign/slice';

import { CampaignSectionProps } from 'components/Campaigns/CampaignDetails/CampaignDetails';
import { FormError } from 'components/Shared/CustomMui';
import DatePicker from 'components/Shared/CustomMui/DatePicker/DatePicker';

import { Grid } from '@mui/material';

import { CampaignSectionWrapper } from '.';

type Campaign = definitions['Campaign'];

interface Props extends CampaignSectionProps {
  attribution_window_end: Campaign['attribution_window_end'];
  campaign_start: null | Campaign['campaign_start'];
  campaign_end: null | Campaign['campaign_end'];
  state: definitions['CampaignState'];
  selectedProductGtins: string[];
  retailerId: string;
  countryCode: string;
}

const CampaignWindow: FC<Props> = (props: Props) => {
  const {
    countryCode,
    campaign_start,
    campaign_end,
    state,
    formState,
    validate,
    retailerId,
    selectedProductGtins,
  } = props;
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [productsUnavailability, setProductsUnavailability] = useState<
    definitions['ProductUnavailabilityDuration'][]
  >([]);

  const startValidations = validate?.('campaign_start');
  const endDateValidations = validate?.('campaign_end');

  const [getProductsUnavailability] = useLazyGetProductsUnavailabilityQuery();

  const datePickerOpenHandler = async (date?: Date) => {
    if (selectedProductGtins?.length && !!countryCode) {
      const activeDate = startOfMonth(date || new Date());
      const productsUnavailabilityResponse = await fetchProductsUnavailability(
        activeDate
      );
      setProductsUnavailability(productsUnavailabilityResponse);
    }
  };

  const fetchProductsUnavailability = (
    activeDate: Date
  ): Promise<definitions['ProductUnavailabilityDuration'][]> => {
    return getProductsUnavailability({
      gtins: selectedProductGtins,
      retailer_id: retailerId,
      start: formattedISOString(
        subMonths(activeDate, MONTH_RANGE_TO_FETCH_UNAVAILABILITY)
      ),
      end: formattedISOString(
        addMonths(activeDate, MONTH_RANGE_TO_FETCH_UNAVAILABILITY)
      ),
      country_code: countryCode,
    }).unwrap();
  };

  return (
    <CampaignSectionWrapper
      header={t('campaign_window')}
      subHeader={t('subheader_campaign_window')}
      required
    >
      <Grid container>
        <Grid item>
          <DatePicker
            dateRange={[campaign_start, campaign_end]}
            fixedStart={
              state !== 'CAMPAIGN_STATE_DRAFT' &&
              state !== 'CAMPAIGN_STATE_REJECTED'
            }
            pauseRanges={productsUnavailability}
            onOpen={datePickerOpenHandler}
            onMonthChange={(date: Date) => datePickerOpenHandler(date)}
            onChange={(dateRange) => {
              dispatch(setDraftTouchedFields('campaign_start'));
              dispatch(setDraftTouchedFields('campaign_end'));
              dispatch(
                updateDraft({
                  campaign_start: dateRange[0]
                    ? formattedISOString(new Date(dateRange[0]))
                    : null,
                  campaign_end: dateRange[1]
                    ? formattedISOString(new Date(dateRange[1]))
                    : null,
                })
              ); // PACMAN-842
            }}
            startError={!!startValidations}
            endError={!!endDateValidations}
            startText={t('datepicker_start')}
            endText={t('datepicker_end')}
            disabled={
              formState.readonlyFields.includes('CampaignWindow') ||
              !selectedProductGtins?.length
            }
          />
          {<FormError>{startValidations || endDateValidations}</FormError>}
        </Grid>
      </Grid>
    </CampaignSectionWrapper>
  );
};

export default memo(CampaignWindow);
