import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Link as RouterLink } from 'react-router-dom';

import { Routes } from 'routes/Routes';

import styles from './Footer.module.scss';

export const Footer = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <div className={styles.footer}>
      <RouterLink to={Routes.IMPRINT} className={styles.link}>
        {t('imprint')}
      </RouterLink>
      <span className={styles.divider}> | </span>
      <RouterLink to={Routes.PRIVACY_POLICY} className={styles.link}>
        {t('privacy_policy')}
      </RouterLink>
      <span className={styles.divider}> | </span>
      <RouterLink to={Routes.COOKIE_NOTICE} className={styles.link}>
        {t('cookie_notice')}
      </RouterLink>
      {auth?.isAuthenticated && (
        <>
          <span className={styles.divider}> | </span>
          <a
            className={styles.link}
            target="_blank"
            href="https://www.media.schwarz/agb"
            rel="noreferrer"
          >
            {t('terms_and_conditions')}
          </a>
        </>
      )}
    </div>
  );
};
