import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { User } from 'types';

import { PrivateRoutes } from 'routes/PrivateRoutes';
import { getAuthorizedRoutes } from 'routes/RouteInfo';
import { Routes } from 'routes/Routes';

import { setAppUser } from 'store/modules/user/slice';

export const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const auth = useAuth();

  function redirectToUnauthorizedPath(
    redirectToPath: string,
    userRole: string
  ): boolean {
    const authorizedRoutes = getAuthorizedRoutes(
      Object.values(PrivateRoutes),
      userRole
    );

    return !authorizedRoutes.some((x) => matchPath(x.path, redirectToPath));
  }

  useEffect(() => {
    const userRole = auth.user?.profile.role as string;
    if (auth.isAuthenticated && userRole) {
      if (auth?.user) {
        dispatch(
          setAppUser({
            ...auth?.user?.profile,
            advertiser_id: auth?.user?.profile['advertiser-id'],
            network_id: auth?.user?.profile['network-id'],
          } as User)
        );
      }
      // get redirect_uri from query params
      const searchParams = new URLSearchParams(location.search);
      const redirectUri = searchParams.get('redirect_uri');
      let redirectToPath = redirectUri || Routes.ROOT;

      // check if from is not in the list of authorized routes
      // we don't want to redirect to the not found screen immediately after login
      if (redirectToUnauthorizedPath(redirectToPath, userRole))
        redirectToPath = Routes.ROOT;

      navigate(redirectToPath, { replace: true });
    }
  }, [auth]);

  return !auth.isAuthenticated ? <>Loading...</> : <></>;
};
