import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { tkCampaignSortField } from 'i18n/translationKeyMaps';
import { xor } from 'lodash';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { definitions } from 'types/api';

import { getRelatedCampaignStates } from 'store/modules/campaign/helpers';
import { selectUser } from 'store/modules/user/selectors';

import CampaignsTable from 'components/Shared/CampaignsTable/CampaignsTable';
import { EntitySelectedFilterType } from 'components/Shared/ListActionBar/components';

import { Box, Grid } from '@mui/material';

import { SelectCampaignType } from '../SelectCampaignType/SelectCampaignType';
import { CampaignListActionBar } from './CampaignListActionbar/CampaignListActionbar';
import styles from './CampaignListing.module.scss';

const CampaignsListing: FC = (): ReactElement => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isAllAdvertisersSelected, setIsAllAdvertisersSelected] =
    useState<boolean>(searchParams.get('all-advertisers') === 'true');

  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams?.get('q') || ''
  );
  const [activeSortValues, setActiveSortValues] = useState<{
    field: definitions['CampaignSortField'];
    order: definitions['SortOrder'];
  }>({
    field:
      searchParams.has('sortBy') &&
      !!tkCampaignSortField(
        searchParams.get('sortBy') as definitions['CampaignSortField']
      )
        ? (searchParams?.get('sortBy') as definitions['CampaignSortField'])
        : 'CAMPAIGN_SORT_NAME',
    order:
      searchParams.has('sortOrder') &&
      ['SORT_ORDER_UNKNOWN', 'SORT_ORDER_ASC', 'SORT_ORDER_DESC'].includes(
        searchParams.get('sortOrder')!
      )
        ? (searchParams?.get('sortOrder') as definitions['SortOrder'])
        : 'SORT_ORDER_ASC',
  });
  const [stateFilterValues, setStateFilterValues] = useState<string[]>(
    searchParams?.getAll('state') || []
  );
  const [typeFilterValues, setTypeFilterValues] = useState<string[]>(
    searchParams?.getAll('type') || []
  );

  const [unAssignedPage, setUnAssignedPage] = useState(
    parseInt(
      searchParams?.get('unAssignedPage') || `${DEFAULT_API_PARAMETERS.page}`
    )
  );
  const [assignedPage, setAssignedPage] = useState(
    parseInt(
      searchParams?.get('assignedPage') || `${DEFAULT_API_PARAMETERS.page}`
    )
  );

  const resetListingItems = () => {
    setUnAssignedPage(1);
    setAssignedPage(1);
    updateSearchParams({}, ['unAssignedPage', 'assignedPage']);
  };

  const handleFilterChange = (filters: EntitySelectedFilterType[]) => {
    let stateFilters =
      filters
        ?.find((filter) => filter.field?.value === 'state')
        ?.activeFilterValues?.map((x) => x.value) || [];

    const typeFilters =
      filters
        ?.find((filter) => filter.field?.value === 'type')
        ?.activeFilterValues?.map((x) => x.value) || [];

    if (xor(stateFilters, stateFilterValues).length) {
      resetListingItems();
      setStateFilterValues(stateFilters);
    }

    if (xor(typeFilters, typeFilterValues).length) {
      resetListingItems();
      setTypeFilterValues(typeFilters);
    }
    updateSearchParams({
      type: typeFilters,
      state: stateFilters,
    });
  };

  const extendedStateFilterValues = stateFilterValues.reduce(
    (acc: string[], state: string) =>
      acc.concat(
        getRelatedCampaignStates(state as definitions['CampaignState'])
      ),
    []
  );

  const updateSearchParams = (
    paramsToAdd: Record<string, string | string[]>,
    paramsToDelete?: string[]
  ): void => {
    if (Object.keys(paramsToAdd).length) {
      const paramNames = Object.keys(paramsToAdd);
      paramNames.forEach((paramName: string) => {
        if (Array.isArray(paramsToAdd[paramName])) {
          searchParams.delete(paramName);
          (paramsToAdd[paramName] as string[]).forEach((paramValue: string) => {
            searchParams.append(paramName, paramValue);
          });
        } else {
          searchParams.set(paramName, paramsToAdd[paramName] as string);
        }
      });
    }
    if (paramsToDelete?.length) {
      paramsToDelete.forEach((paramName: string) =>
        searchParams.delete(paramName)
      );
    }
    setSearchParams(searchParams);
  };

  const areSearchAndFiltersSelected = (): boolean => {
    return !!(
      typeFilterValues.length ||
      stateFilterValues.length ||
      searchTerm.length > 0
    );
  };

  return (
    <>
      {user?.advertiser_id && (
        <>
          <Box className={styles.action_bar}>
            <CampaignListActionBar
              onAllAdvertisersSelectionChange={(value) => {
                resetListingItems();
                setIsAllAdvertisersSelected(value);
                updateSearchParams({
                  'all-advertisers': value ? 'true' : 'false',
                });
              }}
              allAdvertisersSelected={isAllAdvertisersSelected}
              activeSearchTerm={searchTerm}
              activeSort={{
                field: activeSortValues.field,
                order: activeSortValues.order,
              }}
              initialFilters={{
                state: stateFilterValues,
                type: typeFilterValues,
              }}
              onSearch={(term: string) => {
                resetListingItems();
                setSearchTerm(term);
                if (!!term) {
                  updateSearchParams({ q: term });
                } else {
                  updateSearchParams({}, ['q']);
                }
              }}
              onSort={(sortValue, sortOrder) => {
                resetListingItems();
                setActiveSortValues({
                  field: sortValue,
                  order: sortOrder,
                });
                updateSearchParams({
                  sortBy: sortValue,
                  sortOrder,
                });
              }}
              onFiltersChange={handleFilterChange}
            ></CampaignListActionBar>
          </Box>
          <Grid container spacing={4}>
            <Grid item className={styles.table_container}>
              <CampaignsTable
                allAdvertisersSelected={isAllAdvertisersSelected}
                activeSortValues={activeSortValues}
                searchField={searchTerm}
                stateFilterValues={extendedStateFilterValues}
                typeFilterValues={typeFilterValues}
                groupAssignment={
                  areSearchAndFiltersSelected() ? 'DEFAULT' : 'UNASSIGNED'
                }
                page={unAssignedPage}
                setPage={(page: number) => {
                  updateSearchParams({ unAssignedPage: page.toString() });
                  setUnAssignedPage(page);
                }}
                title={t(
                  areSearchAndFiltersSelected() ? 'campaigns' : 'unassigned'
                )}
              />
            </Grid>

            {!areSearchAndFiltersSelected() && (
              <Grid item className={styles.table_container}>
                <CampaignsTable
                  allAdvertisersSelected={isAllAdvertisersSelected}
                  activeSortValues={activeSortValues}
                  searchField={searchTerm}
                  stateFilterValues={stateFilterValues}
                  typeFilterValues={typeFilterValues}
                  groupAssignment={'ASSIGNED'}
                  page={assignedPage}
                  setPage={(page: number) => {
                    updateSearchParams({ assignedPage: page.toString() });
                    setAssignedPage(page);
                  }}
                  title={t('assigned')}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}

      {isCreateModalOpen && (
        <SelectCampaignType
          handleCancelButtonClick={() => setIsCreateModalOpen(false)}
        />
      )}
    </>
  );
};

export default CampaignsListing;
