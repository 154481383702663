import { FC, MutableRefObject, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as CircleCheckIcon } from 'assets/icons/circle-check.svg';
import { ReactComponent as CirclePlusIcon } from 'assets/icons/circle-plus.svg';
import { useElementOnScreen } from 'hooks';
import { xor } from 'lodash';
import {
  DEFAULT_API_PARAMETERS,
  FETCH_PRODUCT_BY_GTIN_PAGE_SIZE,
} from 'shared/constants';
import { definitions } from 'types/api';

import {
  useLazyGetTaxonomyProductsByGTINQuery,
  useLazyGetTaxonomyProductsQuery,
} from 'store/api/endpoints/productTaxonomies';

import { Column, Table } from 'components/Shared/CustomMui';
import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import { Button, IconButton, SvgIcon } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import styles from './AddProductsDialog.module.scss';
import { AddProductsDialogFilter } from './AddProductsDialogFilter/AddProductsDialogFilter';

interface Props {
  onCancel: () => void;
  onBackClick?: () => void;
  onConfirm: (
    productGTINs: string[],
    attributesAndSearchTerm: { attributes: string[]; searchTerm: string }
  ) => void;
  typeUniverse?: boolean;
  backButtonText?: string;
  taxonomyId: string;
  alreadySelectedProductGTINs?: string[];
  alreadyAppliedSearchTerm?: string;
  alreadySelectedProductAttributes?: string[];
  retailer_ids?: string[];
  readonly?: boolean;
}

export const AddProductsDialog: FC<Props> = ({
  onCancel,
  onBackClick,
  onConfirm,
  typeUniverse,
  taxonomyId,
  readonly,
  backButtonText,
  alreadyAppliedSearchTerm,
  alreadySelectedProductGTINs = [],
  alreadySelectedProductAttributes = [],
}: Props): ReactElement => {
  const { t } = useTranslation();

  const [allProducts, setAllProducts] = useState<
    definitions['TaxonomyProduct'][]
  >([]);
  const [selectedProductGTINs, setSelectedProductGTINs] = useState<string[]>(
    alreadySelectedProductGTINs
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    alreadyAppliedSearchTerm || ''
  );
  const [page, setPage] = useState<number>(DEFAULT_API_PARAMETERS.page);
  const [showSelectedOnly, setShowSelectedOnly] = useState<boolean>(
    !!alreadySelectedProductGTINs?.length
  );
  const [showSelectAllConfirmation, setShowSelectAllConfirmation] =
    useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);

  const [areInitialAttributesSet, setAreInitialAttributesSet] =
    useState<boolean>(true);
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>(
    alreadySelectedProductAttributes
  );

  const [
    getTaxonomyProductsPerPage,
    {
      data: taxonomyProductsApiData,
      isLoading: isTaxonomyProductsLoading,
      isFetching: isTaxonomyProductsFetching,
    },
  ] = useLazyGetTaxonomyProductsQuery();

  const [
    getTaxonomyProductsByGtins,
    {
      data: selectedProductsData,
      isLoading: isSelectedProductsLoading,
      isFetching: isSelectedProductsFetching,
    },
  ] = useLazyGetTaxonomyProductsByGTINQuery();

  /**
   * Fetches taxonomy products based on taxonomy id and optional taxonomy attributes
   */
  useEffect(() => {
    const fetchTaxonomyProducts = async () => {
      if (taxonomyId && !showSelectedOnly) {
        await getTaxonomyProductsPerPage({
          product_taxonomy_id: taxonomyId,
          query: {
            attributes: selectedAttributes,
            page,
            per_page: DEFAULT_API_PARAMETERS.per_page,
            ...(searchTerm && { search: searchTerm }),
          },
        });
      }
    };
    fetchTaxonomyProducts();
  }, [page, searchTerm, taxonomyId, selectedAttributes, showSelectedOnly]);

  const [selectAllEnabled, setSelectAllEnabled] = useState<boolean>(false);
  const [areAllSelected, setAreAllSelected] = useState<boolean>(
    !!alreadySelectedProductAttributes?.length || !!alreadyAppliedSearchTerm
  );

  useEffect(() => {
    if (selectedAttributes.length || !!searchTerm) {
      setSelectAllEnabled(true);
    } else {
      setSelectAllEnabled(false);
      if (areAllSelected) {
        setAreAllSelected(false);
        setSelectedProductGTINs([]);
      }
    }
  }, [selectedAttributes, searchTerm]);

  useEffect(() => {
    if (areAllSelected) {
      setSelectedProductGTINs(allProducts.map((product) => product.gtin));
    }
  }, [allProducts, areAllSelected]);

  useEffect(() => {
    const setProducts = async () => {
      const newTaxonomyResults = taxonomyProductsApiData?.products || [];
      setAllProducts((previousProducts: definitions['TaxonomyProduct'][]) => {
        const allProductsList =
          page > 1
            ? previousProducts.concat(newTaxonomyResults)
            : [...newTaxonomyResults];
        checkAndSetSelectedProducts(allProductsList, areAllSelected);
        return allProductsList;
      });
    };
    if (!isTaxonomyProductsFetching) {
      setProducts();
    }
  }, [taxonomyProductsApiData, isTaxonomyProductsFetching]);

  useEffect(() => {
    if (showSelectedOnly) {
      const fetchSelectedProductObjects = async () => {
        const startingIndex = (page - 1) * FETCH_PRODUCT_BY_GTIN_PAGE_SIZE;
        const gtinsToLoad = selectedProductGTINs.slice(
          startingIndex,
          startingIndex + (FETCH_PRODUCT_BY_GTIN_PAGE_SIZE - 1)
        );
        if (gtinsToLoad.length > 0) {
          await getTaxonomyProductsByGtins({
            product_taxonomy_id: taxonomyId,
            gtins: gtinsToLoad,
          });
        }
      };
      fetchSelectedProductObjects();
    }
  }, [showSelectedOnly, page]);

  useEffect(() => {
    if (showSelectedOnly && !isSelectedProductsFetching) {
      if (page === 1) {
        setAllProducts(selectedProductsData?.products || []);
      } else {
        setAllProducts((previousProducts) => [
          ...previousProducts,
          ...(selectedProductsData?.products || []),
        ]);
      }
    }
  }, [selectedProductsData, isSelectedProductsFetching]);

  const checkAndSetSelectedProducts = (
    allProductsList: definitions['TaxonomyProduct'][],
    areAllSelected: boolean
  ): void => {
    if (areAllSelected) {
      setSelectedProductGTINs(
        allProductsList?.map(
          (product: definitions['TaxonomyProduct']) => product.gtin
        )
      );
    }
  };

  const selectedProductGTIN = (gtin: string): string | undefined => {
    return selectedProductGTINs.find((productGtin) => productGtin === gtin);
  };

  const [containerRef, isVisible] = useElementOnScreen(
    {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    },
    (showSelectedOnly
      ? selectedProductGTINs?.length
      : taxonomyProductsApiData?.pagination.results) === allProducts?.length
  );

  useEffect(() => {
    if (
      isTaxonomyProductsLoading ||
      isTaxonomyProductsFetching ||
      isSelectedProductsLoading ||
      isSelectedProductsFetching
    ) {
      return;
    }
    if (isVisible) {
      setPage((previousPage: number) => previousPage + 1);
    }
  }, [isVisible]);

  const headerText = t(
    readonly ? 'products' : typeUniverse ? 'select_products' : 'products'
  );

  const subheaderText = readonly
    ? ''
    : typeUniverse
    ? t('subheader_universe_products_selection')
    : t('subheader_campaign_products_selection');

  const columns: Column[] = [
    {
      field: 'gtin',
      headerName: t('gtin'),
    },
    {
      field: 'product_name',
      headerName: t('description'),
    },
    {
      key: 'product_selection',
      field: 'gtin',
      headerName: '',
      justify: 'flex-end',
      renderCell: (gtin: string) => {
        if (readonly) return null;
        return (
          <>
            <IconButton
              data-id={gtin}
              color={!!selectedProductGTIN(gtin) ? 'success' : 'primary'}
              disabled={areAllSelected}
              onClick={() =>
                setSelectedProductGTINs((previousSelectedGTINs: string[]) => {
                  if (previousSelectedGTINs.includes(gtin)) {
                    return previousSelectedGTINs.filter(
                      (productGTINIterator: string) =>
                        productGTINIterator !== gtin
                    );
                  } else {
                    // Append products at the end of the list
                    return [...previousSelectedGTINs, gtin];
                  }
                })
              }
            >
              <SvgIcon
                component={
                  !!selectedProductGTIN(gtin) ? CircleCheckIcon : CirclePlusIcon
                }
                viewBox={'0 0 32 32'}
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  const setSelectedAttributesValues = (attributes: string[]): void => {
    if (
      typeUniverse &&
      alreadySelectedProductAttributes.length &&
      !areInitialAttributesSet
    ) {
      setSelectedAttributes(alreadySelectedProductAttributes);
      setAreAllSelected(true);
      setAreInitialAttributesSet(true);
    } else {
      setSelectedAttributes(attributes);
      setAreAllSelected(false);
    }
  };

  const handleToggleAllSelectionClick = (): void => {
    setAreAllSelected((prevState: boolean) => {
      const newState = !prevState;
      if (!newState) {
        setSelectedProductGTINs([]);
      } else {
        if (selectedProductGTINs?.length) {
          setShowSelectAllConfirmation(true);
          // The new state of areAllSelected will be determined in the `confirm` callback of the selectAll confirmation prompt
          return prevState;
        } else {
          checkAndSetSelectedProducts(allProducts, true);
        }
      }
      return newState;
    });
  };

  const handleClose = (): void => {
    if (
      !!xor(selectedProductGTINs, alreadySelectedProductGTINs)?.length &&
      !readonly
    ) {
      setShowDeleteConfirmation(true);
    } else {
      onCancel();
    }
  };

  return (
    <>
      <DialogWrapper
        width={1000}
        headerText={headerText}
        subHeaderText={subheaderText}
        handleClose={handleClose}
        dialogContent={
          <Grid
            container
            justifyContent={'space-between'}
            className={!!onBackClick ? styles.dialog_with_back_button : ''}
          >
            {!!onBackClick && (
              <Grid item xs={12}>
                <Button
                  size="small"
                  startIcon={
                    <SvgIcon
                      component={ChevronLeftIcon}
                      viewBox={'0 0 32 32'}
                    />
                  }
                  onClick={onBackClick}
                >
                  {backButtonText || t('back')}
                </Button>
              </Grid>
            )}
            {!readonly && (
              <Grid item xs={12} className={styles.filters}>
                <AddProductsDialogFilter
                  product_taxonomy_id={taxonomyId}
                  activeSearchTerm={searchTerm}
                  initiallySelectedAttributes={alreadySelectedProductAttributes}
                  showSelectedOnly={showSelectedOnly}
                  disableShowSelectedOnlyFilter={
                    areAllSelected ||
                    (!!alreadyAppliedSearchTerm && !!searchTerm)
                  }
                  onSearch={(searchTerm: string) => {
                    setPage(1);
                    setSearchTerm(searchTerm);
                  }}
                  onAttributeFilter={(attributes: string[]) => {
                    if (xor(attributes, selectedAttributes).length) {
                      setPage(1);
                      setSelectedAttributesValues(attributes);
                      // [PACMAN-1441] Whenever the selected attributes are updated, the product selection should be reset to allow a fresh selection.
                      setSelectedProductGTINs([]);
                    }
                  }}
                  onSelectedOnlyFilterUpdate={(
                    areOnlySelectedShown: boolean
                  ) => {
                    setPage(1);
                    setAllProducts([]);
                    setShowSelectedOnly(areOnlySelectedShown);
                  }}
                />
              </Grid>
            )}
            <Grid container>
              <Grid item xs={12} className={styles.entries}>
                <div className={styles.result_count}>
                  <Typography variant="h3">{t('results')}</Typography>
                  <Typography variant="h3" color="textSecondary">
                    (
                    {allProducts.length
                      ? `${allProducts.length}/${
                          showSelectedOnly
                            ? selectedProductGTINs.length
                            : taxonomyProductsApiData?.pagination.results
                        }`
                      : 0}
                    )
                  </Typography>
                </div>
                {typeUniverse && !readonly && !showSelectedOnly && (
                  <Button
                    disabled={!selectAllEnabled}
                    onClick={handleToggleAllSelectionClick}
                    variant="contained"
                    size="small"
                  >
                    {areAllSelected
                      ? `- ${t('remove_all')}`
                      : `+ ${t('select_all')}`}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Table
              className={styles.scrollable_section}
              emptyState={
                <div className={styles.no_selected_product_section}>
                  {t('no_search_results')}
                </div>
              }
              columns={columns}
              minHeight={10}
              rows={allProducts.map((product) => {
                // Pass product gtin as id, because rows requires type Row where id is mandatory
                return { id: product.gtin, ...product };
              })}
              footerRef={containerRef as MutableRefObject<null>}
            />
          </Grid>
        }
        dialogActions={
          <>
            <Button onClick={handleClose} variant="outlined">
              {t('cancel')}
            </Button>
            {!readonly && (
              <Button
                disabled={!selectedProductGTINs?.length}
                onClick={() =>
                  onConfirm(!areAllSelected ? selectedProductGTINs : [], {
                    attributes: areAllSelected ? selectedAttributes : [],
                    searchTerm: areAllSelected ? searchTerm : '',
                  })
                }
                variant="contained"
              >
                {t('confirm')}
              </Button>
            )}
          </>
        }
      />
      {showDeleteConfirmation && (
        <DialogWrapper
          width={639}
          showCloseIcon={false}
          headerText={t('discard_prompt_header_selection')}
          handleClose={() => setShowDeleteConfirmation(false)}
          dialogContent={t('discard_prompt_message')}
          dialogActions={
            <>
              <Button
                onClick={() => setShowDeleteConfirmation(false)}
                variant="outlined"
              >
                {t('discard_prompt_cancel_button_text')}
              </Button>

              <Button
                onClick={() => {
                  setShowDeleteConfirmation(false);
                  onCancel();
                }}
                variant="contained"
              >
                {t('discard_prompt_confirm_button_text')}
              </Button>
            </>
          }
        />
      )}
      {showSelectAllConfirmation && (
        <DialogWrapper
          width={639}
          showCloseIcon={false}
          headerText={t('select_all_prompt_header')}
          handleClose={() => setShowSelectAllConfirmation(false)}
          dialogContent={t('select_all_prompt_message')}
          dialogActions={
            <>
              <Button
                onClick={() => setShowSelectAllConfirmation(false)}
                variant="outlined"
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={() => {
                  setShowSelectAllConfirmation(false);
                  setAreAllSelected(true);
                  checkAndSetSelectedProducts(allProducts, true);
                }}
                variant="contained"
              >
                {t('proceed')}
              </Button>
            </>
          }
        />
      )}
    </>
  );
};
