import { styled } from 'theme';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const InputS = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: number | string }>(({ width, theme }) => ({
  marginBottom: theme.spacing(4),
  position: 'relative',
  '& label': {
    color: theme.palette.text.secondary,
    display: 'block',
    fontSize: 13,
    lineHeight: '16px',
    marginBottom: theme.spacing(0.5),
  },
  '& .MuiInputBase-root': {
    width: width ?? 365,
  },
}));

export const SpanS = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const ErrorTooltipS = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#E42912',
    color: '#FFFFFF',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxWidth: 150,
  },
  zIndex: 0,
}));
