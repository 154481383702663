import { Component, ErrorInfo, ReactNode } from 'react';

import i18n from 'i18n';

import ErrorGeneral from 'components/Shared/Error/ErrorGeneral';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  message?: string;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, message: '' };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({ hasError: true });
    this.setState({ message: `${error.message}  ${error.stack}` });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorGeneral
          locationState={{
            error: {
              message: this.state.message || i18n.t('error_general'),
              status: '500',
            },
          }}
        />
      );
    }
    return this.props.children;
  }
}
