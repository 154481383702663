import React from 'react';

import classnames from 'classnames';

interface Props {
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const CloseHeavy: React.FC<Props> = ({ className, onClick }) => (
  <svg
    className={classnames('SVG-CloseHeavy-root', className)}
    fill="currentColor"
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
  >
    <path d="M11.0302 2.03023L9.96973 0.969727L5.99998 4.93948L2.03023 0.969727L0.969727 2.03023L4.93948 5.99998L0.969727 9.96973L2.03023 11.0302L5.99998 7.06048L9.96973 11.0302L11.0302 9.96973L7.06048 5.99998L11.0302 2.03023Z" />
  </svg>
);

export default CloseHeavy;
