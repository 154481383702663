import { useSelector } from 'react-redux';

import { ReactComponent as CircleAttentionIcon } from 'assets/icons/circle-attention.svg';
import { UserRole } from 'types';

import { selectUser } from 'store/modules/user/selectors';

import { SvgIcon, Tooltip } from '@mui/material';

import styles from './RejectReason.module.scss';

interface Props {
  reason: string;
}

export const RejectReason = ({ reason }: Props) => {
  const user = useSelector(selectUser);

  return (
    <>
      {user?.role === UserRole.KEY_ACCOUNT_MANAGER && (
        <span
          data-title={reason}
          data-testid="reject-reason-text"
          className={styles.reject_reason}
        >
          <Tooltip title={reason}>
            <SvgIcon component={CircleAttentionIcon} viewBox={'0 0 32 32'} />
          </Tooltip>
        </span>
      )}
    </>
  );
};
