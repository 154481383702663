import { createAction } from '@reduxjs/toolkit';

import {
  Notification,
  NotificationType,
} from 'store/modules/notifications/types';

interface AddNotification {
  id?: string;
  duration?: number;
  dismissId?: string;
  type: NotificationType;
  text: string;
}

export const addNotification =
  createAction<AddNotification>('notifications/add');
export const removeNotification = createAction<Notification['id']>(
  'notifications/remove'
);
