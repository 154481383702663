import { useTranslation } from 'react-i18next';

import { ReactComponent as CircleHelpIcon } from 'assets/icons/circle-help.svg';
import classnames from 'classnames';

import { useCampaignCumulatives } from 'components/Reporting/hooks/useCampaignCumulatives';
import {
  MetricType,
  metricTypeToFieldNameMapping,
} from 'components/Reporting/utils/utils';
import { NumberFormat } from 'components/Shared';

import { Paper, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';

import styles from './MetricTile.module.scss';

interface Props {
  metric: MetricType;
  decimalScale?: number;
  type?: 'number' | 'price';
  selectedMetric: MetricType;
  cumulatives: ReturnType<typeof useCampaignCumulatives>;
  variant: 'A' | 'B';
  currency: string;
  areMultipleCurrenciesInvolved: boolean;
  tileClickHandler: () => void;
}

export const MetricTile = ({
  type,
  metric,
  tileClickHandler,
  variant,
  currency,
  areMultipleCurrenciesInvolved,
  selectedMetric,
  cumulatives,
  decimalScale = 0,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Paper
      onClick={() => {
        if (selectedMetric !== metric) {
          tileClickHandler();
        }
      }}
      elevation={0}
      className={classnames(styles.tile, {
        [styles.active]: selectedMetric === metric,
        [styles.variant_a]: variant === 'A',
        [styles.variant_b]: variant === 'B',
      })}
    >
      <Stack direction="row" spacing={0.25} alignItems="center">
        <Typography variant="body1">{t(metric)}</Typography>
        <Tooltip
          title={t(metricTypeToFieldNameMapping[metric].translationKey)}
          placement="right"
        >
          <SvgIcon component={CircleHelpIcon} viewBox={'0 0 32 32'} />
        </Tooltip>
      </Stack>
      <Typography variant="subtitle1">
        {areMultipleCurrenciesInvolved && type === 'price' ? (
          <>{t('multiple_currencies')}</>
        ) : (
          !!cumulatives && (
            <NumberFormat
              decimalScale={decimalScale}
              displayType="text"
              thousandSeparator={'.'}
              decimalSeparator={','}
              value={Number(
                cumulatives[
                  metricTypeToFieldNameMapping[metric].cumulativeField
                ]
              )}
              {...(type === 'price' && {
                prefix: currency,
                fixedDecimalScale: true,
                decimalScale: 2,
              })}
            />
          )
        )}
      </Typography>
    </Paper>
  );
};
