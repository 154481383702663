import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { EMAIL_VALIDATION_REGEX } from 'shared/constants';
import { UserRole } from 'shared/enums';
import { ErrorCode } from 'types';
import { definitions } from 'types/api';

import { useAddUserMutation } from 'store/api/endpoints/userManagement';
import { addNotification } from 'store/modules/notifications/actions';
import { selectUser } from 'store/modules/user/selectors';

import { SelectInput, TextInput } from 'components/Shared/CustomMui';
import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import { LoadingButton } from '@mui/lab';
import { FormControl, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

interface Props {
  onClose: (addedUser: definitions['User'] | void) => void;
}

interface AddUserFormType {
  name: string;
  email: string;
  phone: string;
  role: UserRole | '';
}

const AddUser: FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const roles = Object.values(UserRole).map((role: string) => ({
    label: t(role),
    value: role,
  }));
  const initialFormState: AddUserFormType = {
    name: '',
    email: '',
    role: '',
    phone: '',
  };
  const [form, setForm] = useState<AddUserFormType>(initialFormState);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
  const [touchedFields, setTouchedFields] =
    React.useState<{ [key in keyof Partial<AddUserFormType>]: boolean }>();

  const [addUser, { isLoading: isUserAdding }] = useAddUserMutation();

  const isPhoneNumberValid = (phoneNumber: string): boolean => {
    const validNumberRegex = /^\+[0-9]{11,14}$/;
    return validNumberRegex.test(phoneNumber);
  };

  const userErrorMessage: string = form?.name
    ? ''
    : t('error_required', { entity: t('name') });
  const emailErrorMessage: string = form?.email
    ? isEmailValid
      ? ''
      : (t(ErrorCode.AUTH_INVALID_EMAIL) as string)
    : t('error_required', { entity: t('email') });
  const phoneErrorMessage: string = form?.phone
    ? isPhoneValid
      ? ''
      : (t('error_invalid_phone') as string)
    : t('error_required', { entity: t('phone') });
  const roleErrorMessage: string = form?.role
    ? ''
    : t('error_required', { entity: t('role') });
  const isConfirmDisabled =
    Object.values(form).some((value) => !value) ||
    !isEmailValid ||
    !isPhoneValid;

  const handleFormChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ): void => {
    setForm({ ...form, [event.target.name]: event.target.value });
    switch (event.target.name) {
      case 'email': {
        setIsEmailValid(EMAIL_VALIDATION_REGEX.test(event.target.value));
        break;
      }
      case 'phone': {
        setIsPhoneValid(isPhoneNumberValid(event.target.value));
        break;
      }
      default:
        break;
    }
  };

  const handleBlur = (key: keyof AddUserFormType): void => {
    setTouchedFields({ ...touchedFields, [key]: true });
  };

  const onCancel = (): void => {
    setForm(initialFormState);
    props.onClose();
  };

  const handleSave = async (): Promise<void> => {
    const addedUser = await addUser({
      ...form,
      role: form?.role as definitions['UserRole'],
      advertiser_id: user?.advertiser_id as string,
    } as definitions['User']).unwrap();
    if (addedUser) {
      dispatch(
        addNotification({
          text: t('invite_user_success'),
          type: 'success',
        })
      );
      props.onClose(addedUser);
    }
  };

  return (
    <DialogWrapper
      width={800}
      headerText={t('add_user')}
      subHeaderText={t('subheader_add_user')}
      handleClose={onCancel}
      dialogContent={
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TextInput
              required
              width={'100%'}
              label={t('name')}
              name="name"
              onChange={handleFormChange}
              value={form?.name}
              error={touchedFields?.name && userErrorMessage}
              onBlur={() => handleBlur('name')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInput
              required
              width={'100%'}
              error={touchedFields?.email && emailErrorMessage}
              label={t('email')}
              name="email"
              onChange={handleFormChange}
              value={form?.email}
              onBlur={() => handleBlur('email')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInput
              required
              width={'100%'}
              name="phone"
              value={form?.phone}
              error={touchedFields?.phone && phoneErrorMessage}
              label={t('phone')}
              onChange={handleFormChange}
              onBlur={() => handleBlur('phone')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <SelectInput
                required
                width={'100%'}
                label={t('role')}
                name="role"
                value={form?.role as string}
                onChange={handleFormChange}
                items={roles}
                error={touchedFields?.role && roleErrorMessage}
              />
            </FormControl>
          </Grid>
        </Grid>
      }
      dialogActions={
        <>
          <Button variant="outlined" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            loading={isUserAdding}
            disabled={isConfirmDisabled}
            onClick={handleSave}
          >
            {t('confirm')}
          </LoadingButton>
        </>
      }
    />
  );
};

export default AddUser;
