import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { tkCampaignSortField } from 'i18n/translationKeyMaps';
import xor from 'lodash/xor';
import { APPROVAL_STATE_TYPES, DEFAULT_API_PARAMETERS } from 'shared/constants';
import { UserRole } from 'types';
import { definitions } from 'types/api';
import Event from 'utils/EventsEmitter';

import { Routes } from 'routes/Routes';

import { useGetCampaignsQuery } from 'store/api/endpoints/campaign';
import { selectUser } from 'store/modules/user/selectors';

import { PageHeader } from 'components/Shared/CustomMui';

import { Grid, Typography } from '@mui/material';

import LoadingIndicator from '../Shared/LoadingIndicator/LoadingIndicator';
import styles from './CampaignApproval.module.scss';
import { CampaignApprovalActionbar } from './CampaignApprovalActionbar/CampaignApprovalActionbar';
import { CampaignApprovalTable } from './CampaignApprovalTable/CampaignApprovalTable';

const DEFAULT_SORT_AND_FILTER_VALUES: {
  sortField: definitions['CampaignSortField'];
  sortOrder: definitions['SortOrder'];
  statusFilter: definitions['CampaignState'][];
} = {
  sortField: 'CAMPAIGN_SORT_CAMPAIGN_START',
  sortOrder: 'SORT_ORDER_ASC',
  statusFilter: ['CAMPAIGN_STATE_SUBMITTED'],
};

const CampaignApproval: FC = (): ReactElement => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeSortValues, setActiveSortValues] = useState<{
    field: definitions['CampaignSortField'];
    order: definitions['SortOrder'];
  }>({
    field:
      searchParams.has('sortBy') &&
      !!tkCampaignSortField(
        searchParams.get('sortBy') as definitions['CampaignSortField']
      )
        ? (searchParams?.get('sortBy') as definitions['CampaignSortField'])
        : DEFAULT_SORT_AND_FILTER_VALUES.sortField,
    order:
      searchParams.has('sortOrder') &&
      ['SORT_ORDER_UNKNOWN', 'SORT_ORDER_ASC', 'SORT_ORDER_DESC'].includes(
        searchParams.get('sortOrder')!
      )
        ? (searchParams?.get('sortOrder') as definitions['SortOrder'])
        : DEFAULT_SORT_AND_FILTER_VALUES.sortOrder,
  });

  const [searchField, setSearchField] = useState<string>(
    searchParams?.get('q') || ''
  );
  const [statusFilterValues, setStatusFilterValues] = useState<
    definitions['CampaignState'][]
  >(
    (searchParams?.getAll('state') as definitions['CampaignState'][])?.length
      ? (searchParams?.getAll('state') as definitions['CampaignState'][])
      : DEFAULT_SORT_AND_FILTER_VALUES.statusFilter
  );

  const [page, setPage] = useState(
    parseInt(searchParams?.get('page') || `${DEFAULT_API_PARAMETERS.page}`)
  );

  const {
    data: newlyFetchedCampaigns,
    isLoading: isGetCampaignsLoading,
    isFetching: isGetCampaignsFetching,
  } = useGetCampaignsQuery(
    {
      page,
      sortBy: activeSortValues.field,
      order: activeSortValues.order,
      filter: {
        network_id: user?.network_id,
        search: searchField,
        state: statusFilterValues?.length
          ? statusFilterValues
          : APPROVAL_STATE_TYPES,
        type:
          user?.role === UserRole.SALESFORCE_MANAGER
            ? ['CAMPAIGN_TYPE_CONVERSION']
            : [],
      },
    },
    {
      skip: !user?.network_id,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    Event.addListener('event/navBarClicked', (payload) => {
      if (payload.includes(Routes.CAMPAIGNS_OVERVIEW)) {
        setPage(DEFAULT_API_PARAMETERS.page);
        setSearchField('');
        setStatusFilterValues(DEFAULT_SORT_AND_FILTER_VALUES.statusFilter);
        setActiveSortValues({
          field: DEFAULT_SORT_AND_FILTER_VALUES.sortField,
          order: DEFAULT_SORT_AND_FILTER_VALUES.sortOrder,
        });
      }
    });
  }, []);

  const handlePageChange = (_e: React.MouseEvent, newPage: number): void => {
    setPage(newPage);
    updateSearchParams({ page: newPage.toString() });
  };

  const resetListingItems = (): void => {
    setPage(DEFAULT_API_PARAMETERS.page);
    updateSearchParams({}, ['page']);
  };

  const handleFiltering = (values: definitions['CampaignState'][]): void => {
    updateSearchParams({
      state: values?.length ? values : [],
    });
    if (xor(values, statusFilterValues).length) {
      resetListingItems();
      setStatusFilterValues(values);
    }
  };

  const updateSearchParams = (
    paramsToAdd: Record<string, string | string[]>,
    paramsToDelete?: string[]
  ): void => {
    if (Object.keys(paramsToAdd).length) {
      const paramNames = Object.keys(paramsToAdd);
      paramNames.forEach((paramName: string) => {
        if (Array.isArray(paramsToAdd[paramName])) {
          searchParams.delete(paramName);
          (paramsToAdd[paramName] as string[]).forEach((paramValue: string) => {
            searchParams.append(paramName, paramValue);
          });
        } else {
          searchParams.set(paramName, paramsToAdd[paramName] as string);
        }
      });
    }
    if (paramsToDelete?.length) {
      paramsToDelete.forEach((paramName: string) =>
        searchParams.delete(paramName)
      );
    }
    setSearchParams(searchParams);
  };

  return (
    <>
      <LoadingIndicator
        isAppLoading={isGetCampaignsLoading || isGetCampaignsFetching}
      />
      <PageHeader
        headerText={t('campaign_approval')}
        subHeaderText={t('campaign_approval_description')}
      />
      <CampaignApprovalActionbar
        key={statusFilterValues + searchField + activeSortValues}
        activeStateFilters={statusFilterValues}
        activeSearchTerm={searchField}
        activeSort={activeSortValues}
        onSearch={(searchTerm: string) => {
          resetListingItems();
          setSearchField(searchTerm);
          if (!!searchTerm) {
            updateSearchParams({ q: searchTerm });
          } else {
            updateSearchParams({}, ['q']);
          }
        }}
        onFilter={(_, values) =>
          handleFiltering(values as definitions['CampaignState'][])
        }
        onSort={(sortValue, sortOrder) => {
          resetListingItems();
          setActiveSortValues({
            field: sortValue,
            order: sortOrder,
          });
          updateSearchParams({
            sortBy: sortValue,
            sortOrder,
          });
        }}
      />

      <Grid
        container
        className={styles.results_container}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item className={styles.result_count}>
          <Typography variant="h3">{t('results')}</Typography>
          <Typography variant="h3" color="textSecondary">
            ({newlyFetchedCampaigns?.query?.results})
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {!!newlyFetchedCampaigns?.query?.results && (
          <Grid item className={styles.table_container}>
            <CampaignApprovalTable
              campaigns={newlyFetchedCampaigns.results || []}
              pagination={{
                onPageChange: handlePageChange,
                page: newlyFetchedCampaigns?.query.page || page,
                per_page:
                  newlyFetchedCampaigns?.query.per_page ||
                  DEFAULT_API_PARAMETERS.per_page,
                results: newlyFetchedCampaigns?.query.results || 0,
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CampaignApproval;
