import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as TriangleWarningIcon } from 'assets/icons/triangle-warning.svg';
import { UserRole } from 'types';
import { definitions } from 'types/api';

import { selectUser } from 'store/modules/user/selectors';

import { mapUniversePercentageOrScore } from 'components/Universes/utils';

import { Stack, SvgIcon, Typography } from '@mui/material';

import styles from '../UniverseItem.module.scss';
import { UniverseItemField } from './UniverseItemField';

interface Props {
  build: definitions['UniverseBuild'];
}
export const UniverseAudienceField = ({ build }: Props) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const getScoreValueBasedOnBuilderTypeAndUserRole = (
    score: string
  ): string => {
    if (
      user?.role === UserRole.KEY_ACCOUNT_MANAGER &&
      build.shoppers_in_universe
    ) {
      return build.shoppers_in_universe;
    } else {
      return build.type === 'UNIVERSE_BUILD_TYPE_IMPORT'
        ? score
        : mapUniversePercentageOrScore(build);
    }
  };

  const field =
    build.type === 'UNIVERSE_BUILD_TYPE_IMPORT'
      ? t('number_of_shoppers')
      : build.type === 'UNIVERSE_BUILD_TYPE_BUILDER'
      ? t('audience')
      : '';

  const noShoppersInUniverse = build.status ===
    'UNIVERSE_BUILDER_STATUS_SUCCESS' &&
    build.is_empty && (
      <Stack direction="row" className={'warning'} spacing={1}>
        <SvgIcon
          component={TriangleWarningIcon}
          viewBox={'0 0 32 32'}
          className={styles.test_icon}
        />
        <Typography>{t('no_shoppers_in_universe')}</Typography>
      </Stack>
    );

  const score = build.score
    ? getScoreValueBasedOnBuilderTypeAndUserRole(build.score)
    : t('calculation_in_progress');

  return (
    <UniverseItemField field={field} value={noShoppersInUniverse || score} />
  );
};
