import { useTranslation } from 'react-i18next';

import { useAdBlockDetector } from 'hooks';

import { Alert } from '@mui/lab';

type Props = {
  children?: React.ReactNode;
};

const AdBlockWrapper = ({ children }: Props) => {
  const { detected } = useAdBlockDetector({ checkWithExternalService: false });
  const { t } = useTranslation();
  return (
    <>
      {detected && (
        <Alert severity="warning" sx={{ mb: 2, lineHeight: '18px' }}>
          {t('ad_block_detected')}
        </Alert>
      )}
      {children}
    </>
  );
};

export { AdBlockWrapper };
