import { Footer } from '../Footer/Footer';
import styles from './LayoutWithFooter.module.scss';

export const LayoutWithFooter: React.FC = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      {children}
      <Footer />
    </div>
  );
};
