import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { tkCampaignState } from 'i18n/translationKeyMaps';
import { CAMPAIGN_INTERNAL_STATES } from 'shared/constants/campaign-internal-states';
import { UserRole } from 'types';
import { definitions } from 'types/api';

import { getRelatedCampaignStates } from 'store/modules/campaign/helpers';

import {
  campaignSortFields,
  campaignStatusValues,
} from 'components/Campaigns/CampaignListing/CampaignListActionbar/CampaignListActionbarValues';
import { AccessResolver } from 'components/Shared/AccessResolver/AccessResolver';
import { ListActionBar } from 'components/Shared/ListActionBar/ListActionBar';

import styles from './ReportingFilters.module.scss';

export type ReportingPageFilterType = keyof Pick<
  definitions['Campaign'],
  'state'
>;

interface ReportingFiltersProps {
  activeSearchTerm: string;
  activeSortValue: {
    field: definitions['CampaignSortField'];
    order: definitions['SortOrder'];
  };
  activeStateFilters: string[];
  onSearch: (searchTerm: string) => void;
  onFilter: (
    filterField: keyof Pick<definitions['Campaign'], 'type' | 'state'>,
    filterValues: string[]
  ) => void;
  onSort: (
    sortBy: definitions['CampaignSortField'],
    sortOrder: definitions['SortOrder']
  ) => void;
  allAdvertisersSelected?: boolean;
  onAllAdvertisersSelectionChange?: (isSelected: boolean) => void;
}

export const ReportingFilters: FC<ReportingFiltersProps> = ({
  onFilter,
  onSearch,
  onSort,
  activeSortValue,
  activeStateFilters,
  activeSearchTerm,
  allAdvertisersSelected,
  onAllAdvertisersSelectionChange,
}: ReportingFiltersProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={styles.filter_bar_wrapper}>
      <ListActionBar>
        <ListActionBar.Search
          activeSearchTerm={activeSearchTerm}
          onSearch={(term: string) => {
            if (term !== activeSearchTerm) {
              onSearch(term);
            }
          }}
        ></ListActionBar.Search>
        <ListActionBar.Sort
          activeSortField={activeSortValue.field}
          activeSortOrder={activeSortValue.order}
          sortFields={campaignSortFields}
          onSort={(sortValue: string, sortOrder: definitions['SortOrder']) => {
            onSort(sortValue as definitions['CampaignSortField'], sortOrder);
          }}
        ></ListActionBar.Sort>

        <ListActionBar.MultiSelectFilter
          showFilterIcon={true}
          filterItem={{
            field: { label: t('state'), value: 'state' },
            filterValues: campaignStatusValues,
            activeFilterValues: activeStateFilters
              .filter((state) => !CAMPAIGN_INTERNAL_STATES.includes(state))
              .map((filterValue) => ({
                label: tkCampaignState(filterValue as any),
                value: filterValue,
              })),
          }}
          onFilter={(values: string[]) => {
            let campaignRelatedStates: string[] = [];
            values.forEach(
              (state) =>
                (campaignRelatedStates = campaignRelatedStates.concat(
                  getRelatedCampaignStates(
                    state as definitions['CampaignState']
                  )
                ))
            );
            onFilter('state', campaignRelatedStates);
          }}
        ></ListActionBar.MultiSelectFilter>
        {onAllAdvertisersSelectionChange && (
          <AccessResolver requiredRoles={[UserRole.KEY_ACCOUNT_MANAGER]}>
            <ListActionBar.SwitchFilter
              alreadyChecked={allAdvertisersSelected}
              filterName={{
                label: t('all_advertisers'),
                value: 'all_advertisers',
              }}
              onFilter={onAllAdvertisersSelectionChange}
            ></ListActionBar.SwitchFilter>
          </AccessResolver>
        )}
      </ListActionBar>
    </div>
  );
};
