import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGetEnvironment } from 'hooks';

import { Routes } from 'routes/Routes';

import { selectUser } from 'store/modules/user/selectors';

import { PageHeader, TextInput } from 'components/Shared/CustomMui';

import { Button, Grid, Typography } from '@mui/material';

const Profile: FC = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const { authConfig } = useGetEnvironment();

  if (!user) return null;

  return (
    <>
      <PageHeader
        headerText={t('my_profile')}
        subHeaderText={t('subheader_profile')}
      />
      <Grid container sx={{ marginBottom: '48px' }}>
        <Grid item xs={12} container gap={4} sx={{ marginBottom: '16px' }}>
          <TextInput
            label={t('first_name')}
            value={user?.given_name || ''}
            readOnly
            data-testid="first-name"
          />
          <TextInput
            label={t('last_name')}
            value={user?.family_name || ''}
            readOnly
            data-testid="last-name"
          />
        </Grid>
        <Grid item xs={12} container gap={4} sx={{ marginBottom: '16px' }}>
          <TextInput
            label={t('phone')}
            value={(user?.phone as string) || ''}
            readOnly
          />
          <TextInput
            label={t('position')}
            value={(user?.role as string) || ''}
            readOnly
            data-testid="position"
          />
        </Grid>
        <Grid item xs={12} container gap={4}>
          <TextInput
            label={t('email')}
            value={user.email || ''}
            readOnly
            disabled
            data-testid="email"
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{ marginBottom: '12px' }}
            data-testid="password-title"
          >
            {t('password')}
          </Typography>
          <Typography
            variant="h4"
            sx={{ marginBottom: '24px' }}
            data-testid="password-description"
          >
            {t('password_description')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              (window.location.href = `${
                authConfig?.authority
              }/protocol/openid-connect/auth?response_type=code&client_id=${
                authConfig?.client_id
              }&redirect_uri=${
                window.location.origin + Routes.AUTHENTICATION_CALLBACK
              }&kc_action=UPDATE_PASSWORD`)
            }
            data-testid="change-password-button"
          >
            {t('change_password')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
