import { useTranslation } from 'react-i18next';

import { ListActionBar } from 'components/Shared/ListActionBar/ListActionBar';

interface Props {
  onSearch: (term: string) => void;
  onFilter: (filterName: string, filterValue?: string) => void;
  activeSearchTerm: string;
  activeBuilderTypeFilter?: string;
}

export const UniverseListActionBar = ({
  onSearch,
  onFilter,
  activeSearchTerm,
  activeBuilderTypeFilter,
}: Props) => {
  const { t } = useTranslation();
  return (
    <ListActionBar>
      <ListActionBar.Search
        activeSearchTerm={activeSearchTerm}
        onSearch={(term: string) => {
          if (term !== activeSearchTerm) {
            onSearch(term);
          }
        }}
      />
      <ListActionBar.SwitchFilter
        alreadyChecked={
          activeBuilderTypeFilter === 'UNIVERSE_BUILD_TYPE_BUILDER'
        }
        filterName={{
          label: t('only_manually_created_universes'),
          value: t('only_manually_created_universes'),
        }}
        onFilter={(value: boolean) =>
          value
            ? onFilter('builder_type', 'UNIVERSE_BUILD_TYPE_BUILDER')
            : onFilter('builder_type', undefined)
        }
      ></ListActionBar.SwitchFilter>
    </ListActionBar>
  );
};
