import { styled } from 'theme';

import { Paper, Typography } from '@mui/material';

type PaperSProps = {
  selected: boolean;
};

const PaperS = styled(Paper)<PaperSProps>(({ theme, selected }) => ({
  cursor: 'pointer',
  width: '100%',
  height: '350px',
  padding: theme.spacing(2),
  borderRadius: 4,
  boxShadow:
    '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
  border: selected
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid transparent',
}));

const ImgS = styled('img')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const TypographyS = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export { PaperS, ImgS, TypographyS };
