import { FC } from 'react';
import { useSelector } from 'react-redux';

import {
  useGetAdvertiserQuery,
  useGetAllCountriesQuery,
  useGetChannelsQuery,
} from 'store/api/endpoints/settings';
import {
  selectAdvertiser,
  selectSettingsLoaded,
} from 'store/modules/settings/selectors';
import { selectUser } from 'store/modules/user/selectors';

import { AdBlockWrapper } from 'components/Core/AdBlockWrapper/AdBlockWrapper';
import { AppS, MainS } from 'components/Core/Application/Application.style';
import Notifications from 'components/Shared/Notifications/Notifications';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { LayoutWithFooter } from '../LayoutWithFooter/LayoutWithFooter';
import NavigationBar from '../Navigation/Bar/Bar';

export const Application: FC = ({ children }) => {
  const userProfile = useSelector(selectUser);
  const settingsLoaded = useSelector(selectSettingsLoaded);
  const user = useSelector(selectUser);
  const advertiser = useSelector(selectAdvertiser);

  useGetAdvertiserQuery(undefined, {
    skip: !userProfile?.advertiser_id,
  });
  useGetChannelsQuery(undefined, {
    skip:
      userProfile === null ||
      userProfile?.role === 'STAFF' ||
      !(advertiser?.network_id || user?.network_id),
  });
  useGetAllCountriesQuery(undefined, {
    skip: userProfile === null || userProfile?.role === 'STAFF',
  });

  return (
    <AppS>
      <NavigationBar />
      <Notifications />
      <MainS>
        <LayoutWithFooter>
          {settingsLoaded && (
            <ErrorBoundary>
              <AdBlockWrapper>{children}</AdBlockWrapper>
            </ErrorBoundary>
          )}
        </LayoutWithFooter>
      </MainS>
    </AppS>
  );
};
