import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { styled, SxProps, Theme } from '@mui/system';

const DialogS = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'dialogWidth',
})<{ dialogWidth?: number }>(({ dialogWidth, theme }) => ({
  '& .MuiPaper-root': {
    width: dialogWidth,
    maxWidth: dialogWidth,
  },
  '& .MuiDialogTitle-root': {
    '& .MuiTypography-root': {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '30px',
    },
  },
  '& .MuiDialogContent-root': {
    paddingTop: 0,
  },
  '& .MuiDialogContent-other': {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  },
}));

type Props = Pick<DialogProps, 'children'> & {
  id: string;
  headerText: string | React.ReactNode;
  descriptionText?: string;
  confirmButtonText: string;
  onConfirm: () => void | Promise<void>;
  onCancel: () => void;
  loading?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  dialogWidth?: number;
};
const CustomDialog: React.FC<Props> = (props) => {
  const {
    id,
    headerText,
    descriptionText,
    children,
    confirmButtonText,
    onConfirm,
    onCancel,
    loading = false,
    disabled = false,
    sx,
    dialogWidth = 550,
  } = props;
  const { t } = useTranslation();

  return (
    <DialogS aria-labelledby={id} open={true} sx={sx} dialogWidth={dialogWidth}>
      <DialogTitle id={id}>{headerText}</DialogTitle>
      <DialogContent>
        {!!descriptionText && (
          <DialogContentText>{descriptionText}</DialogContentText>
        )}
        <div className="MuiDialogContent-other">{children}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          {t('cancel')}
        </Button>
        <LoadingButton
          onClick={onConfirm}
          disabled={disabled}
          loading={loading}
          variant="contained"
        >
          {confirmButtonText}
        </LoadingButton>
      </DialogActions>
    </DialogS>
  );
};
export { CustomDialog };
