import { UserProfile } from 'oidc-client-ts';
import { definitions } from 'types/api';

// export type User = {
//   first_name: string;
//   last_name: string;
//   full_name?: string;
//   company: string;
//   position: string;
//   role: string;
//   network_id: string;
//   verified: boolean;
// } & Omit<definitions['User'], 'role'>;

export type User = UserProfile & {
  network_id?: string;
  'advertiser-id'?: string;
} & Omit<definitions['User'], 'role'>;

export enum UserRole {
  ADVERTISER_ADMIN = 'advertiser-admin',
  ADVERTISER_CAMPAIGN_MANAGER = 'advertiser-campaign-manager',
  APPROVAL_MANAGER = 'approval-manager',
  KEY_ACCOUNT_MANAGER = 'key-account-manager',
  SALESFORCE_MANAGER = 'salesforce-manager',
  STAFF = 'staff',
}
