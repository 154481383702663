import { styled } from 'theme';

import { Menu } from '@mui/material';

export const UserDropdownStyle = styled(Menu)(({ theme }) => ({
  '.MuiMenu-paper': {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  '.MuiList-root': {
    padding: 0,
  },
}));
