import { useTranslation } from 'react-i18next';

import { upsertObjectInArray } from 'utils';

import { useListActionBar } from 'components/Shared/ListActionBar/hooks/useListActionBar';

import { Button, Chip, Stack } from '@mui/material';

import { LabelValuePairType } from '../types';
import styles from './SelectedFilterBar.module.scss';

export const SelectedFilterBar = () => {
  const { t } = useTranslation();
  const { setUpdatedFilters, setSelectedFilters, selectedFilters } =
    useListActionBar();

  const handleRemoveFilterValue = (fieldName: string, filterValue: string) => {
    // get the current filter object from the selectedFilters
    const currentFilterObject = selectedFilters.filter(
      (x) => x.field.value === fieldName
    )[0];

    // extract the removed filter value from the filter object
    const filters = currentFilterObject?.activeFilterValues?.filter(
      (x) => x.value !== filterValue
    );

    // update the selectedFilters with the new filter object
    const newSelectedFilters = upsertObjectInArray(
      [...selectedFilters],
      {
        field: { label: currentFilterObject.field.label, value: fieldName },
        activeFilterValues: filters,
      },
      'field'
    );
    setSelectedFilters(newSelectedFilters);
    setUpdatedFilters(newSelectedFilters);
  };

  const clearAllFilters = () => {
    setSelectedFilters([]);
    setUpdatedFilters([]);
  };

  return (
    <Stack
      className={styles.filter_bar_wrapper}
      direction="row-reverse"
      alignItems="center"
      justifyContent="flex-end"
      spacing={4}
    >
      {selectedFilters.length && (
        <Button
          variant="contained"
          onClick={clearAllFilters}
          className={styles.filter_clear}
        >
          {t('clear_all')}
        </Button>
      )}
      {selectedFilters
        .filter((filter) => !!filter?.activeFilterValues?.length)
        .map((filter) => (
          <div key={filter.field.value}>
            {!!filter?.activeFilterValues?.length && (
              <Stack direction="column">
                <div className={styles.filter_field}>{filter.field.label}</div>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {filter?.activeFilterValues.map(
                    (filterValue: LabelValuePairType) => (
                      <Chip
                        key={filterValue.value}
                        className={styles.filter_value}
                        label={filterValue.label}
                        onDelete={() =>
                          handleRemoveFilterValue(
                            filter.field.value,
                            filterValue.value
                          )
                        }
                      />
                    )
                  )}
                </Stack>
              </Stack>
            )}
          </div>
        ))}
    </Stack>
  );
};
