import { useSelector } from 'react-redux';

import { useCampaigns } from 'hooks';
import { Campaign, ExtendedCampaign } from 'shared/interfaces';
import { UserRole } from 'types';
import { definitions } from 'types/api';

import {
  useGetCampaignByIdQuery,
  useGetCampaignsQuery,
} from 'store/api/endpoints/campaign';
import { useGetCumulativesQuery } from 'store/api/endpoints/reports';
import { selectChannels } from 'store/modules/settings/selectors';

interface Props {
  page: number;
  perPage: number;
  search?: string;
  advertiserId?: string;
  campaignId?: string;
  campaignGroupIds?: string[];
  stateFilterValues: string[];
  userRole: UserRole;
  sortOrder?: definitions['SortOrder'];
  sortField?: definitions['CampaignSortField'];
}

type ReportingTableDataReturnType = {
  campaigns: ExtendedCampaign[];
  isLoading: boolean;
  numberOfCampaigns?: number;
};

export const useReportingTableData = ({
  page,
  search,
  perPage,
  userRole,
  sortOrder,
  sortField,
  campaignId,
  advertiserId,
  campaignGroupIds,
  stateFilterValues,
}: Props): ReportingTableDataReturnType => {
  const networkChannels = useSelector(selectChannels);

  const { data, isFetching } = useGetCampaignsQuery(
    {
      page,
      per_page: perPage,
      sortBy: sortField,
      order: sortOrder,
      filter: {
        advertiser_id: advertiserId,
        group_id: campaignGroupIds,
        search: search,
        state: stateFilterValues,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: singleCampaign, isFetching: isGetCampaignsByIdFetching } =
    useGetCampaignByIdQuery(campaignId!, { skip: !campaignId });

  const campaigns: Campaign[] = (
    singleCampaign && page === 1 ? [singleCampaign] : []
  ).concat(
    data?.results.filter((campaign) => campaign.id !== singleCampaign?.id) || []
  );

  const { data: cumulativesData, isFetching: cumulativesIsFetching } =
    useGetCumulativesQuery(
      campaigns.map((campaign) => campaign.id),
      {
        skip: !campaigns.length || isFetching || isGetCampaignsByIdFetching,
      }
    );

  const extendedCampaigns = useCampaigns(
    campaigns || [],
    cumulativesData || [],
    networkChannels
  );

  // https://schwarz-media-platform.atlassian.net/browse/PACMAN-478
  const canDisplayMetricsForUser = (
    campaignType: ExtendedCampaign
  ): boolean => {
    return !(
      campaignType?.type === 'CAMPAIGN_TYPE_CONVERSION' &&
      [
        UserRole.ADVERTISER_ADMIN,
        UserRole.ADVERTISER_CAMPAIGN_MANAGER,
      ].includes(userRole)
    );
  };

  const filterOutData: ExtendedCampaign[] = extendedCampaigns.map(
    (campaign: ExtendedCampaign) => ({
      ...campaign,
      number_of_views_cumulative: canDisplayMetricsForUser(campaign)
        ? campaign.number_of_views_cumulative
        : '-',
      number_of_clicks_cumulative: canDisplayMetricsForUser(campaign)
        ? campaign.number_of_clicks_cumulative
        : '-',
      shoppers_reached_cumulative: canDisplayMetricsForUser(campaign)
        ? campaign.shoppers_reached_cumulative
        : '-',
      number_of_impressions_cumulative: canDisplayMetricsForUser(campaign)
        ? campaign.number_of_impressions_cumulative
        : '-',
    })
  );

  return {
    campaigns: filterOutData,
    isLoading:
      isFetching || cumulativesIsFetching || isGetCampaignsByIdFetching,
    numberOfCampaigns: data?.query?.results,
  };
};
