import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import xor from 'lodash/xor';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';

import { useStoreDispatch } from 'store';
import {
  useGetAllTaxonomiesQuery,
  useGetTaxonomyProductsByGTINQuery,
} from 'store/api/endpoints/productTaxonomies';
import { updateDraft } from 'store/modules/campaign/slice';

import { CampaignSectionProps } from 'components/Campaigns/CampaignDetails/CampaignDetails';
import { GreyS } from 'components/Campaigns/CampaignDetails/sections/Products/Products.style';
import { AddProductsDialog } from 'components/Shared/AddProductsDialog/AddProductsDialog';

import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { CampaignSectionWrapper } from '../CampaignSectionWrapper';
import { AdditionallyPromotedProducts } from './AdditionallyPromotedProducts/AdditionallyPromotedProducts';
import styles from './Products.module.scss';
import { SelectedProducts } from './SelectedProducts/SelectedProducts';

interface Props extends CampaignSectionProps {
  promotedProductGTINs: string[];
  additionallyPromotedProductGTINs: string[];
  retailer_ids?: string[];
  countryCode: string;
}

const Products: FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const {
    promotedProductGTINs,
    formState,
    validate: showValidation,
    additionallyPromotedProductGTINs,
    retailer_ids,
    countryCode,
  } = props;
  const NUMBER_OF_PRODUCTS_TO_DISPLAY = 20;
  const [editProducts, setEditProducts] = useState<boolean>(false);

  const validationMessage = showValidation('promoted_products');

  const enabledForEdit = !formState.readonlyFields.includes('Products');

  /**
   * As we only have 2 channels, namely Lidl and Kaufland at the moment,
   * we can handle the pagination once we have too many taxonomies.
   */
  const { data: taxonomiesApiData } = useGetAllTaxonomiesQuery(
    {
      retailer_ids,
      page: DEFAULT_API_PARAMETERS.page,
      per_page: DEFAULT_API_PARAMETERS.per_page,
    },
    {
      skip: !retailer_ids,
    }
  );

  const taxonomies = taxonomiesApiData?.product_taxonomies.length
    ? taxonomiesApiData.product_taxonomies.filter(
        (taxonomy) => taxonomy.country_code === countryCode
      )
    : [];

  const selectedTaxonomyId = taxonomies.length
    ? taxonomies[0].product_taxonomy_id
    : '';

  const displayedProductsGtins = promotedProductGTINs?.slice(
    0,
    NUMBER_OF_PRODUCTS_TO_DISPLAY
  );

  const { data: taxonomyProducts, isLoading: isTaxonomyProductsLoading } =
    useGetTaxonomyProductsByGTINQuery(
      {
        product_taxonomy_id: selectedTaxonomyId,
        gtins: displayedProductsGtins,
      },
      {
        skip: !selectedTaxonomyId || !displayedProductsGtins.length,
      }
    );

  const handleAddRemove = (gtin: string) => {
    dispatch(
      updateDraft({
        promoted_products: xor(promotedProductGTINs, [gtin]),
      })
    );
  };

  return (
    <CampaignSectionWrapper
      header={t('products')}
      subHeader={t('subheader_campaign_products')}
      error={validationMessage}
      required
    >
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          className={styles.products_box_header}
        >
          <Typography variant="h3">
            <span>{t('selected_products')}</span>
            <span className={styles.number_of_products}>{` (${
              promotedProductGTINs?.length || 0
            })`}</span>
          </Typography>
          {enabledForEdit && (
            <Button
              data-testid="add-products"
              onClick={() => setEditProducts(true)}
              variant="contained"
              size="small"
            >
              {t(
                promotedProductGTINs.length ? 'edit_products' : 'add_products'
              )}
            </Button>
          )}
        </Stack>
        <GreyS>
          {!isTaxonomyProductsLoading && (
            <>
              <SelectedProducts
                disabled={!enabledForEdit}
                enableCopyGtins={true}
                taxonomyProducts={
                  !displayedProductsGtins.length
                    ? []
                    : taxonomyProducts?.products
                }
                onDeselect={(gtin: string) => {
                  handleAddRemove(gtin);
                }}
                hasMoreProducts={
                  promotedProductGTINs.length > NUMBER_OF_PRODUCTS_TO_DISPLAY
                }
              />

              <Stack alignSelf="end">
                {promotedProductGTINs.length >
                  NUMBER_OF_PRODUCTS_TO_DISPLAY && (
                  <Button
                    variant="text"
                    onClick={() => setEditProducts(true)}
                    size="small"
                  >
                    {t('show_all')}
                  </Button>
                )}
              </Stack>
            </>
          )}
        </GreyS>
      </>
      <AdditionallyPromotedProducts
        productGtins={additionallyPromotedProductGTINs}
        taxonomyId={selectedTaxonomyId}
      />
      {editProducts && (
        <AddProductsDialog
          taxonomyId={selectedTaxonomyId}
          alreadySelectedProductGTINs={promotedProductGTINs}
          onCancel={() => setEditProducts(false)}
          retailer_ids={retailer_ids}
          onConfirm={(productGTINs: string[]) => {
            dispatch(
              updateDraft({
                promoted_products: productGTINs,
              })
            );
            setEditProducts(false);
          }}
        />
      )}
    </CampaignSectionWrapper>
  );
};

export default Products;
