import React from 'react';

import { Grid, Typography } from '@mui/material';

import styles from '../UniverseItem.module.scss';

interface Props {
  field: string;
  value: React.ReactNode;
}

export const UniverseItemField = ({ field, value }: Props) => {
  return (
    <Grid item xs={6} className={styles.description_item}>
      <Typography
        color="textSecondary"
        component="div"
        className={styles.display_label}
      >
        {field}
      </Typography>
      <div data-testid={field} className={styles.display_value}>
        {value}
      </div>
    </Grid>
  );
};
