import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CashIcon } from 'assets/icons/cash.svg';
import { ReactComponent as MoneyChangeIcon } from 'assets/icons/money-change.svg';
import classnames from 'classnames';
import { ExtendedCampaignGroup } from 'shared/interfaces';

import { Routes } from 'routes/Routes';

import { CustomIconButton, NumberFormat } from 'components/Shared';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionSummary,
  Radio,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import { SelectionType } from '../CampaignGroupAccordion/CampaignGroupAccordion';
import {
  CampaignGroupAccordionContext,
  CampaignGroupAccordionContextType,
} from '../CampaignGroupAccordion/context/CampaignGroupAccordionContext';
import styles from './CampaignGroupAccordionHeader.module.scss';

type Props = {
  expanded: boolean;
  selection?: SelectionType;
  numberOfAllCampaigns?: number;
  campaignGroup: ExtendedCampaignGroup;
};

export const CampaignGroupAccordionHeader = ({
  expanded,
  selection,
  campaignGroup,
  numberOfAllCampaigns,
}: Props) => {
  const { t } = useTranslation();

  const { numberOfTableItems } = useContext(
    CampaignGroupAccordionContext
  ) as CampaignGroupAccordionContextType;

  const numberOfCampaignItems = numberOfAllCampaigns || numberOfTableItems;

  const remainingBudgetCellWidth = campaignGroup?.roas_cumulative ? 4 : 7;

  return (
    <>
      <AccordionSummary
        id={campaignGroup?.group_id}
        className={classnames([styles.header], {
          [styles.expanded]: expanded,
        })}
        expandIcon={<ExpandMoreIcon color="primary" />}
        aria-controls={`${campaignGroup?.group_id}-content`}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          className={styles.header_content}
        >
          <Grid item xs={4}>
            <Stack direction="row" alignItems="center">
              {selection && (
                <Radio
                  checked={selection.selected}
                  onClick={(event) => {
                    event.stopPropagation();
                    selection.onSelect({
                      ...campaignGroup,
                      id: campaignGroup?.group_id || '',
                      name: campaignGroup?.name || '',
                      objectType: 'CampaignGroup',
                      areMultipleCurrenciesInvolved:
                        (campaignGroup?.campaign_group_currencies?.length ??
                          0) > 1,
                    });
                  }}
                ></Radio>
              )}
              <Typography variant="h3" className={styles.title}>
                {campaignGroup?.name}
                {!!numberOfCampaignItems && (
                  <span className={styles.count}>
                    ({numberOfCampaignItems})
                  </span>
                )}
              </Typography>
            </Stack>
          </Grid>

          {!!campaignGroup?.number_of_campaigns ? (
            <>
              <Grid item xs={remainingBudgetCellWidth}>
                {campaignGroup?.sum_of_remaining_budgets && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className={styles.budget}
                  >
                    <SvgIcon component={CashIcon} viewBox={'0 0 32 32'} />
                    <Typography variant="h3" className={styles.title}>
                      {t('remaining_budget')}
                      <span className={styles.count}>
                        (
                        {campaignGroup?.campaign_group_currencies?.length ===
                        1 ? (
                          <>
                            <NumberFormat
                              displayType="text"
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              value={Number(
                                campaignGroup?.sum_of_remaining_budgets
                              )}
                              prefix={campaignGroup?.currency?.symbol}
                              decimalScale={2}
                            />
                            <span> of </span>
                            <NumberFormat
                              displayType="text"
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              value={Number(
                                campaignGroup?.sum_of_total_budgets
                              )}
                              prefix={campaignGroup?.currency?.symbol}
                              decimalScale={2}
                            />
                          </>
                        ) : (
                          <span>{t('multiple_currencies')}</span>
                        )}
                        )
                      </span>
                    </Typography>
                  </Stack>
                )}
              </Grid>
              {campaignGroup?.roas_cumulative && (
                <Grid item xs={3}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className={styles.budget}
                  >
                    <SvgIcon
                      component={MoneyChangeIcon}
                      viewBox={'0 0 32 32'}
                    />
                    <Typography variant="h3" className={styles.title}>
                      {t('group_roas')}
                      <span className={styles.count}>
                        (
                        {campaignGroup?.campaign_group_currencies?.length ===
                        1 ? (
                          <NumberFormat
                            displayType="text"
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            value={Number(campaignGroup?.roas_cumulative)}
                            prefix={campaignGroup?.currency?.symbol}
                            decimalScale={2}
                          />
                        ) : (
                          <span>{t('multiple_currencies')}</span>
                        )}
                        )
                      </span>
                    </Typography>
                  </Stack>
                </Grid>
              )}
            </>
          ) : (
            <></>
          )}

          <Grid item xs={1} className={styles.header_action_wrapper}>
            <CustomIconButton
              title={t('edit_campaign_group')}
              icon="edit"
              to={`${Routes.CAMPAIGN_GROUPS}/edit/${campaignGroup?.group_id}`}
              color="secondary"
            />
          </Grid>
        </Grid>
      </AccordionSummary>
    </>
  );
};
