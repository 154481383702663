import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  COLLAPSED_VIEW_MAX_ITEM_COUNT,
  DEFAULT_API_PARAMETERS,
} from 'shared/constants';
import { DraftUniverse } from 'shared/interfaces';
import { definitions } from 'types/api';

import { useGetUniverseChangeLogQuery } from 'store/api/endpoints/universes';

import { CampaignSectionWrapper } from 'components/Campaigns/CampaignDetails/sections';

import { Button, Stack } from '@mui/material';

import { UniverseChangeLogRow } from './components/UniverseChangeLogRow';

interface Params {
  universe: DraftUniverse;
}

export const UniverseChangeLog = ({ universe }: Params) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(DEFAULT_API_PARAMETERS.page);
  const [changeLogItems, setChangeLogItems] = useState<
    definitions['UniverseChangeLog'][]
  >([]);

  const { data } = useGetUniverseChangeLogQuery(
    {
      path: { universe_id: universe.id! },
      query: { per_page: COLLAPSED_VIEW_MAX_ITEM_COUNT, page: page },
    },
    {
      skip: !universe.id,
    }
  );

  useEffect(() => {
    setPage(1);
  }, [universe]);

  useEffect(() => {
    if (data) {
      if (page === 1) setChangeLogItems(data.universe_change_logs || []);
      else
        setChangeLogItems([
          ...changeLogItems,
          ...(data.universe_change_logs || []),
        ]);
    }
  }, [data?.universe_change_logs]);

  return (
    <>
      {!!changeLogItems?.length && (
        <CampaignSectionWrapper
          header={t('Changelog')}
          subHeader={t('universe_changelog_description')}
        >
          {changeLogItems?.map((changeLogRow, index) => (
            <div key={index}>
              <UniverseChangeLogRow
                changeLogRow={changeLogRow}
                universe={universe}
              />
            </div>
          ))}
          <Stack direction="row" justifyContent="end">
            {page > DEFAULT_API_PARAMETERS.page && (
              <Button onClick={() => setPage(DEFAULT_API_PARAMETERS.page)}>
                {t('show_less')}
              </Button>
            )}
            {changeLogItems.length < (data?.pagination?.results || 0) && (
              <Button onClick={() => setPage(page + 1)}>
                {t('show_more')}
              </Button>
            )}
          </Stack>
        </CampaignSectionWrapper>
      )}
    </>
  );
};
