import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down-small.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/chevron-up-small.svg';
import classNames from 'classnames';
import { definitions } from 'types/api';

import { PopperStep, SelectionBoxProps } from 'components/Universes/Rule/Rule';
import styles from 'components/Universes/Rule/Rule.module.scss';

import { Button } from '@mui/material';

interface Props extends SelectionBoxProps {
  selectedAttribute?: definitions['ShopperMetadata'] | 'Products';
  setPopperStep: (popperStep: PopperStep | null) => void;
}

export const SelectRuleAttributeType = ({
  selected,
  disabled,
  readonly,
  hasError,
  setPopperStep,
  selectedAttribute,
}: Props) => {
  const { t } = useTranslation();

  if (readonly && !selectedAttribute) return null;

  return (
    <Button
      id={PopperStep.ATTRIBUTE_TYPE}
      className={classNames(styles.block, {
        [styles.readonly]: readonly,
        [styles.disabled]: disabled,
        [styles.selected]: selected,
        [styles.error]: hasError,
      })}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        setPopperStep(selected ? null : PopperStep.ATTRIBUTE_TYPE);
      }}
      endIcon={
        readonly ? null : selected ? (
          <ArrowUpIcon className={styles.icon} />
        ) : (
          <ArrowDownIcon className={styles.icon} />
        )
      }
    >
      {selectedAttribute ? (
        selectedAttribute !== 'Products' ? (
          <>{selectedAttribute.attribute_name}</>
        ) : (
          <>{t('bought_products')}</>
        )
      ) : (
        <>{t('add_criteria')}</>
      )}
    </Button>
  );
};
