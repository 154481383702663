import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down-small.svg';
import { ReactComponent as CircleAttentionIcon } from 'assets/icons/circle-attention.svg';
import { definitions } from 'types/api';

import { useGetTaxonomyProductsByGTINQuery } from 'store/api/endpoints/productTaxonomies';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SvgIcon,
} from '@mui/material';

import styles from './AdditionallyPromotedProducts.module.scss';

interface Props {
  productGtins: string[];
  taxonomyId: string;
}
export const AdditionallyPromotedProducts = ({
  productGtins,
  taxonomyId,
}: Props) => {
  const { t } = useTranslation();
  const { data: products } = useGetTaxonomyProductsByGTINQuery(
    {
      product_taxonomy_id: taxonomyId,
      gtins: productGtins,
    },
    {
      skip: !taxonomyId || !productGtins?.length,
    }
  );

  return (
    <>
      {productGtins?.length !== 0 && (
        <Accordion disableGutters={true} className={styles.additional_products}>
          <AccordionSummary
            expandIcon={
              <SvgIcon component={ChevronDownIcon} viewBox={'0 0 32 32'} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <>
              <SvgIcon component={CircleAttentionIcon} viewBox={'0 0 32 32'} />
              <span className={styles.additional_product_hint}>
                {t('additional_product_hint')}
              </span>
            </>
          </AccordionSummary>
          <AccordionDetails>
            {productGtins?.map((productGtin: string) => {
              const product = products?.products.find(
                (product: definitions['TaxonomyProduct']) =>
                  productGtin === product.gtin
              );
              return (
                <span
                  className={styles.product_item}
                  key={productGtin}
                >{`${productGtin} | ${product?.product_name ?? '-'}`}</span>
              );
            })}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
