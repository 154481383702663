import { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { UserRole } from 'types';
import { useFeature } from 'utils/FeatureFlags';

import { selectUser } from 'store/modules/user/selectors';

import { CampaignGroupAccordionProvider } from 'components/CampaignGroups/CampaignGroupListing/CampaignGroupAccordion/context/CampaignGroupAccordionContext';
import { PageHeader } from 'components/Shared/CustomMui';

import { Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import { MetricsAndChart } from './MetricsAndChart';
import styles from './Reporting.module.scss';
import { ReportingCampaignGroupListing } from './ReportingCampaignGroupListing/ReportingCampaignGroupListing';
import { ReportingCampaignTable } from './ReportingCampaignTable/ReportingCampaignTable';
import { CompareCampaignMenu } from './components/CompareCampaignMenu/CompareCampaignMenu';
import { ReportDataType } from './utils/utils';

const Reporting: FC = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [searchParams] = useSearchParams();

  const searchParamCampaignId = searchParams?.get('campaign');
  const searchParamCampaignGroupId = searchParams?.get('campaign_group');
  const searchParamIsAllAdvertisersSelected =
    searchParams?.get('all-advertisers');

  const isCampaignGroupReportingEnabled = useFeature(
    'isCampaignGroupReportingEnabled'
  ) as boolean;

  const isCreativeABTestingEnabled = useFeature('isCreativeABTestingEnabled');

  const [selectedReportDataObject, setSelectedReportDataObject] =
    useState<ReportDataType>();

  const [selectedReportDataObjectB, setSelectedReportDataObjectB] =
    useState<ReportDataType>();

  const [selectedTabIndex, setSelectedTabIndex] = useState<
    'CampaignGroups' | 'Campaigns'
  >(searchParamCampaignId ? 'Campaigns' : 'CampaignGroups');

  return (
    <CampaignGroupAccordionProvider>
      <PageHeader
        headerText={t('reporting')}
        subHeaderText={t('subheader_reporting')}
      />
      {user?.advertiser_id && (
        <>
          <div className={styles.title}>
            <Typography variant="h2">
              {selectedReportDataObject?.name}
            </Typography>
            {isCreativeABTestingEnabled &&
              selectedReportDataObject?.objectType === 'Campaign' && (
                <CompareCampaignMenu
                  user={user}
                  onCampaignSelect={(campaign) => {
                    if (campaign)
                      setSelectedReportDataObjectB({
                        objectType: 'Campaign',
                        ...campaign,
                        areMultipleCurrenciesInvolved: false,
                      });
                    else setSelectedReportDataObjectB(undefined);
                  }}
                />
              )}
          </div>
          {selectedReportDataObject && (
            <MetricsAndChart
              reportDataA={selectedReportDataObject}
              reportDataB={selectedReportDataObjectB}
              userRole={user?.role as UserRole}
            />
          )}
          <Box>
            <Tabs
              className={styles.tabs_wrapper}
              value={selectedTabIndex}
              onChange={(
                event: SyntheticEvent<Element, Event>,
                value: 'CampaignGroups' | 'Campaigns'
              ) => {
                setSelectedReportDataObject(undefined);
                setSelectedReportDataObjectB(undefined);
                setSelectedTabIndex(value);
              }}
            >
              <Tab
                className={styles.tab}
                label={t('campaign_groups')}
                value="CampaignGroups"
              />
              <Tab
                className={styles.tab}
                label={t('campaigns')}
                value="Campaigns"
              />
            </Tabs>
            <Box className={styles.tab_content_wrapper}>
              <Box>
                {selectedTabIndex === 'CampaignGroups' && (
                  <ReportingCampaignGroupListing
                    onSelect={(reportData) => {
                      setSelectedReportDataObject(reportData);
                      if (reportData.objectType === 'CampaignGroup')
                        setSelectedReportDataObjectB(undefined);
                    }}
                    singleCampaignGroupId={
                      searchParamCampaignGroupId || undefined
                    }
                    isCampaignGroupReportingEnabled={
                      isCampaignGroupReportingEnabled
                    }
                  />
                )}
              </Box>
              <Box>
                {selectedTabIndex === 'Campaigns' && (
                  <ReportingCampaignTable
                    allAdvertisersSelected={
                      !!searchParamIsAllAdvertisersSelected
                    }
                    perPage={DEFAULT_API_PARAMETERS.per_page_large}
                    selectedId={selectedReportDataObject?.id}
                    campaignId={searchParamCampaignId || undefined}
                    onSelect={setSelectedReportDataObject}
                    userPreferencesKey="reportingPageSettings_campaignsTab"
                  />
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </CampaignGroupAccordionProvider>
  );
};

export default Reporting;
