import styles from './StaticPage.module.scss';

export const PrivacyPolicy = () => {
  return (
    <div className={styles.content}>
      <h1>Schwarz Media GmbH</h1>
      <h5>Datenschutzhinweise</h5>
      <p>
        Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst und sind
        bestrebt, Sie umfassend über die Verarbeitung Ihrer personenbezogenen
        Daten zu informieren. Die nachfolgenden Datenschutzhinweise geben Ihnen
        Auskunft darüber, auf welche Art und Weise und zu welchen Zwecken wir
        personenbezogene Daten von Ihnen verarbeiten, wenn Sie Besucher unserer
        Webseite sind, Kontakt mit uns aufnehmen oder unsere Seiten in den
        sozialen Medien nutzen.
      </p>

      <p>
        Ihre personenbezogenen Daten erheben wir grundsätzlich bei Ihnen selbst.
        Als gesetzliche Grundlage dient insbesondere die
        EU-Datenschutz-Grundverordnung (DSGVO).
      </p>

      <p>Version 1.0</p>
      <h2>1. Verantwortlicher i.S.d. Art. 4 Ziff. 7 DSGVO</h2>
      <p>
        Der für die Datenverarbeitung Verantwortliche i.S.d. Art. 4 Ziff. 7
        DSGVO ist:
      </p>

      <div>Schwarz Media GmbH</div>
      <div>Stiftsbergstraße 1</div>
      <div>74172 Neckarsulm</div>

      <p>
        <div>Telefon: +49 7132 30 788817</div>
        <div>E-Mail: info@media.schwarz</div>
      </p>

      <h2>2. Daten, die wir beim Besuch dieser Webseite verarbeiten</h2>

      <h3>2.1.      Zwecke und Rechtsgrundlage der Datenverarbeitung</h3>

      <p>
        Beim Besuch dieser Webseite werden Protokolldateien mit folgendem Inhalt
        erzeugt:
      </p>

      <ul>
        <li>
          Der Webseite/Applikation, von der Sie unsere Seite aufrufen
          (Referrer-URL);
        </li>
        <li>Der IP-Adresse;</li>
        <li>Dem Zugriffsdatum und der Zugriffszeit;</li>
        <li>Der Anfrage des Clients;</li>
        <li>Dem http-Antwort-Code;</li>
        <li>Der übertragenen Datenmenge;</li>
        <li>Dem Namen und der URL der abgerufenen Datei;</li>
        <li>
          Der Information über den von Ihnen verwendeten Browser und das von
          Ihnen verwendete Betriebssystem;
        </li>
        <li>Dem Namen Ihre Access-Providers</li>
      </ul>

      <p>
        Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f)
        DSGVO. Das berechtigte Interesse ergibt sich hierbei aus dem Schutz
        unserer Systeme und der Verhinderung von missbräuchlichem bzw.
        betrügerischem Verhalten bei jedem Zugriff eines Nutzers auf diese
        Webseite.
      </p>

      <p>
        Soweit eine Verarbeitung der genannten Daten für die Vorbereitung und /
        oder Durchführung eines Vertragsverhältnisses erforderlich ist,
        verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b)
        DSGVO.
      </p>

      <h3>2.2.      Empfänger/Kategorien von Empfängern</h3>

      <p>
        Ihre personenbezogenen Daten können in Ausnahmefällen zu Support- und
        Wartungszwecken der Schwarz Media Plattform GmbH, Stiftsbergstraße 1,
        74172 Neckarsulm zugänglich sein, da unsere Webseite in unserem Auftrag
        auf von der Schwarz Media Plattform GmbH bereitgestellten Servern
        betrieben wird.
      </p>

      <h3>2.3.      Pflicht zu Bereitstellung Ihrer Daten</h3>

      <p>
        Sie sind weder vertraglich noch gesetzlich verpflichtet, uns
        personenbezogene Daten zur Verfügung zu stellen. Die genannten Daten
        werden technisch bedingt jedoch verarbeitet, sobald Sie unsere Seite
        aufrufen. Wenn Sie uns die Daten nicht mehr zur Verfügung stellen
        möchten, ist dies nur möglich, indem Sie unsere Webseite nicht mehr
        nutzen.
      </p>

      <h3>2.4.      Speicherdauer</h3>

      <p>Wir speichern die genannten Daten für eine Dauer von 30 Tagen.</p>

      <h2>3. Benutzerkonto der Schwarz Media Plattform/Aufträge</h2>

      <h3>3.1.      Zwecke und Rechtsgrundlage der Datenverarbeitung</h3>

      <p>
        Um das Portal der Schwarz Media zur Auftragsvergabe und -kontrolle
        nutzen zu können, werden Ihr Name, Vorname, Position, E-Mail-Adresse und
        ein Passwort verarbeitet. Zur Nutzung einer
        Zwei-Faktor-Authentifizierung verarbeiten wir ihre Mobilfunknummer.
      </p>

      <p>
        Im Rahmen der Nutzung unseres Auftragsportals können Sie Ihre
        Kontaktdaten verwalten und Werbekampagnen beauftragen und kontrollieren.
        Dazu verarbeiten wir Angaben zu den von Ihnen beauftragten
        Werbekampagnen inklusive Auftragshistorie und -statistiken.
      </p>

      <p>
        Rechtsgrundlage hierfür sind Artikel 6 Absatz 1 Buchstabe b) und f)
        DSGVO. Wir verarbeiten Ihre Daten im Rahmen der Durchführung eines
        Vertragsverhältnisses (oder dessen Anbahnung) zwischen Ihnen oder Ihrem
        Arbeitgeber und uns und zur sicheren Nutzung des Portals. Grundlage
        hierfür ist unser berechtigtes Interesse an einer sicheren Ausgestaltung
        der Abwicklung des Vertragsverhältnisses mit Ihnen oder Ihrem
        Arbeitgeber.
      </p>
      <h3>3.2.      Empfänger/Kategorien von Empfängern</h3>

      <p>
        Ihre personenbezogenen Daten können in Ausnahmefällen zu Support- und
        Wartungszwecken der Schwarz Media Plattform GmbH, Stiftsbergstraße 1,
        74172 Neckarsulm zugänglich sein, da unsere Webseite in unserem Auftrag
        auf von der Schwarz Media Plattform GmbH bereitgestellten Servern
        betrieben wird.
      </p>

      <h3>3.3.      Pflicht zu Bereitstellung Ihrer Daten</h3>

      <p>
        Sie sind weder vertraglich noch gesetzlich verpflichtet, uns
        personenbezogene Daten zur Verfügung zu stellen. Wenn Sie uns die Daten
        nicht (mehr) zur Verfügung stellen möchten, ist es nicht länger möglich,
        Werbekampagnen über die Plattform zu beauftragen.
      </p>
      <h3>3.4.      Speicherdauer</h3>

      <p>
        Sofern Sie die Löschung des Benutzerkontos veranlassen, wird dieses
        unmittelbar deaktiviert. Deaktivierte Benutzerkonten verbleiben, bis zur
        vierteljährlichen Löschung, im System gespeichert.
      </p>
      <p>
        Personenbezogene Daten, die im Rahmen einer Vertragsanbahnung oder
        -durchführung verarbeitet werden, werden nach spätestens 12 Jahren
        gelöscht.
      </p>

      <h2>4. Einsatz von Cookies</h2>

      <p>
        Wir, die Schwarz Media GmbH, Stiftsbergstraße 1, 74172 Neckarsulm sind
        Verantwortliche für die Datenverarbeitungen im Zusammenhang mit dem
        Einsatz sogenannter <strong>Cookies</strong> und anderer ähnlicher 
        <strong>Technologien zur Verarbeitung von Nutzungsdaten</strong> auf
        allen (Sub-) Domains unter https://portal.schwarz/
      </p>

      <p>
        Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem
        Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie
        unsere Webseiten besuchen. Cookies richten auf Ihrem Endgerät keinen
        Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
        In dem Cookie werden Informationen abgelegt, die sich jeweils im
        Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben.
      </p>

      <p>
        Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von
        Ihrer Identität erhalten.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass er Sie über die Platzierung
        von Cookies informiert. So wird der Gebrauch von Cookies für Sie
        transparent. Zudem können Sie den Browser so einstellen, dass er
        generell keine Cookies akzeptiert oder nur Cookies von einzelnen
        Anbietern ablehnt. Wir möchten Sie jedoch darauf hinweisen, dass
        hierdurch u.U. die Funktionalität dieser Webseite beeinträchtigt sein
        kann.
      </p>

      <h3>4.1.      Zwecke und Rechtsgrundlage der Datenverarbeitung</h3>

      <p>
        Der Einsatz von Cookies und den anderen Technologien zur Verarbeitung
        von Nutzungsdaten dient – je nach Kategorie des Cookies bzw. der anderen
        Technologie – folgenden Zwecken:
      </p>

      <p>
        <strong>Notwendig</strong>:
      </p>

      <p>
        Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem
        sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere
        Bereiche der Webseite ermöglichen. Außerdem helfen diese Cookies bei der
        Überwachung der ordnungsgemäßen Funktion. Die Webseite kann ohne diese
        Cookies nicht richtig funktionieren.
      </p>

      <p>
        Im Rahmen des Einsatzes von Cookies und ähnlichen Techniken zur
        Verarbeitung von Nutzungsdaten werden abhängig von der Zwecksetzung
        insbesondere die folgenden Arten personenbezogener Daten verarbeitet:
      </p>

      <p>
        <strong>Notwendig</strong>:
      </p>

      <p>
        Authentifizierungsdaten zur Identifizierung eines Benutzers nach
        Anmeldung, um bei weiteren Besuchen Zugang zu autorisierten Inhalten zu
        erhalten (z.B. Zugang zum Kundenportal).
      </p>

      <p>
        Rechtsgrundlage für den Einsatz von technisch notwendigen Cookies und
        ähnlichen Technologien ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
        berechtigtes Interesse liegt dabei in einem technisch stabilen und
        sicheren Betrieb der Webseite.
      </p>
      <h3>4.2.      Empfänger / Kategorien von Empfängern</h3>

      <p>
        Ihre personenbezogenen Daten können in Ausnahmefällen zu Support- und
        Wartungszwecken der Schwarz Media Plattform GmbH, Stiftsbergstraße 1,
        74172 Neckarsulm zugänglich sein, da unsere Webseite in unserem Auftrag
        auf von der Schwarz Media Plattform GmbH bereitgestellten Servern
        betrieben wird.
      </p>

      <h3>4.3.      Übermittlung von Daten in Drittländer</h3>

      <p>
        Wir geben Ihre Daten grundsätzlich nicht an Empfänger mit Sitz außerhalb
        der Europäischen Union oder des Europäischen Wirtschaftsraumes weiter.
      </p>
      <h3>4.4.      Pflicht zur Bereitstellung Ihrer Daten</h3>

      <p>
        Sie sind weder vertraglich noch gesetzlich verpflichtet, uns
        personenbezogene Daten zur Verfügung zu stellen.
      </p>

      <h3>4.5.      Speicherdauer</h3>

      <p>
        Die Speicherdauer für Cookies beträgt 90 Tage, das heißt nach Ablauf
        dieser Zeit werden diese automatisch gelöscht. 
      </p>

      <h2>5. Ihre Betroffenenrechte</h2>

      <p>
        Sie haben das Recht, nach Art. 15 Abs. 1 DSGVO auf Antrag unentgeltlich
        Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten
        zu erhalten.
      </p>

      <p>
        Zusätzlich haben Sie bei Vorliegen der gesetzlichen Voraussetzungen ein
        Recht auf Berichtigung (Art. 16 DSGVO), Löschung (Art. 17 DSGVO) und
        Einschränkung der Verarbeitung (Art. 18 DSGVO) Ihrer personenbezogenen
        Daten.
      </p>

      <p>
        Sofern die Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. e)
        oder lit. f) DSGVO beruht, steht Ihnen nach Art. 21 DSGVO ein
        Widerspruchsrecht zu. Soweit Sie einer Datenverarbeitung widersprechen,
        unterbleibt diese zukünftig, es sei denn, der Verantwortliche kann
        zwingende schutzwürdige Gründe für die weitere Verarbeitung nachweisen,
        die das Interesse des Betroffenen am Widerspruch überwiegen.
      </p>

      <p>
        Sofern Sie selbst die verarbeiteten Daten zur Verfügung gestellt haben,
        steht Ihnen ein Recht auf Datenübertragung nach Art. 20 DSGVO zu.
      </p>

      <p>
        Sofern die Datenverarbeitung auf Grundlage einer Einwilligung nach Art.
        6 Abs. 1 lit. a) oder Art. 9 Abs. 2 lit. a) DSGVO beruht, können Sie die
        Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, ohne dass
        die Rechtmäßigkeit der bisherigen Verarbeitung berührt wird.
      </p>

      <p>
        Wenden Sie sich bitte in den zuvor genannten Fällen, bei offenen Fragen
        oder im Falle von Beschwerden schriftlich oder per E-Mail an den
        Datenschutzbeauftragten. Zudem steht Ihnen ein Recht zur Beschwerde bei
        einer Datenschutz-Aufsichtsbehörde zu. Zuständig ist die
        Datenschutz-Aufsichtsbehörde des Bundeslandes, in dem Sie wohnen oder in
        dem der Verantwortliche seinen Sitz hat.
      </p>

      <h2>6. Kontakt Datenschutzbeauftragter</h2>

      <p>
        Sollten Sie weitere Fragen zur Verarbeitung Ihrer Daten oder zur
        Ausübung Ihrer Rechte haben, können Sie sich an den zuständigen
        Datenschutzbeauftragten beim Verantwortlichen wenden:
      </p>
      <p>
        <div>Schwarz Media GmbH</div>
        <div>– Datenschutzbeauftragter –</div>
        <div>Stiftsbergstraße 1</div>
        <div>74172 Neckarsulm</div>
      </p>

      <p>E-Mail: datenschutz@mail.schwarz</p>
    </div>
  );
};
