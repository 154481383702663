import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isWithinInterval } from 'date-fns';
import { definitions } from 'types/api';
import { useWindowResize } from 'utils/hooks';

import { CircularProgress } from '@mui/material';

import { MetricType } from '../utils/utils';
import {
  CHART_HEIGHT,
  ChartS,
  ChartWrapperS,
  ContainerS,
  RangeFilterS,
} from './TimeSeries.style';
import {
  TimePeriod,
  formatData,
  mapTimePeriod,
  useLoadGoogleCharts,
} from './utils';

interface Props {
  entityAId: string;
  entityBId?: string;
  campaignAData: definitions['TimeSeriesValue'][];
  campaignBData: definitions['TimeSeriesValue'][];
  onTimeRangeChange: (dateRange: { start: Date; end: Date } | null) => void;
  metric: MetricType;
  loading?: boolean;
  productUnavailabilityDurations: definitions['ProductUnavailabilityDuration'][];
}

const TimeSeries: FC<Props> = (props: Props) => {
  const {
    entityAId,
    campaignAData,
    entityBId,
    campaignBData,
    metric,
    onTimeRangeChange,
    productUnavailabilityDurations,
    loading = false,
  } = props;
  const { t } = useTranslation();
  const container = useRef<null | HTMLDivElement>(null);
  const gcLoaded = useLoadGoogleCharts();

  const [selectedRange, setSelectedRange] = useState<{
    from: Date;
    to: Date;
  }>(
    mapTimePeriod(
      TimePeriod.MONTH,
      campaignAData[campaignAData.length - 1]?.date
    )
  );

  useEffect(() => {
    setSelectedRange(
      mapTimePeriod(
        TimePeriod.MONTH,
        campaignAData[campaignAData.length - 1]?.date
      )
    );
    /* Using entity ids and not the data objects, because the data objects depend on the range and as soon as they are updated
    on range change by the user, this in-turn re-triggers a selectedRange reset. Removed unavailability from the dep array
    as it does not change with the range change.
     */
  }, [entityAId, entityBId, metric]);

  const draw = useCallback(() => {
    const dataTable = new google.visualization.DataTable();

    dataTable.addColumn('date', 'Date');
    dataTable.addColumn('number', metric);
    dataTable.addColumn('number', metric);
    dataTable.addColumn('number', `${t('no_promotion')}`);

    const rows = formatData(
      campaignAData,
      campaignBData,
      productUnavailabilityDurations
    );
    dataTable.addRows(rows);

    const dashboard = new window.google.visualization.Dashboard(
      container.current
    );

    // https://developers.google.com/chart/interactive/docs/gallery/linechart#configuration-options
    const chart = new window.google.visualization.ChartWrapper({
      chartType: 'LineChart',
      containerId: 'chart',
      options: {
        height: CHART_HEIGHT,
        legend: {
          position: 'top',
          alignment: 'end',
          textStyle: { color: '#FF9800', fontSize: 13 },
        },
        pointSize: 8,
        pointsVisible: rows.length < 40,
        backgroundColor: {
          fill: '#F8F8F8',
          strokeWidth: 0,
        },
        chartArea: {
          width: '90%',
          height: '90%',
          // How far to draw the chart from the left border.
          left: 100,
          right: 40,
        },
        colors: ['#AB4DB3', '#43B970', '#FF9800'],
        hAxis: {
          format: 'dd.MM.yy',
          showTextEvery: 0,
          ticks: rows
            .filter(
              (row) =>
                row[0] &&
                isWithinInterval(row[0], {
                  start: selectedRange.from,
                  end: selectedRange.to,
                })
            )
            .map((row) => row[0]),
          gridlines: { color: '#EDEDED' },
        },
        vAxes: {
          gridlines: { color: '#EDEDED' },
        },
        vAxis: {
          format: [
            'impressions',
            'views',
            'clicks',
            'shoppers_reached',
            'group_impressions',
            'group_views',
            'group_clicks',
            'group_shoppers_reached',
          ].includes(metric)
            ? // Thousands separator without decimal
              '#,###'
            : 'decimal',
        },
        series: {
          0: {
            visibleInLegend: false,
          },
          1: {
            visibleInLegend: false,
          },
          2: {
            visibleInLegend: productUnavailabilityDurations?.length > 0,
          },
        },
        lineWidth: 3,
      },
    });
    // https://developers.google.com/chart/interactive/docs/gallery/controls#chartrangefilter
    const rangeFilter = new window.google.visualization.ControlWrapper({
      controlType: 'ChartRangeFilter',
      containerId: 'range-filter',
      state: {
        range: {
          start: selectedRange.from,
          end: selectedRange.to,
        },
      },
      options: {
        filterColumnIndex: 0,
        ui: {
          chartType: 'LineChart',
          minRangeSize: 7,
          // snapToData: true,
          chartOptions: {
            backgroundColor: {
              fill: '#F8F8F8',
              strokeWidth: 0,
            },
            colors: ['#8A2093', '#43B970'],
            chartArea: {
              width: '90%',
              height: '100%',
              // How far to draw the range filter from the left border of the chart.
              top: 5,
              right: 40,
              bottom: 5,
              left: 100,
            },
            hAxis: {
              format: 'dd.MM.yy',
              showTextEvery: 0,
              ticks: rows.map((row) => row[0]),
              gridlines: {
                color: 'none',
              },
            },
            pointSize: 2,
          },
        },
      },
    });

    google.visualization.events.addListener(rangeFilter, 'statechange', () => {
      setSelectedRange({
        from: rangeFilter.getState().range.start,
        to: rangeFilter.getState().range.end,
      });
      onTimeRangeChange({
        start: rangeFilter.getState().range.start,
        end: rangeFilter.getState().range.end,
      });
    });

    dashboard.bind(rangeFilter, chart);
    dashboard.draw(dataTable);
  }, [
    campaignAData,
    campaignBData,
    productUnavailabilityDurations,
    selectedRange,
  ]);

  useEffect(() => {
    if (!gcLoaded || !container.current) return;
    window.google.charts.setOnLoadCallback(draw);
  }, [draw, gcLoaded]);

  useWindowResize(draw);

  return (
    <ContainerS ref={container}>
      <ChartWrapperS>
        <>
          <ChartS id="chart" />
          <RangeFilterS id="range-filter" />
        </>
        {loading && (
          <div className="overlay">
            <CircularProgress />
          </div>
        )}
        {!(campaignAData?.length || campaignBData?.length) && !loading && (
          <div className="overlay">
            <span>{t('no_data')}</span>
          </div>
        )}
      </ChartWrapperS>
    </ContainerS>
  );
};

export default TimeSeries;
