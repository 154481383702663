import { useTranslation } from 'react-i18next';

import { definitions } from 'types/api';

import { useStoreDispatch } from 'store';
import {
  setDraftTouchedFields,
  updateDraft,
} from 'store/modules/campaign/slice';

import { NumberFormat } from 'components/Shared';
import {
  NumberInput,
  TextInput,
  ValueLabel,
} from 'components/Shared/CustomMui/Form';

import {
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

import { CampaignSectionProps } from '../CampaignDetails';

interface Props extends CampaignSectionProps {
  campaignType: definitions['CampaignType'];
  externalCampaignId?: string;
  maxIncentiveId?: string;
  incentiveDistribution?: number;
  allCreativeTargetDefined: boolean;
  incentiveNeeded: boolean;
}

export const Incentives = ({
  campaignType,
  externalCampaignId,
  maxIncentiveId,
  incentiveDistribution,
  allCreativeTargetDefined,
  incentiveNeeded,
  formState,
  validate,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  return (
    <Grid container rowSpacing={0} columnSpacing={{ xs: 0 }} direction="column">
      <Grid item sx={{ mb: (theme) => theme.spacing(2) }}>
        <Typography
          variant="h3"
          sx={{
            fontSize: (theme) => theme.typography.body2.fontSize,
          }}
        >
          {t('incentive')}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={incentiveNeeded}
                disabled={formState?.readonlyFields.includes('Incentives')}
                onChange={(value) => {
                  dispatch(
                    updateDraft({
                      incentive_needed: value.target.checked,
                    })
                  );
                  dispatch(setDraftTouchedFields('incentive_distribution'));
                  if (value.target.checked) {
                    dispatch(
                      updateDraft({
                        external_campaign_id: '',
                        incentive_distribution: { value: 100 },
                      })
                    );
                  } else {
                    dispatch(
                      updateDraft({
                        external_campaign_id: '',
                        incentive_distribution: { value: 0 },
                      })
                    );
                  }
                }}
              />
            }
            label={t('incentive_needed')}
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <ValueLabel id={'max-incentive-value'} label={t('max_incentive')}>
          <NumberFormat displayType="text" value={Number(maxIncentiveId)} />
        </ValueLabel>
      </Grid>
      <Grid item>
        <TextInput
          id="external-campaign-id"
          onChange={(e) =>
            dispatch(
              updateDraft({ external_campaign_id: e.target.value?.trim() })
            )
          }
          label={t('incentive_id')}
          value={externalCampaignId?.trim()}
          disabled={
            !incentiveNeeded ||
            formState?.readonlyFields.includes('IncentiveId')
          }
          width="100%"
          required={incentiveNeeded}
          onBlur={() => {
            dispatch(setDraftTouchedFields('external_campaign_id'));
            dispatch(setDraftTouchedFields('incentive_distribution'));
          }}
          error={validate('external_campaign_id')}
          hint={
            <Stack alignItems="end">{`${externalCampaignId?.length} ${t(
              'characters'
            )}`}</Stack>
          }
        />
      </Grid>
      <Grid>
        <NumberInput
          id="incentive-distribution"
          label={t('incentive_distribution')}
          width="100%"
          prefix={!incentiveNeeded ? '' : '%'}
          disabled={
            !incentiveNeeded ||
            formState?.readonlyFields.includes('IncentiveDistribution')
          }
          max={100}
          min={1}
          value={incentiveDistribution ?? ''}
          onChange={(e) =>
            incentiveNeeded &&
            dispatch(
              updateDraft({
                incentive_distribution: {
                  value: Number(e.target.value?.trim()),
                },
              })
            )
          }
          onBlur={() =>
            dispatch(setDraftTouchedFields('incentive_distribution'))
          }
          error={validate('incentive_distribution')}
        />
      </Grid>
      {incentiveNeeded &&
      campaignType === 'CAMPAIGN_TYPE_CONVERSION' &&
      incentiveDistribution !== undefined &&
      incentiveDistribution < 100 &&
      !allCreativeTargetDefined ? (
        <Grid flex="row" className="warning">
          {t('ad_not_clickable_warning')}
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
};
