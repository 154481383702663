import { tkCampaignSortField, tkCampaignState } from 'i18n/translationKeyMaps';
import { definitions } from 'types/api';

export const campaignApprovalStatusValues: {
  label: string;
  value: definitions['CampaignState'];
}[] = [
  {
    label: tkCampaignState('CAMPAIGN_STATE_RUNNING'),
    value: 'CAMPAIGN_STATE_RUNNING',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_PAUSED_MANUAL'),
    value: 'CAMPAIGN_STATE_PAUSED_MANUAL',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_SUBMITTED'),
    value: 'CAMPAIGN_STATE_SUBMITTED',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_APPROVED'),
    value: 'CAMPAIGN_STATE_APPROVED',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_REJECTED'),
    value: 'CAMPAIGN_STATE_REJECTED',
  },
  {
    label: tkCampaignState('CAMPAIGN_STATE_STOPPED'),
    value: 'CAMPAIGN_STATE_STOPPED',
  },
];

export const campaignApprovalSortFields: {
  [key in Exclude<
    definitions['CampaignSortField'],
    'CAMPAIGN_SORT_UNKNOWN' | 'CAMPAIGN_SORT_STATE'
  >]?: string;
} = {
  CAMPAIGN_SORT_CAMPAIGN_START: tkCampaignSortField(
    'CAMPAIGN_SORT_CAMPAIGN_START'
  ),
  CAMPAIGN_SORT_CAMPAIGN_END: tkCampaignSortField('CAMPAIGN_SORT_CAMPAIGN_END'),
  CAMPAIGN_SORT_NAME: tkCampaignSortField('CAMPAIGN_SORT_NAME'),
};
