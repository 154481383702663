import { useTranslation } from 'react-i18next';

import { tkCampaignState } from 'i18n/translationKeyMaps';
import { CAMPAIGN_INTERNAL_STATES } from 'shared/constants';
import { definitions } from 'types/api';

import { ListActionBar } from 'components/Shared/ListActionBar/ListActionBar';

import {
  campaignApprovalSortFields,
  campaignApprovalStatusValues,
} from './CampaignApprovalActionbarValues';

interface Props {
  activeSearchTerm: string;
  activeStateFilters: string[];
  activeSort: {
    field: definitions['CampaignSortField'];
    order: definitions['SortOrder'];
  };
  onSearch: (searchTerm: string) => void;
  onFilter: (
    filterField: keyof Pick<definitions['Campaign'], 'type' | 'state'>,
    filterValues: string[]
  ) => void;
  onSort: (
    sortBy: definitions['CampaignSortField'],
    sortOrder: definitions['SortOrder']
  ) => void;
}

export const CampaignApprovalActionbar = ({
  onFilter,
  onSearch,
  onSort,
  activeSort,
  activeSearchTerm,
  activeStateFilters,
}: Props) => {
  const { t } = useTranslation();
  return (
    <ListActionBar>
      <ListActionBar.Search
        activeSearchTerm={activeSearchTerm}
        onSearch={(term: string) => {
          if (term !== activeSearchTerm) {
            onSearch(term);
          }
        }}
      />
      <ListActionBar.Sort
        activeSortField={activeSort.field}
        activeSortOrder={activeSort.order}
        sortFields={campaignApprovalSortFields}
        onSort={(sortValue: string, sortOrder: definitions['SortOrder']) => {
          onSort(sortValue as definitions['CampaignSortField'], sortOrder);
        }}
      />

      <ListActionBar.MultiSelectFilter
        showFilterIcon={true}
        filterItem={{
          field: { label: t('Status'), value: 'Status' },
          filterValues: campaignApprovalStatusValues,
          activeFilterValues: activeStateFilters
            .filter((state) => !CAMPAIGN_INTERNAL_STATES.includes(state))
            .map((filterValue) => ({
              label: tkCampaignState(filterValue as any),
              value: filterValue,
            })),
        }}
        onFilter={(values: string[]) => {
          if (values.includes('CAMPAIGN_STATE_PAUSED_MANUAL'))
            values = values.concat([
              'CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED',
              'CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE',
            ]);
          onFilter('state', values);
        }}
      />
    </ListActionBar>
  );
};
