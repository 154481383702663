import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ICampaignExecutionsRequest,
  ICampaignExecutionsResponse,
  ICreateCampaignExecutionRequest,
} from 'shared/interfaces/CampaignExecution';
import { Endpoints } from 'types';
import { definitions } from 'types/api';

import { baseApi } from '../baseApi';

/**
 * Enhance baseApi with tags that we use only in campaign execution
 */
const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: [
    'CampaignExecutions',
    'CampaignExecution',
    'CampaignExecutionContext',
  ],
});

// inject campaign executions endpoints to the enhancedBaseApiWithTags
export const campaignExecutionApi = enhancedBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignExecutions: builder.query<
      ICampaignExecutionsResponse,
      ICampaignExecutionsRequest
    >({
      query: (params: ICampaignExecutionsRequest) => ({
        url: Endpoints.executions,
        params: params,
      }),
      transformResponse: (
        response: definitions['GetCampaignExecutionsResponse']
      ) => {
        const { campaign_executions, pagination } = response;
        return { query: { ...pagination }, results: campaign_executions };
      },
      providesTags: ['CampaignExecutions'],
    }),
    getCampaignExecutionById: builder.query<
      definitions['CampaignExecution'],
      string
    >({
      query: (id: string) => `${Endpoints.executions}/${id}`,
      transformResponse: (
        response: definitions['GetCampaignExecutionByIDResponse']
      ) => {
        return response.execution;
      },
      providesTags: ['CampaignExecution'],
    }),
    getCampaignExecutionContextById: builder.query<
      definitions['CampaignExecutionContext'],
      string
    >({
      query: (id: string) => `${Endpoints.executionContexts}/${id}`,
      transformResponse: (
        response: definitions['GetCampaignExecutionContextResponse']
      ) => {
        return response.execution_context;
      },
      providesTags: ['CampaignExecutionContext'],
    }),
    createCampaignExecution: builder.mutation<
      ICampaignExecutionsResponse,
      ICreateCampaignExecutionRequest
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const exC_Res = await fetchWithBQ({
          url: Endpoints.executionContexts,
          method: 'POST',
          body: {
            channel_id: args.channel_id,
          },
        });
        if (exC_Res.error) throw exC_Res.error;

        const exC_Data =
          exC_Res.data as definitions['CreateCampaignExecutionContextResponse'];

        await fetchWithBQ({
          url: Endpoints.executions,
          method: 'POST',
          body: {
            execution_context_id: exC_Data.execution_context_id,
          },
        });

        const campaignExecutionsRes = await fetchWithBQ({
          url: Endpoints.executions,
          params: args,
        });

        const campaignExecutionsData =
          campaignExecutionsRes.data as definitions['GetCampaignExecutionsResponse'];

        const { campaign_executions, pagination } = campaignExecutionsData;

        return campaignExecutionsRes.data
          ? { data: { query: { ...pagination }, results: campaign_executions } }
          : { error: campaignExecutionsRes.error as FetchBaseQueryError };
      },
      invalidatesTags: ['CampaignExecutions'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCampaignExecutionsQuery,
  useGetCampaignExecutionByIdQuery,
  useGetCampaignExecutionContextByIdQuery,
  useCreateCampaignExecutionMutation,
} = campaignExecutionApi;
