import { useEffect, useState } from 'react';

import { useGetEnvironment } from './useGetEnvironment';

type ServiceStatus = 'UP' | 'DOWN' | 'UNKNOWN';

export const useKeycloak = () => {
  const { authConfig } = useGetEnvironment();
  const [serviceStatus, setServiceStatus] = useState<ServiceStatus>('UNKNOWN');

  useEffect(() => {
    const checkKeycloakStatus = async () => {
      try {
        const response = await fetch(authConfig?.healthCheckUrl || '', {
          method: 'HEAD',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 0 || response.status === 200) {
          setServiceStatus('UP');
        } else {
          setServiceStatus('DOWN');
        }
      } catch (error) {
        setServiceStatus('DOWN');
      }
    };
    if (authConfig?.healthCheckUrl) checkKeycloakStatus();
  }, [authConfig]);

  return { serviceStatus };
};
