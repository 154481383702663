import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as TriangleWarningIcon } from 'assets/icons/triangle-warning.svg';
import { DraftUniverse } from 'shared/interfaces';
import { UserRole } from 'types';
import { definitions } from 'types/api';
import { toDateTime } from 'utils/time';

import { selectUser } from 'store/modules/user/selectors';

import { OverviewSection } from 'components/Campaigns/CampaignDetails/Overview/components';
import { mapUniversePercentageOrScore } from 'components/Universes/utils';

import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, SvgIcon, Typography } from '@mui/material';

import styles from './UniverseOverview.module.scss';

interface Props {
  isBuilderType: boolean;
  isLoading: boolean;
  isSaveDisabled: boolean;
  handleSave: () => void;
  universe: DraftUniverse;
}

export const UniverseOverview: FC<Props> = ({
  isBuilderType,
  isLoading,
  isSaveDisabled,
  handleSave,
  universe,
}: Props): ReactElement => {
  const user = useSelector(selectUser);

  const getScoreValueBasedOnUserRole = (): string => {
    return user?.role === UserRole.KEY_ACCOUNT_MANAGER &&
      universe.build?.shoppers_in_universe
      ? universe.build?.shoppers_in_universe
      : mapUniversePercentageOrScore(
          universe.build as definitions['UniverseBuild']
        );
  };

  const { t } = useTranslation();
  return (
    <Card className={styles.overview_card}>
      <Grid container className={styles.overview_title}>
        <Grid item xs={12}>
          <Typography variant="h3">{t('universe_overview')}</Typography>
        </Grid>
        {universe.updated_at && (
          <Grid item xs={12}>
            <Typography variant="body2" color={'textSecondary'}>
              {`${t('last_modified')}: ${toDateTime(universe.updated_at)}`}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <OverviewSection
            topDivider={true}
            label={t(
              isBuilderType ? 'estimated_audience' : 'number_of_shoppers'
            )}
          >
            {isBuilderType ? (
              <Typography
                variant="body1"
                className={styles.estimated_audience_value}
              >
                {universe.build?.is_empty ? (
                  <Stack direction="row" className={'warning'} spacing={1}>
                    <SvgIcon
                      component={TriangleWarningIcon}
                      viewBox={'0 0 32 32'}
                      className={styles.test_icon}
                    />
                    <Typography>{t('no_shoppers_in_universe')}</Typography>
                  </Stack>
                ) : (
                  getScoreValueBasedOnUserRole()
                )}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                className={styles.estimated_audience_value}
              >
                {universe.build?.shoppers_in_universe ||
                  universe.build?.import_shopper_ids?.length ||
                  '-'}
              </Typography>
            )}
          </OverviewSection>
        </Grid>
        <Grid item xs={12}>
          <OverviewSection>
            <Stack
              className={styles.bottom_action_bar}
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <LoadingButton
                loading={isLoading}
                data-testid="save-universe-button"
                disabled={isSaveDisabled}
                onClick={handleSave}
                variant="contained"
              >
                {universe?.build?.type === 'UNIVERSE_BUILD_TYPE_IMPORT'
                  ? t('import')
                  : t('save')}
              </LoadingButton>
            </Stack>
          </OverviewSection>
        </Grid>
      </Grid>
    </Card>
  );
};
