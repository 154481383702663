import { Endpoints } from 'types';
import { definitions, operations } from 'types/api';

import { baseApi } from '../baseApi';

const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['CampaignGroup', 'CampaignGroupList'],
});

export const campaignGroupApi = enhancedBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignGroups: builder.query<
      definitions['GetCampaignGroupsResponse'],
      operations['CampaignService_GetCampaignGroups']['parameters']['query']
    >({
      query: (
        query:
          | undefined
          | operations['CampaignService_GetCampaignGroups']['parameters']['query']
      ) => ({
        url: Endpoints.campaign_groups,
        params: query,
      }),
      providesTags: ['CampaignGroupList'],
    }),
    getCampaignGroupById: builder.query<
      definitions['GetCampaignGroupResponse'],
      string
    >({
      query: (id: string) => ({
        url: `${Endpoints.campaign_groups}/${id}`,
      }),
      providesTags: ['CampaignGroup'],
    }),
    createCampaignGroup: builder.mutation<
      definitions['CreateCampaignGroupResponse'],
      definitions['CreateCampaignGroupRequest']
    >({
      query: (campaignGroup: definitions['CreateCampaignGroupRequest']) => ({
        url: `${Endpoints.campaign_groups}`,
        method: 'POST',
        body: campaignGroup,
      }),
      invalidatesTags: ['CampaignGroupList'],
    }),
    updateCampaignGroup: builder.mutation<
      { group_id: string; name: string },
      definitions['CampaignGroup']
    >({
      query: ({ group_id, name }: { group_id: string; name: string }) => ({
        url: `${Endpoints.campaign_groups}/${group_id}`,
        method: 'PUT',
        body: { name },
      }),
      invalidatesTags: ['CampaignGroup'],
    }),
    deleteCampaignGroupById: builder.mutation<
      definitions['DeleteCampaignGroupResponse'],
      string
    >({
      query: (id: string) => ({
        url: `${Endpoints.campaign_groups}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CampaignGroupList'],
    }),
    removeCampaignFromGroup: builder.mutation<
      definitions['CampaignGroup'],
      { groupId: string; campaign_ids: string[] }
    >({
      query: (args: { groupId: string; campaign_ids: string[] }) => ({
        url: `${Endpoints.campaign_groups}/${args.groupId}/remove-campaigns`,
        method: 'PUT',
        params: {
          campaign_ids: args.campaign_ids,
        },
      }),
      invalidatesTags: ['CampaignGroup'],
    }),
    assignCampaignsToGroup: builder.mutation<
      definitions['AssignCampaignToGroupResponse'],
      { campaignGroupId: string; campaignIds: string[] }
    >({
      query: (requestParams: {
        campaignGroupId: string;
        campaignIds: string[];
      }) => ({
        url: `${Endpoints.campaign_groups}/${requestParams.campaignGroupId}/add-campaigns`,
        method: 'PUT',
        params: { campaign_ids: requestParams.campaignIds },
      }),
      invalidatesTags: ['CampaignGroup'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCampaignGroupsQuery,
  useGetCampaignGroupByIdQuery,
  useCreateCampaignGroupMutation,
  useUpdateCampaignGroupMutation,
  useDeleteCampaignGroupByIdMutation,
  useRemoveCampaignFromGroupMutation,
  useAssignCampaignsToGroupMutation,
} = campaignGroupApi;
