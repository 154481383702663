import { ChangeEvent, FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DEFAULT_API_PARAMETERS, DEFAULT_COUNTRY } from 'shared/constants';
import { FormType } from 'types';

import { Routes } from 'routes/Routes';

import { campaignApi } from 'store/api/endpoints/campaign';
import {
  useAssignCampaignsToGroupMutation,
  useCreateCampaignGroupMutation,
  useDeleteCampaignGroupByIdMutation,
  useRemoveCampaignFromGroupMutation,
} from 'store/api/endpoints/campaignGroup';
import { selectUser } from 'store/modules/user/selectors';

import { useCampaignGroupActionState } from 'hooks/useCampaignGroupActionState';

import { CampaignSectionWrapper } from 'components/Campaigns/CampaignDetails/sections';
import { SelectCampaignType } from 'components/Campaigns/SelectCampaignType/SelectCampaignType';
import { useCampaignGroupListingData } from 'components/Reporting/hooks/useCampaignGroupListingData';
import { Country, NumberFormat } from 'components/Shared';
import CampaignsTable from 'components/Shared/CampaignsTable/CampaignsTable';
import { PageHeader, TextInput } from 'components/Shared/CustomMui';
import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';
import LoadingIndicator from 'components/Shared/LoadingIndicator/LoadingIndicator';

import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { AddCampaignToGroupDialog } from './AddCampaignToGroupDialog/AddCampaignToGroupDialog';
import styles from './CampaignGroupDetails.module.scss';
import { CampaignGroupOverview } from './CampaignGroupOverview/CampaignGroupOverview';

interface CampaignGroupDetailsProps {
  formType: FormType;
}

export const CampaignGroupDetails: FC<CampaignGroupDetailsProps> = (
  props: CampaignGroupDetailsProps
): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);
  const { campaignGroupId } = useParams();
  const [group, setGroup] = useState<{
    name: string;
    countryCode: string;
  }>({ name: '', countryCode: '' });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState(DEFAULT_API_PARAMETERS.page);
  const [isAddCampaignDialogShown, setIsAddCampaignDialogShown] =
    useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [selectedCampaignToUnassign, setSelectedCampaignToUnassign] = useState<
    string | undefined
  >();

  const { formType } = props;
  const { campaignGroup, isLoading } = useCampaignGroupListingData({
    singleCampaignGroupId: campaignGroupId || 'create',
  });

  const { fields, actions } = useCampaignGroupActionState({
    campaignGroup,
    formType,
    groupName: group.name,
  });

  const [createCampaignGroup, { isLoading: isCreatingGroup }] =
    useCreateCampaignGroupMutation();
  const [deleteCampaignGroup, { isLoading: isDeleting }] =
    useDeleteCampaignGroupByIdMutation();
  const [removeCampaignFromGroup, { isLoading: isRemovingCampaignFromGroup }] =
    useRemoveCampaignFromGroupMutation();
  const [assignCampaignsToGroup, { isLoading: isAssigningCampaign }] =
    useAssignCampaignsToGroupMutation();

  useEffect(() => {
    setGroup({
      name: campaignGroup?.name || '',
      countryCode: campaignGroup?.country_code || DEFAULT_COUNTRY.value,
    });
  }, [campaignGroup?.group_id]);

  const handleAssignNewCampaigns = async (
    campaignsToAssign: string[]
  ): Promise<void> => {
    if (campaignsToAssign?.length) {
      await assignCampaignsToGroup({
        campaignGroupId: campaignGroupId!,
        campaignIds: campaignsToAssign,
      });
      setPage(1);
      setIsAddCampaignDialogShown(false);
      dispatch(
        campaignApi.util.invalidateTags([{ type: 'CampaignList' as never }])
      );
    }
  };

  const saveCampaignGroup = async (): Promise<void> => {
    if (formType === 'create') {
      const createResponse = await createCampaignGroup({
        name: group.name,
        country_code: group.countryCode,
        advertiser_id: user?.advertiser_id ? user.advertiser_id : '',
      }).unwrap();
      navigate(`${Routes.CAMPAIGN_GROUPS}/edit/${createResponse.id}`, {
        replace: true,
      });
    }
  };

  const deleteGroup = async (): Promise<void> => {
    if (campaignGroup?.group_id) {
      const deleteResponse = await deleteCampaignGroup(
        campaignGroup?.group_id
      ).unwrap();
      if (deleteResponse) {
        location.key === 'default'
          ? // if there is no history go to the campaigns page
            navigate(Routes.CAMPAIGNS)
          : navigate(-1);
      }
    }
  };

  const deleteCampaignFromGroup = async (campaignId: string): Promise<void> => {
    if (campaignGroupId) {
      const removeCampaignResult = await removeCampaignFromGroup({
        groupId: campaignGroupId,
        campaign_ids: [campaignId],
      }).unwrap();

      if (removeCampaignResult) {
        dispatch(
          campaignApi.util.invalidateTags([{ type: 'CampaignList' as never }])
        );
        setSelectedCampaignToUnassign(undefined);
        setPage(1);
      }
    }
  };

  return (
    <>
      <LoadingIndicator isAppLoading={isLoading || isAssigningCampaign} />
      <Grid container>
        <Grid item xs={12}>
          <PageHeader
            headerText={t(
              formType === 'edit'
                ? 'edit_campaign_group'
                : 'create_campaign_group'
            )}
            subHeaderText={t('edit_campaign_group_subheader')}
            parentLinkText={t('back').toUpperCase()}
            parentLink={location.key === 'default' ? Routes.CAMPAIGNS : -1}
          />
        </Grid>
        <Grid container>
          <Grid item xs={8}>
            <CampaignSectionWrapper header={t('details')}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextInput
                    required
                    disabled={fields['Name'] === 'Readonly'}
                    data-testid="campaign-group-name"
                    className={styles.no_margin}
                    width={'100%'}
                    label={t('name')}
                    value={group.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setGroup((group) => {
                        return { ...group, name: event?.target?.value };
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Country
                    required
                    countryCode={group.countryCode}
                    onCountryCodeChange={(country_code: string) =>
                      setGroup((group) => {
                        return { ...group, countryCode: country_code };
                      })
                    }
                    width={'100%'}
                    disabled={fields['Country'] === 'Readonly'}
                  ></Country>
                </Grid>
                {fields['SumOfBudgets'] !== 'Hidden' && (
                  <Grid item xs={12} className={styles.readonly_field}>
                    <Typography
                      variant="body1"
                      className={styles.readonly_field_label}
                    >
                      {t('sum_of_budgets')}
                    </Typography>
                    <Typography variant="body1">
                      {(campaignGroup?.campaign_group_currencies?.length ?? 0) >
                      1 ? (
                        t('hide_budget_due_to_multiple_currencies_cg')
                      ) : campaignGroup?.sum_of_total_budgets ? (
                        <NumberFormat
                          displayType="text"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          decimalScale={2}
                          fixedDecimalScale
                          value={Number(campaignGroup?.sum_of_total_budgets)}
                          prefix={campaignGroup?.currency?.symbol}
                        />
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CampaignSectionWrapper>
          </Grid>

          <Grid item xs={4} className={styles.overview_container}>
            <CampaignGroupOverview
              campaignGroup={campaignGroup}
              formActionsState={actions}
            >
              {actions['Delete'] !== 'Hidden' && (
                <Button
                  size="large"
                  onClick={() => setOpenDeleteConfirmation(true)}
                  color={'error'}
                  variant="outlined"
                  data-testid="delete-campaign-group"
                >
                  {`${t('delete')}`}
                </Button>
              )}
              {actions['Save'] !== 'Hidden' && (
                <LoadingButton
                  size="large"
                  disabled={actions['Save'] === 'Readonly'}
                  onClick={saveCampaignGroup}
                  variant="contained"
                  data-testid="save-campaign-group"
                  loading={isCreatingGroup}
                >
                  {`${t('save')}`}
                </LoadingButton>
              )}
            </CampaignGroupOverview>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CampaignSectionWrapper header={t('linked_campaigns')}>
            <CampaignsTable
              activeSortValues={{
                field: 'CAMPAIGN_SORT_NAME',
                order: 'SORT_ORDER_ASC',
              }}
              groupIds={campaignGroupId ? [campaignGroupId] : []}
              campaignGroupFormType={formType}
              page={page}
              setPage={(page: number) => setPage(page)}
              title={t('campaigns')}
              actions={
                <div className={styles.action_buttons}>
                  {actions['AddCampaign'] !== 'Hidden' && (
                    <Button
                      size="small"
                      color="secondary"
                      variant={'outlined'}
                      onClick={() => setIsAddCampaignDialogShown(true)}
                    >
                      + {t('add_campaign')}
                    </Button>
                  )}
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => setIsCreateModalOpen(true)}
                  >
                    {`+ ${t('create_new_campaign')}`}
                  </Button>
                </div>
              }
              onUnassignCampaign={setSelectedCampaignToUnassign}
              emptyStateMessage={t('no_linked_campaign')}
            />
          </CampaignSectionWrapper>
        </Grid>
      </Grid>
      {campaignGroupId && isAddCampaignDialogShown && (
        <AddCampaignToGroupDialog
          countryCode={group.countryCode}
          onCancel={() => setIsAddCampaignDialogShown(false)}
          onConfirm={(campaignsToAssign: string[]) => {
            if (campaignsToAssign?.length) {
              handleAssignNewCampaigns(campaignsToAssign);
            }
          }}
        />
      )}
      {isCreateModalOpen && (
        <SelectCampaignType
          groupId={campaignGroupId}
          countryCode={group.countryCode}
          handleCancelButtonClick={() => setIsCreateModalOpen(false)}
        />
      )}
      {openDeleteConfirmation && (
        <DialogWrapper
          width={750}
          showCloseIcon={false}
          headerText={t('delete_campaign_group')}
          handleClose={() => setOpenDeleteConfirmation(false)}
          dialogContent={t('delete_campaign_group_description')}
          dialogActions={
            <>
              <Button
                onClick={() => setOpenDeleteConfirmation(false)}
                variant="outlined"
              >
                {t('cancel')}
              </Button>
              <LoadingButton
                loading={isDeleting}
                onClick={deleteGroup}
                variant="contained"
                data-testid="confirm-delete-campaign-group"
              >
                {t('delete_group')}
              </LoadingButton>
            </>
          }
        />
      )}
      {selectedCampaignToUnassign && (
        <DialogWrapper
          width={750}
          showCloseIcon={false}
          headerText={t('unassign_campaign_confirmation')}
          handleClose={() => setSelectedCampaignToUnassign(undefined)}
          dialogContent={t('unassign_campaign_description')}
          dialogActions={
            <>
              <Button
                onClick={() => setSelectedCampaignToUnassign(undefined)}
                variant="outlined"
                color="secondary"
              >
                {t('cancel')}
              </Button>
              <LoadingButton
                loading={isRemovingCampaignFromGroup}
                onClick={() => {
                  if (selectedCampaignToUnassign)
                    deleteCampaignFromGroup(selectedCampaignToUnassign);
                  else setSelectedCampaignToUnassign(undefined);
                }}
                variant="contained"
              >
                {t('unassign_campaign')}
              </LoadingButton>
            </>
          }
        />
      )}
    </>
  );
};
