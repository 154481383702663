import RobotoNormal100_eot from 'assets/fonts/roboto-100.eot';
import RobotoNormal100_svg from 'assets/fonts/roboto-100.svg';
import RobotoNormal100_ttf from 'assets/fonts/roboto-100.ttf';
import RobotoNormal100_woff from 'assets/fonts/roboto-100.woff';
import RobotoNormal100_woff2 from 'assets/fonts/roboto-100.woff2';
import RobotoItalic100_eot from 'assets/fonts/roboto-100italic.eot';
import RobotoItalic100_svg from 'assets/fonts/roboto-100italic.svg';
import RobotoItalic100_ttf from 'assets/fonts/roboto-100italic.ttf';
import RobotoItalic100_woff from 'assets/fonts/roboto-100italic.woff';
import RobotoItalic100_woff2 from 'assets/fonts/roboto-100italic.woff2';
import RobotoNormal300_eot from 'assets/fonts/roboto-300.eot';
import RobotoNormal300_svg from 'assets/fonts/roboto-300.svg';
import RobotoNormal300_ttf from 'assets/fonts/roboto-300.ttf';
import RobotoNormal300_woff from 'assets/fonts/roboto-300.woff';
import RobotoNormal300_woff2 from 'assets/fonts/roboto-300.woff2';
import RobotoItalic300_eot from 'assets/fonts/roboto-300italic.eot';
import RobotoItalic300_svg from 'assets/fonts/roboto-300italic.svg';
import RobotoItalic300_ttf from 'assets/fonts/roboto-300italic.ttf';
import RobotoItalic300_woff from 'assets/fonts/roboto-300italic.woff';
import RobotoItalic300_woff2 from 'assets/fonts/roboto-300italic.woff2';
import RobotoNormal500_eot from 'assets/fonts/roboto-500.eot';
import RobotoNormal500_svg from 'assets/fonts/roboto-500.svg';
import RobotoNormal500_ttf from 'assets/fonts/roboto-500.ttf';
import RobotoNormal500_woff from 'assets/fonts/roboto-500.woff';
import RobotoNormal500_woff2 from 'assets/fonts/roboto-500.woff2';
import RobotoItalic500_eot from 'assets/fonts/roboto-500italic.eot';
import RobotoItalic500_svg from 'assets/fonts/roboto-500italic.svg';
import RobotoItalic500_ttf from 'assets/fonts/roboto-500italic.ttf';
import RobotoItalic500_woff from 'assets/fonts/roboto-500italic.woff';
import RobotoItalic500_woff2 from 'assets/fonts/roboto-500italic.woff2';
import RobotoNormal700_eot from 'assets/fonts/roboto-700.eot';
import RobotoNormal700_svg from 'assets/fonts/roboto-700.svg';
import RobotoNormal700_ttf from 'assets/fonts/roboto-700.ttf';
import RobotoNormal700_woff from 'assets/fonts/roboto-700.woff';
import RobotoNormal700_woff2 from 'assets/fonts/roboto-700.woff2';
import RobotoItalic700_eot from 'assets/fonts/roboto-700italic.eot';
import RobotoItalic700_svg from 'assets/fonts/roboto-700italic.svg';
import RobotoItalic700_ttf from 'assets/fonts/roboto-700italic.ttf';
import RobotoItalic700_woff from 'assets/fonts/roboto-700italic.woff';
import RobotoItalic700_woff2 from 'assets/fonts/roboto-700italic.woff2';
import RobotoNormal900_eot from 'assets/fonts/roboto-900.eot';
import RobotoNormal900_svg from 'assets/fonts/roboto-900.svg';
import RobotoNormal900_ttf from 'assets/fonts/roboto-900.ttf';
import RobotoNormal900_woff from 'assets/fonts/roboto-900.woff';
import RobotoNormal900_woff2 from 'assets/fonts/roboto-900.woff2';
import RobotoItalic900_eot from 'assets/fonts/roboto-900italic.eot';
import RobotoItalic900_svg from 'assets/fonts/roboto-900italic.svg';
import RobotoItalic900_ttf from 'assets/fonts/roboto-900italic.ttf';
import RobotoItalic900_woff from 'assets/fonts/roboto-900italic.woff';
import RobotoItalic900_woff2 from 'assets/fonts/roboto-900italic.woff2';
import RobotoItalic400_eot from 'assets/fonts/roboto-italic.eot';
import RobotoItalic400_svg from 'assets/fonts/roboto-italic.svg';
import RobotoItalic400_ttf from 'assets/fonts/roboto-italic.ttf';
import RobotoItalic400_woff from 'assets/fonts/roboto-italic.woff';
import RobotoItalic400_woff2 from 'assets/fonts/roboto-italic.woff2';
import RobotoNormal400_eot from 'assets/fonts/roboto-regular.eot';
import RobotoNormal400_svg from 'assets/fonts/roboto-regular.svg';
import RobotoNormal400_ttf from 'assets/fonts/roboto-regular.ttf';
import RobotoNormal400_woff from 'assets/fonts/roboto-regular.woff';
import RobotoNormal400_woff2 from 'assets/fonts/roboto-regular.woff2';

export const fontDefinitions = `
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 100;
		src: url('${RobotoNormal100_eot}'); /* IE9 Compat Modes */
		src: local(''),
		url('${RobotoNormal100_eot}?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('${RobotoNormal100_woff2}') format('woff2'),
		/* Super Modern Browsers */ url('${RobotoNormal100_woff}')
			format('woff'),
		/* Modern Browsers */ url('${RobotoNormal100_ttf}')
			format('truetype'),
		/* Safari, Android, iOS */ url('${RobotoNormal100_svg}#Roboto')
			format('svg'); /* Legacy iOS */   
  }
  /* roboto-100italic - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 100;
		src: url('${RobotoItalic100_eot}'); /* IE9 Compat Modes */
		src: local(''),
		  url('${RobotoItalic100_eot}?#iefix') format('embedded-opentype'),
		  /* IE6-IE8 */ url('${RobotoItalic100_woff2}') format('woff2'),
		  /* Super Modern Browsers */ url('${RobotoItalic100_woff}')
			format('woff'),
		  /* Modern Browsers */ url('${RobotoItalic100_ttf}')
			format('truetype'),
		  /* Safari, Android, iOS */ url('${RobotoItalic100_svg}#Roboto')
			format('svg'); /* Legacy iOS */ 
	}
	/* roboto-300 - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 300;
		src: url('${RobotoNormal300_eot}'); /* IE9 Compat Modes */
		src: local(''),
		  url('${RobotoNormal300_eot}?#iefix') format('embedded-opentype'),
		  /* IE6-IE8 */ url('${RobotoNormal300_woff2}') format('woff2'),
		  /* Super Modern Browsers */ url('${RobotoNormal300_woff}')
			format('woff'),
		  /* Modern Browsers */ url('${RobotoNormal300_ttf}')
			format('truetype'),
		  /* Safari, Android, iOS */ url('${RobotoNormal300_svg}#Roboto')
			format('svg'); /* Legacy iOS */   
	}
	/* roboto-300italic - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 300;
		src: url('${RobotoItalic300_eot}'); /* IE9 Compat Modes */
		src: local(''),
			url('${RobotoItalic300_eot}?#iefix') format('embedded-opentype'),
			/* IE6-IE8 */ url('${RobotoItalic300_woff2}') format('woff2'),
			/* Super Modern Browsers */ url('${RobotoItalic300_woff}')
			format('woff'),
			/* Modern Browsers */ url('${RobotoItalic300_ttf}')
			format('truetype'),
			/* Safari, Android, iOS */ url('${RobotoItalic300_svg}#Roboto')
			format('svg'); /* Legacy iOS */ 
	}
	/* roboto-regular - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		src: url('${RobotoNormal400_eot}'); /* IE9 Compat Modes */
		src: local(''),
		  url('${RobotoNormal400_eot}?#iefix') format('embedded-opentype'),
		  /* IE6-IE8 */ url('${RobotoNormal400_woff2}') format('woff2'),
		  /* Super Modern Browsers */ url('${RobotoNormal400_woff}')
			format('woff'),
		  /* Modern Browsers */ url('${RobotoNormal400_ttf}')
			format('truetype'),
		  /* Safari, Android, iOS */ url('${RobotoNormal400_svg}#Roboto')
			format('svg'); /* Legacy iOS */   
	}
	/* roboto-400italic - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 400;
		src: url('${RobotoItalic400_eot}'); /* IE9 Compat Modes */
		src: local(''),
			url('${RobotoItalic400_eot}?#iefix') format('embedded-opentype'),
			/* IE6-IE8 */ url('${RobotoItalic400_woff2}') format('woff2'),
			/* Super Modern Browsers */ url('${RobotoItalic400_woff}')
			format('woff'),
			/* Modern Browsers */ url('${RobotoItalic400_ttf}')
			format('truetype'),
			/* Safari, Android, iOS */ url('${RobotoItalic400_svg}#Roboto')
			format('svg'); /* Legacy iOS */ 
	}
	/* roboto-500 - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		src: url('${RobotoNormal500_eot}'); /* IE9 Compat Modes */
		src: local(''),
		  url('${RobotoNormal500_eot}?#iefix') format('embedded-opentype'),
		  /* IE6-IE8 */ url('${RobotoNormal500_woff2}') format('woff2'),
		  /* Super Modern Browsers */ url('${RobotoNormal500_woff}')
			format('woff'),
		  /* Modern Browsers */ url('${RobotoNormal500_ttf}')
			format('truetype'),
		  /* Safari, Android, iOS */ url('${RobotoNormal500_svg}#Roboto')
			format('svg'); /* Legacy iOS */   
	}
	/* roboto-500italic - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 500;
		src: url('${RobotoItalic500_eot}'); /* IE9 Compat Modes */
		src: local(''),
			url('${RobotoItalic500_eot}?#iefix') format('embedded-opentype'),
			/* IE6-IE8 */ url('${RobotoItalic500_woff2}') format('woff2'),
			/* Super Modern Browsers */ url('${RobotoItalic500_woff}')
			format('woff'),
			/* Modern Browsers */ url('${RobotoItalic500_ttf}')
			format('truetype'),
			/* Safari, Android, iOS */ url('${RobotoItalic500_svg}#Roboto')
			format('svg'); /* Legacy iOS */ 
	}
	/* roboto-700 - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		src: url('${RobotoNormal700_eot}'); /* IE9 Compat Modes */
		src: local(''),
		  url('${RobotoNormal700_eot}?#iefix') format('embedded-opentype'),
		  /* IE6-IE8 */ url('${RobotoNormal700_woff2}') format('woff2'),
		  /* Super Modern Browsers */ url('${RobotoNormal700_woff}')
			format('woff'),
		  /* Modern Browsers */ url('${RobotoNormal700_ttf}')
			format('truetype'),
		  /* Safari, Android, iOS */ url('${RobotoNormal700_svg}#Roboto')
			format('svg'); /* Legacy iOS */   
	}
	/* roboto-700italic - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 700;
		src: url('${RobotoItalic700_eot}'); /* IE9 Compat Modes */
		src: local(''),
			url('${RobotoItalic700_eot}?#iefix') format('embedded-opentype'),
			/* IE6-IE8 */ url('${RobotoItalic700_woff2}') format('woff2'),
			/* Super Modern Browsers */ url('${RobotoItalic700_woff}')
			format('woff'),
			/* Modern Browsers */ url('${RobotoItalic700_ttf}')
			format('truetype'),
			/* Safari, Android, iOS */ url('${RobotoItalic700_svg}#Roboto')
			format('svg'); /* Legacy iOS */ 
	}
	/* roboto-900 - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 900;
		src: url('${RobotoNormal900_eot}'); /* IE9 Compat Modes */
		src: local(''),
		  url('${RobotoNormal900_eot}?#iefix') format('embedded-opentype'),
		  /* IE6-IE8 */ url('${RobotoNormal900_woff2}') format('woff2'),
		  /* Super Modern Browsers */ url('${RobotoNormal900_woff}')
			format('woff'),
		  /* Modern Browsers */ url('${RobotoNormal900_ttf}')
			format('truetype'),
		  /* Safari, Android, iOS */ url('${RobotoNormal900_svg}#Roboto')
			format('svg'); /* Legacy iOS */   
	}
	/* roboto-900italic - latin */
	@font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 900;
		src: url('${RobotoItalic900_eot}'); /* IE9 Compat Modes */
		src: local(''),
			url('${RobotoItalic900_eot}?#iefix') format('embedded-opentype'),
			/* IE6-IE8 */ url('${RobotoItalic900_woff2}') format('woff2'),
			/* Super Modern Browsers */ url('${RobotoItalic900_woff}')
			format('woff'),
			/* Modern Browsers */ url('${RobotoItalic900_ttf}')
			format('truetype'),
			/* Safari, Android, iOS */ url('${RobotoItalic900_svg}#Roboto')
			format('svg'); /* Legacy iOS */ 
	}`;
