import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DraftUniverse } from 'shared/interfaces';
import { definitions } from 'types/api';

import {
  useCreateUniverseMutation,
  useGetUniverseByIdQuery,
  useUpdateUniverseMutation,
} from 'store/api/endpoints/universes';
import { addNotification } from 'store/modules/notifications/actions';
import { selectAdvertiser } from 'store/modules/settings/selectors';
import { draftUniverse } from 'store/modules/universes/helpers';

export const useUniverseData = (id: string) => {
  const dispatch = useDispatch();
  const advertiser = useSelector(selectAdvertiser);
  const [universeItem, setUniverseItem] = useState<DraftUniverse | null>(null);
  const newUniverse: DraftUniverse = {
    ...draftUniverse,
    build: {
      type:
        id === 'import'
          ? 'UNIVERSE_BUILD_TYPE_IMPORT'
          : 'UNIVERSE_BUILD_TYPE_BUILDER',
    },
    advertiser_id: advertiser?.id,
    network_id: advertiser?.network_id,
    scope: 'UNIVERSE_SCOPE_GLOBAL',
  };

  const { data, isLoading } = useGetUniverseByIdQuery(
    {
      id: id,
      silentNotification: false,
    },
    {
      skip: id === 'create' || id === 'import',
    }
  );

  useEffect(() => {
    if (data) {
      setUniverseItem(data);
    }
  }, [data]);

  const [createUniverse, { isLoading: isCreating }] =
    useCreateUniverseMutation();
  const [updateUniverse, { isLoading: isUpdating }] =
    useUpdateUniverseMutation();

  const universe =
    id === 'create' || id === 'import' ? newUniverse : universeItem;

  const saveUniverse = async (universe: DraftUniverse) => {
    const updateUniverseResponse = await (universe.id
      ? updateUniverse(universe as definitions['Universe'])
      : createUniverse(universe as definitions['Universe'])
    ).unwrap();
    setUniverseItem(updateUniverseResponse.universe);
    if (!!updateUniverseResponse?.message) {
      dispatch(
        addNotification({
          text: updateUniverseResponse?.message,
          type: 'warning',
        })
      );
    }
    return updateUniverseResponse.universe;
  };

  return {
    universe,
    isLoading,
    isSaving: isCreating || isUpdating,
    saveUniverse,
  };
};
