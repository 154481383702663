import { fontDefinitions } from 'theme/fonts';

import { createTheme } from '@mui/material/styles';
import { alpha, createStyled } from '@mui/system';

interface CustomProperties {
  appMinWidth: number;
  appMaxWidth: number;
  drawerWidth: number;
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    custom: CustomProperties;
  }
  interface ThemeOptions {
    custom?: CustomProperties;
  }
}

const GREY_50 = '#F8F8F8';
const GREY_100 = '#EDEDED';
const GREY_200 = '#E4E3E4';
const GREY_300 = '#D2D2D2';
const GREY_400 = '#C4C4C4';
const BLACK = '#000000';
const PRIMARY_COLOR = '#8A2093';
const SECONDARY_COLOR = '#464646';
const SUCCESS_COLOR = '#3CB054';

const PRIMARY_TEXT_COLOR = '#464646';
const SECONDARY_TEXT_COLOR = '#A9A9A9';
const ERROR_COLOR = '#C62249';
const WARNING_COLOR = '#FF9800';
const INFO_COLOR = '#8A2093';

const baseTheme = createTheme();

const theme = createTheme({
  breakpoints: {
    values: {
      ...baseTheme.breakpoints.values,
      xl: 1450,
    },
  },
  custom: {
    appMinWidth: 576,
    appMaxWidth: 1920,
    drawerWidth: 72,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      ${fontDefinitions},     
        body {
          background-color: ${GREY_50};
          color: ${PRIMARY_TEXT_COLOR}
        }
      `,
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: 14,
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
          fontWeight: 400,
          height: '48px',
          lineHeight: '143%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 15,
          height: 40,
          paddingLeft: baseTheme.spacing(3),
          paddingRight: baseTheme.spacing(3),
          textTransform: 'uppercase',
        },

        sizeSmall: {
          padding: '4px 10px',
          fontSize: 13,
          letterSpacing: '0.46px',

          '.MuiSvgIcon-root': {
            height: 12,
            width: 12,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: GREY_300,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 10,
          height: 24,
          padding: 0,
        },
        sizeSmall: {
          height: 20,
        },
        label: {
          paddingLeft: baseTheme.spacing(2.5),
          paddingRight: baseTheme.spacing(2.5),
        },
        labelSmall: {
          paddingLeft: baseTheme.spacing(0.75),
          paddingRight: baseTheme.spacing(0.75),
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: baseTheme.spacing(2),
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 18,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: GREY_100,
          marginTop: baseTheme.spacing(0.75),
          marginBottom: baseTheme.spacing(1),
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: '#00000042',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.grey[500],
          fontSize: 13,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          border: `1px solid ${GREY_100}`,
          borderRadius: baseTheme.shape.borderRadius,
          fontSize: 16,
          '& fieldset': {
            border: 'none',
          },
          '&.Mui-error': {
            border: `1px solid ${baseTheme.palette.error.main}`,
          },
          '&.Mui-disabled, & .MuiSelect-select.Mui-disabled': {
            backgroundColor: `${GREY_50}`,
          },
        },
        input: {
          boxSizing: 'border-box',
          height: 40,
          lineHeight: '40px',
          padding: 0,
          paddingLeft: baseTheme.spacing(1.5),
          paddingRight: baseTheme.spacing(1.5),
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: PRIMARY_COLOR,
          cursor: 'pointer',
          display: 'block',
          fontSize: 14,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: 39,
          padding: 0,
          paddingLeft: baseTheme.spacing(1.5),
          paddingRight: baseTheme.spacing(1.5),
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: 20,
          width: 20,
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      light: alpha(PRIMARY_COLOR, 0.5),
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    success: {
      main: SUCCESS_COLOR,
    },
    error: {
      main: ERROR_COLOR,
    },
    info: {
      main: INFO_COLOR,
    },
    warning: {
      main: WARNING_COLOR,
    },
    text: {
      primary: PRIMARY_TEXT_COLOR,
      secondary: SECONDARY_TEXT_COLOR,
    },
    grey: {
      50: GREY_50,
      100: GREY_100,
      200: GREY_200,
      300: GREY_300,
      400: GREY_400,
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: 'Roboto',
    button: {
      fontSize: 14,
      textTransform: 'none',
    },
    fontSize: 15,
    h1: {
      color: BLACK,
      fontSize: 32,
      fontWeight: 500,
      lineHeight: '38px',
    },
    h2: {
      color: BLACK,
      fontSize: 26,
      fontWeight: 500,
      lineHeight: '32px',
    },
    h3: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '19px',
    },
    h4: {
      color: SECONDARY_TEXT_COLOR,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '19px',
    },
    h6: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: '12px',
    },
    body1: {
      fontSize: 16,
      lineHeight: '19px',
    },
    body2: {
      fontSize: 13,
      lineHeight: '15px',
    },
  },
  zIndex: {
    appBar: 2,
    drawer: 0,
  },
});

export const styled = createStyled({ defaultTheme: theme });

export default theme;
