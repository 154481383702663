import React from 'react';

import debounce from 'lodash/debounce';

export function useWindowResize(onResize: () => void): void {
  const debouncedOnResize = React.useMemo(
    () => debounce(onResize, 100),
    [onResize]
  );
  React.useLayoutEffect(() => {
    window.addEventListener('resize', debouncedOnResize);
    return () => window.removeEventListener('resize', debouncedOnResize);
  }, [debouncedOnResize]);
}
