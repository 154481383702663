import { definitions } from 'types/api';

const getMaxPeriod = (
  timeUnit: definitions['BuildUniverseUserConditionPeriodType']
): number =>
  ({
    BUILDER_CONDITION_PERIOD_TYPE_DAY: 180,
    BUILDER_CONDITION_PERIOD_TYPE_WEEK: 25,
    BUILDER_CONDITION_PERIOD_TYPE_UNKNOWN: 0,
  }[timeUnit]);

export const validateProductError = (productIds: string[]): boolean =>
  !productIds.length;

export const validateAttributeAndSearchQueryError = (
  attributesIds: string[],
  searchQuery?: string
): boolean => !attributesIds.length && !searchQuery;

export const validateGtinsAttributesAndSearchError = (
  productIds: string[],
  attributesIds: string[],
  searchQuery: string
): boolean => {
  return !(productIds.length > 0 || attributesIds.length > 0 || !!searchQuery);
};

export const validatePeriodError = (
  period: number,
  period_type: definitions['BuildUniverseUserConditionPeriodType']
): boolean => period > getMaxPeriod(period_type);

export const validateAttributesError = (
  attributeId?: string,
  metadata?: string[]
): boolean => !!attributeId && !metadata?.length;
