import { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { definitions } from 'types/api';

import {
  useGetTaxonomyAttributesByIDQuery,
  useLazyGetTaxonomyAttributesQuery,
} from 'store/api/endpoints/productTaxonomies';

import { ListActionBar } from 'components/Shared/ListActionBar/ListActionBar';
import {
  EntitySelectedFilterType,
  LabelValuePairType,
} from 'components/Shared/ListActionBar/components';

import { Box } from '@mui/material';

import styles from './AddProductsDialogFilter.module.scss';

interface Props {
  activeSearchTerm: string;
  showSelectedOnly: boolean;
  product_taxonomy_id: string;
  disableShowSelectedOnlyFilter: boolean;
  initiallySelectedAttributes?: string[];
  onSearch: (searchTerm: string) => void;
  onAttributeFilter: (attributes: string[]) => void;
  onSelectedOnlyFilterUpdate: (areOnlySelectedShown: boolean) => void;
}

export const AddProductsDialogFilter: FC<Props> = (
  props: Props
): ReactElement => {
  const {
    activeSearchTerm,
    initiallySelectedAttributes,
    disableShowSelectedOnlyFilter = false,
    onAttributeFilter,
    onSearch,
    onSelectedOnlyFilterUpdate,
    product_taxonomy_id,
    showSelectedOnly,
  } = props;
  const { t } = useTranslation();
  const [attributeSearchTerm, setAttributeSearchTerm] = useState('');
  const [selectedAttributes, setSelectedAttributes] = useState<
    LabelValuePairType[]
  >([]);
  const [allAttributes, setAllAttributes] = useState<
    definitions['ProductTaxonomyAttribute'][]
  >([]);
  const [page, setPage] = useState<number>(DEFAULT_API_PARAMETERS.page);

  const [getTaxonomyAttributes, { data: fetchedAttributes }] =
    useLazyGetTaxonomyAttributesQuery();

  const { data: taxonomyAttributesByID } = useGetTaxonomyAttributesByIDQuery(
    {
      product_taxonomy_id,
      attribute_ids: initiallySelectedAttributes?.length
        ? initiallySelectedAttributes
        : [],
    },
    {
      skip: !initiallySelectedAttributes?.length,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    const fetchAttributes = async () => {
      if (!product_taxonomy_id) {
        return;
      }
      const data = await getTaxonomyAttributes({
        product_taxonomy_id,
        query: {
          ...(!!attributeSearchTerm && { search: attributeSearchTerm }),
          ...(!!activeSearchTerm && { product_count_search: activeSearchTerm }),
          page: page,
          per_page: DEFAULT_API_PARAMETERS.per_page,
        },
      }).unwrap();
      if (data?.attributes.length) {
        if (page > 1)
          setAllAttributes((previousAttributes) =>
            previousAttributes.concat(data?.attributes)
          );
        else setAllAttributes(data?.attributes);
      }
    };
    fetchAttributes();
  }, [product_taxonomy_id, page, activeSearchTerm, attributeSearchTerm]);

  useEffect(() => {
    if (taxonomyAttributesByID && taxonomyAttributesByID.attributes.length) {
      setSelectedAttributes(getFilterValues(taxonomyAttributesByID.attributes));
    }
  }, [taxonomyAttributesByID]);

  const getFilterValues = (
    attributes: definitions['ProductTaxonomyAttribute'][]
  ) => {
    return (
      attributes.map(
        (filterValue: definitions['ProductTaxonomyAttribute']) => ({
          label: filterValue.name,
          value: filterValue.id,
          itemCount: filterValue?.product_count || 0,
        })
      ) || []
    );
  };

  const handleFilterChange = (filters: EntitySelectedFilterType[]): void => {
    let attributeFiltersLabelValuePair =
      filters?.find(
        (filter: EntitySelectedFilterType) =>
          filter.field?.value === 'attributes'
      )?.activeFilterValues || [];
    setSelectedAttributes(attributeFiltersLabelValuePair);
    onAttributeFilter(
      attributeFiltersLabelValuePair?.map(
        (iterator: LabelValuePairType) => iterator.value
      )
    );
  };

  const handleSearchTermChange = (term: string): void => {
    if (term !== activeSearchTerm) {
      onSearch(term);
    }
  };

  const handleLoadMoreItems = () => {
    if (
      fetchedAttributes?.pagination?.results &&
      fetchedAttributes?.pagination?.results > allAttributes.length
    ) {
      setPage((previousPage: number) => previousPage + 1);
    }
  };

  return (
    <Box className={styles.action_bar}>
      <ListActionBar onFiltersChanged={handleFilterChange}>
        <ListActionBar.Search
          onSearch={handleSearchTermChange}
          activeSearchTerm={activeSearchTerm}
        />
        <ListActionBar.AutoCompleteFilter
          showFilterIcon={true}
          onInputChange={(searchTerm: string) => {
            setPage(1);
            setAttributeSearchTerm(searchTerm);
          }}
          filterItem={{
            field: { label: t('attributes'), value: 'attributes' },
            activeFilterValues: selectedAttributes,
            showItemCount: true,
            filterValues: getFilterValues(allAttributes),
          }}
          onFilter={() => {}}
          onLoadMoreItems={handleLoadMoreItems}
        />
        <ListActionBar.SwitchFilter
          alreadyChecked={showSelectedOnly}
          disabled={disableShowSelectedOnlyFilter}
          filterName={{
            label: t('show_selected_only'),
            value: t('show_selected_only'),
          }}
          onFilter={onSelectedOnlyFilterUpdate}
        />
      </ListActionBar>
    </Box>
  );
};
