import { useTranslation } from 'react-i18next';

import { ExtendedChannel } from 'hooks';
import i18n from 'i18n';
import { tkCampaignType } from 'i18n/translationKeyMaps';
import i18next from 'i18next';
import { ExtendedCampaign, ExtendedCampaignGroup } from 'shared/interfaces';
import { definitions } from 'types/api';
import { toDateTime } from 'utils/time';

import { Routes } from 'routes/Routes';

import { NumberFormat } from 'components/Shared';
import { CountryNameWithFlag } from 'components/Shared/CountryNameWithFlag/CountryNameWithFlag';
import {
  CampaignStateChip,
  Column,
  CustomIconButton,
} from 'components/Shared/CustomMui';

import { Tooltip } from '@mui/material';

const COLUMN_WIDTH = 120;

export const ReportingColumns = (
  networkAdvertisers?: definitions['Advertiser'][]
): Column[] => {
  return [
    {
      field: 'name',
      headerName: i18next.t('name'),
      width: 300,
      useRootObject: true,
      renderCell: (campaign: ExtendedCampaign) => (
        <Tooltip title={campaign.name}>
          <span>{campaign.name}</span>
        </Tooltip>
      ),
    },
    {
      field: 'state',
      headerName: i18next.t('status'),
      justify: 'flex-start',
      width: COLUMN_WIDTH,
      renderCell: (state: definitions['CampaignState']) => {
        return <CampaignStateChip state={state} />;
      },
    },
    {
      field: 'type',
      headerName: i18next.t('type'),
      justify: 'flex-start',
      width: 80,
      renderCell: (type: definitions['CampaignType']) => tkCampaignType(type),
    },
    {
      field: 'country_code',
      headerName: i18next.t('country'),
      justify: 'flex-start',
      width: 80,
      renderCell: (country_code: string) => (
        <CountryNameWithFlag
          countryCode={country_code}
          countryName={country_code}
        />
      ),
    },
    {
      field: 'advertiser_id',
      headerName: i18n.t('advertiser_name'),
      renderCell: (id: string) => (
        <>
          {networkAdvertisers?.find((advertiser) => advertiser.id === id)
            ?.display_name || '-'}
        </>
      ),
    },
    {
      field: 'extended_channels',
      headerName: i18next.t('channel'),
      width: 200,
      renderCell: (channels: ExtendedChannel[]) => {
        const allChannels = channels
          .map((channel: ExtendedChannel) => channel.name)
          .join(', ');
        return allChannels ? (
          <Tooltip title={allChannels}>
            <span>{allChannels}</span>
          </Tooltip>
        ) : (
          '-'
        );
      },
    },
    {
      field: 'roas_cumulative',
      headerName: i18next.t('return_on_advertisement_spent'),
      justify: 'flex-end',
      width: 150,
      renderCell: (roas_cumulative: string) => (
        <NumberFormat
          decimalScale={2}
          thousandSeparator={'.'}
          decimalSeparator={','}
          displayType="text"
          value={Number(roas_cumulative)}
        />
      ),
    },
    {
      field: 'number_of_views_cumulative',
      headerName: i18next.t('views'),
      justify: 'flex-end',
      width: COLUMN_WIDTH,
      renderCell: (number_of_views_cumulative: string) =>
        isNaN(Number(number_of_views_cumulative)) ? (
          '-'
        ) : (
          <NumberFormat
            decimalScale={0}
            thousandSeparator={'.'}
            decimalSeparator={','}
            displayType="text"
            value={Number(number_of_views_cumulative)}
          />
        ),
    },
    {
      field: 'number_of_clicks_cumulative',
      headerName: i18next.t('clicks'),
      justify: 'flex-end',
      width: COLUMN_WIDTH,
      renderCell: (number_of_clicks_cumulative: string) =>
        isNaN(Number(number_of_clicks_cumulative)) ? (
          '-'
        ) : (
          <NumberFormat
            decimalScale={0}
            displayType="text"
            thousandSeparator={'.'}
            decimalSeparator={','}
            value={Number(number_of_clicks_cumulative)}
          />
        ),
    },
    {
      field: 'units_purchased_cumulative',
      headerName: i18next.t('units_purchased'),
      justify: 'flex-end',
      width: COLUMN_WIDTH,
      renderCell: (units_purchased: string) => (
        <NumberFormat
          decimalScale={0}
          displayType="text"
          thousandSeparator={'.'}
          decimalSeparator={','}
          value={Number(units_purchased)}
        />
      ),
    },
    {
      field: 'used_budget_total_cumulative',
      headerName: i18next.t('used_budget'),
      justify: 'flex-end',
      width: 150,
      useRootObject: true,
      renderCell: (campaign: ExtendedCampaign) => (
        <NumberFormat
          decimalScale={2}
          displayType="text"
          prefix={campaign?.currency?.symbol}
          thousandSeparator={'.'}
          decimalSeparator={','}
          value={Number(campaign?.used_budget_total_cumulative)}
        />
      ),
    },
    {
      field: 'number_of_impressions_cumulative',
      headerName: i18next.t('impressions'),
      justify: 'flex-end',
      width: COLUMN_WIDTH,
      renderCell: (impression: string) =>
        isNaN(Number(impression)) ? (
          '-'
        ) : (
          <NumberFormat
            decimalScale={0}
            displayType="text"
            thousandSeparator={'.'}
            decimalSeparator={','}
            value={Number(impression)}
          />
        ),
    },
    {
      field: 'campaign_start',
      headerName: i18next.t('campaign_start'),
      justify: 'flex-start',
      width: COLUMN_WIDTH,
      renderCell: (date: string) => toDateTime(date, false),
    },
    {
      field: 'campaign_end',
      headerName: i18next.t('campaign_end'),
      justify: 'flex-start',
      width: COLUMN_WIDTH,
      renderCell: (date: string) =>
        !date ? i18n.t('no_end_date') : toDateTime(date, false),
    },
    {
      field: 'id',
      headerName: '',
      justify: 'flex-end',
      width: 60,
      sticky: 'right',
      renderCell: (id: string) => {
        return (
          <CustomIconButton
            to={`${Routes.CAMPAIGNS}/${id}/edit`}
            icon="edit"
            title={i18next.t('edit_campaign')}
          />
        );
      },
    },
  ];
};

export const GroupReportingMetricsHeaderColumns = (): Column[] => {
  const { t } = useTranslation();
  return [
    {
      field: 'name',
      headerName: i18next.t('campaign_group_metric_header_title'),
      width: 300,
    },
    {
      field: 'state',
      headerName: '',
      justify: 'flex-start',
      width: COLUMN_WIDTH,
    },
    {
      field: 'type',
      headerName: '',
      justify: 'flex-start',
      width: 80,
    },
    {
      field: 'country_code',
      headerName: '',
      justify: 'flex-start',
      width: 80,
    },
    {
      field: 'extended_channels',
      headerName: '',
      width: 200,
    },
    {
      field: 'roas_cumulative',
      headerName: '',
      justify: 'flex-end',
      width: 150,
      useRootObject: true,
      renderCell: (campaignGroup: ExtendedCampaignGroup) => {
        return campaignGroup?.campaign_group_currencies?.length === 1 ? (
          <NumberFormat
            decimalScale={2}
            thousandSeparator={'.'}
            decimalSeparator={','}
            displayType="text"
            value={Number(campaignGroup?.roas_cumulative)}
          />
        ) : !campaignGroup?.campaign_group_currencies?.length ? (
          ''
        ) : (
          <span>{t('multiple_currencies')}</span>
        );
      },
    },
    {
      field: 'number_of_views_cumulative',
      headerName: '',
      justify: 'flex-end',
      width: COLUMN_WIDTH,
      renderCell: (number_of_views_cumulative: string) =>
        isNaN(Number(number_of_views_cumulative)) ? (
          '-'
        ) : (
          <NumberFormat
            decimalScale={0}
            thousandSeparator={'.'}
            decimalSeparator={','}
            displayType="text"
            value={Number(number_of_views_cumulative)}
          />
        ),
    },
    {
      field: 'number_of_clicks_cumulative',
      headerName: '',
      justify: 'flex-end',
      width: COLUMN_WIDTH,
      renderCell: (number_of_clicks_cumulative: string) =>
        isNaN(Number(number_of_clicks_cumulative)) ? (
          '-'
        ) : (
          <NumberFormat
            decimalScale={0}
            displayType="text"
            thousandSeparator={'.'}
            decimalSeparator={','}
            value={Number(number_of_clicks_cumulative)}
          />
        ),
    },
    {
      field: 'units_purchased_cumulative',
      headerName: '',
      justify: 'flex-end',
      width: COLUMN_WIDTH,
      renderCell: (units_purchased: string) => (
        <NumberFormat
          decimalScale={0}
          displayType="text"
          thousandSeparator={'.'}
          decimalSeparator={','}
          value={Number(units_purchased)}
        />
      ),
    },
    {
      field: 'used_budget_total_cumulative',
      headerName: '',
      justify: 'flex-end',
      width: 150,
      useRootObject: true,
      renderCell: (campaignGroup: ExtendedCampaignGroup) => {
        return campaignGroup?.campaign_group_currencies?.length === 1 ? (
          <NumberFormat
            decimalScale={2}
            displayType="text"
            prefix={campaignGroup?.currency?.symbol}
            thousandSeparator={'.'}
            decimalSeparator={','}
            value={Number(campaignGroup?.used_budget_total_cumulative)}
          />
        ) : !campaignGroup?.campaign_group_currencies?.length ? (
          ''
        ) : (
          <span>{t('multiple_currencies')}</span>
        );
      },
    },
    {
      field: 'number_of_impressions_cumulative',
      headerName: '',
      justify: 'flex-end',
      width: COLUMN_WIDTH,
      renderCell: (impression: string) =>
        isNaN(Number(impression)) ? (
          '-'
        ) : (
          <NumberFormat
            decimalScale={0}
            displayType="text"
            thousandSeparator={'.'}
            decimalSeparator={','}
            value={Number(impression)}
          />
        ),
    },
    {
      field: 'campaign_start',
      headerName: '',
      justify: 'flex-start',
      width: COLUMN_WIDTH,
    },
    {
      field: 'campaign_end',
      headerName: '',
      justify: 'flex-start',
      width: COLUMN_WIDTH,
    },
    {
      field: 'id',
      headerName: '',
      justify: 'flex-end',
      width: 60,
      sticky: 'right',
    },
  ];
};
