import styles from './UniverseChangeLogRowItem.module.scss';

interface Props {
  label: string;
  value?: string;
}

export const UniverseChangeLogRowItem = ({ label, value }: Props) => {
  if (!value) return null;

  return (
    <div className={styles.wrapper}>
      {label} <span className={styles.value}> {value}</span>
    </div>
  );
};
