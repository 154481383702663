import React from 'react';
import { useLocation } from 'react-router-dom';

export const randString = () => Math.random().toString(36).substring(7);

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const objectKeys = <Obj>(obj: Obj): (keyof Obj)[] => {
  return Object.keys(obj) as (keyof Obj)[];
};

export const upsertObjectInArray = <Obj>(
  array: Obj[],
  elementToUpsert: Obj,
  key: keyof Obj
): Obj[] => {
  const i = array.findIndex(
    (element) =>
      JSON.stringify(element[key]) === JSON.stringify(elementToUpsert[key])
  );
  if (i > -1) array[i] = elementToUpsert;
  else array.push(elementToUpsert);
  return array;
};

export const getEnumKeyByValue = <T extends { [key: string]: string }>(
  enumType: T,
  enumValue?: T[keyof T] | string
): string => {
  let keys = Object.keys(enumType).filter((x) => enumType[x] === enumValue);
  return keys.length > 0 ? keys[0] : '';
};
