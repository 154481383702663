import { Endpoints } from 'types';
import { definitions, operations } from 'types/api';

import { baseApi } from '../baseApi';

const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['ShopperMetadataList', 'ShopperMetadataListByIds'],
});

export const shopperMetadataApi = enhancedBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getShopperMetadataList: builder.query<
      definitions['GetAllShopperMetadataResponse'],
      operations['ShopperMetadataService_GetAllShopperMetadata']['parameters']
    >({
      query: (
        params: operations['ShopperMetadataService_GetAllShopperMetadata']['parameters']
      ) => ({
        url: Endpoints.shopperMetadata,
        params: {
          ...params.query,
        },
      }),
      providesTags: ['ShopperMetadataList'],
    }),
    getShopperMetadataListByIds: builder.query<
      definitions['GetShopperMetadataByIdResponse'],
      operations['ShopperMetadataService_GetShopperMetadataByID']['parameters']
    >({
      query: (
        params: operations['ShopperMetadataService_GetShopperMetadataByID']['parameters']
      ) => ({
        url: `${Endpoints.shopperMetadata}/${params?.path?.attribute_ids?.join(
          ','
        )}`,
        params: {
          ...params.query,
        },
      }),
      providesTags: ['ShopperMetadataListByIds'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetShopperMetadataListQuery,
  useGetShopperMetadataListByIdsQuery,
} = shopperMetadataApi;
