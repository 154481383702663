import { styled } from 'theme';

import { Avatar } from '@mui/material';
import Grid from '@mui/material/Grid';

type AvatarSProps = {
  isSelected: boolean;
};

export const AvatarStyle = styled(Avatar, {
  shouldForwardProp: (p: string) => p !== 'isSelected',
})<AvatarSProps>(({ isSelected = false, theme }) => ({
  color: '#FFFFFF',
  height: theme.spacing(5),
  width: theme.spacing(5),
  backgroundColor: isSelected
    ? theme.palette.primary.main
    : theme.palette.grey[300],
}));

export const UserMenuStyle = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
