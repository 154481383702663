import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_COUNTRY } from 'shared/constants';
import { UserRole } from 'types';
import { localMiddayISO, TOMORROW } from 'utils/time';

import { useStoreDispatch } from 'store';
import { useGetNetworkAdvertisersQuery } from 'store/api/endpoints/settings';
import {
  setDraftTouchedFields,
  updateDraft,
} from 'store/modules/campaign/slice';

import { CampaignSectionProps } from 'components/Campaigns/CampaignDetails/CampaignDetails';
import { Country, TextInput } from 'components/Shared';
import { AccessResolver } from 'components/Shared/AccessResolver/AccessResolver';
import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import { CampaignGroupSelection } from './CampaignGroupSelection/CampaignGroupSelection';
import { CampaignSectionWrapper } from './CampaignSectionWrapper';

interface Props extends CampaignSectionProps {
  name: string;
  countryCode: string;
  advertiserId: string;
  campaignGroupId?: string;
  resetCSP: () => void;
}

const Details = ({
  name,
  countryCode,
  advertiserId,
  campaignGroupId,
  formState,
  validate,
  resetCSP,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { data: allAdvertisers } = useGetNetworkAdvertisersQuery();

  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(
    countryCode
  );
  const [countryChangeConfirmation, setCountryChangeConfirmation] =
    useState(false);

  return (
    <CampaignSectionWrapper header={t('campaign_details')}>
      <Grid container rowSpacing={0}>
        <Grid item xs={6}>
          <TextInput
            id="name"
            error={validate('name')}
            onBlur={() => dispatch(setDraftTouchedFields('name'))}
            onChange={(e) => dispatch(updateDraft({ name: e.target.value }))}
            label={t('name')}
            value={name}
            disabled={formState.readonlyFields.includes('Name')}
            required={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Country
            required
            countryCode={countryCode || DEFAULT_COUNTRY.value}
            disabled={formState.readonlyFields.includes('Country')}
            onCountryCodeChange={(countryCode: string) => {
              setSelectedCountry(countryCode);
              setCountryChangeConfirmation(true);
            }}
          />
        </Grid>
        {!formState.hiddenFields.includes('CampaignGroup') && (
          <Grid item xs={6}>
            <CampaignGroupSelection
              campaignGroupId={campaignGroupId}
              validate={validate('campaign_group_id')}
              disabled={formState.readonlyFields.includes('CampaignGroup')}
              country_code={countryCode}
              onTouch={() =>
                dispatch(setDraftTouchedFields('campaign_group_id'))
              }
              onChange={(campaignGroupId) =>
                dispatch(updateDraft({ campaign_group_id: campaignGroupId }))
              }
            />
          </Grid>
        )}
        <AccessResolver
          requiredRoles={[
            UserRole.KEY_ACCOUNT_MANAGER,
            UserRole.APPROVAL_MANAGER,
          ]}
        >
          <Grid item xs={6}>
            <Typography color="textSecondary" variant="caption" component="div">
              {t('advertiser_name')}
            </Typography>
            <Typography color="textPrimary" component="div">
              {allAdvertisers?.networkAdvertisers?.length
                ? allAdvertisers?.networkAdvertisers?.find(
                    (networkAdvertiser) =>
                      networkAdvertiser?.id === advertiserId
                  )?.display_name
                : '-'}
            </Typography>
          </Grid>
        </AccessResolver>
      </Grid>
      {countryChangeConfirmation && (
        <DialogWrapper
          width={550}
          showCloseIcon={false}
          headerText={t('confirm_country_change_header')}
          handleClose={() => setCountryChangeConfirmation(false)}
          dialogContent={<i>{t('confirm_country_change_message')}</i>}
          dialogActions={
            <>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setCountryChangeConfirmation(false)}
              >
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    updateDraft({
                      country_code: selectedCountry,
                      campaign_group_id: '',
                      channels: [],
                      universe_id: '',
                      promoted_products: [],
                      campaign_start: localMiddayISO(TOMORROW),
                      campaign_end: null,
                      budget: '', //PACMAN-1666
                      advertiser_cpa_bid: '', //PACMAN-1666
                      advertiser_cpc_bid: '', //PACMAN-1666
                      advertiser_cpm_bid: '', //PACMAN-1666
                      target_daily_budget: '', //PACMAN-1666
                    })
                  );
                  resetCSP();
                  setCountryChangeConfirmation(false);
                }}
              >
                {t('confirm')}
              </Button>
            </>
          }
        />
      )}
    </CampaignSectionWrapper>
  );
};

export default React.memo(Details);
