import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { definitions } from 'types/api';

import { CampaignSectionProps } from 'components/Campaigns/CampaignDetails/CampaignDetails';

import { Grid, Tab, Tabs } from '@mui/material';

import { CampaignSectionWrapper } from '../CampaignSectionWrapper';
import { CreativeItem } from './CreativeItem';
import Styles from './Creatives.module.scss';
import { CreativeData } from './hooks/useCreativesData';

interface Props extends CampaignSectionProps {
  campaignCreatives: CreativeData[];
  languages?: definitions['Language'][];
  updateCreative: (index: number, data: any) => void;
  isTargetUrlRequired: boolean;
}
export const Creatives = ({
  campaignCreatives,
  languages,
  formState,
  validate,
  updateCreative,
  isTargetUrlRequired,
}: Props) => {
  const { t } = useTranslation();
  const validationMessage = validate?.('creatives');
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>('');

  useEffect(() => {
    // This is required because on changing country and consequently the languages, we need to reset the selected language.
    setSelectedLanguageCode(languages?.length ? languages[0]?.code : '');
  }, [languages]);

  const creatives = campaignCreatives.filter(
    (creative) => creative.data?.language === selectedLanguageCode
  );

  return (
    <Grid container direction="row" justifyContent="space-around">
      <Grid item xs={12}>
        <CampaignSectionWrapper
          header={t('creatives')}
          subHeader={t('subheader_campaign_creatives')}
          error={validationMessage}
          required
        >
          <Tabs
            value={selectedLanguageCode}
            onChange={(_, value: string) => setSelectedLanguageCode(value)}
            className={Styles.tabs}
          >
            {selectedLanguageCode &&
              languages?.map((language) => (
                <Tab
                  key={language.code}
                  value={language.code}
                  label={`${language.name} (${
                    campaignCreatives?.filter(
                      (creative) =>
                        creative.data?.language === language.code &&
                        creative.data?.isValid
                    ).length
                  }/${creatives?.length})`}
                />
              ))}
          </Tabs>

          <Grid container spacing={1}>
            {creatives?.map((creative) => (
              <Grid item xs={6} key={creative.index}>
                <CreativeItem
                  index={creative.index}
                  key={creative.index}
                  channelCreativeOption={creative.options}
                  editable={!formState.readonlyFields.includes('Creatives')}
                  campaignCreative={creative.data}
                  languageCode={selectedLanguageCode}
                  updateCreative={updateCreative}
                  isTargetUrlRequired={isTargetUrlRequired}
                />
              </Grid>
            ))}
          </Grid>
        </CampaignSectionWrapper>
      </Grid>
    </Grid>
  );
};
