import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';

import { Divider, MenuItem, SvgIcon } from '@mui/material';

import { AccountInfo } from './AccountInfo';
import { UserDropdownStyle } from './UserDropdown.style';

type UserDropdownProps = {
  user: any;
  anchorEl: Element | null;
  handleClose: () => void;
  onProfileClick: () => void;
  onLogoutClick: () => void;
};

export const UserDropdown = (props: UserDropdownProps) => {
  const { anchorEl, handleClose, onProfileClick, onLogoutClick, user } = props;

  const { t } = useTranslation();

  return (
    <UserDropdownStyle
      id="user-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      disableScrollLock={true}
    >
      <AccountInfo user={user} />
      <Divider />
      <MenuItem
        onClick={onProfileClick}
        data-testid="my-profile-menu-item"
        sx={{ color: (theme) => theme.palette.primary.main }}
      >
        {/*<PermIdentityOutlinedIcon*/}
        {/*  sx={{*/}
        {/*    marginRight: '12px',*/}
        {/*    color: (theme) => theme.palette.primary.main,*/}
        {/*  }}*/}
        {/*/>*/}
        <SvgIcon
          component={UserIcon}
          viewBox={'0 0 32 32'}
          sx={{
            marginRight: '12px',
            color: (theme) => theme.palette.primary.main,
          }}
        />
        {t('my_profile')}
      </MenuItem>
      <MenuItem onClick={onLogoutClick} data-testid="logout-menu-item">
        <SvgIcon
          component={LogoutIcon}
          viewBox={'0 0 32 32'}
          sx={{
            marginRight: '12px',
          }}
        />
        {t('logout')}
      </MenuItem>
    </UserDropdownStyle>
  );
};
