import { useIdleTimer as useReactIdleTimer } from 'react-idle-timer';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'routes/Routes';

import { SESSION_EXTENSION_TIME_IN_SECONDS } from '../shared/constants';

export interface IdleTimerType {
  minutes?: number;
  activityConfirmationHandler: () => void;
}

export const useIdleTimer = ({
  minutes = 1,
  activityConfirmationHandler,
}: IdleTimerType) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const getTimeout = (minutes: number) => 1000 * 60 * minutes;

  const logout = () => {
    if (auth?.isAuthenticated) {
      auth.signoutRedirect({
        post_logout_redirect_uri: `${window.location.origin}${Routes.LOGIN}?redirect_uri=${window.location.pathname}`,
        id_token_hint: auth?.user?.id_token,
      });
      navigate(Routes.LOGIN);
    }
  };
  const timeout = getTimeout(minutes);

  const onIdle = () => {
    logout();
  };

  return useReactIdleTimer({
    timeout,
    onIdle,
    onPrompt: activityConfirmationHandler,
    promptBeforeIdle: SESSION_EXTENSION_TIME_IN_SECONDS * 1000,
  });
};
