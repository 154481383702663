import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';

import { Grid, Link, Stack, SvgIcon, Typography } from '@mui/material';

import styles from './PageHeader.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  headerText: string;
  subHeaderText: string;
  parentLink?: string | number;
  parentLinkText?: string;
}
export const PageHeader = ({
  headerText,
  subHeaderText,
  parentLinkText,
  parentLink,
  children,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const LINE_HEIGHT_IN_PIXEL = 19;
  const LINES_TO_DISPLAYED = 2;
  const [showMore, setShowMore] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const [subHeaderClass, setSubHeaderClass] = useState<string>();
  const [subHeaderLines, setSubHeaderLines] = useState<number>(0);

  const calcNumberOfLines = () => {
    const subHeaderInnerHeight = ref.current?.clientHeight;
    /**
     * Get the inner height of an element in pixels and divide them by 19 (LINE_HEIGHT_IN_PIXEL: which is the
     * line-height of html body) to calculate the total number of lines displayed in sub header element.
     * So that we can restrict the number of line to 2 (LINES_TO_DISPLAYED) with css classname.
     */
    setSubHeaderLines(
      subHeaderInnerHeight ? subHeaderInnerHeight / LINE_HEIGHT_IN_PIXEL : 0
    );
  };

  useEffect(() => {
    calcNumberOfLines();
    /**
     * Classname to shorten the number of lines for subHeader has to be applied only
     * after we calculate the number of lines based on subHeader's initial inner height
     */
    setSubHeaderClass(styles.sub_header__content_show_less);
  }, []);

  /**
   * Toggle between show all and show less css classes for sub header element
   */
  const toggleShowMore = () => {
    if (!showMore) {
      setSubHeaderClass(styles.sub_header__content_show_all);
      setShowMore(!showMore);
    } else {
      setSubHeaderClass(styles.sub_header__content_show_less);
      setShowMore(!showMore);
    }
  };

  const parentNavigate = (): void => {
    if (typeof parentLink === 'number') {
      navigate(parentLink);
    } else if (typeof parentLink === 'string') {
      navigate(parentLink);
    }
  };

  return (
    <Grid container className={styles.page_header}>
      {parentLinkText && parentLink && (
        <Grid item xs={12}>
          <div
            onClick={() => parentNavigate()}
            className={styles.back_link}
            data-testid="back-link"
          >
            <Stack direction="row" spacing={1}>
              <SvgIcon
                component={ArrowLeftIcon}
                viewBox={'0 0 32 32'}
                fontSize="large"
              />
              <span>{parentLinkText}</span>
            </Stack>
          </div>
        </Grid>
      )}
      {children && (
        <Grid container className={styles.alert_container}>
          <Grid item xs={12}>
            <div className="alert">{children}</div>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" data-testid="page-header-text">
            {headerText}
          </Typography>
        </Grid>
        {subHeaderText && (
          <Grid item xs={12} className={styles.sub_header}>
            <div
              ref={ref}
              aria-label="sub_header text"
              className={subHeaderClass}
            >
              {subHeaderText}
            </div>
            {/* Only display show more button when sub header element has more than 2 lines */}
            {subHeaderLines > LINES_TO_DISPLAYED && (
              <Link
                className={styles.more}
                variant="body2"
                onClick={toggleShowMore}
              >
                {showMore ? t('show_less') : t('show_more')}
              </Link>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
