import { tkCampaignGroupSortField } from 'i18n/translationKeyMaps';
import { definitions } from 'types/api';

import { ListActionBar } from 'components/Shared/ListActionBar/ListActionBar';

export const campaignGroupSortFields: {
  [key in definitions['CampaignGroupSortField']]: string;
} = {
  CAMPAIGN_GROUP_SORT_NAME: tkCampaignGroupSortField(
    'CAMPAIGN_GROUP_SORT_NAME'
  ),
  CAMPAIGN_GROUP_SORT_BUDGET: tkCampaignGroupSortField(
    'CAMPAIGN_GROUP_SORT_BUDGET'
  ),
  CAMPAIGN_GROUP_SORT_LAST_MODIFIED: tkCampaignGroupSortField(
    'CAMPAIGN_GROUP_SORT_LAST_MODIFIED'
  ),
  CAMPAIGN_GROUP_SORT_UNKNOWN: '',
};

interface Props {
  activeSearchTerm: string;
  activeSort: {
    field: definitions['CampaignGroupSortField'];
    order: definitions['SortOrder'];
  };
  initialFilters: [];
  onSearch: (searchTerm: string) => void;
  onSort: (
    sortBy: definitions['CampaignGroupSortField'],
    sortOrder: definitions['SortOrder']
  ) => void;
}

export const CampaignGroupListActionBar = ({
  onSearch,
  onSort,
  activeSort,
  activeSearchTerm,
}: Props) => {
  return (
    <ListActionBar>
      <ListActionBar.Search
        activeSearchTerm={activeSearchTerm}
        onSearch={(term: string) => {
          if (term !== activeSearchTerm) {
            onSearch(term);
          }
        }}
      />
      <ListActionBar.Sort
        activeSortField={activeSort.field}
        activeSortOrder={activeSort.order}
        sortFields={campaignGroupSortFields}
        onSort={(sortValue: string, sortOrder: definitions['SortOrder']) => {
          onSort(sortValue as definitions['CampaignGroupSortField'], sortOrder);
        }}
      />
    </ListActionBar>
  );
};
