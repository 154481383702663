export const Routes = {
  ROOT: '/',
  ERROR: '/error',
  CAMPAIGNS: '/campaigns',
  CAMPAIGN_GROUPS: '/campaign-groups',
  CAMPAIGNS_OVERVIEW: '/campaigns-overview',
  EXECUTIONS: '/executions',
  PROFILE: '/profile',
  UNIVERSES: '/universes',
  AUDIENCES: '/audiences',
  REPORTING: '/reporting',
  LOGIN: '/login',
  USERS: '/users',
  IMPRINT: '/imprint',
  PRIVACY_POLICY: '/privacy-policy',
  COOKIE_NOTICE: '/cookie-notice',
  AUTHENTICATION_CALLBACK: '/authentication/callback',
};
