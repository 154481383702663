import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ClearIcon } from 'assets/icons/x-small.svg';
import { isAfter, parseISO } from 'date-fns';
import { TOOLTIP_DELAY } from 'shared/constants';
import { ISODateRange } from 'types';
import { definitions } from 'types/api';
import isWithinIntervalUTC from 'utils/isWithinIntervalUTC';
import { isValidDate, localEndDayISO, localStartDayISO } from 'utils/time';

import { Pause } from '@mui/icons-material';
import {
  DateRangePicker,
  DateRangePickerDay,
  DateRangePickerDayProps,
} from '@mui/lab';
import {
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import styles from './DatePicker.module.scss';

interface DatePickerProps {
  dateRange: ISODateRange;
  disabled?: boolean;
  pauseRanges?: definitions['ProductUnavailabilityDuration'][];
  fixedStart?: boolean;
  onChange: (dateRange: ISODateRange) => void;
  onOpen?: () => void;
  onMonthChange?: (date: Date) => void;
  startError?: boolean;
  startText: string;
  endText: string;
  endError?: boolean;
}
const DatePicker: FC<DatePickerProps> = (props: DatePickerProps) => {
  const {
    dateRange,
    disabled,
    pauseRanges,
    fixedStart = false,
    startError = false,
    endError = false,
    startText,
    endText,
    onChange,
    onOpen,
    onMonthChange,
  } = props;

  const { t } = useTranslation();

  const renderWeekPickerDay = (
    date: Date,
    dateRangePickerDayProps: DateRangePickerDayProps<Date>
  ) => {
    let dateRangePickerDay = (
      <DateRangePickerDay {...dateRangePickerDayProps} />
    );
    pauseRanges?.forEach(
      (pauseRange: definitions['ProductUnavailabilityDuration']) => {
        if (pauseRange?.start && pauseRange?.end) {
          if (
            !isWithinIntervalUTC(
              date,
              new Date(pauseRange.start),
              new Date(pauseRange.end)
            )
          ) {
            return;
          }

          dateRangePickerDay = (
            <Tooltip
              key={date.toISOString()}
              enterDelay={TOOLTIP_DELAY}
              title={
                t('campaign_paused_on_day') ||
                'Campaign will be paused on this day'
              }
            >
              <DateRangePickerDay
                className={styles.date_range_picker_day_pause}
                {...dateRangePickerDayProps}
              >
                {date?.getDate()}
                <Pause className={styles.pause_icon} />
              </DateRangePickerDay>
            </Tooltip>
          );
        }
      }
    );
    return dateRangePickerDay;
  };

  // Calendar set in local date
  return (
    <DateRangePicker
      disableCloseOnSelect={false}
      startText=""
      endText=""
      mask="__/__/____"
      renderDay={renderWeekPickerDay}
      onOpen={onOpen}
      onMonthChange={onMonthChange}
      onChange={(dateRange) => {
        // localMiddayISO adjusts picker output from 00:00 to 12:00
        // this ensures UTC/CET has the same date value when converting between the two
        // temporary workaround as BE currently doesn't store timezone
        if (!isValidDate(dateRange[0]) || !isValidDate(dateRange[1])) return;
        const startValue = dateRange[0] ? localStartDayISO(dateRange[0]) : null;
        const endValue = dateRange[1] ? localEndDayISO(dateRange[1]) : null;
        onChange([startValue === null ? undefined : startValue, endValue]);
      }}
      PopperProps={{
        placement: 'bottom-end',
      }}
      reduceAnimations
      renderInput={(startProps, endProps) => (
        <Grid alignItems="center" container spacing={2}>
          <Grid item>
            <div className={styles.text_field_container}>
              <label htmlFor="datepicker_start">{startText}</label>
              <TextField
                id="datepicker_start"
                sx={{ '& .MuiInputBase-root': { paddingRight: 0 } }}
                {...startProps}
                disabled={disabled || fixedStart}
                InputProps={{
                  error: startError,
                  endAdornment: (
                    <>
                      {!disabled && !fixedStart && dateRange[0] && (
                        <IconButton
                          onClick={() => {
                            onChange([null, dateRange[1]]);
                          }}
                        >
                          <SvgIcon
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                            }}
                            component={ClearIcon}
                            viewBox={'0 0 32 32'}
                          />
                        </IconButton>
                      )}
                    </>
                  ),
                  inputProps: {
                    ...startProps.inputProps,
                    disabled: disabled || fixedStart,
                    placeholder: '',
                  },
                }}
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid item>
            <Typography color="textSecondary" variant="body2">
              to
            </Typography>
          </Grid>
          <Grid item>
            <div className={styles.text_field_container}>
              <label htmlFor="datepicker_end">{endText}</label>
              <TextField
                id="datepicker_end"
                {...endProps}
                inputProps={{
                  ...endProps.inputProps,
                }}
                disabled={disabled}
                sx={{ '& .MuiInputBase-root': { paddingRight: 0 } }}
                InputProps={{
                  error: endError,
                  endAdornment: (
                    <>
                      {!disabled && dateRange[1] && (
                        <IconButton
                          onClick={() => {
                            onChange([dateRange[0], null]);
                          }}
                        >
                          <SvgIcon
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                            }}
                            component={ClearIcon}
                            viewBox={'0 0 32 32'}
                          />
                        </IconButton>
                      )}
                    </>
                  ),
                  inputProps: {
                    ...endProps.inputProps,
                    disabled,
                    placeholder: '',
                  },
                }}
                variant="outlined"
              />
            </div>
          </Grid>
        </Grid>
      )}
      shouldDisableDate={(date: Date) => {
        return (
          fixedStart && !!dateRange[0] && isAfter(parseISO(dateRange[0]), date)
        );
      }}
      value={dateRange}
    />
  );
};
export default DatePicker;
