import { styled } from 'theme';

export const AccountInfoStyle = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  '.MuiTypography-body1': {
    fontSize: '0.9rem',
    fontWeight: '500',
  },
}));
