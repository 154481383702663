import { FC, ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import CampaignListingNew from 'components/Campaigns/CampaignListing/CampaignListing';
import { SelectCampaignType } from 'components/Campaigns/SelectCampaignType/SelectCampaignType';
import { PageHeader } from 'components/Shared/CustomMui';

import { Tab, Tabs } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';

import { CampaignGroupListing } from './CampaignGroupListing/CampaignGroupListing';
import styles from './CampaignsAndGroupListing.module.scss';

export const CampaignsAndGroupListingWrapper: FC = (): ReactElement => {
  const location = useLocation();
  // key here is to rerender the CampaignsAndGroupListing component when the location key changes on clicking the NavBar items.
  return <CampaignsAndGroupListing key={location.key} />;
};

export const CampaignsAndGroupListing: FC = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    parseInt(searchParams.get('activeTab') || '0')
  );
  const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] =
    useState<boolean>(false);

  const handleTabIndexChange = (
    event: SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setActiveTabIndex(value);
  };

  useEffect(() => {
    if (
      searchParams?.get('activeTab') &&
      parseInt(searchParams.get('activeTab') as string) === activeTabIndex
    ) {
      return;
    }
    const searchObject = Object.fromEntries(searchParams.entries());
    Object.keys(searchObject).forEach((key: string) => {
      searchParams.delete(key);
    });
    searchParams.set('activeTab', activeTabIndex.toString());
    setSearchParams(searchParams);
  }, [activeTabIndex, searchParams]);

  const handleCreateGroupClick = (): void => {
    navigate(`/campaign-groups/create`);
  };

  return (
    <>
      <PageHeader
        headerText={t('campaigns')}
        subHeaderText={t('subheader_campaigns')}
      />

      <Box className={styles.tabs_wrapper}>
        <Tabs value={activeTabIndex} onChange={handleTabIndexChange}>
          <Tab
            className={styles.tab}
            label={t('campaign_groups')}
            data-testid="campaign-groups-tab"
          />
          <Tab
            className={styles.tab}
            label={t('campaigns')}
            data-testid="campaigns-tab"
          />
        </Tabs>
        <div className={styles.action_container}>
          <Button
            size="small"
            color="secondary"
            onClick={() => setIsCreateCampaignModalOpen(true)}
            variant="outlined"
          >
            {`+ ${t('create_new_campaign')}`}
          </Button>
          <Button
            size="small"
            onClick={() => handleCreateGroupClick()}
            variant="contained"
          >
            {`+ ${t('create_new_campaign_group')}`}
          </Button>
        </div>
      </Box>
      <Box className={styles.tab_content_wrapper}>
        <Box>{activeTabIndex === 0 && <CampaignGroupListing />}</Box>
        <Box>{activeTabIndex === 1 && <CampaignListingNew />}</Box>
      </Box>
      {isCreateCampaignModalOpen && (
        <SelectCampaignType
          handleCancelButtonClick={() => setIsCreateCampaignModalOpen(false)}
        />
      )}
    </>
  );
};
