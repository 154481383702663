import i18n from 'i18n';
import { definitions } from 'types/api';

import { AlertProps } from 'components/Shared/CustomMui/Alert/Alert';

export const STATE_TO_MESSAGE_MAPPING: {
  [key in definitions['CampaignState']]?: AlertProps;
} = {
  CAMPAIGN_STATE_PAUSED_MANUAL: {
    text: i18n.t('campaign_paused_message'),
    severity: 'info',
  },
  CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE: {
    text: i18n.t('no_promotion_message'),
    severity: 'info',
  },
  CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED: {
    text: i18n.t('budget_depleted_message'),
    severity: 'info',
  },
};
