export * from './active-campaign-possible-states';
export * from './approval-state-types';
export * from './campaign-internal-states';
export * from './campaign-readonly-states';
export * from './campaign-success-prediction';
export * from './collapsed-view-max-item-count';
export * from './csp-execution-allowed-states';
export * from './debounce-time';
export * from './default-api-parameters';
export * from './default-country';
export * from './email-validation-regex';
export * from './fetch-product-by-gtin-page-size';
export * from './month-range-to-fetch-unavailability';
export * from './session-extension-time-in-seconds';
export * from './state-to-message-mapping';
export * from './tooltip-delay';
export * from './universe-rules';

export const DATE_ONGOING = '9999-12-31T00:00:00Z';
