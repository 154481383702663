import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { tkCampaignGroupSortField } from 'i18n/translationKeyMaps';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { ExtendedCampaignGroup } from 'shared/interfaces';
import { definitions } from 'types/api';

import { useCampaignGroupListingData } from 'components/Reporting/hooks/useCampaignGroupListingData';
import CampaignsTable from 'components/Shared/CampaignsTable/CampaignsTable';
import LoadingIndicator from 'components/Shared/LoadingIndicator/LoadingIndicator';

import { Stack, TablePagination, Typography } from '@mui/material';

import { CampaignGroupAccordion } from './CampaignGroupAccordion/CampaignGroupAccordion';
import { CampaignGroupListActionBar } from './CampaignGroupListActionBar/CampaignGroupListActionBar';
import styles from './CampaignGroupListing.module.scss';

export const CampaignGroupListing = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [campaignsPage, setCampaignsPage] = useState(1);
  const [groupsPage, setGroupsPage] = useState(
    parseInt(searchParams?.get('page') || '0')
  );

  const [searchField, setSearchField] = useState<string>(
    searchParams?.get('q') || ''
  );
  const [activeSortValues, setActiveSortValues] = useState<{
    field: definitions['CampaignGroupSortField'];
    order: definitions['SortOrder'];
  }>({
    field:
      searchParams.has('sortBy') &&
      !!tkCampaignGroupSortField(
        searchParams.get('sortBy')! as definitions['CampaignGroupSortField']
      )
        ? (searchParams?.get('sortBy') as definitions['CampaignGroupSortField'])
        : 'CAMPAIGN_GROUP_SORT_LAST_MODIFIED',
    order:
      searchParams.has('sortOrder') &&
      ['SORT_ORDER_UNKNOWN', 'SORT_ORDER_ASC', 'SORT_ORDER_DESC'].includes(
        searchParams.get('sortOrder')!
      )
        ? (searchParams?.get('sortOrder') as definitions['SortOrder'])
        : 'SORT_ORDER_DESC',
  });

  const {
    campaignGroups: data,
    isLoading,
    pagination,
  } = useCampaignGroupListingData({
    page: groupsPage,
    search: searchField,
    sortOrder: activeSortValues.order,
    sortField: activeSortValues.field,
  });

  const resetPageNumberAndExpandedIndex = (): void => {
    updateSearchParams({}, ['page', 'group']);
    setGroupsPage(0);
  };

  const updateSearchParams = (
    paramsToAdd: Record<string, string>,
    paramsToDelete?: string[]
  ): void => {
    if (Object.keys(paramsToAdd).length) {
      const paramNames = Object.keys(paramsToAdd);
      paramNames.forEach((paramName: string) => {
        searchParams.set(paramName, paramsToAdd[paramName]);
      });
    }
    if (paramsToDelete?.length) {
      paramsToDelete.forEach((paramName: string) =>
        searchParams.delete(paramName)
      );
    }
    setSearchParams(searchParams);
  };

  return (
    <>
      <LoadingIndicator isAppLoading={isLoading} />
      <CampaignGroupListActionBar
        activeSearchTerm={searchField}
        activeSort={{
          field: activeSortValues.field,
          order: activeSortValues.order,
        }}
        initialFilters={[]}
        onSearch={(term) => {
          resetPageNumberAndExpandedIndex();
          setSearchField(term);
          if (!!term) {
            updateSearchParams({ q: term });
          } else {
            updateSearchParams({}, ['q']);
          }
        }}
        onSort={(sortValue, sortOrder) => {
          resetPageNumberAndExpandedIndex();
          setActiveSortValues({
            field: sortValue,
            order: sortOrder,
          });
          updateSearchParams({ sortBy: sortValue, sortOrder });
        }}
      />
      <Stack direction="row" spacing={1} className={styles.title}>
        <Typography variant="h3">{t('campaign_groups')}</Typography>
        <Typography
          data-testid="campaign-group-search-count"
          variant="h3"
          color="textSecondary"
        >
          ({pagination?.results})
        </Typography>
      </Stack>
      <div>
        {!isLoading &&
          data &&
          data?.map((campaignGroup: ExtendedCampaignGroup) => (
            <CampaignGroupAccordion
              key={campaignGroup.group_id}
              campaignGroup={campaignGroup}
              headerExpansionHandler={() => {
                if (campaignGroup.group_id === searchParams?.get('group')) {
                  updateSearchParams({}, ['group']);
                } else {
                  updateSearchParams({ group: campaignGroup.group_id! });
                  setCampaignsPage(1);
                }
              }}
              expanded={campaignGroup.group_id === searchParams?.get('group')}
            >
              {campaignGroup.group_id && (
                <CampaignsTable
                  activeSortValues={{
                    field:
                      activeSortValues.field ===
                      'CAMPAIGN_GROUP_SORT_LAST_MODIFIED'
                        ? 'CAMPAIGN_SORT_UPDATED_AT'
                        : 'CAMPAIGN_SORT_UNKNOWN',
                    order: activeSortValues.order,
                  }}
                  groupIds={[campaignGroup.group_id]}
                  setPage={setCampaignsPage}
                  page={campaignsPage}
                  emptyStateMessage={t('no_linked_campaign')}
                />
              )}
            </CampaignGroupAccordion>
          ))}
      </div>
      <TablePagination
        component="div"
        page={groupsPage}
        rowsPerPage={DEFAULT_API_PARAMETERS.per_page_small}
        onPageChange={(event, pageNumber) => {
          updateSearchParams({ page: pageNumber.toString() });
          setGroupsPage(pageNumber);
        }}
        rowsPerPageOptions={[]}
        count={pagination?.results || 0}
      />
    </>
  );
};
