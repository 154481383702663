import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { definitions } from 'types/api';

import { Routes } from 'routes/Routes';

import {
  useDeleteUserMutation,
  useGetAllUserQuery,
} from 'store/api/endpoints/userManagement';
import { addNotification } from 'store/modules/notifications/actions';
import { selectUser } from 'store/modules/user/selectors';

import { Column, PageHeader, Table } from 'components/Shared/CustomMui';
import { EmptyState } from 'components/Shared/EmptyState/EmptyState';
import LoadingIndicator from 'components/Shared/LoadingIndicator/LoadingIndicator';

import { LoadingButton } from '@mui/lab';
import { Grid, IconButton, SvgIcon, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { DialogWrapper } from '../Shared/CustomMui/DialogWrapper/DialogWrapper';
import AddUser from './AddUser/AddUser';

type TableUser = definitions['User'] & { id: string };

export const UserManagement: FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useAuth();
  const user = useSelector(selectUser);
  const [isAddUserDialogShown, setIsAddUserDialogShown] =
    useState<boolean>(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<string>('');

  const { users, isLoading: isGetAllUserLoading } = useGetAllUserQuery(
    user?.advertiser_id as string,
    {
      skip: !user?.advertiser_id,
      selectFromResult: ({ data, isLoading }) => ({
        users: data?.map((user) => ({
          ...user,
          id: user.user_id,
        })),
        isLoading,
      }),
    }
  );
  const [deleteUser] = useDeleteUserMutation();

  const columns: Column[] = [
    {
      field: 'name',
      headerName: t('name'),
    },
    {
      field: 'email',
      headerName: t('email'),
    },
    {
      field: 'role',
      headerName: t('role'),
      renderCell: (role: string): string => t(role),
    },
    {
      field: 'phone',
      width: 200,
      headerName: t('phone'),
    },
    {
      field: 'user_id',
      justify: 'flex-end',
      headerName: '',
      width: 50,
      sticky: 'right',
      renderCell: (userId: string) => (
        <Tooltip title={t('delete') || 'Delete'}>
          <IconButton onClick={() => setIdToBeDeleted(userId)}>
            <SvgIcon component={TrashIcon} viewBox={'0 0 32 32'} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const setAddUserDialogStatus = (status: boolean): void => {
    setIsAddUserDialogShown(status);
  };

  const handleAddUserClose = (): void => {
    setAddUserDialogStatus(false);
  };

  const handleUserDeleteConfirmation = async (): Promise<void> => {
    await deleteUser(idToBeDeleted);
    dispatch(
      addNotification({ type: 'success', text: t('user_deletion_success') })
    );
    if (user?.sub === idToBeDeleted) {
      await auth.signoutRedirect({
        post_logout_redirect_uri: window.location.origin + Routes.LOGIN,
        id_token_hint: auth?.user?.id_token,
      });
    }
    setIdToBeDeleted('');
  };

  return (
    <>
      <LoadingIndicator isAppLoading={isGetAllUserLoading}></LoadingIndicator>
      <PageHeader
        headerText={t('user_management')}
        subHeaderText={t('subheader_user_management')}
      />
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        style={{ marginBottom: '1rem' }}
      >
        <Typography variant={'h3'}>
          {t('results')}: {users?.length}
        </Typography>
        <Button
          size="small"
          color="primary"
          onClick={() => setAddUserDialogStatus(true)}
          variant="contained"
        >
          {'+ ' + t('add_user')}
        </Button>
      </Grid>
      <Grid container>
        <Grid item width={'100%'}>
          <Table
            emptyState={<EmptyState text={t('users_empty_state_title')} />}
            columns={columns}
            minHeight={10}
            rows={users ? users : []}
          />
        </Grid>
      </Grid>
      {isAddUserDialogShown && <AddUser onClose={handleAddUserClose} />}
      {idToBeDeleted && (
        <DialogWrapper
          width={550}
          id="confirmation-prompt"
          headerText={t('delete_user')}
          handleClose={() => setIdToBeDeleted('')}
          dialogContent={t('user_deletion_confirmation', {
            userName: users?.find(
              (user: TableUser) => user.user_id === idToBeDeleted
            )?.name,
          })}
          dialogActions={
            <>
              <Button onClick={() => setIdToBeDeleted('')} variant="outlined">
                {t('cancel')}
              </Button>
              <LoadingButton
                onClick={handleUserDeleteConfirmation}
                variant="contained"
              >
                {t('delete')}
              </LoadingButton>
            </>
          }
        />
      )}
    </>
  );
};
