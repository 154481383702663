import React from 'react';
import { useTranslation } from 'react-i18next';

import classnames from 'classnames';
import { definitions } from 'types/api';

import { ProductChip } from 'components/Shared/ProductChip/ProductChip';

import { Stack } from '@mui/material';

import styles from './SelectedProducts.module.scss';

export interface Props {
  disabled: boolean;
  enableCopyGtins: boolean;
  hasMoreProducts: boolean;
  taxonomyProducts?: definitions['TaxonomyProduct'][];
  onDeselect: (gtin: string) => void;
}

export const SelectedProducts: React.FC<Props> = (props) => {
  const {
    onDeselect,
    hasMoreProducts,
    taxonomyProducts,
    enableCopyGtins,
    disabled = false,
  } = props;

  const { t } = useTranslation();

  if (!taxonomyProducts || taxonomyProducts.length === 0) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        className={styles.no_products}
      >
        <Stack alignSelf="center">{t('no_selected_product_message')}</Stack>
      </Stack>
    );
  }

  return (
    <div
      className={classnames([styles.products_wrapper], {
        [styles.fade]: hasMoreProducts,
      })}
    >
      {taxonomyProducts?.map((s) => (
        <ProductChip
          key={s.gtin}
          product_name={s.product_name}
          gtin={s.gtin}
          copyable={enableCopyGtins && disabled}
          onDeselect={() => onDeselect(s.gtin)}
          disabled={disabled}
        ></ProductChip>
      ))}
    </div>
  );
};
