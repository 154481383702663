import { useSelector } from 'react-redux';

import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { ExtendedCampaignGroup } from 'shared/interfaces';
import { definitions } from 'types/api';

import {
  useGetCampaignGroupByIdQuery,
  useGetCampaignGroupsQuery,
} from 'store/api/endpoints/campaignGroup';
import { useGetCampaignGroupCumulativesQuery } from 'store/api/endpoints/reports';
import { selectCountries } from 'store/modules/settings/selectors';
import { selectUser } from 'store/modules/user/selectors';

interface Props {
  page?: number;
  search?: string;
  singleCampaignGroupId?: string;
  sortOrder?: definitions['SortOrder'];
  sortField?: definitions['CampaignGroupSortField'];
  filterByCampaignStates?: definitions['CampaignState'][];
}

type ReportingCampaignGroupListingReturnType = {
  refetch: () => void;
  isLoading: boolean;
  pagination?: definitions['Pagination'];
  campaignGroup?: ExtendedCampaignGroup;
  campaignGroups?: ExtendedCampaignGroup[];
};

export const useCampaignGroupListingData = ({
  page,
  search,
  sortOrder,
  sortField,
  singleCampaignGroupId,
  filterByCampaignStates,
}: Props): ReportingCampaignGroupListingReturnType => {
  const user = useSelector(selectUser);
  const countries = useSelector(selectCountries);

  const { data: campaignGroupData, isFetching: isFetchingCampaignGroupData } =
    useGetCampaignGroupsQuery(
      {
        page: (page || 0) + 1,
        per_page: DEFAULT_API_PARAMETERS.per_page_small,
        advertiser_id: user?.advertiser_id!,
        sort_by: sortField,
        order: sortOrder,
        search: search,
        ...(!!filterByCampaignStates?.length && {
          filter_by_campaign_states: filterByCampaignStates,
        }),
      },
      {
        skip:
          !user?.advertiser_id ||
          !!singleCampaignGroupId ||
          singleCampaignGroupId === 'create',
        refetchOnMountOrArgChange: true,
      }
    );

  const {
    data: singleCampaignGroup,
    isFetching: isFetchingSingleCampaignGroup,
    refetch,
  } = useGetCampaignGroupByIdQuery(singleCampaignGroupId!, {
    skip: !singleCampaignGroupId || singleCampaignGroupId === 'create',
    refetchOnMountOrArgChange: true,
  });

  const campaignGroups: ExtendedCampaignGroup[] =
    campaignGroupData?.campaign_groups ||
    (singleCampaignGroup?.campaign_group
      ? [singleCampaignGroup?.campaign_group]
      : []);

  const {
    data: campaignGroupCumulatives,
    isFetching: isFetchingCampaignGroupCumulatives,
  } = useGetCampaignGroupCumulativesQuery(
    campaignGroups
      ?.filter((campaignGroup) => campaignGroup.group_id)
      .map((campaignGroup) => campaignGroup.group_id!) || [],
    {
      refetchOnMountOrArgChange: true,
      skip: !campaignGroups || campaignGroups.length === 0,
    }
  );

  const extendedCampaignGroups = campaignGroups.map((campaignGroup) => {
    const campaignCumulative = campaignGroupCumulatives?.find(
      (cumulative: definitions['CumulativeMetric']) =>
        cumulative.campaign_group_id === campaignGroup.group_id
    );

    // get the campaign group currency from the first currency in the array
    const currencyCode = campaignGroup?.campaign_group_currencies?.[0];
    const currencySymbol = countries?.find(
      (country) => country.currency?.code === currencyCode
    )?.currency;

    let extendedCampaign: ExtendedCampaignGroup = {
      ...campaignGroup,
      ...campaignCumulative,
      currency: currencySymbol,
    };
    return extendedCampaign;
  });

  return {
    refetch,
    campaignGroup: extendedCampaignGroups?.[0],
    campaignGroups: extendedCampaignGroups,
    pagination: campaignGroupData?.pagination,
    isLoading:
      isFetchingCampaignGroupData ||
      isFetchingSingleCampaignGroup ||
      isFetchingCampaignGroupCumulatives,
  };
};
