import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { definitions } from 'types/api';

import { useStoreDispatch } from 'store';
import {
  getFileExtensionHintForFE,
  getMimeTypeForFE,
} from 'store/modules/campaign/helpers';
import { setDraftTouchedFields } from 'store/modules/campaign/slice';
import { CREATIVE_FILE_MAX_SIZE_LIMIT } from 'store/modules/campaign/validations';

import { ImageFile, useImageUpload } from 'hooks/useImageUpload';

import { Button, Typography } from '@mui/material';

import styles from './UploadCreativeImage.module.scss';

interface Props {
  className?: string;
  showThumbnail?: boolean;
  thumbnail?: string;
  aspectRatio?: string;
  mimeTypes?: definitions['CreativeMimeType'][];
  setFile: (file?: ImageFile) => void;
}

type CreativeFileErrorType =
  | 'CREATIVE_INVALID_DIMENSIONS'
  | 'CREATIVE_FILE_SIZE_EXCEEDED';

export const UploadCreativeImage = ({
  thumbnail,
  setFile,
  aspectRatio,
  mimeTypes,
}: Props) => {
  const { t } = useTranslation();

  const acceptedMimes =
    mimeTypes?.map((mimetype) => getMimeTypeForFE(mimetype)) || [];

  const acceptedFileExtensions =
    mimeTypes?.map((mimetype) => getFileExtensionHintForFE(mimetype)) || [];

  const acceptedDimensionValue = aspectRatio?.match(/\d+/g) || [];
  const [creativeFileErrors, setCreativeFileErrors] = useState<
    CreativeFileErrorType[]
  >([]);
  const dispatch = useStoreDispatch();

  const { getRootProps, getInputProps, acceptedFiles } = useImageUpload({
    accept: acceptedMimes,
    noClick: false,
    noKeyboard: false,
  });

  useEffect(() => {
    // Validate dimensions & file size
    if (acceptedFiles.length > 0) {
      const creativeFileError: CreativeFileErrorType[] = [];
      if (acceptedFiles[0]?.file?.size > CREATIVE_FILE_MAX_SIZE_LIMIT) {
        creativeFileError.push('CREATIVE_FILE_SIZE_EXCEEDED');
      }
      if (
        acceptedDimensionValue.length &&
        ![
          `${acceptedDimensionValue?.[0]}x${acceptedDimensionValue?.[1]}`,
        ].includes(`${acceptedFiles[0].width}x${acceptedFiles[0].height}`)
      ) {
        creativeFileError.push('CREATIVE_INVALID_DIMENSIONS');
      }
      if (!!creativeFileError?.length) {
        setCreativeFileErrors(creativeFileError);
      } else {
        setFile(acceptedFiles[0]);
      }
    }
  }, [thumbnail, acceptedFiles]);

  return (
    <>
      <div className={styles.upload_creative_wrapper} {...getRootProps()}>
        <Typography variant="body1" className={styles.upload_text}>
          {t('drag_and_drop')}
        </Typography>
        <input
          {...getInputProps()}
          data-aspect-ratio={aspectRatio}
          data-mime-types={acceptedMimes}
        />
        <Button
          data-testid="add-creative"
          data-aspect-ratio={aspectRatio}
          data-mime-types={acceptedMimes}
          onClick={() => {
            dispatch(setDraftTouchedFields('creatives'));
          }}
          variant="outlined"
        >
          {t('browse_files')}
        </Button>
        <Typography
          variant="caption"
          className={classNames(styles.upload_hint, {
            error: creativeFileErrors?.includes('CREATIVE_INVALID_DIMENSIONS'),
          })}
        >
          {t('upload_file_hint', {
            fileExtensions: acceptedFileExtensions?.join(' or '),
            aspectRatio: aspectRatio,
          })}
        </Typography>
        {creativeFileErrors?.includes('CREATIVE_FILE_SIZE_EXCEEDED') && (
          <Typography variant="caption" className="error">
            {t('upload_file_max_size_error', {
              fileSizeLimit: CREATIVE_FILE_MAX_SIZE_LIMIT / 1000,
            })}
          </Typography>
        )}
      </div>
    </>
  );
};
