import { ReactElement } from 'react';

import { ReactComponent as ClearIcon } from 'assets/icons/x.svg';

import { Grid, SvgIcon, Typography } from '@mui/material';

interface Props {
  text: string;
}

export const EmptyState = (props: Props): ReactElement => {
  const { text } = props;
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid>
        <SvgIcon
          component={ClearIcon}
          viewBox={'0 0 32 32'}
          sx={{
            color: (theme) => theme.palette.text.secondary,
            my: 2,
          }}
        />
      </Grid>
      <Grid>
        <Typography
          variant="body1"
          sx={{ color: (theme) => theme.palette.text.secondary, mb: 2 }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
