import { createSlice } from '@reduxjs/toolkit';
import { randString } from 'utils';

import {
  addNotification,
  removeNotification,
} from 'store/modules/notifications/actions';

import { Notification } from './types';

interface State {
  notifications: Notification[];
}

const initialState: State = { notifications: [] };

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNotification, (state, action) => {
        state.notifications.push({
          id: action.payload.id ?? action.type + randString(),
          text: action.payload.text,
          type: action.payload.type,
          duration: action.payload.duration,
          dismissId: action.payload.dismissId,
        });
      })
      .addCase(removeNotification, (state, action) => {
        state.notifications = state.notifications.filter(
          ({ id }) => id !== action.payload
        );
      });
  },
});

export default slice.reducer;
