import { TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const HEADER_CELL_HEIGHT = 56;
export const CELL_HEIGHT = 52;

export const RootS = styled('div', {
  shouldForwardProp: (p) => p !== 'minHeight' && p !== 'paginationDisabled',
})<{ minHeight?: number; paginationDisabled: boolean }>(
  ({ theme, minHeight, paginationDisabled }) => ({
    background: 'white',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
    width: '100%',
    '&.no-content': {
      '.MuiTableCell-root': {
        '&.sticky-left, &.sticky-right': {
          boxShadow: 'none',
        },
      },
    },
    '& .MuiTableBody-root': {
      display: 'block',
      minHeight: minHeight ? minHeight * CELL_HEIGHT : 0,
      position: 'relative',
    },
    '& .MuiCheckbox-root': {
      padding: 0,
    },
    '& .MuiToolbar-root': {
      height: CELL_HEIGHT,
      minHeight: CELL_HEIGHT,
      '& p': {
        color: theme.palette.text.secondary,
      },
      ...(paginationDisabled && {
        visibility: 'hidden',
      }),
    },
    '& .no-content-message': {
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  })
);

export const HeaderRowS = styled(TableRow)(({ theme }) => ({
  background: 'white',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  display: 'flex',
  height: HEADER_CELL_HEIGHT,
  '&:nth-of-type(2)': {
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
  },
}));

export const RowS = styled(TableRow, {
  shouldForwardProp: (p) => p !== 'rowClick' && p !== 'selected',
})<{ rowClick: boolean; selected: boolean }>(
  ({ theme, rowClick, selected }) => ({
    background: 'white',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    height: CELL_HEIGHT,
    '&:nth-of-type(even)': {
      background: theme.palette.grey[50],
    },
    ...(selected && {
      background: '#F6DFF7 !important',
    }),
    ...(rowClick && {
      cursor: 'pointer',
      transition: 'background 0.2s',
      '&:hover': {
        background: theme.palette.grey[100],
      },
    }),
  })
);

export const CellS = styled(TableCell, {
  shouldForwardProp: (p) => p !== 'width' && p !== 'height',
})<{ height: number; width?: number }>(({ theme, height, width }) => ({
  alignItems: 'center',
  background: 'transparent',
  border: 'none',
  display: 'flex',
  flex: 1,
  fontSize: 14,
  lineHeight: height + 'px',
  height: '100%',
  overflow: 'hidden',
  padding: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  whiteSpace: 'nowrap',
  ...(!!width && {
    flexBasis: width,
    minWidth: width,
    maxWidth: width,
  }),
  '&.sticky-right': {
    right: 0,
    position: 'sticky',
    background: 'inherit',
    boxShadow: '-2px 0px 2px rgba(0, 0, 0, 0.2)',
  },
  '&.sticky-left': {
    left: 0,
    position: 'sticky',
    background: 'inherit',
    boxShadow: '2px 0px 2px rgba(0, 0, 0, 0.2)',
  },
  '& > span': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
