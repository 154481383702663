import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const selectDraftCampaign = createSelector(
  (state: RootState) => state.campaigns,
  (campaigns) => campaigns.draft
);

export const selectDraftValidations = createSelector(
  (state: RootState) => state.campaigns,
  (campaigns) => campaigns.draftValidations
);

export const selectDraftHasChanges = createSelector(
  (state: RootState) => state.campaigns,
  (campaigns) => campaigns.draftHasChanges
);

export const selectDraftTouchedFields = createSelector(
  (state: RootState) => state.campaigns,
  (campaigns) => campaigns.draftTouchedFields
);

export const selectCampaignDetailsAlerts = createSelector(
  (state: RootState) => state.campaigns,
  (campaigns) => campaigns.alerts
);
