import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CampaignFormStatus } from 'hooks';
import { FormType } from 'types';

import { CampaignFormActionType } from 'hooks/CampaignStateManagement/actionMapping';

import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';

type ActionHandlers = {
  [K in CampaignFormActionType as `${Lowercase<
    string & K
  >}Campaign`]: () => void;
};

type Props = {
  disabled: boolean;
  formType: FormType;
  disableSave?: boolean;
  formState: CampaignFormStatus;
  submittingState: 'Submitting' | 'Idle';
} & Partial<ActionHandlers>;

export const OverviewActionButtons = (props: Props) => {
  const { t } = useTranslation();
  const {
    formState,
    disabled,
    formType,
    submittingState,
    disableSave,
    deleteCampaign,
  } = props;

  const [currentAction, setCurrentAction] = useState<CampaignFormActionType>();

  const onAction = (action: CampaignFormActionType): any => {
    setCurrentAction(action);
    // invoke the method
    return props[`${action}Campaign`]!();
  };

  return (
    <>
      {formState.formActions.includes('delete') && (
        <Button
          color="error"
          data-testid="CampaignDeleteButton"
          disabled={disabled || formType === 'create'}
          onClick={deleteCampaign}
          variant="outlined"
        >
          {t('delete')}
        </Button>
      )}
      {formState.formActions.includes('save') && (
        <LoadingButton
          color="primary"
          disabled={disabled || disableSave}
          onClick={() => onAction('save')}
          loading={currentAction === 'save' && submittingState === 'Submitting'}
          variant="outlined"
        >
          {t('save')}
        </LoadingButton>
      )}
      {formState.formActions.includes('submit') && formType !== 'create' && (
        <LoadingButton
          color="primary"
          disabled={disabled || !disableSave}
          onClick={() => onAction('submit')}
          loading={
            currentAction === 'submit' && submittingState === 'Submitting'
          }
          variant="contained"
        >
          {t('submit')}
        </LoadingButton>
      )}

      {formState.formActions.includes('reject') && (
        <LoadingButton
          color="error"
          disabled={disabled}
          onClick={() => onAction('reject')}
          loading={
            currentAction === 'reject' && submittingState === 'Submitting'
          }
          variant="outlined"
        >
          {t('reject')}
        </LoadingButton>
      )}
      {formState.formActions.includes('approve') && (
        <LoadingButton
          color="primary"
          disabled={disabled}
          onClick={() => onAction('approve')}
          loading={
            currentAction === 'approve' && submittingState === 'Submitting'
          }
          variant="outlined"
        >
          {t('approve')}
        </LoadingButton>
      )}
      {formState.formActions.includes('stop') && (
        <LoadingButton
          color="error"
          disabled={disabled || !disableSave}
          onClick={() => onAction('stop')}
          loading={currentAction === 'stop' && submittingState === 'Submitting'}
          variant="outlined"
        >
          {t('stop')}
        </LoadingButton>
      )}
      {formState.formActions.includes('resume') && (
        <LoadingButton
          color="primary"
          disabled={disabled || !disableSave}
          onClick={() => onAction('resume')}
          loading={
            currentAction === 'resume' && submittingState === 'Submitting'
          }
          variant="contained"
        >
          {t('resume')}
        </LoadingButton>
      )}
      {formState.formActions.includes('pause') && (
        <LoadingButton
          color="primary"
          disabled={disabled || !disableSave}
          onClick={() => onAction('pause')}
          loading={
            currentAction === 'pause' && submittingState === 'Submitting'
          }
          variant="contained"
        >
          {t('pause')}
        </LoadingButton>
      )}

      {formState.formActions.includes('withdraw') && (
        <LoadingButton
          color="primary"
          disabled={disabled || !disableSave}
          onClick={() => onAction('withdraw')}
          loading={
            currentAction === 'withdraw' && submittingState === 'Submitting'
          }
          variant="contained"
        >
          {t('withdrawSubmission')}
        </LoadingButton>
      )}
    </>
  );
};
