import { definitions } from 'types/api';

import { CreativeWithFile } from 'store/modules/campaign/helpers';

import { CampaignSectionProps } from 'components/Campaigns/CampaignDetails/CampaignDetails';

import { Creatives } from './Creatives';
import { useCreativesData } from './hooks/useCreativesData';

interface Props extends CampaignSectionProps {
  campaignCreatives: (definitions['Creative'] & CreativeWithFile)[];
  channel?: Partial<definitions['Channel']>;
  countryCode: string;
  isTargetUrlRequired: boolean;
}

export const CreativesWrapper = ({
  campaignCreatives,
  channel,
  countryCode,
  formState,
  validate,
  isTargetUrlRequired,
}: Props) => {
  const {
    languages,
    creatives: allCreatives,
    updateCreative,
  } = useCreativesData({
    countryCode,
    creatives: campaignCreatives,
    channel,
  });

  return (
    <Creatives
      formState={formState}
      languages={languages}
      updateCreative={updateCreative}
      campaignCreatives={allCreatives}
      validate={validate}
      isTargetUrlRequired={isTargetUrlRequired}
    ></Creatives>
  );
};
