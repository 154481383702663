import { useCallback } from 'react';

import { endOfDay, isWithinInterval } from 'date-fns';
import { definitions } from 'types/api';

import {
  MetricType,
  metricTypeToFieldNameMapping,
  ReportDataType,
} from '../utils/utils';

interface Props {
  reportData?: ReportDataType;
  selectedMetric: MetricType;
  metrics: definitions['ByDayTimeSeries'] | undefined;
  dateRange?: { start: Date; end: Date } | null;
}

export const useCampaignCumulatives = ({
  reportData,
  selectedMetric,
  metrics,
  dateRange,
}: Props) => {
  const calculateCumulativeValueBasedOnField = (
    metric: MetricType,
    timeSeriesValues: definitions['TimeSeriesValue'][],
    operator: 'sum' | 'average' | 'static_total'
  ): number => {
    if (operator === 'static_total') {
      return !!reportData?.shoppers_reached_cumulative
        ? parseInt(reportData?.shoppers_reached_cumulative)
        : 0;
    }
    if (timeSeriesValues?.length) {
      const sumOfValues = timeSeriesValues.reduce(
        (accumulator: number, iterator: definitions['TimeSeriesValue']) => {
          return accumulator + Number(iterator.value);
        },
        0
      );
      return operator === 'average'
        ? sumOfValues / timeSeriesValues?.length
        : sumOfValues;
    } else {
      return 0;
    }
  };

  const getCumulativeMetricObject = () => {
    return {
      ...reportData,
      average_cpa: calculateCumulativeValueBasedOnField(
        'average_cpa',
        metrics?.average_cpa_by_day as definitions['TimeSeriesValue'][],
        'average'
      ),
      average_cpm: calculateCumulativeValueBasedOnField(
        'average_cpm',
        metrics?.average_cpm_by_day as definitions['TimeSeriesValue'][],
        'average'
      ),
      average_cpc: calculateCumulativeValueBasedOnField(
        'average_cpc',
        metrics?.average_cpc_by_day as definitions['TimeSeriesValue'][],
        'average'
      ),
    };
  };

  const campaignCumulative = useCallback(() => {
    if (dateRange?.start || dateRange?.end) {
      let campaignMetricsObject: { [key: string]: any } | undefined = metrics;
      if (campaignMetricsObject) {
        /* Declare a new cumulative object which would contain the value derived from adding all the corresponding
        cumulative values which are within the specified date range */
        const newCampaignCumulative: { [key: string]: number } = {};

        const withInDateRangeIndividualMetricValues: definitions['TimeSeriesValue'][] =
          campaignMetricsObject?.[
            metricTypeToFieldNameMapping[selectedMetric]?.metricField
          ]?.filter((dateWiseValue: definitions['TimeSeriesValue']) =>
            isWithinInterval(new Date(dateWiseValue?.date as string), {
              start: dateRange.start,
              end: endOfDay(dateRange.end),
            })
          );
        newCampaignCumulative[
          metricTypeToFieldNameMapping[selectedMetric]?.cumulativeField
        ] = calculateCumulativeValueBasedOnField(
          selectedMetric,
          withInDateRangeIndividualMetricValues,
          metricTypeToFieldNameMapping[selectedMetric]
            ?.operatorForCumulativeCalculation
        );

        return {
          ...getCumulativeMetricObject(),
          ...newCampaignCumulative,
        };
      } else {
        return null;
      }
    } else {
      return getCumulativeMetricObject();
    }
  }, [dateRange, metrics, reportData]);
  return campaignCumulative();
};
