import { useCallback, useEffect, useState } from 'react';

import { UserRole } from 'types';
import { definitions } from 'types/api';

import { CampaignMetrics, MetricType, ReportObjectType } from '../utils/utils';

export const useReportingMetrics = <T extends ReportObjectType>(
  type: ReportObjectType,
  userRole: UserRole,
  campaignType: T extends 'Campaigns' ? definitions['CampaignType'] : any
) => {
  const [metrics, setMetrics] = useState<MetricType[]>([]);

  const defaultCPAMetrics = CampaignMetrics.filter(
    (x) => !['average_cpm', 'average_cpc'].includes(x)
  );
  const defaultCPMMetrics = CampaignMetrics.filter(
    (x) => !['average_cpa', 'average_cpc'].includes(x)
  );
  const defaultCPCMetrics = CampaignMetrics.filter(
    (x) => !['average_cpa', 'average_cpm'].includes(x)
  );

  useEffect(() => {
    const allowedMetrics = getAvailableMetrics();
    setMetrics(allowedMetrics);
  }, [campaignType, userRole]);

  const campaignMetricsMapping: {
    [key in definitions['CampaignType']]: {
      [key in Partial<UserRole>]?: MetricType[];
    };
  } = {
    CAMPAIGN_TYPE_CONVERSION: {
      [UserRole.ADVERTISER_CAMPAIGN_MANAGER]: [
        'units_purchased',
        'revenue',
        'average_cpa',
        'used_budget',
      ],
      [UserRole.ADVERTISER_ADMIN]: [
        'units_purchased',
        'revenue',
        'average_cpa',
        'used_budget',
      ],
      [UserRole.KEY_ACCOUNT_MANAGER]: [...defaultCPAMetrics],
    },
    CAMPAIGN_TYPE_IMPRESSION: {
      [UserRole.ADVERTISER_CAMPAIGN_MANAGER]: [...defaultCPMMetrics],
      [UserRole.ADVERTISER_ADMIN]: [...defaultCPMMetrics],
      [UserRole.KEY_ACCOUNT_MANAGER]: [...defaultCPMMetrics],
    },
    CAMPAIGN_TYPE_CLICK: {
      [UserRole.ADVERTISER_CAMPAIGN_MANAGER]: [...defaultCPCMetrics],
      [UserRole.ADVERTISER_ADMIN]: [...defaultCPCMetrics],
      [UserRole.KEY_ACCOUNT_MANAGER]: [...defaultCPCMetrics],
    },
    CAMPAIGN_TYPE_UNKNOWN: {},
  };

  const campaignGroupMetricsMapping: {
    [key in Partial<UserRole>]?: MetricType[];
  } = {
    [UserRole.ADVERTISER_CAMPAIGN_MANAGER]: [
      'group_revenue',
      'group_shoppers_reached',
      'group_units_purchased',
      'group_used_budget',
    ],
    [UserRole.ADVERTISER_ADMIN]: [
      'group_revenue',
      'group_shoppers_reached',
      'group_units_purchased',
      'group_used_budget',
    ],
    [UserRole.KEY_ACCOUNT_MANAGER]: [
      'group_impressions',
      'group_views',
      'group_clicks',
      'group_shoppers_reached',
      'group_units_purchased',
      'group_revenue',
      'group_used_budget',
    ],
  };

  const getAvailableMetrics = useCallback((): MetricType[] => {
    return (
      (type === 'Campaign'
        ? campaignMetricsMapping[campaignType][userRole]
        : campaignGroupMetricsMapping[userRole]) || []
    );
  }, [campaignType, userRole]);

  return metrics;
};
