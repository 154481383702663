import React, { FC, MouseEvent } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserRole } from 'types';

import { Routes } from 'routes/Routes';

import { selectAdvertiser } from 'store/modules/settings/selectors';
import { selectUser } from 'store/modules/user/selectors';

import { AdvertisersDropdown } from 'components/Core/Navigation/Components/AdvertisersDropdown';
import { AccessResolver } from 'components/Shared/AccessResolver/AccessResolver';

import { AppBar, Stack } from '@mui/material';

import { UserDropdown, UserMenu } from '../Components';
import NavBar from '../NavBar/NavBar';
import styles from './Bar.module.scss';

const NavigationBar: FC = () => {
  const auth = useAuth();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const advertiser = useSelector(selectAdvertiser);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogout() {
    // clear the local storage
    window.localStorage.clear();

    setAnchorEl(null);
    auth.signoutRedirect({
      post_logout_redirect_uri: window.location.origin + Routes.LOGIN,
      id_token_hint: auth?.user?.id_token,
    });
  }

  return (
    <AppBar className={styles.app_bar} color="transparent" position="static">
      <div className={styles.logo_navbar_container}>
        <img
          className={styles.logo}
          src="/img/SO1-logo-mini.png"
          alt="SMP-logo"
        />
        <NavBar />
      </div>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ px: 4 }}>
        <AccessResolver requiredRoles={[UserRole.KEY_ACCOUNT_MANAGER]}>
          <AdvertisersDropdown />
        </AccessResolver>
        <UserMenu
          user={user}
          advertiser={advertiser}
          handleIconClick={handleClick}
          key={user?.user_id as string}
          isSelected={Boolean(anchorEl)}
        />
        <UserDropdown
          user={user}
          anchorEl={anchorEl}
          handleClose={handleClose}
          onLogoutClick={handleLogout}
          onProfileClick={() => {
            navigate(Routes.PROFILE);
            handleClose();
          }}
        />
      </Stack>
    </AppBar>
  );
};

export default NavigationBar;
