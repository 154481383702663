import NumberFormat, { NumberFormatProps } from 'react-number-format';

export const CustomNumberFormat = ({
  prefix,
  suffix,
  ...rest
}: NumberFormatProps) => {
  return (
    <NumberFormat
      decimalScale={2}
      thousandSeparator={'.'} // We can predefine the thousand separator here, eliminating the need to repeat it in every component, as we use the same format for DE numbers.
      decimalSeparator={','}
      {...(!!prefix && { prefix: `${prefix} ` })}
      {...(!!suffix && { suffix: ` ${suffix}` })}
      {...rest}
    />
  );
};
