import { styled } from 'theme';

import { Grid } from '@mui/material';

export const RootS = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundImage: `url('/img/background.png')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 45%',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
}));

export const PaperS = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.paper,
  flex: '0 0 0',
  padding: theme.spacing(5),

  '& .MuiAlert-root': {
    height: theme.spacing(8),
  },
}));

export const LogoS = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& img': {
    height: 155,
    width: 170,
  },
}));
