import styles from './StaticPage.module.scss';

export const Imprint = () => {
  return (
    <div className={styles.content}>
      <h1>Imprint</h1>
      <p>
        <div>Schwarz Media GmbH</div>
        <div>Stiftsbergstraße1</div>
        <div>74172 Neckarsulm </div>
      </p>
      <p>
        E-Mail: <a href="mailto:info@media.schwarz">info@media.schwarz</a>
      </p>
      <p>Amtsgericht Stuttgart HRB 760400 </p>
      Geschäftsführer:
      <ol>
        <li>Rolf Schumann</li>
        <li>Robert Jozic</li>
        <li> Sebastian M. Schork</li>
      </ol>
      <h2>Our entitlement to the supply chain</h2>
      <p>
        The companies of Schwarz Group respect human rights and environmental
        standards within their own organizations and in their global supply
        chains. The goal is to promote their application and prevent violations.
        The companies of Schwarz Group work together with their employees,
        customers, business partners and other stakeholders to promote
        compliance with human rights and environmental standards. More
        information on{' '}
        <a
          href="https://jobs.schwarz/en/corporate-responsibility"
          target="_blank"
          rel="noreferrer"
        >
          corporate responsibility
        </a>
        .
      </p>
    </div>
  );
};
