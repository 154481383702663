import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CircleClose } from 'assets/icons/circle-x.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import classNames from 'classnames';
import { definitions } from 'types/api';

import { useStoreDispatch } from 'store';
import { addNotification } from 'store/modules/notifications/actions';

import { Chip, Stack, SvgIcon } from '@mui/material';

import styles from './ProductChip.module.scss';

type Props = Pick<definitions['TaxonomyProduct'], 'product_name' | 'gtin'> & {
  copyable: boolean;
  onDeselect: (id: string) => void;
  disabled: boolean;
};
export const ProductChip = ({
  product_name,
  gtin,
  copyable,
  onDeselect,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (copyable) {
      navigator.clipboard.writeText(gtin);
      dispatch(
        addNotification({
          text: t('copied_to_clipboard'),
          type: 'default',
        })
      );
    }
  };

  const editableChipProps = {
    deleteIcon: <SvgIcon component={CircleClose} viewBox={'0 0 32 32'} />,
    onDelete: () => onDeselect(gtin),
  };

  const className = classNames(styles.product_chip, {
    [styles.clipboard_copy]: copyable,
  });

  return (
    <>
      <Chip
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={className}
        key={gtin}
        label={
          <>
            <span className={styles.gtin}>{gtin}</span>
            <span className={styles.divider}>|</span>
            <span className={styles.name}>{product_name}</span>
            {isHovered && copyable && (
              <Stack
                className={styles.copy}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <span>{t('copy_gtin')}</span>
                <SvgIcon
                  component={CopyIcon}
                  viewBox={'0 0 32 32'}
                  fontSize="small"
                />
              </Stack>
            )}
          </>
        }
        onClick={handleClick}
        {...(!disabled && editableChipProps)}
      />
    </>
  );
};
