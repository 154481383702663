import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { TimeUnit } from 'types';
import { definitions } from 'types/api';

import { NumberInputBase } from 'components/Shared/CustomMui';
import styles from 'components/Universes/Rule/Rule.module.scss';

import { Grid, Typography } from '@mui/material';

interface Props {
  periodError: boolean;
  condition: definitions['BuildUniverseUserCondition'];
  onChange: (condition: definitions['BuildUniverseUserCondition']) => void;
}

export const RuleTimeSpanPopper = ({
  periodError,
  onChange,
  condition,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography color="textSecondary" variant="body2">
        {t('in_last')}
      </Typography>
      <Grid container>
        <Grid item className={styles.time_span_input}>
          <NumberInputBase
            error={periodError}
            max={999}
            onBlur={(e) => {
              onChange({
                ...condition,
                period: Number(e.target.value),
              });
            }}
            thousandSeparator={false}
            value={condition?.period?.toString()}
          />
        </Grid>
        <Grid
          item
          className={classNames(styles.block, {
            [styles.selected]:
              condition.period_type === 'BUILDER_CONDITION_PERIOD_TYPE_DAY',
          })}
          onClick={() => {
            onChange({
              ...condition,
              period_type: 'BUILDER_CONDITION_PERIOD_TYPE_DAY',
            });
          }}
        >
          {t(TimeUnit.DAY.toLowerCase(), {
            count: Number(condition?.period),
          })}
        </Grid>

        <Grid
          item
          className={classNames(styles.block, {
            [styles.selected]:
              condition.period_type === 'BUILDER_CONDITION_PERIOD_TYPE_WEEK',
          })}
          onClick={() => {
            onChange({
              ...condition,
              period_type: 'BUILDER_CONDITION_PERIOD_TYPE_WEEK',
            });
          }}
        >
          {t(TimeUnit.WEEK.toLowerCase(), {
            count: Number(condition?.period),
          })}
        </Grid>
      </Grid>
    </>
  );
};
