export const parseCSV = (
  value: string,
  defaultValue?: string,
  delimiter: RegExp = /[\r\n]+/
): string[] => {
  if (value) {
    return value
      .split(delimiter)
      .map((value) => value.trim())
      .filter((value) => value !== '');
  }

  return defaultValue ? [defaultValue] : [];
};
