export * from 'types/modules/error-codes';
export * from 'types/modules/report';
export * from 'types/modules/user';

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
}

export enum Endpoints {
  advertisers = '/v1/advertisers',
  campaignPools = '/v1/campaign_pools',
  campaigns = '/v1/campaigns',
  campaign_groups = '/v1/campaign-groups',
  channels = '/v1/channels',
  executions = '/v1/executions',
  executionContexts = '/v1/execution-contexts',
  labels = '/v1/labels',
  networks = '/v1/networks',
  productTaxonomies = '/v1/product-taxonomies',
  productUnavailability = '/v1/product-unavailability',
  report = '/v1/reports',
  retailers = '/v1/retailers',
  settings = '/v1/settings',
  countries = '/v1/countries',
  universes = '/v1/universes',
  users = '/v1/users',
  shopperMetadata = '/v1/shopper-metadata',
}

export interface ErrorMsg {
  code: string;
  message?: string;
}

export type ISODateRange = [
  null | undefined | string,
  null | undefined | string
];

export enum LoadingState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum Locales {
  enGB = 'en-GB',
  de = 'de',
}

export interface LocationState {
  error?: {
    status: string;
    message: string;
  };
}
export interface Pagination {
  page: number;
  per_page: number;
  results: number;
}

export enum TimeUnit {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum FrequencyCapLimitTimeUnit {
  'Day' = 'BR_LIMIT_TIME_UNIT_DAY',
  'Week' = 'BR_LIMIT_TIME_UNIT_WEEK',
  'Month' = 'BR_LIMIT_TIME_UNIT_MONTH',
  'Campaign Duration' = 'BR_LIMIT_TIME_UNIT_CAMPAIGN_DURATION',
}

export const formModes = ['create', 'edit', 'view', 'validate'] as const;
export type FormType = typeof formModes[number];
