import { styled } from 'theme';

import Grid from '@mui/material/Grid';

const TopBarS = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '& .NumProducts-root': {
    color: theme.palette.grey[300],
  },
  '& .MuiOutlinedInput-root': {
    background: theme.palette.grey[50],
    fontSize: 12,
    height: 24,
    paddingRight: theme.spacing(0.5),
    width: 160,
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
      height: 16,
    },
  },
  '& .MuiOutlinedInput-input': {
    paddingLeft: theme.spacing(1.5),
  },
}));

export default TopBarS;
