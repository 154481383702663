import { useEffect, useState } from 'react';

import { ExtendedCampaign } from 'shared/interfaces';
import { UserRole } from 'types';

import { useGetCumulativesQuery } from 'store/api/endpoints/reports';

import { Grid } from '@mui/material';

import styles from './Reporting.module.scss';
import TimeSeries from './charts/TimeSeries';
import { MetricTile } from './components/MetricTile/MetricTile';
import { useCampaignCumulatives } from './hooks/useCampaignCumulatives';
import { useMetricsAndChartData } from './hooks/useMetricsAndChartData';
import { useReportingMetrics } from './hooks/useReportingMetrics';
import {
  ALL_PRICE_METRICS,
  MetricType,
  ReportDataType,
  selectMetricTimeSeriesData,
} from './utils/utils';

interface Props {
  reportDataA: ReportDataType;
  reportDataB?: ReportDataType;
  userRole: UserRole;
}

export const MetricsAndChart = ({
  reportDataA,
  reportDataB,
  userRole,
}: Props) => {
  const metricNamesForCampaignType = useReportingMetrics(
    reportDataA.objectType,
    userRole,
    (reportDataA as ExtendedCampaign).type
  );

  const [selectedMetric, setSelectedMetric] = useState<MetricType>(
    metricNamesForCampaignType[0]
  );

  const [dateRange, setDateRange] = useState<{
    start: Date;
    end: Date;
  } | null>();

  const {
    loading: loadingCampaignAData,
    byDayTimeSeriesData: campaignAByDayTimeSeriesData,
    productUnavailabilityData: productUnavailabilityDataA,
  } = useMetricsAndChartData(reportDataA);

  const {
    loading: loadingCampaignBData,
    byDayTimeSeriesData: campaignBByDayTimeSeriesData,
  } = useMetricsAndChartData(reportDataB);

  const {
    data: campaignBCumulativeData,
    isLoading: loadingCampaignBCumulativeData,
  } = useGetCumulativesQuery([reportDataB?.id!], {
    refetchOnMountOrArgChange: true,
    skip: !reportDataB || !reportDataB?.id,
  });

  const reportACumulatives = useCampaignCumulatives({
    reportData: reportDataA,
    selectedMetric,
    metrics: campaignAByDayTimeSeriesData,
    dateRange,
  });

  const reportBCumulatives = useCampaignCumulatives({
    reportData:
      !loadingCampaignBCumulativeData && reportDataB && campaignBCumulativeData
        ? { ...campaignBCumulativeData[0], ...reportDataB }
        : undefined,
    selectedMetric,
    metrics: campaignBByDayTimeSeriesData,
    dateRange,
  });

  useEffect(() => {
    if (metricNamesForCampaignType?.length > 0)
      setSelectedMetric(metricNamesForCampaignType[0]);
  }, [metricNamesForCampaignType]);

  // reset date range when switching between campaigns
  useEffect(() => {
    setDateRange(null);
  }, [reportDataA, reportDataB]);

  const campaignAMetricsByDayTimeSeries = selectMetricTimeSeriesData(
    campaignAByDayTimeSeriesData,
    selectedMetric
  );

  const campaignBMetricsByDayTimeSeries = selectMetricTimeSeriesData(
    campaignBByDayTimeSeriesData,
    selectedMetric
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2} mt={2}>
          <div className={styles.metric_tiles}>
            {metricNamesForCampaignType.map((metric: MetricType) => (
              <MetricTile
                key={metric}
                metric={metric}
                currency={reportDataA?.currency?.symbol || ''}
                areMultipleCurrenciesInvolved={
                  reportDataA?.areMultipleCurrenciesInvolved
                }
                selectedMetric={selectedMetric}
                type={ALL_PRICE_METRICS.includes(metric) ? 'price' : 'number'}
                tileClickHandler={() => {
                  setSelectedMetric(metric);
                  // reset date range when switching between metrics
                  setDateRange(null);
                }}
                cumulatives={reportACumulatives}
                variant="A"
              />
            ))}
          </div>
        </Grid>
        <Grid item xs={reportDataB ? 8 : 10}>
          {selectedMetric &&
            campaignAByDayTimeSeriesData &&
            (campaignAByDayTimeSeriesData?.campaign_id === reportDataA?.id ||
              campaignAByDayTimeSeriesData?.campaign_group_id ===
                reportDataA?.id) && (
              <TimeSeries
                metric={selectedMetric}
                entityAId={reportDataA?.id}
                campaignAData={campaignAMetricsByDayTimeSeries || []}
                entityBId={reportDataB?.id}
                campaignBData={campaignBMetricsByDayTimeSeries || []}
                productUnavailabilityDurations={
                  productUnavailabilityDataA || []
                }
                onTimeRangeChange={(
                  appliedDateRange: {
                    start: Date;
                    end: Date;
                  } | null
                ) => {
                  setDateRange(appliedDateRange);
                }}
                loading={
                  loadingCampaignAData ||
                  loadingCampaignBData ||
                  loadingCampaignBCumulativeData
                }
              />
            )}
        </Grid>
        {reportDataB && (
          <Grid item xs={2} mt={2}>
            <div className={styles.metric_tiles}>
              {metricNamesForCampaignType.map((metric: MetricType) => (
                <MetricTile
                  key={metric}
                  metric={metric}
                  currency={reportDataB?.currency?.symbol || ''}
                  areMultipleCurrenciesInvolved={
                    reportDataB?.areMultipleCurrenciesInvolved
                  }
                  selectedMetric={selectedMetric}
                  type={ALL_PRICE_METRICS.includes(metric) ? 'price' : 'number'}
                  tileClickHandler={() => {
                    setSelectedMetric(metric);
                    // reset date range when switching between metrics
                    setDateRange(null);
                  }}
                  cumulatives={reportBCumulatives}
                  variant="B"
                />
              ))}
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};
