import { FC, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as CircleCheckIcon } from 'assets/icons/circle-check.svg';
import { ReactComponent as CirclePlusIcon } from 'assets/icons/circle-plus.svg';
import { ExtendedChannel, useCampaigns } from 'hooks';
import { tkCampaignType } from 'i18n/translationKeyMaps';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import {
  CampaignsResponse,
  ExtendedCampaign,
} from 'shared/interfaces/Campaign';
import { definitions } from 'types/api';

import { selectChannels } from 'store/modules/settings/selectors';

import { NumberFormat } from 'components/Shared';
import { Table } from 'components/Shared/CustomMui';
import {
  CampaignStateChip,
  Column,
  Pagination,
} from 'components/Shared/CustomMui/';

import { Grid, IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';

import { CountryNameWithFlag } from '../CountryNameWithFlag/CountryNameWithFlag';
import { EmptyState } from '../EmptyState/EmptyState';
import styles from './CampaignsSelectionTable.module.scss';

interface Props {
  page: number;
  title?: string;
  setPage: (page: number) => void;
  campaignResponse?: CampaignsResponse;
  previouslySelectedCampaignIds?: string[];
  onSelectionChange: (campaignIds: string[]) => void;
}

export const CampaignsSelectionTable: FC<Props> = (
  props: Props
): ReactElement => {
  const {
    campaignResponse,
    onSelectionChange,
    page,
    setPage,
    title,
    previouslySelectedCampaignIds,
  } = props;
  const { t } = useTranslation();

  const networkChannels = useSelector(selectChannels);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>([]);

  const extendedCampaigns = useCampaigns(
    campaignResponse?.results || [],
    [],
    networkChannels
  );

  useEffect(() => {
    if (previouslySelectedCampaignIds?.length) {
      setSelectedCampaignIds((existingItems) => [
        ...existingItems,
        ...previouslySelectedCampaignIds,
      ]);
    }
  }, [previouslySelectedCampaignIds]);

  const isCampaignIdSelected = (id: string): boolean => {
    return (
      selectedCampaignIds.findIndex(
        (campaignId: string) => campaignId === id
      ) >= 0
    );
  };

  const columns: Column[] = [
    {
      field: 'name',
      headerName: t('name'),
      renderCell: (name) => (
        <Tooltip title={name}>
          <span>{name}</span>
        </Tooltip>
      ),
    },
    {
      field: 'country_code',
      headerName: t('country'),
      width: 80,
      renderCell: (country_code: string) => (
        <CountryNameWithFlag
          countryCode={country_code}
          countryName={country_code}
        />
      ),
    },
    {
      field: 'type',
      headerName: t('type'),
      width: 50,
      renderCell: (type: definitions['CampaignType']) => tkCampaignType(type),
    },
    {
      field: 'state',
      headerName: t('status'),
      width: 80,
      renderCell: (state) => <CampaignStateChip state={state} />,
    },
    {
      field: 'extended_channels',
      headerName: t('channels'),
      renderCell: (extendedChannels) => {
        const allChannels = extendedChannels
          ?.map((channel: ExtendedChannel) => channel.name)
          .join(', ');
        return allChannels ? (
          <Tooltip title={allChannels}>
            <span>{allChannels}</span>
          </Tooltip>
        ) : (
          '-'
        );
      },
    },
    {
      field: 'budget',
      headerName: t('budget'),
      width: 150,
      useRootObject: true,
      renderCell: (campaign: ExtendedCampaign) => (
        <NumberFormat
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={0}
          displayType="text"
          prefix={campaign?.currency?.symbol}
          value={Number(campaign?.budget)}
        />
      ),
    },
    {
      field: 'id',
      headerName: '',
      width: 80,
      sticky: 'right',
      renderCell: (id: string): ReactElement => (
        <>
          <IconButton
            color={isCampaignIdSelected(id) ? 'success' : 'primary'}
            onClick={() => {
              let newSelectedCampaignIds = [...selectedCampaignIds];
              if (isCampaignIdSelected(id)) {
                newSelectedCampaignIds.splice(
                  newSelectedCampaignIds.findIndex(
                    (campaignId: string) => id === campaignId
                  ),
                  1
                );
              } else {
                newSelectedCampaignIds = [...newSelectedCampaignIds, id];
              }
              setSelectedCampaignIds(newSelectedCampaignIds);
              onSelectionChange(newSelectedCampaignIds);
            }}
          >
            <SvgIcon
              component={
                isCampaignIdSelected(id) ? CircleCheckIcon : CirclePlusIcon
              }
              viewBox={'0 0 32 32'}
            />
          </IconButton>
        </>
      ),
    },
  ];

  const pagination: Pagination = {
    onPageChange: (event: MouseEvent, newPage: number) => setPage(newPage),
    page,
    per_page: DEFAULT_API_PARAMETERS.per_page,
    results: campaignResponse?.query?.results || 0,
  };

  return (
    <>
      <Grid
        container
        className={styles.results_container}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item className={styles.result_count}>
          {title && (
            <>
              <Typography variant="h3">{title}</Typography>
              <Typography variant="h3" color="textSecondary">
                ({campaignResponse?.query?.results})
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Table
        emptyState={<EmptyState text={t('no_results')} />}
        columns={columns}
        minHeight={campaignResponse?.query?.results ? 1 : 2}
        rows={
          extendedCampaigns?.length
            ? extendedCampaigns.map((campaign: ExtendedCampaign) => ({
                ...campaign,
                selected: isCampaignIdSelected(campaign?.id),
              }))
            : []
        }
        pagination={pagination}
      />
    </>
  );
};
