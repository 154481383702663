import { IconButton, Toolbar } from '@mui/material';

import { AvatarStyle, UserMenuStyle } from './UserMenuItem.style';

type UserMenuProps = {
  advertiser: any;
  user: any;
  handleIconClick: any;
  isSelected: boolean;
};

export const UserMenu = (props: UserMenuProps) => {
  const { user, handleIconClick, isSelected } = props;

  return (
    <UserMenuStyle item>
      <Toolbar disableGutters>
        {!!user && (
          <>
            <IconButton
              id="user-menu-open-button"
              edge="end"
              aria-label="user-menu-open-button"
              aria-haspopup="true"
              onClick={handleIconClick}
            >
              <AvatarStyle isSelected={isSelected}>
                {user.email[0].toUpperCase()}
              </AvatarStyle>
            </IconButton>
          </>
        )}
      </Toolbar>
    </UserMenuStyle>
  );
};
