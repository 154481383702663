import { ReactElement } from 'react';

import styles from './StaticPage.module.scss';

export const CookieNotice = (): ReactElement => {
  return (
    <div className={styles.content}>
      <h1>Schwarz Media GmbH</h1>
      <h2>Cookie Bestimmungen</h2>
      <p>
        Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem
        Endgerät (Laptop, Tablet, Smartphone o. ä.) gespeichert werden, wenn Sie
        unsere Webseiten besuchen. Cookies richten auf Ihrem Endgerät keinen
        Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
        In dem Cookie werden Informationen abgelegt, die sich jeweils im
        Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies
        bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer
        Identität erhalten.
      </p>

      <p>
        Eine Informationsübersicht zu den eingesetzten Cookies und anderen
        Technologien, nebst den jeweiligen Verarbeitungszwecken, den
        Speicherdauern und etwa eingebundenen Drittanbietern finden Sie
        nachfolgend.
      </p>

      <p>
        Der Einsatz von Cookies und den anderen Technologien zur Verarbeitung
        von Nutzungsdaten dient – je nach Kategorie des Cookies bzw. der anderen
        Technologie – folgenden Zwecken:
      </p>

      <h2>Technisch notwendig</h2>

      <p>
        Dies sind Cookies und ähnliche Methoden, ohne die Sie unsere Dienste
        nicht nutzen können (etwa zur richtigen Anzeige unserer Webseite/von
        Ihnen gewünschten Funktionen, zum Speichern Ihrer Anmeldung im
        Login-Bereich etc.).
      </p>

      <table>
        <tr>
          <th>Cookies</th>
          <th>Cookie-Untergruppe</th>
          <th>Ablauf</th>
          <th>Verwendete Cookies</th>
          <th>Beschreibung</th>
        </tr>
        <tr>
          <td>4e44b393c27e7fe1d9c527129a8310b8</td>
          <td>psf-auth.dataenv.com (Keycloak Authentication Service)</td>
          <td>Sitzung/ Session</td>
          <td>Erstanbieter/ First Party Provider</td>
          <td></td>
        </tr>
        <tr>
          <td>KEYCLOAK_SESSION_LEGACY</td>
          <td>psf-auth.dataenv.com (Keycloak Authentication Service)</td>
          <td>365 Tage/ days</td>
          <td>Erstanbieter/ First Party Provider</td>
          <td>
            LEGACY cookie variants are a workaround for older Apple operating
            systems with browsers that ignore cookies set with the
            'SameSite=None' property.
          </td>
        </tr>
        <tr>
          <td>KEYCLOAK_IDENTITY_LEGACY</td>
          <td>psf-auth.dataenv.com (Keycloak Authentication Service)</td>
          <td>Sitzung/ Session</td>
          <td>Erstanbieter/ First Party Provider</td>
          <td>
            LEGACY cookie variants are a workaround for older Apple operating
            systems with browsers that ignore cookies set with the
            'SameSite=None' property.
          </td>
        </tr>
        <tr>
          <td>lbcookie</td>
          <td>psf-auth.dataenv.com (Keycloak Authentication Service)</td>
          <td>Sitzung/ Session</td>
          <td>Erstanbieter/ First Party Provider</td>
          <td></td>
        </tr>
        <tr>
          <td>KEYCLOAK_SESSION</td>
          <td>psf-auth.dataenv.com (Keycloak Authentication Service)</td>
          <td>365 Tage/ days</td>
          <td>Erstanbieter/ First Party Provider</td>
          <td>Authentication session ID associated to the user's Tenant.</td>
        </tr>
        <tr>
          <td>AUTH_SESSION_ID_LEGACY</td>
          <td>psf-auth.dataenv.com (Keycloak Authentication Service)</td>
          <td>Sitzung/ Session</td>
          <td>Erstanbieter/ First Party Provider</td>
          <td></td>
        </tr>
        <tr>
          <td>KEYCLOAK_IDENTITY</td>
          <td>psf-auth.dataenv.com (Keycloak Authentication Service)</td>
          <td>Sitzung/ Session</td>
          <td>Erstanbieter (First Party Provider)</td>
          <td>Authentication token (JWT) which includes User ID.</td>
        </tr>
        <tr>
          <td>AUTH_SESSION_ID</td>
          <td>psf-auth.dataenv.com (Keycloak Authentication Service)</td>
          <td>Sitzung/ Session</td>
          <td>Erstanbieter (First Party Provider)</td>
          <td>Authenticated session ID (internal).</td>
        </tr>
      </table>
    </div>
  );
};
