import { ChangeEvent, MutableRefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as XSmallIcon } from 'assets/icons/x-small.svg';
import { useElementOnScreen } from 'hooks';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { definitions } from 'types/api';

import { useGetShopperMetadataListQuery } from 'store/api/endpoints/shopperMetadata';

import { InputAdornment, SvgIcon, TextField } from '@mui/material';

import styles from './RuleAttributeType.module.scss';

interface Props {
  retailerId?: string;
  countryCode?: string;
  onAttributeTypeChange: (
    selectedAttribute: definitions['ShopperMetadata'] | 'Products'
  ) => void;
}

export const RuleAttributeTypePopper = ({
  retailerId,
  countryCode,
  onAttributeTypeChange,
}: Props) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState<string>();
  const [allAttributes, setAllAttributes] = useState<
    definitions['ShopperMetadata'][]
  >([]);
  const [page, setPage] = useState(DEFAULT_API_PARAMETERS.page);

  const { data: attributes, isLoading } = useGetShopperMetadataListQuery(
    {
      query: {
        page,
        per_page: DEFAULT_API_PARAMETERS.per_page_small,
        country_code: countryCode!,
        retailer_id: retailerId!,
        search: searchTerm,
      },
    },
    {
      skip: !countryCode || !retailerId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [containerRef, isVisible] = useElementOnScreen(
    {},
    attributes?.pagination?.results === allAttributes?.length
  );

  const doesBoughtProductsMatchSearch =
    searchTerm &&
    t('bought_products').toLowerCase().includes(searchTerm?.toLowerCase());

  useEffect(() => {
    if (
      isVisible &&
      attributes?.pagination.results !== 0 &&
      attributes?.pagination.results !== allAttributes?.length
    ) {
      setPage((previousPage: number) => previousPage + 1);
    }
  }, [isVisible]);

  useEffect(() => {
    if (attributes?.shopper_metadata?.length) {
      setAllAttributes((previousCampaigns) =>
        previousCampaigns.concat(attributes.shopper_metadata)
      );
    }
  }, [attributes]);

  return (
    <>
      {!isLoading && (
        <>
          {(!!attributes?.shopper_metadata?.length || searchTerm) && (
            <TextField
              autoFocus
              value={searchTerm}
              type="text"
              variant="standard"
              size="small"
              className={styles.search_bar}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setAllAttributes([]);
                setPage(DEFAULT_API_PARAMETERS.page);
                setSearchTerm(event.target.value);
              }}
              placeholder={t('search_criteria')?.toUpperCase()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {searchTerm ? (
                      <SvgIcon
                        component={XSmallIcon}
                        viewBox={'0 0 32 32'}
                        onClick={() => {
                          setSearchTerm('');
                        }}
                        className={styles.clear_icon}
                      />
                    ) : (
                      <SvgIcon
                        component={SearchIcon}
                        viewBox={'0 0 32 32'}
                        className={styles.search_icon}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
          <ul className={styles.list}>
            {allAttributes?.length === 0 &&
              searchTerm &&
              !doesBoughtProductsMatchSearch && (
                <li className={`${styles.empty_result} ${styles.item}`}>
                  {t('no_results')}
                </li>
              )}
            {(!searchTerm || doesBoughtProductsMatchSearch) && (
              <li
                className={styles.item}
                onClick={() => onAttributeTypeChange('Products')}
              >
                {t('bought_products')}
              </li>
            )}
            {allAttributes?.map((attribute: definitions['ShopperMetadata']) => (
              <li
                key={attribute.attribute_id}
                className={styles.item}
                onClick={() => onAttributeTypeChange(attribute)}
              >
                {attribute.attribute_name}
              </li>
            ))}
            <li ref={containerRef as MutableRefObject<null>}></li>
          </ul>
        </>
      )}
    </>
  );
};
