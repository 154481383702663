import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowSortIcon } from 'assets/icons/arrows-sort.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down-small.svg';
import classnames from 'classnames';
import { definitions } from 'types/api';
import { objectKeys } from 'utils';

import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
} from '@mui/material';

import styles from './Sort.module.scss';

export interface SortProps {
  sortFields: Record<string, string>;
  onSort: (sortValue: string, sortOrder: definitions['SortOrder']) => void;
  activeSortField: string;
  activeSortOrder: definitions['SortOrder'];
}

export const Sort = (props: SortProps) => {
  const { activeSortField, activeSortOrder, onSort, sortFields } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleSortOrderChanged = () => {
    onSort(
      activeSortField,
      activeSortOrder === 'SORT_ORDER_DESC'
        ? 'SORT_ORDER_ASC'
        : 'SORT_ORDER_DESC'
    );
  };
  const handleSortFiledChanged = (event: SelectChangeEvent) => {
    onSort(event.target.value, activeSortOrder);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0}
      className={styles.sorting_wrapper}
    >
      <IconButton
        disableRipple
        className={
          activeSortOrder === 'SORT_ORDER_DESC'
            ? styles.sort_desc
            : styles.sort_asc
        }
        aria-label="Sort Order"
        onClick={handleSortOrderChanged}
      >
        <SvgIcon component={ArrowSortIcon} viewBox={'0 0 36 36'} />
      </IconButton>
      <FormControl>
        <Select
          className={classnames(styles.sort_select_field, {
            [styles.opened]: open,
          })}
          IconComponent={ArrowDownIcon}
          displayEmpty
          value={activeSortField}
          onChange={(event: SelectChangeEvent) => handleSortFiledChanged(event)}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          MenuProps={{
            classes: {
              list: styles.sort_list,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          <MenuItem value={''} disabled={true}>
            {t('sort_by')}
          </MenuItem>
          {objectKeys(sortFields).map((sortItemKey) => (
            <MenuItem key={sortItemKey} value={sortItemKey}>
              {sortFields[sortItemKey]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
