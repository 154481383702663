import { definitions } from 'types/api';

import { useGetProductsUnavailabilityQuery } from 'store/api/endpoints/productUnavailability';
import {
  useGetCampaignGroupMetricsByDayQuery,
  useGetMetricsByDayQuery,
} from 'store/api/endpoints/reports';

import { ReportDataType } from '../utils/utils';

type MetricsAndChartDataReturnType = {
  loading: boolean;
  productUnavailabilityData?: definitions['ProductUnavailabilityDuration'][];
  byDayTimeSeriesData?: definitions['ByDayTimeSeries'];
};

export const useMetricsAndChartData = (
  reportData?: ReportDataType
): MetricsAndChartDataReturnType => {
  const { data: productUnavailabilityData } = useGetProductsUnavailabilityQuery(
    {
      gtins: reportData?.promoted_products?.concat(
        reportData?.additionally_promoted_products || []
      ),
      retailer_id: reportData?.extended_channels?.[0]?.retailer_id,
      start: reportData?.campaign_start || '',
      end: reportData?.campaign_end || '',
      country_code: reportData?.country_code || '',
    },
    {
      skip:
        true ||
        reportData?.objectType === 'CampaignGroup' ||
        !reportData?.country_code,
    }
  );

  const {
    data: campaignByDayTimeSeries,
    isFetching: isFetchingCampaignByDayTimeSeries,
  } = useGetMetricsByDayQuery(reportData ? [reportData.id] : [], {
    refetchOnMountOrArgChange: true,
    skip:
      !reportData || !reportData?.id || reportData?.objectType !== 'Campaign',
  });

  const {
    data: campaignGroupByDayTimeSeries,
    isFetching: isFetchingCampaignGroupByDayTimeSeries,
  } = useGetCampaignGroupMetricsByDayQuery(reportData ? [reportData.id] : [], {
    refetchOnMountOrArgChange: true,
    skip:
      !reportData ||
      !reportData.id ||
      reportData.objectType !== 'CampaignGroup',
  });

  const reportByDayTimeSeries =
    reportData?.objectType === 'Campaign'
      ? campaignByDayTimeSeries?.[0]
      : campaignGroupByDayTimeSeries?.[0];

  return {
    loading:
      isFetchingCampaignByDayTimeSeries ||
      isFetchingCampaignGroupByDayTimeSeries,
    productUnavailabilityData: productUnavailabilityData,
    byDayTimeSeriesData: reportData ? reportByDayTimeSeries : undefined,
  } as const;
};
