import { RouteInfo } from 'routes/RouteInfo';
import { Routes } from 'routes/Routes';

import Login from 'components/Login/Login';
import { Imprint, PrivacyPolicy } from 'components/StaticPages';

import { AuthCallback } from '../components/Auth/AuthCallback';
import { CookieNotice } from '../components/StaticPages/CookieNotice';

export const PublicRoutes: Record<string, RouteInfo> = {
  LOGIN: {
    path: Routes.LOGIN,
    roles: [],
    translationKey: 'login',
    element: <Login />,
  },
  AUTHENTICATION_CALLBACK: {
    path: Routes.AUTHENTICATION_CALLBACK,
    roles: [],
    translationKey: 'auth_callback',
    element: <AuthCallback />,
  },
  IMPRINT: {
    path: Routes.IMPRINT,
    roles: [],
    translationKey: 'imprint',
    element: <Imprint />,
  },
  PRIVACY_POLICY: {
    path: Routes.PRIVACY_POLICY,
    roles: [],
    translationKey: 'privacy_policy',
    element: <PrivacyPolicy />,
  },
  COOKIE_NOTICE: {
    path: Routes.COOKIE_NOTICE,
    roles: [],
    translationKey: 'cookie_notice',
    element: <CookieNotice />,
  },
};
