import { useTranslation } from 'react-i18next';

import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as SplitHorizontal } from 'assets/icons/split-horizontal.svg';
import { LayoutType } from 'shared/enums';

import { Divider, IconButton, Stack, SvgIcon } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import styles from './Layouts.module.scss';

export interface LayoutsProps {
  activeLayout?: LayoutType;
  onChange: (listLayout: LayoutType) => void;
}

export const Layouts = (props: LayoutsProps) => {
  const { activeLayout = LayoutType.Tile, onChange } = props;
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={1} className={styles.layouts_wrapper}>
      <Tooltip title={t('tile_view') || 'Tile view'}>
        <IconButton
          data-testid="tile-view"
          className={activeLayout === LayoutType.Tile ? styles.active : ''}
          aria-label="Tile View"
          onClick={() => onChange(LayoutType.Tile)}
        >
          <SvgIcon component={SplitHorizontal} viewBox={'0 0 30 30'} />
        </IconButton>
      </Tooltip>
      <Divider variant="middle" orientation="vertical" flexItem />
      <Tooltip title={t('table_view') || 'Table view'}>
        <IconButton
          data-testid="table-view"
          className={activeLayout === LayoutType.Table ? styles.active : ''}
          aria-label="Table View"
          onClick={() => onChange(LayoutType.Table)}
        >
          <SvgIcon component={ListIcon} viewBox={'0 0 30 30'} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
