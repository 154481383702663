import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FrequencyCapLimitTimeUnit } from 'types';
import { definitions } from 'types/api';
import { getEnumKeyByValue } from 'utils';

import { useGetChangeLogQuery } from 'store/api/endpoints/campaign';
import { useLazyGetUniverseByIdQuery } from 'store/api/endpoints/universes';

export const useChangeLogRequest = (campaignId: string) => {
  const { t } = useTranslation();

  const [changeLogItems, setChangeLogItems] =
    useState<definitions['ChangeLogRow'][]>();

  const [getUniverseById] = useLazyGetUniverseByIdQuery();
  const { data: changeLogs } = useGetChangeLogQuery({
    campaignId,
  });

  useEffect(() => {
    getAsyncChangeLogItems().then((data) => {
      const filteredData = data.filter((n) =>
        n.nodes.filter((x) => x !== undefined)
      ) as definitions['ChangeLogRow'][];
      setChangeLogItems(filteredData);
    });
  }, [changeLogs]);

  const getUniverseName = async (universeId?: string) => {
    let universeName: string = '';
    try {
      universeName = universeId
        ? (
            await getUniverseById({
              id: universeId,
              silentNotification: true,
            }).unwrap()
          ).name
        : universeId || '';
    } catch {
      universeName = t('deleted_universe');
    }
    return universeName;
  };

  const getFrequencyCapNodeDetails = (node: definitions['Node']) => {
    if (node.field === 'Business Rule Limit Time Unit') {
      return {
        to: getEnumKeyByValue(FrequencyCapLimitTimeUnit, node.to),
        from: getEnumKeyByValue(FrequencyCapLimitTimeUnit, node.from),
        field: 'Frequency cap time unit',
      };
    } else if (node.field === 'Business Rule Limit Value')
      return {
        ...node,
        field: 'Frequency cap views',
      };
  };

  const getAsyncNodeDetails = async (node: definitions['Node']) => {
    if (node.field === 'Universe ID') {
      return {
        ...node,
        from: await getUniverseName(node.from),
        to: await getUniverseName(node.to),
        field: t('universe'),
      };
    }
    if (
      node.field === 'Business Rule Limit Time Unit' ||
      node.field === 'Business Rule Limit Value'
    )
      return getFrequencyCapNodeDetails(node);

    // The format of universe changelog row in API response is as follows:
    // { "field": "universe",
    //   "from": "",
    //   "to": "",
    //   "message": "Some universe name|xxxx-xxxxx-xxxxx-xxxxxx" }
    if (node.field === 'universe') {
      const universeName = node.message?.split('|')[0];
      const universeId = node.message?.split('|')[1];
      return {
        from: universeName,
        to: universeId,
        field: t('changelog_universe_rules_field_label'),
        message: '',
      };
    }

    return node;
  };

  const getAsyncChangeLogItems = async () => {
    const result = changeLogs?.rows?.map(async (row) => {
      const nodes = row.nodes?.map((node) => {
        return getAsyncNodeDetails(node);
      });

      return {
        ...row,
        nodes: await Promise.all(nodes || []),
      };
    });
    return await Promise.all(result || []);
  };

  return changeLogItems;
};
