import { styled } from 'theme';

export const AppS = styled('div')(({ theme }) => ({
  maxWidth: theme.custom.appMaxWidth,
  minWidth: theme.custom.appMinWidth,
}));

export const MainS = styled('main')(({ theme }) => ({
  marginTop: 100,
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
  paddingBottom: theme.spacing(4),
}));
