import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { definitions } from 'types/api';

import { useStoreDispatch } from 'store';
import { CreativeWithFile } from 'store/modules/campaign/helpers';
import { selectDraftValidations } from 'store/modules/campaign/selectors';
import { updateDraft } from 'store/modules/campaign/slice';
import { selectCountries } from 'store/modules/settings/selectors';

interface Props {
  countryCode: string;
  creatives: CreativeWithFile[];
  channel?: Partial<definitions['Channel']>;
}

export type CreativeData = {
  index: number;
  data: CreativeWithFile;
  options: definitions['CreativeOption'];
};

const getDimensionsFromAspectRatio = (aspectRatio?: string) => {
  if (!aspectRatio) {
    return { width: '0', height: '0' };
  }
  const [width, height] = aspectRatio.split('x');
  return { width, height };
};

export const useCreativesData = ({
  countryCode,
  creatives,
  channel,
}: Props) => {
  const dispatch = useStoreDispatch();
  const countries = useSelector(selectCountries);
  const validations = useSelector(selectDraftValidations);

  const [allCreatives, setAllCreatives] = useState<CreativeData[]>([]);

  const countryLanguages = countries?.find(
    (country: definitions['Country']) => country.code === countryCode
  )?.languages;

  const isCreativeValid = (index: number) => {
    const targetUrlValid =
      (
        validations['creatives']?.['V CREATIVE_TARGET_URL_INVALID'] as boolean[]
      )?.[index] === false;

    const targetUrlEmpty =
      (
        validations['creatives']?.['V CREATIVE_TARGET_URL_EMPTY'] as boolean[]
      )?.[index] === false;

    const hasCreativeImage =
      (validations['creatives']?.['V CREATIVE_IMAGE_EMPTY'] as boolean[])?.[
        index
      ] === false || creatives?.[index]?.file;

    return targetUrlValid && targetUrlEmpty && hasCreativeImage;
  };

  let campaignCreativesArray: CreativeData[] = [];
  countryLanguages?.forEach((language, languageIndex) => {
    channel?.creative_options?.forEach((creativeOption, optionIndex) => {
      const creativeIndex =
        optionIndex + languageIndex * (channel?.creative_options?.length || 0);
      const { height, width } = getDimensionsFromAspectRatio(
        creativeOption?.creative_aspect_ratio?.replace('px', '')
      );
      // Find the creative that matches the language, aspect ratio, and mime type
      // If not found, use the order comes from the API
      const creative =
        creatives?.find(
          (x) =>
            x.width?.toString() === width &&
            x.height?.toString() === height &&
            creativeOption?.creative_mime_types?.includes(x.mime_type) &&
            x.language === language?.code
        ) || creatives?.[creativeIndex];

      campaignCreativesArray.push({
        index: creativeIndex,
        options: creativeOption,
        data: creative
          ? { ...creative, isValid: isCreativeValid(creativeIndex) }
          : {
              isValid: false,
              language: language?.code,
              mime_type: creativeOption?.creative_mime_types?.[0] || '',
              width,
              height,
            },
      } as CreativeData);
    });
  });

  useEffect(() => {
    setAllCreatives(campaignCreativesArray);
  }, [creatives, countryLanguages]);

  const updateCreative = (index: number, data: CreativeWithFile) => {
    const updatedCreatives = allCreatives.map((creative) => {
      if (creative.index === index) {
        return { ...creative.data, ...data };
      }
      return creative.data;
    });

    dispatch(
      updateDraft({
        creatives: updatedCreatives,
      })
    );
  };

  return {
    languages: countryLanguages,
    creatives: allCreatives,
    updateCreative,
  };
};
