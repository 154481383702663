import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  accept?: string | string[];
  noClick?: boolean;
  noKeyboard?: boolean;
  imageValidator?: <ImageFile>(file: ImageFile) => any;
  onFilesDropped?: (files: File[]) => any;
}

export type ImageFile = {
  file: File;
  preview?: string;
  width: number;
  height: number;
};
export const useImageUpload = ({
  imageValidator,
  accept,
  onFilesDropped,
  noClick = false,
  noKeyboard = false,
}: Props) => {
  const [acceptedFiles, setAcceptedFiles] = useState<ImageFile[]>([]);

  const onDrop = useCallback(
    (files: File[]) => {
      if (onFilesDropped) onFilesDropped(files);
    },
    [onFilesDropped]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    acceptedFiles: acceptedDropzoneFiles,
  } = useDropzone({
    onDrop,
    multiple: false,
    validator: imageValidator,
    accept,
    noClick,
    noKeyboard,
  });

  useEffect(() => {
    async function getImageDimensions() {
      if (acceptedDropzoneFiles.length > 0) {
        let imageFile: Partial<ImageFile> = {};
        const reader = new FileReader();
        reader.readAsDataURL(acceptedDropzoneFiles[0]);
        reader.onload = () => {
          const image = new Image();
          const base64 = reader.result as string;
          image.src = base64;
          image.onload = () => {
            imageFile.file = acceptedDropzoneFiles[0];
            imageFile.preview = base64;
            imageFile.width = image.width;
            imageFile.height = image.height;
            setAcceptedFiles([imageFile as ImageFile]);
          };
        };
      }
    }
    getImageDimensions();
  }, [acceptedDropzoneFiles]);

  return {
    isDragActive,
    acceptedFiles,
    getRootProps,
    getInputProps,

    open,
  };
};
