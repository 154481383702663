import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { definitions } from 'types/api';

import { useStoreDispatch } from 'store';
import { useGetRetailersQuery } from 'store/api/endpoints/retailer';
import {
  addCampaignAlert,
  removeCampaignAlert,
  updateDraft,
} from 'store/modules/campaign/slice';
import { selectUser } from 'store/modules/user/selectors';

import { CampaignSectionProps } from 'components/Campaigns/CampaignDetails/CampaignDetails';
import { AlertProps } from 'components/Shared/CustomMui/Alert/Alert';
import { UniverseItem } from 'components/Universes/UniverseItem/UniverseItem';

import { Button, Stack } from '@mui/material';

import { CampaignSectionWrapper } from '../CampaignSectionWrapper';
import styles from './UniverseSection.module.scss';
import { UniverseSelectionDialog } from './UniverseSelectionDialog';

interface Props extends CampaignSectionProps {
  universe?: definitions['Universe'];
  retailerId?: string;
}

const UniverseSection: FC<Props> = ({
  formState,
  universe,
  retailerId,
  validate: showValidation,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const validationMessage = showValidation('universe_id');

  const user = useSelector(selectUser);
  const { data: retailers = [] } = useGetRetailersQuery(undefined, {
    skip: !user?.advertiser_id,
    refetchOnMountOrArgChange: true,
  });

  const [assignUniverse, setAssignUniverse] = useState(false);

  const enabledForEdit = !formState.readonlyFields.includes('Universes');

  const handleRemove = () => {
    dispatch(updateDraft({ universe_id: '', universe: undefined }));
  };

  useEffect(() => {
    const largeUniverseAlert: AlertProps = {
      text: t('large_universe_size_warning_message'),
      severity: 'warning',
    };
    if (!universe) removeCampaignAlert(largeUniverseAlert);
    else
      dispatch(
        universe?.build?.metadata?.includes('err_query_limit_200k_exceeded')
          ? addCampaignAlert(largeUniverseAlert)
          : removeCampaignAlert(largeUniverseAlert)
      );
  }, [universe]);

  return (
    <CampaignSectionWrapper
      header={t('universes')}
      subHeader={t('subheader_campaign_universes')}
      error={validationMessage}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-end"
        className={styles.universe_box_header}
      >
        {!universe && enabledForEdit && (
          <Button
            data-testid="add-audience"
            onClick={() => setAssignUniverse(true)}
            variant="contained"
            size="small"
          >
            {t('add_universe')}
          </Button>
        )}
      </Stack>

      <div className={styles.universe_card_container}>
        {!universe && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            className={styles.no_universe}
          >
            <Stack alignSelf="center">
              {t('no_universe_selected_message')}
            </Stack>
          </Stack>
        )}
        {universe && (
          <UniverseItem
            retailers={retailers}
            onClick={() => {}}
            onRemove={handleRemove}
            universe={universe}
            readonly={!enabledForEdit}
            deletable={enabledForEdit}
            mode="select"
          />
        )}
      </div>
      {assignUniverse && (
        <UniverseSelectionDialog
          onClose={() => setAssignUniverse(false)}
          retailerId={retailerId}
        />
      )}
    </CampaignSectionWrapper>
  );
};

export default UniverseSection;
