import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { definitions } from 'types/api';
import { toDateTime } from 'utils/time';

import { Routes } from 'routes/Routes';

import { CampaignStateChip } from 'components/Shared/CustomMui';

import { Avatar, Grid, SvgIcon, Typography } from '@mui/material';

import styles from './ChangeLogRow.module.scss';

interface ChangeLogRowProps {
  primaryKpiFieldLabel: string;
  changeLogRow: definitions['ChangeLogRow'] | null;
}

export const ChangeLogRow: FC<ChangeLogRowProps> = (
  props: ChangeLogRowProps
): ReactElement => {
  const { changeLogRow, primaryKpiFieldLabel } = props;
  const { t } = useTranslation();
  const allNodes = changeLogRow?.nodes || [];
  const [displayedNodes, setDisplayedNodes] = useState<definitions['Node'][]>(
    allNodes?.slice(0, 5) || []
  );

  const showAllNodes = (): void => {
    if (displayedNodes?.length < allNodes.length) {
      setDisplayedNodes(allNodes || []);
    }
  };

  const getChangeNodeElementBasedOnField = (
    node: definitions['Node']
  ): ReactElement => {
    let nodeFieldName = node?.field?.toLowerCase();
    if (nodeFieldName?.match(/^creative.*image$/i)) {
      nodeFieldName = 'creative image';
    }
    switch (nodeFieldName) {
      case 'campaign state': {
        return (
          <>
            {node?.from && (
              <>
                {node?.to && (
                  <span className={styles.node_from_label}>{t('from')}:</span>
                )}
                <div className={styles.state_chip}>
                  <CampaignStateChip
                    state={node.from as definitions['CampaignState']}
                  />
                </div>
              </>
            )}
            {node?.to ? (
              <>
                {t('to')}
                <div className={styles.state_chip}>
                  <CampaignStateChip
                    state={node.to as definitions['CampaignState']}
                  />
                </div>
              </>
            ) : (
              <span>{t('changelog_removed_label')}</span>
            )}
          </>
        );
      }
      case 'creative image': {
        return (
          <>
            {node?.from && (
              <>
                {node?.to && (
                  <span className={styles.node_from_label}>{t('from')}:</span>
                )}
                <img
                  className={styles.creative_preview_image}
                  src={node?.from}
                  alt="creative from"
                />
              </>
            )}
            {node?.to ? (
              <>
                <span> {t('to')}:</span>
                <img
                  className={styles.creative_preview_image}
                  src={node?.to}
                  alt="creative to"
                />
              </>
            ) : (
              <span>{t('changelog_removed_label')}</span>
            )}
          </>
        );
      }

      case 'last updated at':
      case 'campaign start':
      case 'campaign end':
      case 'created at': {
        return (
          <>
            {node?.from && (
              <>
                {node?.to && (
                  <span className={styles.node_from_label}>{t('from')}:</span>
                )}
                <span className={styles.node_from_value}>
                  {toDateTime(node.from, false, true)}
                </span>
              </>
            )}
            {node?.to ? (
              <>
                <span>{t('to')}:</span>
                <span className={styles.node_to_value}>
                  {toDateTime(node.to, false, true)}
                </span>
              </>
            ) : (
              <span>{t('changelog_removed_label')}</span>
            )}
          </>
        );
      }
      case t('changelog_universe_rules_field_label').toLowerCase(): {
        return (
          <>
            <span>{t('changelog_check_details_label')} </span>
            <Link
              className={styles.text_link}
              to={`${Routes.UNIVERSES}/${node.from}`}
              target="_blank"
            >
              {node.to}
            </Link>
          </>
        );
      }
      default:
        return (
          <>
            {node?.from && (
              <>
                {node?.to && (
                  <span className={styles.node_from_label}>{t('from')}:</span>
                )}
                <span className={styles.node_from_value}>{node.from}</span>
              </>
            )}
            {node?.to ? (
              <>
                <span>{t('to')}:</span>
                <span className={styles.node_to_value}>{node.to}</span>
              </>
            ) : (
              <span>{t('changelog_removed_label')}</span>
            )}
          </>
        );
    }
  };

  return (
    <Grid container className={styles.change_log_row_wrapper}>
      <Grid item xs={1} className={styles.avatar}>
        <Avatar>
          {<SvgIcon component={UserIcon} viewBox={'0 0 32 32'} />}
        </Avatar>
      </Grid>
      <Grid item xs={11} className={styles.content_wrapper}>
        <Grid container>
          <Grid item xs={12} className={styles.title}>
            <h4>
              {changeLogRow?.actor_email} {t('changed')}:
            </h4>
            <Typography variant="body2" color={'textSecondary'}>
              {toDateTime(changeLogRow?.timestamp)}
            </Typography>
          </Grid>
          <Grid item xs={12} className={styles.content}>
            {displayedNodes?.map((node: definitions['Node'], index: number) => (
              <div className={styles.node_item} key={index}>
                <span>{node?.field} </span>
                {getChangeNodeElementBasedOnField(node)}
                {node?.message && <span>{node.message}</span>}
              </div>
            ))}
            {displayedNodes?.length !== allNodes?.length && (
              <span
                className={styles.additional_nodes_count}
                onClick={showAllNodes}
              >
                +{allNodes?.length - displayedNodes?.length}
                <span> {t('more')?.toUpperCase()}</span>
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
      {(changeLogRow?.remaining_budget || changeLogRow?.primary_kpi) && (
        <Grid item xs={12} className={styles.kpi_wrapper}>
          <Grid container justifyContent="center">
            <Grid item xs={5} className={styles.kpi_item}>
              <Typography variant="body2">{t('remaining_budget')}:</Typography>
              <Typography color="textSecondary" variant="body2">
                {changeLogRow?.remaining_budget}
              </Typography>
            </Grid>
            <Grid item xs={5} className={styles.kpi_item}>
              <Typography variant="body2">{primaryKpiFieldLabel}:</Typography>
              <Typography color="textSecondary" variant="body2">
                {changeLogRow?.primary_kpi}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
