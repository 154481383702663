import React from 'react';

import { ReactComponent as InfoIcon } from 'assets/icons/circle-help.svg';

import {
  CardContent,
  Divider,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';

import styles from './OverviewSection.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  topDivider?: boolean;
  infoTooltip?: string;
  bottomDivider?: boolean;
  variant?: any;
}

export const OverviewSection = ({
  label,
  infoTooltip,
  children,
  topDivider = false,
  bottomDivider = true,
  variant = 'body2',
  ...props
}: Props) => {
  return (
    <div {...props}>
      {topDivider && <Divider sx={{ my: (theme) => theme.spacing(1) }} />}
      <CardContent
        sx={{
          px: (theme) => theme.spacing(2),
          py: (theme) => theme.spacing(1),
        }}
      >
        {label && (
          <Stack
            direction="row"
            spacing={0}
            alignItems="center"
            className={styles.overview_section_title}
          >
            <Typography
              sx={{
                color: (theme) =>
                  variant === 'h3' ? '#464646' : theme.palette.text.secondary,
                pb: (theme) => theme.spacing(1),
              }}
              variant={variant}
            >
              {label}
            </Typography>
            {infoTooltip && (
              <Tooltip
                title={
                  <div style={{ whiteSpace: 'pre-line' }}>{infoTooltip}</div>
                }
              >
                <SvgIcon component={InfoIcon} viewBox="0 0 32 32" />
              </Tooltip>
            )}
          </Stack>
        )}
        {children}
      </CardContent>
      {bottomDivider && <Divider sx={{ my: (theme) => theme.spacing(1) }} />}
    </div>
  );
};
