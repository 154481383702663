import { User } from 'oidc-client-ts';
import { AuthConfig } from 'shared/interfaces';

export const getUserFromLocalStorage = (
  authConfig: AuthConfig
): User | null => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${authConfig?.authority}:${authConfig?.client_id}`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
};
