import React, { FC, HTMLAttributes, ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { UserRole } from 'types';

import { selectUser } from 'store/modules/user/selectors';

interface AccessResolverProps extends HTMLAttributes<HTMLHtmlElement> {
  /**
   * Roles on which the condition check will be performed
   */
  requiredRoles: UserRole[];
  /**
   * [OPTIONAL]
   * Set reverseRoleCheck to true in case a reverse check on `requiredRoles` has to be performed.
   * It renders if passed roles are not present with the current user.
   */
  reverseRoleCheck?: boolean;
  /**
   * [OPTIONAL]
   * If, along with the role check, some additional check is needed to be performed, the boolean result of
   * that check can also be passed to be used in conjunction with the role check.
   */
  additionalCondition?: boolean;
  /**
   * [OPTIONAL]
   * Element to be rendered in case the role check condition is not satisfied.
   */
  emptyStatePlaceholder?: ReactElement;
}

export const AccessResolver: FC<AccessResolverProps> = ({
  children,
  requiredRoles,
  reverseRoleCheck = false,
  additionalCondition = true,
  emptyStatePlaceholder,
}: AccessResolverProps): ReactElement => {
  const user = useSelector(selectUser);

  const elementToRender = useCallback(() => {
    const userHasRequiredRoles = requiredRoles.includes(user?.role as UserRole);
    const isRoleCheckTruthy = reverseRoleCheck
      ? !userHasRequiredRoles
      : userHasRequiredRoles;

    if (isRoleCheckTruthy && additionalCondition) {
      return children as ReactElement;
    } else {
      return emptyStatePlaceholder || <></>;
    }
  }, [additionalCondition, children]);

  return elementToRender();
};
