import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useFeature } from 'utils/FeatureFlags';

import { Routes } from 'routes/Routes';

import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import { Button, Grid } from '@mui/material';

import { CampaignDetailsLocationState } from '../CampaignDetails/CampaignDetails';
import CampaignTypeTile from './CampaignTypeTile';

const CONVERSION = 'CONVERSION';
const IMPRESSION = 'IMPRESSION';
const CPC = 'CPC';

type SelectCampaignTypeProps = {
  groupId?: string;
  countryCode?: string;
  handleCancelButtonClick: () => void;
};

export const SelectCampaignType = (props: SelectCampaignTypeProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId, countryCode, handleCancelButtonClick } = props;

  const [selectedCampaignType, setSelectedCampaignType] = useState<string>('');

  const isCPCCampaignEnabled = useFeature('isCPCCampaignEnabled') as boolean;

  const navigateToCampaignCreation = () => {
    setSelectedCampaignType('');
    const locationState = groupId
      ? {
          state: {
            groupId: groupId,
            countryCode: countryCode,
          } as CampaignDetailsLocationState,
        }
      : undefined;
    switch (selectedCampaignType) {
      case CONVERSION:
        navigate(`${Routes.CAMPAIGNS}/create/cpa`, locationState);
        break;
      case IMPRESSION:
        navigate(`${Routes.CAMPAIGNS}/create/cpm`, locationState);
        break;
      case CPC:
        navigate(`${Routes.CAMPAIGNS}/create/cpc`, locationState);
        break;
      default:
        break;
    }
  };

  return (
    <DialogWrapper
      width={isCPCCampaignEnabled ? 1040 : 800}
      headerText={t('campaign_type_selection')}
      subHeaderText={t(
        isCPCCampaignEnabled
          ? 'campaign_type_selection_description'
          : 'campaign_type_selection_description_without_cpc'
      )}
      handleClose={handleCancelButtonClick}
      dialogContent={
        <Grid container>
          <Grid item xs={isCPCCampaignEnabled ? 4 : 6}>
            <CampaignTypeTile
              campaignType={t('campaign_type_conversion')}
              description={t('campaign_type_conversion_description')}
              imgPath="/img/cpa_campaign.png"
              isSelected={selectedCampaignType === CONVERSION}
              handleClick={() => setSelectedCampaignType(CONVERSION)}
            />
          </Grid>
          <Grid item xs={isCPCCampaignEnabled ? 4 : 6}>
            <CampaignTypeTile
              imgPath="/img/cpm_campaign.png"
              description={t('campaign_type_impression_description')}
              campaignType={t('campaign_type_impression')}
              isSelected={selectedCampaignType === IMPRESSION}
              handleClick={() => setSelectedCampaignType(IMPRESSION)}
            />
          </Grid>
          {isCPCCampaignEnabled && (
            <Grid item xs={4}>
              <CampaignTypeTile
                imgPath="/img/cpc_campaign.png"
                description={t('campaign_type_cpc_description')}
                campaignType={t('campaign_type_click')}
                isSelected={selectedCampaignType === CPC}
                handleClick={() => setSelectedCampaignType(CPC)}
              />
            </Grid>
          )}
        </Grid>
      }
      dialogActions={
        <>
          <Button variant="outlined" onClick={handleCancelButtonClick}>
            {t('cancel')}
          </Button>
          <Button
            disabled={selectedCampaignType.length === 0}
            variant="contained"
            color="primary"
            onClick={() => navigateToCampaignCreation()}
            data-testid="confirm-button"
          >
            {t('confirm')}
          </Button>
        </>
      }
    />
  );
};
