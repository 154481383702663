import { useTranslation } from 'react-i18next';

import { tkCampaignState, tkCampaignType } from 'i18n/translationKeyMaps';
import { PageFilter } from 'shared/interfaces/UserPreferences';
import { UserRole } from 'types';
import { definitions } from 'types/api';
import { objectKeys } from 'utils';
import { useFeature } from 'utils/FeatureFlags';

import { AccessResolver } from 'components/Shared/AccessResolver/AccessResolver';
import { ListActionBar } from 'components/Shared/ListActionBar/ListActionBar';
import {
  EntitySelectedFilterType,
  LabelValuePairType,
} from 'components/Shared/ListActionBar/components';

import {
  campaignSortFields,
  campaignStatusValues,
  campaignTypeValues,
} from './CampaignListActionbarValues';

export type CampaignPageFilterType = keyof Pick<
  definitions['Campaign'],
  'type' | 'state'
>;

interface Props {
  activeSearchTerm: string;
  activeSort: {
    field: definitions['CampaignSortField'];
    order: definitions['SortOrder'];
  };
  initialFilters: PageFilter<CampaignPageFilterType>;
  onSearch: (searchTerm: string) => void;
  onSort: (
    sortBy: definitions['CampaignSortField'],
    sortOrder: definitions['SortOrder']
  ) => void;
  onFiltersChange: (filters: EntitySelectedFilterType[]) => void;
  allAdvertisersSelected?: boolean;
  onAllAdvertisersSelectionChange?: (isSelected: boolean) => void;
  supportedStateFilterValues?: LabelValuePairType[];
}

export const CampaignListActionBar = ({
  onSearch,
  onSort,
  activeSort,
  activeSearchTerm,
  onFiltersChange,
  initialFilters,
  allAdvertisersSelected,
  supportedStateFilterValues,
  onAllAdvertisersSelectionChange,
}: Props) => {
  const { t } = useTranslation();

  const isCPCCampaignEnabled = useFeature('isCPCCampaignEnabled') as boolean;

  const initialCampaignFilters: EntitySelectedFilterType[] = objectKeys(
    initialFilters
  )?.map((key) => ({
    field: { label: t(key), value: key },
    activeFilterValues: initialFilters[key].map((filter) => ({
      label:
        key === 'state'
          ? tkCampaignState(filter as definitions['CampaignState'])
          : tkCampaignType(filter as definitions['CampaignType']),
      value: filter,
    })),
  }));

  return (
    <ListActionBar
      initialFilters={initialCampaignFilters}
      onFiltersChanged={onFiltersChange}
    >
      <ListActionBar.Search
        onSearch={(term: string) => {
          if (term !== activeSearchTerm) {
            onSearch(term);
          }
        }}
        activeSearchTerm={activeSearchTerm}
      ></ListActionBar.Search>
      <ListActionBar.Sort
        activeSortField={activeSort.field}
        activeSortOrder={activeSort.order}
        sortFields={campaignSortFields}
        onSort={(sortValue: string, sortOrder: definitions['SortOrder']) => {
          onSort(sortValue as definitions['CampaignSortField'], sortOrder);
        }}
      ></ListActionBar.Sort>

      <ListActionBar.MultiSelectFilter
        showFilterIcon={true}
        filterItem={{
          field: { label: t('state'), value: 'state' },
          filterValues: supportedStateFilterValues || campaignStatusValues,
        }}
        onFilter={() => {}}
      ></ListActionBar.MultiSelectFilter>

      <ListActionBar.MultiSelectFilter
        filterItem={{
          field: { label: t('type'), value: 'type' },
          filterValues: campaignTypeValues.filter((campaignTypeValue) =>
            isCPCCampaignEnabled
              ? campaignTypeValue
              : campaignTypeValue.value !== 'CAMPAIGN_TYPE_CLICK'
          ),
        }}
        onFilter={() => {}}
      ></ListActionBar.MultiSelectFilter>
      <AccessResolver requiredRoles={[UserRole.KEY_ACCOUNT_MANAGER]}>
        {onAllAdvertisersSelectionChange ? (
          <ListActionBar.SwitchFilter
            filterName={{
              label: t('all_advertisers'),
              value: 'all_advertisers',
            }}
            onFilter={onAllAdvertisersSelectionChange}
            alreadyChecked={allAdvertisersSelected}
          ></ListActionBar.SwitchFilter>
        ) : (
          <></>
        )}
      </AccessResolver>
    </ListActionBar>
  );
};
