import { DEFAULT_COUNTRY } from 'shared/constants';
import { DraftUniverse } from 'shared/interfaces/Universe';

export const draftUniverse: DraftUniverse = {
  name: '',
  country_code: DEFAULT_COUNTRY.value,
  build: {
    type: 'UNIVERSE_BUILD_TYPE_BUILDER',
    rule_sets: [],
  },
  state: 'STATE_ACTIVE',
};
