import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { definitions } from 'types/api';

import {
  useGetCampaignGroupByIdQuery,
  useGetCampaignGroupsQuery,
} from 'store/api/endpoints/campaignGroup';
import { selectUser } from 'store/modules/user/selectors';

import { InputValidationMessage, TextInput } from 'components/Shared/CustomMui';

import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';

import styles from './CampaignGroupSelection.module.scss';

interface Props {
  disabled?: boolean;
  campaignGroupId?: string;
  validate: InputValidationMessage;
  country_code?: string;

  onTouch: () => void;
  onChange: (campaignGroupId?: string) => void;
}

export const CampaignGroupSelection = ({
  disabled,
  campaignGroupId,
  validate,
  onChange,
  country_code,
}: Props) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [selectedCampaignGroup, setSelectedCampaignGroup] = useState<
    definitions['CampaignGroup'] | null
  >(null);
  const [userInput, setUserInput] = useState('');

  const { data } = useGetCampaignGroupsQuery(
    {
      advertiser_id: user?.advertiser_id!,
      country_code: country_code,
      search: userInput,
    },
    {
      skip: !user?.advertiser_id,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: campaignGroup } = useGetCampaignGroupByIdQuery(
    campaignGroupId!,
    {
      skip: !campaignGroupId,
    }
  );

  useEffect(() => {
    if (!campaignGroupId) {
      setSelectedCampaignGroup(null);
    } else if (campaignGroup) {
      setSelectedCampaignGroup(campaignGroup?.campaign_group);
    }
  }, [campaignGroupId, campaignGroup]);

  const isOptionEqualToValue = (
    option: definitions['CampaignGroup']
  ): boolean => {
    if (!data) return true;
    return (
      data.campaign_groups?.some(
        (campaignGroup) => campaignGroup.group_id === option.group_id
      ) || false
    );
  };

  return (
    <Autocomplete
      key={campaignGroupId}
      autoComplete
      includeInputInList
      inputValue={userInput}
      value={selectedCampaignGroup}
      getOptionLabel={(option) => option.name || ''}
      options={data?.campaign_groups || []}
      onChange={(
        event: SyntheticEvent,
        value: definitions['CampaignGroup'] | null
      ) => {
        onChange(value?.group_id || '');
        setSelectedCampaignGroup(value);
      }}
      onInputChange={(event: SyntheticEvent, value: string) => {
        setUserInput(value);
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <div ref={params.InputProps.ref}>
          <TextInput
            required
            label={t('campaign_group')}
            value={selectedCampaignGroup?.group_id || ''}
            className={styles.select_input}
            error={validate}
            disabled={disabled}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'campaign-group-select',
            }}
          />
        </div>
      )}
      noOptionsText={t('no_data')}
      renderOption={(props, option: definitions['CampaignGroup']) => (
        <li {...props} className={styles.option}>
          {option.name}
        </li>
      )}
      className={classnames([styles.select], {
        [styles.disabled]: disabled,
      })}
      isOptionEqualToValue={isOptionEqualToValue}
      disabled={disabled}
    />
  );
};
