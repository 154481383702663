import { useTranslation } from 'react-i18next';

import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { DraftUniverse } from 'shared/interfaces';
import { definitions } from 'types/api';
import { toDateTime } from 'utils/time';

import { RuleSet } from 'components/Universes/EditUniverse/RuleSet/RuleSet';

import { Avatar, Grid, Stack, SvgIcon, Typography } from '@mui/material';

import styles from './UniverseChangeLogRow.module.scss';
import { UniverseChangeLogRowItem } from './UniverseChangeLogRowItem';

interface Props {
  changeLogRow: definitions['UniverseChangeLog'];
  universe: DraftUniverse;
}

export const UniverseChangeLogRow = ({ changeLogRow, universe }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={styles.details}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar>
            {<SvgIcon component={UserIcon} viewBox={'0 0 32 32'} />}
          </Avatar>

          <Typography variant="body1">
            {t('updated_by')}: {changeLogRow?.actor_email}
          </Typography>
        </Stack>
        <Typography
          variant="body1"
          color={'textSecondary'}
          className={styles.date}
        >
          {toDateTime(changeLogRow?.updated_at)}
        </Typography>
      </Stack>
      <UniverseChangeLogRowItem
        label={`${t('name')} ${t('changed_to')}`}
        value={changeLogRow?.universe_name}
      />
      {changeLogRow?.rule_sets_no_treatment_percentage === 0 && (
        <UniverseChangeLogRowItem
          label={t('control_group_switched_off_changelog_label')}
          value={' '}
        />
      )}
      {!!changeLogRow?.rule_sets_no_treatment_percentage && (
        <UniverseChangeLogRowItem
          label={t('control_group_switched_on_changelog_label')}
          value={`${changeLogRow?.rule_sets_no_treatment_percentage}%`}
        />
      )}
      <Grid item xs={12} className={styles.content}>
        {changeLogRow?.rule_sets?.map((ruleSet, index) => (
          <div key={index}>
            <RuleSet
              ruleSet={ruleSet}
              index={index}
              isLoading={false}
              universe={universe}
              onUniverseUpdate={() => {}}
              readonly={true}
            ></RuleSet>
          </div>
        ))}
      </Grid>
    </div>
  );
};
