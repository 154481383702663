import { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import theme, { styled } from 'theme';

import { SvgIcon } from '@mui/material';

const EditButtonBase = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  color: theme.palette.primary.main,
}));

type EditButtonProps = {
  selected: boolean;
  url: string;
};

const EditButton = (props: EditButtonProps) => {
  const { selected, url } = props;
  const navigate = useNavigate();

  const color = useMemo(
    () => (selected ? theme.palette.primary.main : theme.palette.text.primary),
    [selected]
  );

  return (
    <EditButtonBase onClick={() => navigate(url)}>
      <SvgIcon
        component={EditIcon}
        sx={{
          color: color,
        }}
        viewBox={'0 0 32 32'}
      />
    </EditButtonBase>
  );
};

export default EditButton;
