import { ChangeEvent, FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedCampaign } from 'shared/interfaces/Campaign';
import { useFeature } from 'utils/FeatureFlags';

import { TextInput } from 'components/Shared/CustomMui';
import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
} from '@mui/material';

import styles from './CloneCampaign.module.scss';

interface Props {
  onClose: () => void;
  campaignToClone: Partial<ExtendedCampaign>;
  cloneConfirmationHandler: (
    cloneCampaignName: string,
    isABTest: boolean
  ) => void;
}

export const CloneCampaign: FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { campaignToClone, onClose, cloneConfirmationHandler } = props;
  const [cloneCampaignName, setCloneCampaignName] = useState(
    `Copy of - ${campaignToClone?.name}`
  );
  const [isABTest, setIsABTest] = useState(false);

  const canDisplayABTestingSection =
    useFeature('isCreativeABTestingEnabled') &&
    campaignToClone?.ab_testing_variant === 'NOT_DEFINED';

  const isCampaignValidForCloning =
    !!campaignToClone?.budget &&
    !!campaignToClone?.campaign_start &&
    !!campaignToClone?.campaign_end &&
    !!campaignToClone?.promoted_products?.length &&
    !!campaignToClone?.universe_id;

  return (
    <DialogWrapper
      width={600}
      headerText={t('clone_campaign_confirmation_header', {
        campaignName: campaignToClone?.name,
      })}
      subHeaderText={t('clone_campaign_confirmation_message', {
        campaignName: campaignToClone?.name,
      })}
      handleClose={onClose}
      showCloseIcon={false}
      dialogContent={
        <Grid container>
          <Grid item xs={12}>
            <TextInput
              label={t('name')}
              value={cloneCampaignName}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setCloneCampaignName(event?.target?.value)
              }
            />
          </Grid>
          {canDisplayABTestingSection && (
            <Grid item xs={12}>
              <div className={styles.label}>
                {t('creative_ab_testing_label')}
              </div>
              <FormControlLabel
                className={styles.checkbox}
                control={
                  <Checkbox
                    onChange={(e) => setIsABTest(e.target.checked)}
                    disabled={!isCampaignValidForCloning}
                    data-testid="ab-test-checkbox"
                  />
                }
                label={t('creative_ab_testing_checkbox')}
              />
              {!isCampaignValidForCloning && (
                <FormHelperText className={styles.helper_text}>
                  {t('creative_ab_testing_validation')}
                </FormHelperText>
              )}
            </Grid>
          )}
        </Grid>
      }
      dialogActions={
        <>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            disabled={!cloneCampaignName}
            variant="contained"
            color="primary"
            onClick={() =>
              cloneConfirmationHandler(cloneCampaignName, isABTest)
            }
            data-testid="confirm-clone-button"
          >
            {t('clone_campaign')}
          </Button>
        </>
      }
    />
  );
};
