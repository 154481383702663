import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as SyncIcon } from 'assets/icons/sync.svg';
import classNames from 'classnames';
import {
  CSP_EXECUTION_POLLING_INTERVAL,
  CSP_EXECUTION_POLLING_TIMEOUT_INTERVAL,
} from 'shared/constants/campaign-success-prediction';
import { CSP_EXECUTION_ALLOWED_UNIVERSE_BUILD_TYPES } from 'shared/constants/csp-execution-allowed-universe-build-types';
import { definitions } from 'types/api';

import { useGetCSPByExecutionIdQuery } from 'store/api/endpoints/campaignSuccessPrediction';
import { DraftCampaignType } from 'store/modules/campaign/helpers';
import { selectDraftHasChanges } from 'store/modules/campaign/selectors';

import { Stack, SvgIcon, Typography } from '@mui/material';

import styles from './CampaignSuccessPrediction.module.scss';

interface Props {
  campaign: DraftCampaignType;
  calculationMetricLabel?: string;
  executionId?: string;
  calculationStarted: () => void;
  calculationFinished: (
    oldValue?: string,
    newValue?: string,
    status?: string
  ) => void;
  universeBuildType?: definitions['UniverseBuildType'];
}

export const CampaignSuccessPrediction = ({
  campaign,
  calculationMetricLabel,
  executionId,
  calculationStarted,
  calculationFinished,
  universeBuildType,
}: Props) => {
  const { t } = useTranslation();
  const hasChanges = useSelector(selectDraftHasChanges);

  const isCalculationExecutionAllowed =
    !campaign.universe ||
    CSP_EXECUTION_ALLOWED_UNIVERSE_BUILD_TYPES.includes(
      campaign.universe?.build?.type
    );

  const [executionStatus, setExecutionStatus] = useState<
    definitions['CampaignSuccessPredictionState'] | 'IDLE'
  >('IDLE');
  const executionStatusRef = useRef(executionStatus);
  executionStatusRef.current = executionStatus;
  let timeoutRef: NodeJS.Timeout | undefined;

  const [predictionValue, setPredictionValue] = useState<string>('');
  const [isPollingTimedOut, setIsPollingTimedOut] = useState<boolean>(false);

  const getPollingInterval = (): number => {
    if (executionStatus === 'PENDING' && !isPollingTimedOut) {
      return CSP_EXECUTION_POLLING_INTERVAL;
    }
    return 0;
  };

  const {
    data: successPrediction,
    isSuccess,
    startedTimeStamp,
  } = useGetCSPByExecutionIdQuery(
    { campaignId: campaign.id, executionId: executionId! },
    {
      skip:
        !executionId ||
        executionStatus === 'IDLE' ||
        !isCalculationExecutionAllowed,
      // poll prediction only during the pending phase
      pollingInterval: getPollingInterval(),
      refetchOnMountOrArgChange: true,
    }
  );

  const isCalculationFinished = (state: string): boolean =>
    ['SUCCEEDED', 'FAILED'].includes(state);

  /* on component mount if there is an executionId, 
  reset the prediction value and set the status to PENDING to start polling.
  if there is no executionId, set the status to IDLE and clear the prediction value */
  useEffect(() => {
    if (executionId) {
      setIsPollingTimedOut(false);
      calculationStarted();
      setPredictionValue('');
      setExecutionStatus('PENDING');
    } else {
      setPredictionValue('');
      setExecutionStatus('IDLE');
    }
  }, [executionId]);

  useEffect(() => {
    if (executionStatus === 'PENDING') {
      timeoutRef = setTimeout(() => {
        if (executionStatusRef.current === 'PENDING') {
          setIsPollingTimedOut(true);
          calculationFinished(predictionValue, '');
          setExecutionStatus('IDLE');
        }
      }, CSP_EXECUTION_POLLING_TIMEOUT_INTERVAL);
    } else if (isCalculationFinished(executionStatus)) {
      clearTimeout(timeoutRef);
      executionStatusRef.current = 'IDLE';
    }
  }, [executionStatus]);

  useEffect(() => {
    if (isCalculationFinished(successPrediction?.state as string)) {
      calculationFinished(
        predictionValue,
        `${successPrediction?.lower_value} - ${successPrediction?.upper_value}`,
        successPrediction?.state
      );
      setExecutionStatus('IDLE');
    }
    if (successPrediction && executionId) {
      setExecutionStatus(successPrediction.state);
      if (successPrediction.lower_value && successPrediction.upper_value)
        setPredictionValue(
          `${getNumberFormatForDE(
            successPrediction.lower_value
          )} - ${getNumberFormatForDE(successPrediction.upper_value)}`
        );
    }
  }, [successPrediction?.state]);

  useEffect(() => {
    if (successPrediction) setExecutionStatus(successPrediction.state);
  }, [startedTimeStamp]);

  const getNumberFormatForDE = (
    value: string,
    options?: Intl.NumberFormatOptions | undefined
  ): string => {
    return new Intl.NumberFormat('de-DE', options).format(Number(value));
  };

  const calculationHintTranslationKey =
    campaign.type === 'CAMPAIGN_TYPE_CONVERSION'
      ? 'click_to_calculate_cpa'
      : 'click_to_calculate_cpm';

  const isExecutionSuccessfulOrIdle =
    (executionStatus === 'SUCCEEDED' || executionStatus === 'IDLE') &&
    isCalculationExecutionAllowed;

  const noExecutionTranslationKey =
    universeBuildType === 'UNIVERSE_BUILD_TYPE_EXTERNAL'
      ? 'csp_no_estimation_for_external_universe'
      : universeBuildType === 'UNIVERSE_BUILD_TYPE_IMPORT'
      ? 'csp_no_estimation_for_imported_universe'
      : undefined;

  return (
    <>
      {campaign.id && isSuccess && isExecutionSuccessfulOrIdle && (
        <Typography
          sx={{
            pb: (theme) => theme.spacing(0.5),
          }}
          variant="body2"
          color={'textSecondary'}
        >
          {calculationMetricLabel}
        </Typography>
      )}
      {(executionStatus === 'PENDING' ||
        executionStatus === 'FAILED' ||
        executionStatus === 'TIMEOUT') &&
        isCalculationExecutionAllowed && (
          <Stack
            direction="row"
            className={classNames({
              [styles.in_progress]: executionStatus === 'PENDING',
            })}
            spacing={0.5}
          >
            <SvgIcon component={SyncIcon} viewBox={'0 0 36 36'} />
            {executionStatus === 'PENDING' && <span>{t('in_progress')}..</span>}
            {executionStatus === 'FAILED' && (
              <span>
                {t(
                  successPrediction?.error_reason ===
                    'CAMPAIGN_SUCCESS_PREDICTION_ERROR_NO_SALES_DATA'
                    ? 'no_sales_data'
                    : 'execution_failed'
                )}
              </span>
            )}
            {executionStatus === 'TIMEOUT' && (
              <span>{t('execution_failed')}</span>
            )}
          </Stack>
        )}
      {isExecutionSuccessfulOrIdle && (
        <Stack direction="row" className={styles.value} spacing={0.5}>
          <div
            className={classNames(styles.value, {
              [styles.not_valid]: hasChanges || !predictionValue,
            })}
          >
            {predictionValue ? (
              predictionValue
            ) : (
              <span className={styles.hint}>
                {t(calculationHintTranslationKey)}
              </span>
            )}
          </div>
        </Stack>
      )}
      {noExecutionTranslationKey && (
        <span className={styles.hint}>{t(noExecutionTranslationKey)}</span>
      )}
    </>
  );
};
