import {
  Dispatch,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  PayloadAction,
} from '@reduxjs/toolkit';
import i18n from 'i18next';

import { Routes } from 'routes/Routes';

import { addNotification } from 'store/modules/notifications/actions';

const ERROR_NOTIFICATION_DURATION = 7000;

export type PayloadActionType = PayloadAction<{
  status: number;
  data: { code: string; message: string; silentNotification: boolean };
}>;

export const errorHandlerMiddleware: Middleware =
  (api: MiddlewareAPI) => (next: Dispatch) => (action: PayloadActionType) => {
    if (isRejectedWithValue(action)) {
      let errorMessage = i18n.t('error_general');

      if (action?.payload?.status === 401) {
        window.location.href =
          window.location.origin +
          Routes.LOGIN +
          '?redirect_uri=' +
          window.location.pathname;
      } else if (
        action?.payload?.status >= 400 &&
        action?.payload?.status < 500
      ) {
        errorMessage = action.payload?.data?.message;
      }

      if (!action?.payload?.data?.silentNotification)
        api.dispatch(
          addNotification({
            // use the same id for all general errors to prevent multiple notifications popping up
            id:
              errorMessage === i18n.t('error_general')
                ? 'error_general'
                : undefined,
            text: errorMessage || '',
            type: 'error',
            duration: ERROR_NOTIFICATION_DURATION,
          })
        );
    }

    return next(action);
  };
