import {
  ChangeEvent,
  FC,
  MouseEvent,
  MutableRefObject,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down-small.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as XSmallIcon } from 'assets/icons/x-small.svg';
import classnames from 'classnames';
import { useElementOnScreen } from 'hooks';
import debounce from 'lodash/debounce';
import { DEBOUNCE_TIME } from 'shared/constants';
import { upsertObjectInArray } from 'utils';

import { useListActionBar } from 'components/Shared/ListActionBar/hooks/useListActionBar';

import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popper,
  Stack,
  SvgIcon,
  TextField,
} from '@mui/material';

import commonFilterStyles from '../Filter.module.scss';
import {
  EntityFilterType,
  EntitySelectedFilterType,
  LabelValuePairType,
} from '../types';
import styles from './AutoCompleteFilter.module.scss';

export interface AutoCompleteFilterProps {
  showFilterIcon: boolean;
  filterItem: EntityFilterType;
  onInputChange: (input: string) => void;
  onFilter: (values: string[]) => void;
  onLoadMoreItems?: () => void;
  inputFieldLabel?: string;
}

export const AutoCompleteFilter: FC<AutoCompleteFilterProps> = ({
  filterItem,
  onFilter,
  onInputChange,
  showFilterIcon,
  inputFieldLabel,
  onLoadMoreItems,
}: AutoCompleteFilterProps): ReactElement => {
  const { t } = useTranslation();
  const {
    selectedFilters,
    setSelectedFilters,
    setUpdatedFilters,
    updatedFilters,
  } = useListActionBar();
  const [inputValue, setInputValue] = useState<string>('');
  const [checkboxSelectedItems, setCheckboxSelectedItems] = useState<
    LabelValuePairType[]
  >([]);

  const debouncedOnInputChange = useMemo(
    () => debounce(onInputChange, DEBOUNCE_TIME),
    []
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [containerRef, isVisible] = useElementOnScreen({}, false);

  useEffect(() => {
    if (isVisible && onLoadMoreItems) {
      onLoadMoreItems();
    }
  }, [isVisible]);

  useEffect(() => {
    if (filterItem?.activeFilterValues?.length && open) {
      setSelectedFilters([
        {
          field: filterItem.field,
          activeFilterValues: filterItem?.activeFilterValues,
        },
      ]);
      setCheckboxSelectedItems(filterItem?.activeFilterValues);
    }
    if (!open) {
      setInputValue('');
    }
  }, [open]);

  useEffect(() => {
    if (filterItem?.activeFilterValues?.length) {
      setSelectedFilters([
        {
          field: filterItem.field,
          activeFilterValues: filterItem?.activeFilterValues,
        },
      ]);
      setCheckboxSelectedItems(filterItem?.activeFilterValues);
    }
  }, [filterItem?.activeFilterValues]);

  const selectedFilterValues =
    selectedFilters.filter(
      (selectedFilter: EntitySelectedFilterType) =>
        selectedFilter.field.value === filterItem?.field.value
    )[0]?.activeFilterValues || [];

  useEffect(() => {
    debouncedOnInputChange(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (updatedFilters !== undefined) {
      setCheckboxSelectedItems(selectedFilterValues);
      onFilter(
        selectedFilterValues?.map(
          (selectedFilter: LabelValuePairType) => selectedFilter.value
        )
      );
      setUpdatedFilters(undefined);
    }
  }, [updatedFilters]);

  const handlePopupTriggerClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  const handleClose = (): void => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setCheckboxSelectedItems([]);
    setAnchorEl(null);
  };

  const isItemSelected = (item: LabelValuePairType): boolean => {
    return (
      checkboxSelectedItems?.findIndex(
        (selectedItem: LabelValuePairType) => selectedItem.value === item.value
      ) >= 0
    );
  };

  const applyFilters = (): void => {
    const filters = upsertObjectInArray(
      [...selectedFilters],
      {
        field: filterItem.field,
        activeFilterValues: checkboxSelectedItems,
      },
      'field'
    );
    setSelectedFilters(filters);
    setUpdatedFilters(filters);
    handleClose();
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      className={commonFilterStyles.filtering_wrapper}
    >
      <Box className={styles.filter_wrapper}>
        {showFilterIcon && (
          <SvgIcon
            component={FilterIcon}
            className={styles.icon}
            viewBox={'0 0 32 32'}
          />
        )}
        <Button
          className={classnames(styles.filter_trigger_button, {
            [styles.active]: open,
          })}
          disableRipple
          aria-describedby={'trigger-button'}
          onClick={handlePopupTriggerClick}
        >
          <span>{filterItem?.field?.label}</span>
          <SvgIcon component={ArrowDownIcon} viewBox={'0 0 32 32'} />
        </Button>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={styles.popper}
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <TextField
              autoFocus
              value={inputValue}
              type="text"
              variant="standard"
              size="small"
              className={styles.search_bar}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setInputValue(event.target.value)
              }
              placeholder={inputFieldLabel || t('search') || 'Search'}
              InputProps={{
                'aria-label': inputFieldLabel || t('search') || 'Search',
                startAdornment: (
                  <InputAdornment position="start">
                    {inputValue ? (
                      <SvgIcon
                        component={XSmallIcon}
                        viewBox={'0 0 32 32'}
                        onClick={() => {
                          setInputValue('');
                        }}
                      />
                    ) : (
                      <SvgIcon
                        component={SearchIcon}
                        viewBox={'0 0 28 28'}
                        className={styles.search_icon}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <MenuItem value={''} disabled={true}>
              {t('filter_by')}
            </MenuItem>
            <div className={styles.options_wrapper}>
              {filterItem?.filterValues?.map((item: LabelValuePairType) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  disableRipple
                  onClick={() =>
                    setCheckboxSelectedItems(
                      (previousValues: LabelValuePairType[]) => {
                        return isItemSelected(item)
                          ? previousValues.filter(
                              (previousValue) =>
                                previousValue.value !== item.value
                            )
                          : [...previousValues, item];
                      }
                    )
                  }
                  className={classnames([commonFilterStyles.filter_checkbox], {
                    [commonFilterStyles.selected]: isItemSelected(item),
                  })}
                >
                  <ListItemIcon>
                    <Checkbox checked={isItemSelected(item)} disableRipple />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      item.label +
                      (filterItem?.showItemCount
                        ? ` (${item?.itemCount || 0})`
                        : '')
                    }
                  />
                </MenuItem>
              ))}
              <MenuItem ref={containerRef as MutableRefObject<null>}></MenuItem>
            </div>
            <Stack
              className={commonFilterStyles.filter_actions}
              spacing={1}
              direction="row"
            >
              <Button
                disableRipple
                color="secondary"
                variant="outlined"
                onClick={handleClose}
              >
                {t('cancel')}
              </Button>
              <Button disableRipple variant="contained" onClick={applyFilters}>
                {t('confirm')}
              </Button>
            </Stack>
          </div>
        </ClickAwayListener>
      </Popper>
    </Stack>
  );
};
