import { isWithinInterval } from 'date-fns';

export default function isWithinIntervalUTC(
  date: Date,
  start: Date,
  end: Date
): boolean {
  // shift timezone to UTC
  const startUTC = new Date(
    start.getUTCFullYear(),
    start.getUTCMonth(),
    start.getUTCDate()
  );
  const endUTC = new Date(
    end.getUTCFullYear(),
    end.getUTCMonth(),
    end.getUTCDate()
  );

  // apply interval
  return isWithinInterval(date, {
    start: startUTC,
    end: endUTC,
  });
}
