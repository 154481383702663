import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { definitions } from 'types/api';
import { useFeature } from 'utils/FeatureFlags';

import { useStoreDispatch } from 'store';
import { selectDraftCampaign } from 'store/modules/campaign/selectors';
import {
  addCampaignAlert,
  removeCampaignAlert,
  updateDraft,
} from 'store/modules/campaign/slice';
import { selectChannels } from 'store/modules/settings/selectors';
import { selectUser } from 'store/modules/user/selectors';

import { CampaignSectionProps } from 'components/Campaigns/CampaignDetails/CampaignDetails';
import { SelectInput } from 'components/Shared/CustomMui';
import { AlertProps } from 'components/Shared/CustomMui/Alert/Alert';
import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import { Button, FormControl, SelectChangeEvent } from '@mui/material';

import { CampaignSectionWrapper } from './CampaignSectionWrapper';

interface Props extends CampaignSectionProps {
  campaignChannels: definitions['CampaignChannel'][];
  campaignType: definitions['CampaignType'];
  countryCode: string;
}

const Channels: FC<Props> = (props: Props) => {
  const { campaignChannels, formState, validate, campaignType, countryCode } =
    props;
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const user = useSelector(selectUser);
  const networkChannels = useSelector(selectChannels);
  const draftCampaign = useSelector(selectDraftCampaign);

  const validationMessage = validate?.('channels');
  const disabledChannelIds = Object.keys(useFeature('disabledChannels'));
  const [newChannelToSelect, setNewChannelToSelect] = useState<string>(''); // Only set when changing channel, only if product or universe are selected.
  const internalAdvertiserOnlyChannels = Object.keys(
    useFeature('internalAdvertiserOnlyChannels')
  );
  const schwarzInternalAdvertiserIds = Object.keys(
    useFeature('schwarzInternalAdvertiserIds')
  );
  const isChannelSelectionDisabled =
    formState.readonlyFields.includes('Channels');

  useEffect(() => {
    if (!campaignChannels?.length) {
      handleChannelChange(countryChannels()[0]?.id);
    }
  }, [countryCode]);

  const countryChannels = useCallback(() => {
    return networkChannels.filter(
      (channel) =>
        channel.supported_countries?.includes(countryCode) ||
        channel.supported_countries_test?.includes(countryCode)
    );
  }, [countryCode]);

  useEffect(() => {
    // Preselect the first channel if there are no campaign channels passed.
    if (!campaignChannels.length && networkChannels?.length) {
      dispatch(
        updateDraft({
          channels: [{ channel_id: networkChannels[0]?.id }],
          universe_id: '',
          creatives: [],
        })
      );
    }
  }, []);

  const isChannelDisabled = (networkChannelId: string): boolean =>
    formState.readonlyFields.includes('Channels') ||
    disabledChannelIds.includes(networkChannelId);

  const handleChannelChange = (channelId: string): void => {
    if (
      draftCampaign?.promoted_products?.length ||
      draftCampaign?.universe_id
    ) {
      setNewChannelToSelect(channelId || '');
    } else {
      updateCampaignDraftWithChannel(channelId || '');
    }
  };

  const updateCampaignDraftWithChannel = (
    channelId: string = newChannelToSelect
  ): void => {
    const selectedChannel = networkChannels.find(
      (channel) => channel.id === channelId
    );

    dispatch(
      updateDraft({
        channels: channelId ? [{ channel_id: channelId }] : [],
        universe_id: '',
        promoted_products: [],
        additionally_promoted_products: [],
        creatives: [],
      })
    );
    const pushModeChannelInfo: AlertProps = {
      text: t('push_mode_info'),
      severity: 'info',
    };

    dispatch(
      selectedChannel?.channel_type === 'CHANNEL_TYPE_PUSH_MODE'
        ? addCampaignAlert(pushModeChannelInfo)
        : removeCampaignAlert(pushModeChannelInfo)
    );
  };

  const channelsList = (
    isChannelSelectionDisabled
      ? networkChannels.filter((c) => c.id === campaignChannels[0]?.channel_id) // if channel selection is disabled, show the selected network channel
      : countryChannels().filter((networkChannel) => {
          return schwarzInternalAdvertiserIds.includes(
            user?.advertiser_id as string
          )
            ? true
            : !internalAdvertiserOnlyChannels.includes(networkChannel?.id) ||
                isChannelSelectionDisabled; //PACMAN-1244: if channel selection is disabled, it's safe to show dummy channels.
        })
  ).map((item: definitions['Channel']) => ({
    label: item.name,
    value: item.id,
    disabled: isChannelDisabled(item?.id),
  }));

  return (
    <>
      <CampaignSectionWrapper
        header={t('channels')}
        subHeader={
          campaignType !== 'CAMPAIGN_TYPE_CLICK'
            ? t('subheader_campaign_channels')
            : t('subheader_cpc_campaign_channels')
        }
        error={validationMessage}
        required
      >
        <FormControl>
          <SelectInput
            data-testid={'channel-selector'}
            label={t('channel_selection')}
            value={campaignChannels[0]?.channel_id || ''}
            items={channelsList}
            onChange={(event: SelectChangeEvent) => {
              handleChannelChange(event.target.value);
            }}
            disabled={isChannelSelectionDisabled}
            required={false}
          />
        </FormControl>
      </CampaignSectionWrapper>

      {!!newChannelToSelect && (
        <DialogWrapper
          width={550}
          showCloseIcon={false}
          headerText={t('confirm_channel_change_header')}
          handleClose={() => setNewChannelToSelect('')}
          dialogContent={<i>{t('confirm_channel_change_message')}</i>}
          dialogActions={
            <>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setNewChannelToSelect('')}
              >
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  updateCampaignDraftWithChannel();
                  setNewChannelToSelect('');
                }}
              >
                {t('confirm')}
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

export default memo(Channels);
