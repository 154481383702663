import React, { ReactElement } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import classnames from 'classnames';

import { FormHelperText, InputAdornment, OutlinedInput } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

import { InputS } from './Form.style';
import { FormError } from './FormError';
import { FormLabel } from './FormLabel';
import { InputValidationMessage } from './TextInput';

const NumberFormatCustom = React.forwardRef((props: any, ref) => {
  const { onChange, ...rest } = props;
  const thousandSeparator =
    typeof rest.thousandSeparator === 'undefined'
      ? '.'
      : rest.thousandSeparator;
  return (
    <NumberFormat
      {...rest}
      allowLeadingZeros={false}
      allowNegative={false}
      decimalSeparator={','}
      getInputRef={ref}
      isAllowed={(values) => {
        const { value } = values;
        return Number(value) <= props.max;
      }}
      isNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
    />
  );
});
interface NumberInputBaseProps extends NumberFormatProps {
  disabled?: boolean;
  error?: InputValidationMessage;
  max?: number;
  sx?: SxProps<Theme>;
  suffixIcon?: ReactElement;
}
export const NumberInputBase: React.FC<NumberInputBaseProps> = (props) => {
  const {
    decimalScale = 0,
    disabled = false,
    error,
    max = 9999999999,
    onBlur,
    onChange,
    sx,
    value,
    suffixIcon,
    prefix,
    suffix,
    ...rest
  } = props;
  return (
    <OutlinedInput
      className="NumberInputBase-root"
      disabled={disabled}
      error={!!error}
      fullWidth
      inputComponent={NumberFormatCustom}
      inputProps={{
        ...rest,
        ...(!!prefix && { prefix: `${prefix} ` }),
        ...(!!suffix && { suffix: ` ${suffix}` }),
        decimalScale,
        max,
      }}
      onBlur={onBlur}
      onChange={onChange}
      sx={sx}
      value={value}
      endAdornment={
        suffixIcon && (
          <InputAdornment position="end">{suffixIcon}</InputAdornment>
        )
      }
    />
  );
};
interface NumberInputProps extends NumberInputBaseProps {
  className?: string;
  label?: string;
  helper?: string;
  id?: string;
  width?: number | string;
  sx?: SxProps<Theme>;
}
export const NumberInput: React.FC<NumberInputProps> = (props) => {
  const { className, width, id, error, label, helper, sx, ...rest } = props;
  return (
    <InputS
      width={width}
      sx={{ ...sx }}
      className={classnames('NumberInput-root', className)}
    >
      <FormLabel id={id} label={label} required={rest.required} />
      <NumberInputBase id={id} error={!!error} {...rest} />
      {error && typeof error === 'string' && <FormError>{error}</FormError>}
      {!error && helper && <FormHelperText>{helper}</FormHelperText>}
    </InputS>
  );
};
