import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as InfoIcon } from 'assets/icons/circle-information.svg';
import isEqual from 'lodash/isEqual';
import { UNIVERSE_RULE_SET_LIMIT } from 'shared/constants';
import { DraftUniverse } from 'shared/interfaces/Universe';
import { UserRole } from 'types';
import { useFeature } from 'utils/FeatureFlags';

import { useGetRetailersQuery } from 'store/api/endpoints/retailer';
import { useLazyGetEstimationQuery } from 'store/api/endpoints/universes';
import { selectAdvertiser } from 'store/modules/settings/selectors';
import {
  validateAttributesError,
  validateGtinsAttributesAndSearchError,
  validatePeriodError,
} from 'store/modules/universes/validations';

import { Country } from 'components/Shared/';
import { AccessResolver } from 'components/Shared/AccessResolver/AccessResolver';
import { SelectInput, TextInput } from 'components/Shared/CustomMui';
import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import {
  Checkbox,
  FormControlLabel,
  SelectChangeEvent,
  Slider,
  Stack,
  SvgIcon,
} from '@mui/material';
import Button from '@mui/material//Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { EMPTY_RULE_SET, isBuilderConditionValid } from '../utils';
import styles from './EditUniverse.module.scss';
import { RuleSet } from './RuleSet/RuleSet';
import { StaticUniverseUploader } from './StaticUniverseUploader/StaticUniverseUploader';
import { UniverseOverview } from './UniverseOverview/UniverseOverview';
import { UniversePageHeader } from './UniversePageHeader/UniversePageHeader';

interface Props {
  onSave: (universe: DraftUniverse) => void;
  draftUniverse: DraftUniverse;
  isLoading: boolean;
  isSaving?: boolean;
  isCountryReadOnly?: boolean;
}

const EditUniverse: FC<Props> = ({
  isSaving = false,
  onSave,
  isCountryReadOnly,
  draftUniverse,
}: Props) => {
  const { t } = useTranslation();
  const advertiser = useSelector(selectAdvertiser);
  const { data: retailers = [] } = useGetRetailersQuery(undefined);

  const [universe, setUniverse] = useState<DraftUniverse>(draftUniverse);

  const formHasChanges = (): boolean => {
    // Check if form has changes by comparing editable fields of draftUniverse and universe
    return (
      !isEqual(universe.name, draftUniverse?.name) ||
      !isEqual(universe.country_code, draftUniverse?.country_code) ||
      !isEqual(universe.retailer_id, draftUniverse?.retailer_id) ||
      !isEqual(
        universe.build?.rule_sets_no_treatment_percentage,
        draftUniverse?.build?.rule_sets_no_treatment_percentage
      ) ||
      !isEqual(universe.build?.rule_sets, draftUniverse?.build?.rule_sets)
    );
  };

  const [selectedCountry, setSelectedCountry] = useState<string>(
    universe.country_code!
  );
  const [newRetailer, setNewRetailer] = useState<string>();

  const disabledRetailerIds = Object.keys(useFeature('disabledRetailers'));

  const [getEstimationTrigger] = useLazyGetEstimationQuery();

  const isRuleSetLimitReached =
    (universe?.build?.rule_sets?.length || 0) >= UNIVERSE_RULE_SET_LIMIT;

  const builderConditionErrors =
    !universe.build?.rule_sets?.length ||
    (!!universe.build?.rule_sets?.length &&
      universe.build.rule_sets.some((builder_conditions) => {
        return builder_conditions?.builder_conditions?.some(
          (condition) =>
            validatePeriodError(condition.period, condition.period_type) ||
            (!condition.attribute_id &&
              validateGtinsAttributesAndSearchError(
                condition.gtins ? condition.gtins : [],
                condition.attributes ? condition.attributes : [],
                condition.search_query ? condition.search_query : ''
              )) ||
            validateAttributesError(
              condition.attribute_id,
              condition.metadata_ids
            )
        );
      }));

  const isUniverseNameMissing =
    universe.scope === 'UNIVERSE_SCOPE_GLOBAL' && !universe.name?.length;
  const isRuleSetMissing =
    universe.build?.type === 'UNIVERSE_BUILD_TYPE_BUILDER' &&
    !universe.build?.rule_sets?.length;
  const isImportShopperIdsMissing =
    universe.build?.type === 'UNIVERSE_BUILD_TYPE_IMPORT' &&
    !universe.build.import_shopper_ids?.length;
  const isRetailerIdMissing = !universe?.retailer_id;
  const hasEmptyRuleSet = universe.build?.rule_sets?.some(
    (ruleSet) => !ruleSet.builder_conditions?.length
  );
  const hasInvalidRuleSetOrConditions =
    universe.build?.type === 'UNIVERSE_BUILD_TYPE_BUILDER'
      ? hasEmptyRuleSet || isRuleSetMissing || builderConditionErrors
      : false;

  const saveDisabled =
    !formHasChanges() ||
    isSaving ||
    isRetailerIdMissing ||
    isUniverseNameMissing ||
    isImportShopperIdsMissing ||
    hasInvalidRuleSetOrConditions;

  // filter retailers based on selected country
  const filteredRetailers = retailers.filter(
    (retailer) =>
      retailer.supported_countries?.includes(selectedCountry) ||
      retailer.supported_countries_test?.includes(selectedCountry)
  );

  useEffect(() => {
    setUniverse({
      ...universe,
      advertiser_id: advertiser?.id,
      network_id: advertiser?.network_id,
    });
  }, [advertiser]);

  useEffect(() => {
    async function _fetchEstimation() {
      // do not fetch estimations if build already contains score
      if (isEqual(draftUniverse?.build, universe.build)) {
        return;
      }

      setUniverse((universe) => ({
        ...universe,
        build: {
          ...universe.build,
          score: '',
        },
      }));

      if (
        !universe.build?.rule_sets ||
        universe.build.rule_sets.length === 0 ||
        builderConditionErrors
      ) {
        return;
      }
      const { score } = await getEstimationTrigger({
        rule_sets: universe.build.rule_sets,
        retailer_id: universe.retailer_id as string,
        country_code: universe.country_code as string,
        builder_conditions: [],
      }).unwrap();
      setUniverse((universe) => ({
        ...universe,
        build: {
          ...universe.build,
          score,
        },
      }));
    }
    _fetchEstimation();
  }, [setUniverse, builderConditionErrors, universe.build?.rule_sets]);

  const isBuilderType = universe.build?.type === 'UNIVERSE_BUILD_TYPE_BUILDER';

  const addRuleSetHandler = (): void => {
    if (!isRuleSetLimitReached) {
      setUniverse((universe: DraftUniverse) => ({
        ...universe,
        build: {
          ...universe.build,
          rule_sets: [...(universe.build?.rule_sets || []), EMPTY_RULE_SET],
        },
      }));
    }
  };

  const setRetailerAndClearRuleSet = (retailerId: string): void => {
    setUniverse({
      ...universe,
      retailer_id: retailerId,
      // clear builder conditions as products and attributes are retailer-dependent
      build: {
        ...universe.build,
        rule_sets: [EMPTY_RULE_SET],
      },
    });
  };

  return (
    <>
      <UniversePageHeader universe={universe} />
      <Grid
        container
        className={styles.universe_description_fields}
        justifyContent={'space-between'}
      >
        <Grid item xs={7} className={styles.input_fields_wrapper}>
          <Grid item xs={6}>
            <TextInput
              required
              id="name"
              className={styles.form_field}
              label={t('name')}
              disabled={isSaving}
              onChange={(event) => {
                setUniverse({
                  ...universe,
                  name: event.target.value,
                });
              }}
              value={universe.name || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <Country
              required
              countryCode={selectedCountry}
              onCountryCodeChange={(countryCode: string) => {
                setSelectedCountry(countryCode);
                setUniverse({
                  ...universe,
                  country_code: countryCode,
                  retailer_id: '',
                  // clear builder conditions as products and attributes retailer-dependent
                  build: {
                    ...universe.build,
                    rule_sets: [],
                  },
                });
              }}
              disabled={!!universe.id || isCountryReadOnly}
            />
          </Grid>

          <Grid item xs={12} className={styles.country_wrapper}>
            <SelectInput
              className={styles.form_field}
              htmlFor="Retailer"
              label={t('retailer')}
              value={
                filteredRetailers.length && universe.retailer_id
                  ? universe?.retailer_id
                  : ''
              }
              onChange={(event: SelectChangeEvent) => {
                if (
                  !!universe?.retailer_id &&
                  universe?.build?.rule_sets?.some(
                    (ruleSet) =>
                      !!ruleSet.builder_conditions?.some((condition) =>
                        isBuilderConditionValid(condition)
                      )
                  )
                ) {
                  setNewRetailer(event.target.value);
                } else {
                  setRetailerAndClearRuleSet(event.target.value);
                }
              }}
              items={filteredRetailers.map((retailer) => ({
                label: retailer.display_name,
                value: retailer.id,
                disabled: disabledRetailerIds.includes(retailer.id),
              }))}
              disabled={!!universe.id}
              required
            />
          </Grid>
          {
            <AccessResolver
              requiredRoles={[UserRole.KEY_ACCOUNT_MANAGER]}
              additionalCondition={
                universe?.build?.type === 'UNIVERSE_BUILD_TYPE_BUILDER'
              }
            >
              <Grid item xs={6} className={styles.ab_test_toggle_wrapper}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !!universe?.build?.rule_sets_no_treatment_percentage
                      }
                      onChange={(event) =>
                        setUniverse({
                          ...universe,
                          build: {
                            ...universe?.build,
                            rule_sets_no_treatment_percentage: event.target
                              .checked
                              ? 10
                              : 0,
                          },
                        })
                      }
                    />
                  }
                  label={t('enable_ab_test')}
                />
              </Grid>
              {!!universe?.build?.rule_sets_no_treatment_percentage &&
              universe?.build?.rule_sets_no_treatment_percentage >= 10 ? (
                <Grid item xs={6} className={styles.control_group_size_wrapper}>
                  <Typography
                    className={styles.control_group_size_label}
                    variant="body2"
                  >
                    {t('control_group_percentage_label')}
                  </Typography>
                  <Slider
                    className={styles.control_group_size_slider}
                    step={10}
                    defaultValue={10}
                    valueLabelDisplay="auto"
                    value={universe?.build?.rule_sets_no_treatment_percentage}
                    onChange={(_, value: number | number[]) =>
                      setUniverse({
                        ...universe,
                        build: {
                          ...universe?.build,
                          rule_sets_no_treatment_percentage: value as number,
                        },
                      })
                    }
                    marks={[...Array(9).keys()].map((key: number) => ({
                      label: `${(key + 1) * 10}`,
                      value: (key + 1) * 10,
                    }))}
                    min={10}
                    max={90}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </AccessResolver>
          }
        </Grid>
        <Grid item xs={4}>
          <UniverseOverview
            isBuilderType={isBuilderType}
            isLoading={isSaving}
            isSaveDisabled={saveDisabled}
            handleSave={() => onSave(universe)}
            universe={universe}
          />
        </Grid>

        {!isBuilderType && (
          <Grid item xs={12}>
            <StaticUniverseUploader
              onSuccessfulUpload={(shoppers: string[]) => {
                setUniverse((universe) => ({
                  ...universe,
                  build: {
                    ...universe.build,
                    import_shopper_ids: shoppers,
                  },
                }));
              }}
            />
          </Grid>
        )}
        {isBuilderType && (
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={1}
              className={styles.rule_list_title}
            >
              <Typography variant="h3">
                {t('universe_rules')}
                <span className={styles.required}> *</span>
              </Typography>
              <Typography variant="h3" color="textSecondary">
                (
                {universe.build?.rule_sets
                  ?.map((ruleSet) => ruleSet.builder_conditions)
                  ?.flat()?.length || 0}
                )
              </Typography>
            </Stack>
            {universe.build?.rule_sets?.map((ruleSet, i) => (
              <RuleSet
                key={`rule-set-${i}`}
                index={i}
                ruleSet={ruleSet}
                universe={universe}
                isLoading={isSaving}
                onUniverseUpdate={setUniverse}
              />
            ))}
            {!isRuleSetLimitReached ? (
              <Grid item className={styles.new_rule_set}>
                <Button
                  className="uppercase"
                  data-testid="add-rule-set"
                  disabled={!universe?.retailer_id}
                  variant="outlined"
                  onClick={addRuleSetHandler}
                >
                  {t('add_section')}
                </Button>
                <Typography variant="body1" color="textSecondary">
                  {t('add_section_message')}
                </Typography>
              </Grid>
            ) : (
              <div className={styles.rule_set_limit_reached_message}>
                <SvgIcon
                  component={InfoIcon}
                  color={'secondary'}
                  viewBox="0 0 32 32"
                />
                <Typography
                  variant="h3"
                  color={'textSecondary'}
                  className={styles.rule_set_limit_reached_message__text}
                >{`${t('universe_rule_set_limit_reached')}`}</Typography>
              </div>
            )}
          </Grid>
        )}
        {newRetailer && (
          <DialogWrapper
            width={550}
            showCloseIcon={false}
            headerText={t('confirm_retailer_change')}
            handleClose={() => setNewRetailer(undefined)}
            dialogContent={t('retailer_change_confirmation_message')}
            dialogActions={
              <>
                <Button
                  onClick={() => setNewRetailer(undefined)}
                  variant="outlined"
                >
                  {t('cancel')}
                </Button>
                <Button
                  variant="contained"
                  data-testid="confirm-delete-ruleset"
                  onClick={() => {
                    setRetailerAndClearRuleSet(newRetailer);
                    setNewRetailer(undefined);
                  }}
                >
                  {t('confirm')}
                </Button>
              </>
            }
          />
        )}
      </Grid>
    </>
  );
};

export default EditUniverse;
