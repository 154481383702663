import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import {
  tkUniverseBlockLogic,
  tkUniverseBlockPeriodType,
} from 'i18n/translationKeyMaps';
import capitalize from 'lodash/capitalize';
import { definitions } from 'types/api';

import {
  useGetTaxonomyAttributesByIDQuery,
  useGetTaxonomyByIDQuery,
  useGetTaxonomyProductsByGTINQuery,
} from 'store/api/endpoints/productTaxonomies';
import { useGetShopperMetadataListByIdsQuery } from 'store/api/endpoints/shopperMetadata';
import { addNotification } from 'store/modules/notifications/actions';

import {
  generateAttributeRuleText,
  generateProductsRuleText,
  generateShopperMetadataRuleText,
} from '../utils';

export const useBuildConditionText = (universe: definitions['Universe']) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const numberOfUniverseRules: number =
    universe.build.rule_sets?.reduce(
      (acc, ruleSet) => acc + (ruleSet?.builder_conditions?.length || 0),
      0
    ) || 0;

  const firstBuilderConditionRule:
    | definitions['BuildUniverseUserCondition']
    | undefined = universe.build.rule_sets?.[0]?.builder_conditions?.[0];

  // get taxonomy
  const { data: taxonomyItem } = useGetTaxonomyByIDQuery(
    {
      product_taxonomy_id: firstBuilderConditionRule?.product_taxonomy_id!,
    },
    {
      skip:
        !firstBuilderConditionRule ||
        !firstBuilderConditionRule.product_taxonomy_id,
    }
  );

  // get shopper metadata
  const { data: universeMetadataItems } = useGetShopperMetadataListByIdsQuery(
    {
      path: {
        attribute_ids: [firstBuilderConditionRule?.attribute_id ?? ''],
      },
      query: {
        retailer_id: universe.retailer_id!,
        country_code: universe.country_code!,
      },
    },
    {
      skip:
        !firstBuilderConditionRule || !firstBuilderConditionRule.attribute_id,
    }
  );

  // get attribute name
  const { data: attributes } = useGetTaxonomyAttributesByIDQuery(
    {
      product_taxonomy_id: taxonomyItem?.product_taxonomy?.product_taxonomy_id!,
      attribute_ids: firstBuilderConditionRule?.attributes!,
    },
    {
      skip:
        !firstBuilderConditionRule ||
        !taxonomyItem ||
        !taxonomyItem?.product_taxonomy?.product_taxonomy_id ||
        !firstBuilderConditionRule.attributes ||
        !firstBuilderConditionRule.attributes?.length,
      refetchOnMountOrArgChange: true,
    }
  );

  // get products name
  const { data: products, error: taxonomyProductsByGtinError } =
    useGetTaxonomyProductsByGTINQuery(
      {
        product_taxonomy_id:
          taxonomyItem?.product_taxonomy?.product_taxonomy_id!,
        gtins: firstBuilderConditionRule?.gtins!,
        silentNotification: true,
      },
      {
        skip:
          !firstBuilderConditionRule ||
          !taxonomyItem ||
          !taxonomyItem?.product_taxonomy?.product_taxonomy_id ||
          !firstBuilderConditionRule.gtins ||
          !firstBuilderConditionRule.gtins?.length,
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    if ((taxonomyProductsByGtinError as FetchBaseQueryError)?.status === 404) {
      dispatch(
        addNotification({
          text: `${universe?.name} - ${
            ((taxonomyProductsByGtinError as FetchBaseQueryError)?.data as any)
              ?.message
          }`,
          type: 'error',
        })
      );
    }
  }, [taxonomyProductsByGtinError]);

  let universeRuleText = `${capitalize(
    tkUniverseBlockLogic(firstBuilderConditionRule?.logic!)
  )} `;

  if (
    products?.products &&
    products?.products?.length > 0 &&
    firstBuilderConditionRule?.gtins?.length
  ) {
    universeRuleText += `${t('buyers_of')} ${generateProductsRuleText(
      products.products
    )} ${t('in_last')} ${firstBuilderConditionRule?.period} ${t(
      tkUniverseBlockPeriodType(firstBuilderConditionRule?.period_type),
      {
        count: Number(firstBuilderConditionRule?.period),
      }
    )}`;
  } else if (
    (attributes?.attributes &&
      attributes?.attributes?.length > 0 &&
      firstBuilderConditionRule?.attributes?.length) ||
    firstBuilderConditionRule?.search_query
  ) {
    universeRuleText += `${t('buyers_of')} ${generateAttributeRuleText(
      attributes?.attributes,
      firstBuilderConditionRule?.search_query
    )} ${t('in_last')} ${firstBuilderConditionRule?.period} ${t(
      tkUniverseBlockPeriodType(firstBuilderConditionRule?.period_type),
      {
        count: Number(firstBuilderConditionRule?.period),
      }
    )}`;
  } else if (universeMetadataItems) {
    universeRuleText += generateShopperMetadataRuleText(
      universeMetadataItems.shopper_metadata,
      firstBuilderConditionRule?.metadata_ids
    );
  } else if (
    // if universe rule has no taxonomy_id, we can only show the number of products
    !products?.products &&
    firstBuilderConditionRule?.gtins &&
    firstBuilderConditionRule?.gtins?.length > 0
  ) {
    universeRuleText += `${t('buyers_of')} 
    ${firstBuilderConditionRule?.gtins?.length} ${t('products')}
    ${t('in_last')} ${firstBuilderConditionRule?.period} ${t(
      tkUniverseBlockPeriodType(firstBuilderConditionRule?.period_type),
      {
        count: Number(firstBuilderConditionRule?.period),
      }
    )}`;
  }

  return {
    numberOfUniverseRules,
    universeRuleText,
  };
};
