import { isSameDay } from 'date-fns';
import { PropertyName } from 'lodash';
import { DATE_ONGOING, DEFAULT_COUNTRY } from 'shared/constants';
import { ExtendedCampaign } from 'shared/interfaces/Campaign';
import { definitions } from 'types/api';
import { TOMORROW, formattedISOString, localMiddayISO } from 'utils/time';

import { ImageFile } from 'hooks/useImageUpload';

export type CreativeWithFile = definitions['Creative'] & {
  file?: ImageFile;
  file_changed?: boolean;
  isValid?: boolean;
};

export type DraftCampaignType = Omit<ExtendedCampaign, 'creatives'> & {
  creatives: (definitions['Creative'] & CreativeWithFile)[];
};

export const baseCampaign = (): definitions['Campaign'] => ({
  id: '',
  name: '',
  budget: '',
  external_campaign_id: undefined,
  incentive_distribution: undefined,
  state: 'CAMPAIGN_STATE_DRAFT',
  creatives: [],
  created_at: '',
  updated_at: '',
  universe_id: '',
  business_rules_block: [],
  business_rules_limit: [],
  advertiser_cpm_bid: '',
  advertiser_cpa_bid: '',
  promoted_products: [],
  campaign_start: localMiddayISO(TOMORROW),
  campaign_end: '',
  channels: [],
  attribution_window_start: localMiddayISO(new Date()),
  attribution_window_end: '',
  incentive: undefined,
  advertiser_id: '',
  max_daily_budget: '',
  type: 'CAMPAIGN_TYPE_UNKNOWN',
  reject_reason: '',
  country_code: DEFAULT_COUNTRY.value,
  budget_even_distribution: true,
  advertiser_cpc_bid: '',
  target_daily_budget: '',
  campaign_group_id: '',
  bid_modifier: 'CAMPAIGN_BID_MODIFIER_NEUTRAL',
});

export function formatDates(campaign: DraftCampaignType): DraftCampaignType {
  let campaign_start = null;
  let campaign_end = null;

  // convert dates to UTC
  if (campaign.campaign_start) {
    campaign_start = new Date(campaign.campaign_start);
    campaign_start.setUTCFullYear(
      new Date(campaign.campaign_start).getFullYear(),
      new Date(campaign.campaign_start).getMonth(),
      new Date(campaign.campaign_start).getDate()
    );
    campaign_start.setUTCHours(0, 0, 0, 0);
    campaign_start = formattedISOString(campaign_start);
  }
  if (campaign.campaign_end) {
    campaign_end = new Date(campaign.campaign_end);
    campaign_end.setUTCFullYear(
      new Date(campaign.campaign_end).getFullYear(),
      new Date(campaign.campaign_end).getMonth(),
      new Date(campaign.campaign_end).getDate()
    );
    campaign_end.setUTCHours(23, 59, 59, 999);
    campaign_end = formattedISOString(campaign_end);
  }

  return {
    ...campaign,
    attribution_window_end: !campaign.attribution_window_end
      ? DATE_ONGOING
      : campaign.attribution_window_end,
    campaign_start: campaign_start,
    campaign_end: campaign_end,
  };
}

export function parseDates(campaign: DraftCampaignType): DraftCampaignType {
  let campaign_end = null;

  if (campaign.campaign_end) {
    campaign_end = new Date(campaign.campaign_end);
    campaign_end.setFullYear(
      new Date(campaign.campaign_end).getUTCFullYear(),
      new Date(campaign.campaign_end).getUTCMonth(),
      new Date(campaign.campaign_end).getUTCDate()
    );
    campaign_end.setHours(new Date(campaign.campaign_end).getUTCHours());
    campaign_end = formattedISOString(campaign_end);
  }

  return {
    ...campaign,
    attribution_window_end:
      campaign.attribution_window_end === DATE_ONGOING
        ? ''
        : campaign.attribution_window_end,
    campaign_end: campaign?.campaign_end === DATE_ONGOING ? '' : campaign_end,
  };
}

export function campaignComparisonCustomizer(
  value1: any,
  value2: any,
  key: PropertyName | undefined
): boolean | undefined {
  // for the dates we only want to compare the day without the timestamp
  if (key === 'campaign_start' || key === 'campaign_end') {
    if (value1 === value2) return true;
    return isSameDay(
      new Date(value1?.toString()),
      new Date(value2?.toString())
    );
  }

  return undefined;
}

export function getMimeTypeForApi(
  mimeType: string
): definitions['CreativeMimeType'] {
  switch (mimeType) {
    case 'image/jpg':
    case 'image/jpeg':
      return 'CREATIVE_MIME_TYPE_JPEG';
    case 'image/png':
      return 'CREATIVE_MIME_TYPE_PNG';
    case 'image/gif':
      return 'CREATIVE_MIME_TYPE_GIF';
    default:
      return 'CREATIVE_MIME_TYPE_UNKNOWN';
  }
}

export function getMimeTypeForFE(mimeType: string): string {
  switch (mimeType) {
    case 'CREATIVE_MIME_TYPE_JPEG':
      return 'image/jpeg';
    case 'CREATIVE_MIME_TYPE_PNG':
      return 'image/png';
    case 'CREATIVE_MIME_TYPE_GIF':
      return 'image/gif';
    default:
      return 'image/jpeg';
  }
}

export function getFileExtensionHintForFE(mimeType: string): string {
  switch (mimeType) {
    case 'CREATIVE_MIME_TYPE_JPEG':
      return '*.jpeg/*.jpg';
    case 'CREATIVE_MIME_TYPE_PNG':
      return '*.png';
    case 'CREATIVE_MIME_TYPE_GIF':
      return '*.gif';
    default:
      return '*.jpeg/*.jpg';
  }
}

export const getRelatedCampaignStates = (
  campaignState: definitions['CampaignState']
): definitions['CampaignState'][] => {
  if (campaignState === 'CAMPAIGN_STATE_PAUSED_MANUAL')
    return [
      'CAMPAIGN_STATE_PAUSED_MANUAL',
      'CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED',
      'CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE',
    ];
  return [campaignState];
};
