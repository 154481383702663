import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as RemoveIcon } from 'assets/icons/x-small.svg';
import { UserRole } from 'types';
import { definitions } from 'types/api';

import { PrivateRoutes } from 'routes/PrivateRoutes';

import { useStoreDispatch } from 'store';
import { CreativeWithFile } from 'store/modules/campaign/helpers';
import {
  selectDraftCampaign,
  selectDraftTouchedFields,
  selectDraftValidations,
} from 'store/modules/campaign/selectors';
import { addNotification } from 'store/modules/notifications/actions';

import { UploadCreativeImage } from 'components/Creatives/UploadCreativeImage';
import { AccessResolver } from 'components/Shared/AccessResolver/AccessResolver';
import { TextInput } from 'components/Shared/CustomMui';

import { IconButton, SvgIcon, Tooltip } from '@mui/material';

import styles from './CreativeItem.module.scss';

interface Props {
  index: number;
  editable?: boolean;
  campaignCreative: definitions['Creative'] & CreativeWithFile;
  channelCreativeOption: definitions['CreativeOption'];
  languageCode: string;
  isTargetUrlRequired: boolean;
  updateCreative: (index: number, updatedCreative: CreativeWithFile) => void;
}

export const CreativeItem = ({
  index,
  editable,
  languageCode,
  campaignCreative,
  channelCreativeOption,
  updateCreative,
  isTargetUrlRequired,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const draftCampaign = useSelector(selectDraftCampaign);
  const validations = useSelector(selectDraftValidations);
  const touchedFields = useSelector(selectDraftTouchedFields);

  const [isTargetUrlTouched, setIsTargetUrlTouched] = useState(false);
  const [isAltTextTouched, setIsAltTextTouched] = useState(false);

  const [description, setDescription] = useState<string>(
    campaignCreative?.description || ''
  );
  const [targetUrl, setTargetUrl] = useState<string>(
    campaignCreative?.target_url || ''
  );

  useEffect(() => {
    setDescription(campaignCreative?.description || '');
    setTargetUrl(campaignCreative?.target_url || '');
  }, [campaignCreative]);

  const targetUrlInvalidError =
    (isTargetUrlTouched || touchedFields.creatives) &&
    (
      validations['creatives']?.['V CREATIVE_TARGET_URL_INVALID'] as boolean[]
    )?.[index] &&
    t('V CREATIVE_TARGET_URL_INVALID');

  const targetUrlEmptyError =
    (isTargetUrlTouched || touchedFields.creatives) &&
    (validations['creatives']?.['V CREATIVE_TARGET_URL_EMPTY'] as boolean[])?.[
      index
    ] &&
    t('V CREATIVE_TARGET_URL_EMPTY');

  const targetUrlExceedsMaxLengthError =
    (isTargetUrlTouched || touchedFields.creatives) &&
    (
      validations['creatives']?.[
        'V CREATIVE_TARGET_URL_MAX_LENGTH'
      ] as boolean[]
    )?.[index] &&
    t('V CREATIVE_TARGET_URL_MAX_LENGTH');

  const alternativeTextExceedsMaxLengthError =
    (isAltTextTouched || touchedFields.creatives) &&
    (
      validations['creatives']?.[
        'V CREATIVE_ALTERNATIVE_TEXT_MAX_LENGTH'
      ] as boolean[]
    )?.[index] &&
    t('V CREATIVE_ALTERNATIVE_TEXT_MAX_LENGTH');

  return (
    <>
      <div className={styles.header}>
        {(campaignCreative?.language || languageCode).toUpperCase()}
        {'_'}
        {channelCreativeOption.creative_aspect_ratio?.replace('px', '')}
      </div>

      <>
        {campaignCreative?.url || campaignCreative?.file?.preview ? (
          <div className={styles.image_container}>
            <img
              className={styles.preview_image}
              src={campaignCreative?.file?.preview || campaignCreative?.url}
              alt={campaignCreative?.description || 'creative'}
            ></img>
            {editable && (
              <Tooltip title={t('remove')}>
                <IconButton
                  size="small"
                  className={styles.remove_creative_icon}
                  onClick={() => {
                    updateCreative(index, {
                      ...campaignCreative,
                      url: '',
                      file_changed: true,
                      file: undefined,
                    });
                  }}
                >
                  <SvgIcon component={RemoveIcon} viewBox={'0 0 32 32'} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ) : (
          editable && (
            <div className={styles.image_container}>
              <UploadCreativeImage
                className={styles.creatives_drop_zone}
                aspectRatio={channelCreativeOption.creative_aspect_ratio}
                mimeTypes={channelCreativeOption.creative_mime_types}
                setFile={(uploadedFile) => {
                  if (!draftCampaign?.creatives) return;
                  const updatedCreative = {
                    ...draftCampaign.creatives[index],
                    height: uploadedFile?.height || 0,
                    width: uploadedFile?.width || 0,
                    file: uploadedFile,
                    file_changed: true,
                    language: languageCode,
                  };
                  updateCreative(index, updatedCreative);
                  dispatch(
                    addNotification({
                      text: t('creative_added'),
                      type: 'default',
                    })
                  );
                }}
              />
            </div>
          )
        )}
      </>

      <AccessResolver
        requiredRoles={[
          ...new Set<UserRole>([
            ...PrivateRoutes.EDIT_CAMPAIGN.roles,
            ...PrivateRoutes.VALIDATE_CAMPAIGN.roles,
          ]),
        ]}
      >
        <TextInput
          className={styles.creatives_input}
          label={t('alternative_text')}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setDescription(event.target.value);
            updateCreative(index, {
              ...campaignCreative,
              description: event.target.value,
            });
          }}
          onBlur={() => setIsAltTextTouched(true)}
          value={description}
          disabled={!editable}
          hint={
            <span className={styles.creative_input_hint}>{`${
              description?.length
            } ${t('characters')}`}</span>
          }
          error={alternativeTextExceedsMaxLengthError}
        />
        <TextInput
          className={styles.creatives_input}
          data-testid="data-target-url"
          label={t('target_url')}
          disabled={!editable}
          inputProps={{
            type: 'url',
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const trimmedTargetUrl = event.target.value?.trim();
            if (trimmedTargetUrl === targetUrl) return;
            setTargetUrl(trimmedTargetUrl);
            updateCreative(index, {
              ...campaignCreative,
              target_url: trimmedTargetUrl,
            });
          }}
          onBlur={() => setIsTargetUrlTouched(true)}
          value={targetUrl}
          error={
            targetUrlInvalidError ||
            targetUrlEmptyError ||
            targetUrlExceedsMaxLengthError
          }
          required={isTargetUrlRequired}
        />
      </AccessResolver>
    </>
  );
};
