import { ChangeEvent, ReactElement } from 'react';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

import {
  FormControlLabel,
  FormGroup,
  Stack,
  SvgIcon,
  Switch,
} from '@mui/material';

import { LabelValuePairType } from '../types';
import styles from './SwitchFilter.module.scss';

export interface SwitchFilterProps {
  showFilterIcon?: boolean;
  alreadyChecked?: boolean;
  disabled?: boolean;
  filterName: LabelValuePairType;
  onFilter: (isChecked: boolean) => void;
}

export const SwitchFilter = (props: SwitchFilterProps): ReactElement => {
  const {
    alreadyChecked,
    disabled = false,
    filterName,
    onFilter,
    showFilterIcon,
  } = props;

  return (
    <>
      {showFilterIcon && (
        <Stack direction="row" spacing={2}>
          <SvgIcon component={FilterIcon} viewBox={'0 0 32 32'} />
        </Stack>
      )}
      <Stack direction="row" spacing={2} className={styles.filtering_wrapper}>
        <FormGroup>
          <FormControlLabel
            className={styles.filter}
            control={
              <Switch
                disabled={disabled}
                defaultChecked={alreadyChecked}
                onChange={(_: ChangeEvent, checked: boolean) =>
                  onFilter(checked)
                }
                size="small"
              />
            }
            label={filterName?.label}
            disableTypography={true}
          />
        </FormGroup>
      </Stack>
    </>
  );
};
