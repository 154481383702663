import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserRole } from 'types/modules/user';

import { Routes } from 'routes/Routes';

import { selectUser } from 'store/modules/user/selectors';

export const Home = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!auth?.isAuthenticated) navigate(Routes.LOGIN);
    if (
      user?.role === (UserRole.ADVERTISER_CAMPAIGN_MANAGER as string) ||
      user?.role === (UserRole.ADVERTISER_ADMIN as string) ||
      user?.role === (UserRole.KEY_ACCOUNT_MANAGER as string)
    )
      navigate(Routes.CAMPAIGNS);
    else if (user?.role === (UserRole.STAFF as string))
      navigate(Routes.EXECUTIONS);
    else navigate(Routes.CAMPAIGNS_OVERVIEW);
  }, []);
  return <></>;
};
