import { definitions } from 'types/api';

export const APPROVAL_STATE_TYPES: definitions['CampaignState'][] = [
  'CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED',
  'CAMPAIGN_STATE_PAUSED_MANUAL',
  'CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE',
  'CAMPAIGN_STATE_RUNNING',
  'CAMPAIGN_STATE_SUBMITTED',
  'CAMPAIGN_STATE_APPROVED',
  'CAMPAIGN_STATE_REJECTED',
  'CAMPAIGN_STATE_STOPPED',
];
