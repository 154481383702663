import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from 'assets/icons/circle-information.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import classNames from 'classnames';
import {
  DEFAULT_API_PARAMETERS,
  UNIVERSE_RULE_LIMIT,
  UNIVERSE_RULE_SET_LIMIT,
} from 'shared/constants';
import { DraftUniverse, DraftUniverseRuleSetType } from 'shared/interfaces';
import { definitions } from 'types/api';

import { useGetAllTaxonomiesQuery } from 'store/api/endpoints/productTaxonomies';
import { useGetShopperMetadataListByIdsQuery } from 'store/api/endpoints/shopperMetadata';

import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';
import Rule from 'components/Universes/Rule/Rule';
import {
  EMPTY_BUILDER_CONDITION,
  isBuilderConditionValid,
} from 'components/Universes/utils';

import { Grid, IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import styles from './RuleSet.module.scss';

interface RuleSetProps {
  index: number;
  isLoading: boolean;
  universe: DraftUniverse;
  ruleSet: DraftUniverseRuleSetType;
  readonly?: boolean;
  onUniverseUpdate: (universe: DraftUniverse) => void;
}

export const RuleSet: FC<RuleSetProps> = ({
  index,
  ruleSet,
  readonly,
  universe,
  isLoading,
  onUniverseUpdate,
}: RuleSetProps): ReactElement => {
  const { t } = useTranslation();
  const [enableRuleSelection, setEnableRuleSelection] =
    useState<boolean>(false);
  const [showDeleteRuleSetConfirmation, setShowDeleteRuleSetConfirmation] =
    useState<boolean>(false);

  /**
   * As we only have 2 channels, namely Lidl and Kaufland at the moment,
   * we can handle the pagination once we have too many taxonomies.
   */
  const { data: taxonomiesApiData, isLoading: isLoadingTaxonomiesApiData } =
    useGetAllTaxonomiesQuery(
      {
        retailer_ids: universe.retailer_id ? [universe.retailer_id] : [],
        page: DEFAULT_API_PARAMETERS.page,
        per_page: DEFAULT_API_PARAMETERS.per_page,
      },
      {
        skip: !universe.retailer_id,
      }
    );

  // TODO [PACMAN-1118]: use BE filtering once it's ready
  const taxonomies = taxonomiesApiData?.product_taxonomies.length
    ? taxonomiesApiData.product_taxonomies.filter(
        (taxonomy) => taxonomy.country_code === universe.country_code
      )
    : [];
  const selectedTaxonomy = taxonomies.length
    ? taxonomies[0].product_taxonomy_id
    : '';

  const universeMetaDataIds =
    ruleSet?.builder_conditions
      ?.flat()
      ?.filter((condition) => !!condition?.attribute_id)
      ?.map((condition) => condition?.attribute_id as string) || [];

  const { data: universeMetadataItems } = useGetShopperMetadataListByIdsQuery(
    {
      path: {
        attribute_ids: universeMetaDataIds,
      },
      query: {
        retailer_id: universe.retailer_id!,
        country_code: universe.country_code!,
      },
    },
    {
      skip:
        !universeMetaDataIds ||
        universeMetaDataIds.length === 0 ||
        !universe.retailer_id ||
        !universe.country_code,
    }
  );

  /* Determine if new empty rule can be added to the set. Set should have less than UNIVERSE_RULE_LIMIT items
    and the logic, attribute type, attribute values and the time span should have been provided for all other
    rules in this set.
     */
  const canNewRuleBeAddedToSet =
    (ruleSet?.builder_conditions?.length || 0) < UNIVERSE_RULE_LIMIT &&
    ruleSet?.builder_conditions?.every((condition) =>
      isBuilderConditionValid(condition)
    );

  const addNewRuleHandler = (): void => {
    // Adding a new empty rule to the current rule set if the last rule set is complete.
    const updatedRuleSet: definitions['RuleSets'] = canNewRuleBeAddedToSet
      ? {
          builder_conditions: [
            ...(ruleSet?.builder_conditions ?? []),
            EMPTY_BUILDER_CONDITION,
          ],
        }
      : ruleSet;

    // Propagating the updates to the parent component.
    onUniverseUpdate({
      ...universe,
      build: {
        ...universe.build,
        rule_sets: universe?.build?.rule_sets?.map(
          (ruleSetIterator, ruleSetIndex) =>
            index === ruleSetIndex ? updatedRuleSet : ruleSetIterator
        ),
      },
    });
  };

  const handleDeleteConfirmationPrompt = (): void => {
    if (ruleSet?.builder_conditions?.length) {
      setShowDeleteRuleSetConfirmation(true);
    } else {
      ruleSetDeleteHandler();
    }
  };

  const ruleSetDeleteHandler = (): void => {
    onUniverseUpdate({
      ...universe,
      build: {
        ...universe.build,
        rule_sets: universe.build?.rule_sets?.filter(
          (_, ruleSetIndex: number) => ruleSetIndex !== index
        ),
      },
    });
    setShowDeleteRuleSetConfirmation(false);
  };
  if (isLoadingTaxonomiesApiData) return <></>;

  return (
    <>
      <Grid
        container
        className={classNames(styles.rule_set, { [styles.readonly]: readonly })}
        justifyContent="space-between"
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="body2">
            {`${t('rule_set')} ${index + 1}`}
          </Typography>
          {!readonly && (
            <Tooltip title={t('delete_rule_set')}>
              <IconButton
                className={styles.close_icon}
                onClick={handleDeleteConfirmationPrompt}
              >
                <SvgIcon component={TrashIcon} viewBox={'0 0 32 32'} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        {ruleSet?.builder_conditions?.map((condition, ruleIndex) => (
          <Rule
            condition={condition}
            disabled={isLoading}
            readonly={readonly}
            retailerId={universe.retailer_id}
            countryCode={universe.country_code}
            taxonomyId={selectedTaxonomy}
            key={`${ruleIndex}-${condition?.id}`}
            index={ruleIndex}
            onPopperOpen={(open) => {
              setEnableRuleSelection(open);
            }}
            enableRuleSelection={!enableRuleSelection}
            advertiserId={universe.advertiser_id}
            attribute={universeMetadataItems?.shopper_metadata?.find(
              (item) => item.attribute_id === condition.attribute_id
            )}
            onDelete={() => {
              onUniverseUpdate({
                ...universe,
                build: {
                  ...universe.build,
                  rule_sets: universe.build?.rule_sets?.map(
                    (ruleSet, ruleSetIteratorIndex: number) => {
                      return index === ruleSetIteratorIndex
                        ? {
                            builder_conditions:
                              ruleSet?.builder_conditions?.filter(
                                (_, idx) => ruleIndex !== idx
                              ),
                          }
                        : ruleSet;
                    }
                  ),
                },
              });
            }}
            onUpdate={(updatedCondition) => {
              // Updating the current rule set with thw new condition.
              const updatedRules = ruleSet?.builder_conditions?.map(
                (
                  conditionIterator: definitions['BuildUniverseUserCondition'],
                  conditionIndex: number
                ) =>
                  ruleIndex === conditionIndex
                    ? updatedCondition
                    : conditionIterator
              );

              // Propagating the updates to the parent component.
              onUniverseUpdate({
                ...universe,
                build: {
                  ...universe.build,
                  rule_sets: universe?.build?.rule_sets?.map(
                    (ruleSetIterator, ruleSetIndex) =>
                      index === ruleSetIndex
                        ? { builder_conditions: updatedRules }
                        : ruleSetIterator
                  ),
                },
              });
            }}
          />
        ))}
        {!readonly && (
          <>
            {(ruleSet?.builder_conditions?.length ?? 0) <
            UNIVERSE_RULE_LIMIT ? (
              <Grid container className={styles.add_new_rule}>
                <Grid
                  item
                  xs={0.5}
                  className={styles.rule_connector_text_wrapper}
                >
                  <Typography
                    className={classNames(
                      styles.rule_connector_text,
                      'uppercase'
                    )}
                    variant="body1"
                    color="textSecondary"
                  >
                    {t('and')}
                  </Typography>
                </Grid>
                <Grid item xs={11.5}>
                  <Stack direction="row" alignItems="center">
                    <Button
                      className="uppercase"
                      disabled={
                        !universe?.retailer_id || !canNewRuleBeAddedToSet
                      }
                      variant="outlined"
                      onClick={addNewRuleHandler}
                    >
                      {t('add_rule')}
                    </Button>
                    <Typography variant="body1" color="textSecondary">
                      {t('add_rule_message')}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} className={styles.rule_limit_reached_message}>
                <SvgIcon
                  component={InfoIcon}
                  color={'secondary'}
                  viewBox="0 0 32 32"
                />
                <Typography
                  variant="h3"
                  color={'textSecondary'}
                  className={styles.rule_limit_reached_message__text}
                >{`${t('universe_rule_limit_reached')}`}</Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {index < UNIVERSE_RULE_SET_LIMIT - 1 && !readonly && (
        <Grid container justifyContent={'center'}>
          <Typography
            className={classNames(styles.rule_connector_text, 'uppercase')}
            variant="body1"
            color="textSecondary"
          >
            {t('or')}
          </Typography>
        </Grid>
      )}
      {showDeleteRuleSetConfirmation && (
        <DialogWrapper
          width={550}
          showCloseIcon={false}
          headerText={t('confirm_deletion')}
          handleClose={() => setShowDeleteRuleSetConfirmation(false)}
          dialogContent={t('delete_ruleset_confirmation_message')}
          dialogActions={
            <>
              <Button
                onClick={() => setShowDeleteRuleSetConfirmation(false)}
                variant="outlined"
              >
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                data-testid="confirm-delete-ruleset"
                onClick={ruleSetDeleteHandler}
              >
                {t('confirm')}
              </Button>
            </>
          }
        />
      )}
    </>
  );
};
