import { useSelector } from 'react-redux';

import { definitions } from 'types/api';

import { Campaign, ExtendedCampaign } from '../shared/interfaces';
import { selectCountries } from '../store/modules/settings/selectors';

type CampaignChannel = definitions['CampaignChannel'];

export type ExtendedChannel = CampaignChannel & {
  name?: string;
};

export const useCampaigns = (
  campaigns: Campaign[],
  cumulatives: definitions['CumulativeMetric'][],
  networkChannels: definitions['Channel'][],
  universe?: definitions['Universe']
) => {
  const countries = useSelector(selectCountries);
  return campaigns.map((campaign: Campaign) => {
    const campaignCumulative = cumulatives?.find(
      (cumulative: definitions['CumulativeMetric']) =>
        cumulative.campaign_id === campaign.id
    );
    let extendedCampaign: ExtendedCampaign = {
      ...campaign,
      ...campaignCumulative,
      campaign_group_id: campaign.campaign_group_id,
    };
    extendedCampaign.extended_channels = campaign.channels.map(
      (campaignChannel) => {
        const channel = networkChannels?.find(
          (networkChannel) => networkChannel.id === campaignChannel.channel_id
        );
        return {
          ...channel,
        };
      }
    );

    extendedCampaign.currency = countries?.find(
      (country: definitions['Country']) =>
        country.code === campaign?.country_code
    )?.currency;

    extendedCampaign.bid =
      campaign.advertiser_cpa_bid ||
      campaign.advertiser_cpm_bid ||
      campaign.advertiser_cpc_bid ||
      '0';

    extendedCampaign.universe = universe;

    return extendedCampaign;
  });
};
