import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down-small.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/chevron-up-small.svg';
import classNames from 'classnames';
import { tkUniverseBlockLogic } from 'i18n/translationKeyMaps';
import { definitions } from 'types/api';

import { PopperStep, SelectionBoxProps } from 'components/Universes/Rule/Rule';
import styles from 'components/Universes/Rule/Rule.module.scss';

import { Button } from '@mui/material';

interface Props extends SelectionBoxProps {
  setPopperStep: (popperStep: PopperStep | null) => void;
  logic: definitions['BuildUniverseConditionLogic'];
}

export const SelectRuleInclusiveness = ({
  logic,
  selected,
  readonly,
  disabled,
  hasError,
  setPopperStep,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Button
      id={PopperStep.INCLUSIVENESS}
      disabled={disabled}
      className={classNames(styles.block, {
        [styles.readonly]: readonly,
        [styles.disabled]: disabled,
        [styles.selected]: selected,
        [styles.error]: hasError,
      })}
      onClick={(e) => {
        e.stopPropagation();
        setPopperStep(selected ? null : PopperStep.INCLUSIVENESS);
      }}
      endIcon={
        readonly ? null : selected ? (
          <ArrowUpIcon className={styles.icon} />
        ) : (
          <ArrowDownIcon className={styles.icon} />
        )
      }
    >
      {t(tkUniverseBlockLogic(logic))}
    </Button>
  );
};
