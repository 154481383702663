import React from 'react';
import { useParams } from 'react-router-dom';

import { utcToZonedTime } from 'date-fns-tz';
import format from 'date-fns-tz/format';

import {
  useGetCampaignExecutionByIdQuery,
  useGetCampaignExecutionContextByIdQuery,
} from 'store/api/endpoints/campaignExecution';
import { useGetAllChannelsQuery } from 'store/api/endpoints/settings';

import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const ListRootS = styled('ul')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(5),
  padding: 0,
  width: 1000,
  '& li': {
    display: 'flex',
    lineHeight: '24px',
    listStyleType: 'none',
    marginBottom: theme.spacing(1),
    '& div:first-child': {
      fontWeight: 500,
      minWidth: 200,
      width: 200,
    },
    '& div:last-child': {
      color: theme.palette.grey[800],
      marginLeft: theme.spacing(1),
    },
  },
}));

const ListItem: React.FC<{ property: string; value?: any }> = ({
  property,
  value,
}: {
  property: string;
  value?: any;
}) => (
  <li>
    <div>{`${property}: `}</div>
    <div>{value}</div>
  </li>
);

const formatDate = (date: string) => {
  return format(utcToZonedTime(date, 'Europe/Berlin'), 'do MMM yyyy HH:mm', {
    timeZone: 'Europe/Berlin',
  });
};

const Execution: React.FC = () => {
  const { executionId } = useParams();
  const { data: networkChannels } = useGetAllChannelsQuery();

  const { data: execution } = useGetCampaignExecutionByIdQuery(
    executionId ? executionId : '',
    {
      skip: !executionId,
    }
  );
  const { data: executionContext } = useGetCampaignExecutionContextByIdQuery(
    execution?.execution_context_id ? execution?.execution_context_id : '',
    {
      skip: !execution?.execution_context_id,
    }
  );

  const channel = networkChannels?.channels?.find(
    (c) => c.id === executionContext?.channel_id
  );

  return (
    <>
      <Typography variant="h3">Execution</Typography>
      {!!execution && (
        <ListRootS>
          <ListItem property="Execution Id" value={execution.id} />
          <ListItem property="Engine Run Id" value={execution.engine_run_id} />
          <ListItem
            property="Execution Context Id"
            value={execution.execution_context_id}
          />
          <ListItem
            property="Execution Status"
            value={execution.execution_status}
          />
          <ListItem
            property="Created At"
            value={execution.created_at && formatDate(execution.created_at)}
          />
          <ListItem
            property="Updated At"
            value={execution.updated_at && formatDate(execution.updated_at)}
          />
          <ListItem
            property="Last Event Time"
            value={
              execution.last_event_time && formatDate(execution.last_event_time)
            }
          />
          <ListItem property="Last Event Id" value={execution.last_event_id} />
          <ListItem
            property="Last Update Message"
            value={execution.last_update_message}
          />
          <ListItem
            property="Engine Output Filepath"
            value={execution.engine_output_filepath}
          />
          <ListItem
            property="Impulse Output Filepath"
            value={execution.impulse_output_filepath}
          />
          <ListItem property="Impulse Count" value={execution.impulse_count} />
          <ListItem
            property="Total Impact Score"
            value={execution.total_impact_score}
          />
        </ListRootS>
      )}

      {!!executionContext && (
        <>
          <Typography variant="h3">Execution Context</Typography>
          <ListRootS>
            <ListItem
              property="Execution Context Id"
              value={executionContext.execution_context_id}
            />
            <ListItem
              property="Network Id"
              value={executionContext.network_id}
            />
            <ListItem
              property="Channel Id"
              value={`
              ${executionContext.channel_id} 
              ${channel ? `(${channel.name})` : ''}
            `}
            />
            <ListItem
              property="Created At"
              value={
                executionContext.created_at &&
                formatDate(executionContext.created_at)
              }
            />
            <ListItem
              property="Campaign File Location"
              value={executionContext.campaign_file_location}
            />
            <ListItem
              property="Impulse Creation Date"
              value={
                executionContext.impulse_creation_date &&
                formatDate(executionContext.impulse_creation_date)
              }
            />
            <ListItem
              property="Impulse Delivery Date"
              value={
                executionContext.impulse_delivery_date &&
                formatDate(executionContext.impulse_delivery_date)
              }
            />
          </ListRootS>
        </>
      )}
    </>
  );
};

export default Execution;
