import { Link } from 'react-router-dom';

import { ReactComponent as ChartIcon } from 'assets/icons/chart.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/x-small.svg';

import { IconButton, IconButtonProps, SvgIcon, Tooltip } from '@mui/material';

interface Props extends IconButtonProps {
  title: string;
  icon: 'edit' | 'delete' | 'reporting' | 'duplicate';
  to?: string;
  disabled?: boolean;
  onClick?: any;
}

export const CustomIconButton = ({
  title,
  icon,
  onClick,
  disabled,
  to,
  color = 'primary',
  ...rest
}: Props) => {
  const SvgIconComponent = (
    iconName: typeof icon
  ): React.FunctionComponent<React.SVGProps<SVGSVGElement>> =>
    ({
      reporting: ChartIcon,
      edit: EditIcon,
      delete: DeleteIcon,
      duplicate: DuplicateIcon,
    }[iconName]);

  return (
    <Tooltip title={title}>
      <IconButton
        color={color}
        disabled={disabled}
        onClick={onClick}
        {...(to && {
          component: Link,
          to: to,
          onClick: (e) => e.stopPropagation(),
        })}
        {...rest}
      >
        <SvgIcon component={SvgIconComponent(icon)} viewBox={'0 0 32 32'} />
      </IconButton>
    </Tooltip>
  );
};
