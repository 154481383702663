import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Campaign } from 'shared/interfaces';
import { definitions } from 'types/api';
import { toDateTime } from 'utils/time';

import {
  CampaignStateChip,
  Column,
  Pagination,
  Table,
} from 'components/Shared/CustomMui';

import { RejectReason } from '../RejectReason/RejectReason';

interface CampaignApprovalTableProps {
  campaigns: Campaign[];
  pagination: Pagination;
}

export const CampaignApprovalTable: FC<CampaignApprovalTableProps> = ({
  campaigns,
  pagination,
}: CampaignApprovalTableProps): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const rowClickHandler = (campaignId: string): void => {
    navigate(`/campaigns/${campaignId}/validate`);
  };

  const getCampaignStateChip = (campaignId: string): ReactElement => {
    const campaign = campaigns.find(
      (campaign: Campaign) => campaign.id === campaignId
    ) as Campaign;
    const campaignState = campaign?.state;
    return (
      <>
        <CampaignStateChip state={campaignState} />
        {(campaignState as definitions['CampaignState']) ===
          'CAMPAIGN_STATE_REJECTED' && (
          <RejectReason reason={campaign?.reject_reason} />
        )}
      </>
    );
  };

  const columns: Column[] = [
    {
      field: 'name',
      headerName: t('campaign_name'),
      width: 350,
    },
    {
      field: 'id',
      headerName: t('state'),
      renderCell: (campaignId: string) => getCampaignStateChip(campaignId),
    },
    {
      field: 'updated_at',
      headerName: t('last_modified'),
      renderCell: (updatedAt: string) => toDateTime(updatedAt, true),
    },
    {
      field: 'advertiser_id',
      headerName: t('creator'),
      width: 200,
    },
    {
      field: 'campaign_start',
      headerName: t('start_date'),
      renderCell: (startDate: string) => toDateTime(startDate, false),
    },
    {
      field: 'campaign_end',
      headerName: t('end_date'),
      renderCell: (endDate: string) => {
        const date = toDateTime(endDate, false);
        return !date ? t('no_end_date') : date;
      },
    },
  ];

  return (
    <Table
      emptyState={<></>}
      columns={columns}
      minHeight={10}
      rows={campaigns}
      onRowClick={rowClickHandler}
      pagination={pagination}
    />
  );
};
