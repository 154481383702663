import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { Endpoints } from 'types';
import { definitions } from 'types/api';

import { RootState } from 'store';

import { filterOutEmptyMetrics } from 'components/Reporting/utils/utils';

import { baseApi } from '../baseApi';

/**
 * Enhance baseApi with tags that we use only in reports
 */
const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: [
    'Cumulatives',
    'MetricsByDay',
    'AdvertiserMetrics',
    'CampaignGroupCumulatives',
    'CampaignGroupMetricsByDay',
  ],
});

// inject reports endpoints to the enhancedBaseApiWithTags
export const reportsApi = enhancedBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCumulatives: builder.query<definitions['CumulativeMetric'][], string[]>({
      async queryFn(campaign_ids, { getState }, _extraOptions, fetchWithBQ) {
        const network_id =
          (getState() as RootState).settings.advertiser?.network_id ||
          (getState() as RootState).user?.user?.network_id;

        if (!network_id) {
          throw new Error('No network_id');
        }
        const res = await fetchWithBQ({
          url: `${Endpoints.report}/cumulatives`,
          params: {
            network_id,
            campaign_ids,
          },
        });
        const responseData =
          res.data as definitions['GetMetricsByCampaignCumulativeResponse'];

        return res.data
          ? { data: responseData.campaign_cumulative_metrics }
          : { error: res.error as FetchBaseQueryError };
      },
      providesTags: ['Cumulatives'],
    }),
    getMetricsByDay: builder.query<definitions['ByDayTimeSeries'][], string[]>({
      async queryFn(campaign_ids, { getState }, _extraOptions, fetchWithBQ) {
        const network_id =
          (getState() as RootState).settings.advertiser?.network_id ||
          (getState() as RootState).user?.user?.network_id;

        if (!network_id) {
          throw new Error('No network_id');
        }

        const res = await fetchWithBQ({
          url: `${Endpoints.report}/by_day`,
          params: {
            network_id,
            campaign_ids,
          },
        });
        const responseData =
          res.data as definitions['GetMetricsByCampaignByDayResponse'];

        return res.data
          ? {
              data: responseData.campaign_by_day_metrics.map(
                (campaignWiseMetrics: definitions['ByDayTimeSeries']) =>
                  filterOutEmptyMetrics(campaignWiseMetrics)
              ) as definitions['ByDayTimeSeries'][],
            }
          : { error: res.error as FetchBaseQueryError };
      },
      providesTags: ['MetricsByDay'],
    }),
    getAdvertiserMetrics: builder.query<
      definitions['GetAdvertiserMetricsResponse'],
      string
    >({
      query: (advertiserId: string) => ({
        url: `${Endpoints.report}/advertiser`,
        params: { advertiser_id: advertiserId },
      }),
    }),
    // Campaign Groups
    getCampaignGroupCumulatives: builder.query<
      definitions['CumulativeMetric'][],
      string[]
    >({
      async queryFn(
        campaign_group_ids,
        { getState },
        _extraOptions,
        fetchWithBQ
      ) {
        const network_id =
          (getState() as RootState).settings.advertiser?.network_id ||
          (getState() as RootState).user?.user?.network_id;

        if (!network_id) {
          throw new Error('No network_id');
        }
        const res = await fetchWithBQ({
          url: `${Endpoints.report}/groups/cumulatives`,
          params: {
            network_id,
            campaign_group_ids,
          },
        });
        const responseData =
          res.data as definitions['GetMetricsByCampaignGroupCumulativeResponse'];

        return res.data
          ? { data: responseData.group_cumulative_metrics }
          : { error: res.error as FetchBaseQueryError };
      },
      providesTags: ['CampaignGroupCumulatives'],
    }),
    getCampaignGroupMetricsByDay: builder.query<
      definitions['ByDayTimeSeries'][],
      string[]
    >({
      async queryFn(
        campaign_group_ids,
        { getState },
        _extraOptions,
        fetchWithBQ
      ) {
        const network_id =
          (getState() as RootState).settings.advertiser?.network_id ||
          (getState() as RootState).user?.user?.network_id;

        if (!network_id) {
          throw new Error('No network_id');
        }

        const res = await fetchWithBQ({
          url: `${Endpoints.report}/groups/by_day`,
          params: {
            network_id,
            campaign_group_ids,
          },
        });
        const responseData =
          res.data as definitions['GetMetricsByCampaignGroupByDayResponse'];

        return res.data
          ? {
              data: responseData.group_by_day_metrics.map(
                (campaignWiseMetrics: definitions['ByDayTimeSeries']) =>
                  filterOutEmptyMetrics(campaignWiseMetrics)
              ) as definitions['ByDayTimeSeries'][],
            }
          : { error: res.error as FetchBaseQueryError };
      },
      providesTags: ['CampaignGroupMetricsByDay'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCumulativesQuery,
  useLazyGetCumulativesQuery,
  useGetMetricsByDayQuery,
  useLazyGetMetricsByDayQuery,
  useGetAdvertiserMetricsQuery,
  useGetCampaignGroupCumulativesQuery,
  useGetCampaignGroupMetricsByDayQuery,
} = reportsApi;
