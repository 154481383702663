import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { tkCampaignState } from 'i18n/translationKeyMaps';
import { definitions } from 'types/api';

import { Chip } from '@mui/material';

import styles from './CampaignStateChip.module.scss';

interface Props {
  state: definitions['CampaignState'];
}

const getStateChipClass = (state: definitions['CampaignState']): string => {
  const colorsMap: {
    [key in Partial<definitions['CampaignState']>]: string;
  } = {
    CAMPAIGN_STATE_DRAFT: styles.grey,
    CAMPAIGN_STATE_UNKNOWN: styles.grey,
    CAMPAIGN_STATE_PAUSED_MANUAL: styles.grey,
    CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED: styles.orange,
    CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE: styles.orange,
    CAMPAIGN_STATE_RUNNING: styles.green,
    CAMPAIGN_STATE_STOPPED: styles.red,
    CAMPAIGN_STATE_DELETED: styles.red,
    CAMPAIGN_STATE_REJECTED: styles.red,
    CAMPAIGN_STATE_SUBMITTED: styles.orange,
    CAMPAIGN_STATE_APPROVED: styles.green,
  };

  return colorsMap[state] || styles.grey;
};

export const CampaignStateChip = ({ state }: Props) => {
  const { t } = useTranslation();
  const chipClassName = useMemo(() => getStateChipClass(state), [state]);

  return (
    <Chip
      size="small"
      label={t(tkCampaignState(state))}
      data-testid="campaign-state-chip"
      className={`${styles.chip} ${chipClassName}`}
    />
  );
};
