import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import {
  GetAllTaxonomiesRequest,
  GetTaxonomyAttributesByIDRequest,
  GetTaxonomyAttributesRequest,
  GetTaxonomyByIDRequest,
  GetTaxonomyProductsByGTINRequest,
  GetTaxonomyProductsRequest,
} from 'shared/interfaces/ProductTaxonomy';
import { Endpoints } from 'types';
import { definitions } from 'types/api';

import { baseApi } from '../baseApi';

/**
 * Enhance baseApi with tags that we use only in product taxonomies
 */
const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: [
    'TaxonomyList',
    'TaxonomyByID',
    'TaxonomyAttributeList',
    'TaxonomyAttributeListByID',
    'TaxonomyProductList',
    'TaxonomyProductListByGTIN',
  ],
});

// inject product taxonomy endpoints to the enhancedBaseApiWithTags
export const productTaxonomiesApi = enhancedBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllTaxonomies: builder.query<
      definitions['GetAllTaxonomiesResponse'],
      GetAllTaxonomiesRequest
    >({
      query: (args: GetAllTaxonomiesRequest) => ({
        url: Endpoints.productTaxonomies,
        params: {
          ...args,
        },
      }),
      providesTags: ['TaxonomyList'],
    }),
    getTaxonomyByID: builder.query<
      definitions['GetProductTaxonomyByIDResponse'],
      GetTaxonomyByIDRequest
    >({
      query: (args: GetTaxonomyByIDRequest) => ({
        url: `${Endpoints.productTaxonomies}/${args.product_taxonomy_id}`,
      }),
      providesTags: ['TaxonomyByID'],
    }),
    getTaxonomyAttributes: builder.query<
      definitions['GetTaxonomyAttributesResponse'],
      GetTaxonomyAttributesRequest
    >({
      query: (args: GetTaxonomyAttributesRequest) => ({
        url: `${Endpoints.productTaxonomies}/${args.product_taxonomy_id}/attributes`,
        params: {
          ...args.query,
        },
      }),
      providesTags: ['TaxonomyAttributeList'],
    }),
    getTaxonomyAttributesByID: builder.query<
      definitions['GetTaxonomyAttributesByIDResponse'],
      GetTaxonomyAttributesByIDRequest
    >({
      query: (args: GetTaxonomyAttributesByIDRequest) => ({
        url: `${Endpoints.productTaxonomies}/${
          args.product_taxonomy_id
        }/attributes/${args.attribute_ids.join('%2C')}`,
      }),
      providesTags: ['TaxonomyAttributeListByID'],
    }),
    getTaxonomyProducts: builder.query<
      definitions['GetTaxonomyProductsResponse'],
      GetTaxonomyProductsRequest
    >({
      query: (args: GetTaxonomyProductsRequest) => ({
        url: `${Endpoints.productTaxonomies}/${args.product_taxonomy_id}/products`,
        params: {
          ...args.query,
        },
      }),
      providesTags: ['TaxonomyProductList'],
    }),
    getTaxonomyProductsByGTIN: builder.query<
      definitions['GetTaxonomyProductsByGTINResponse'],
      GetTaxonomyProductsByGTINRequest
    >({
      async queryFn(
        { gtins, product_taxonomy_id, silentNotification },
        { getState },
        _extraOptions,
        fetchWithBQ
      ) {
        const res = await fetchWithBQ({
          url: `${
            Endpoints.productTaxonomies
          }/${product_taxonomy_id}/products/${gtins.slice(0, 99).join('%2C')}`, //TODO: We are sending max 100 products to API because of some validations. we need to come up with a proper solution later
        });

        return res.data
          ? {
              data: res.data as definitions['GetTaxonomyProductsByGTINResponse'],
            }
          : {
              error: {
                ...res.error,
                data: {
                  ...(res.error?.data as any),
                  silentNotification,
                },
              } as FetchBaseQueryError,
            };
      },
      providesTags: ['TaxonomyProductListByGTIN'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTaxonomyByIDQuery,
  useGetAllTaxonomiesQuery,
  useGetTaxonomyAttributesQuery,
  useLazyGetTaxonomyAttributesQuery,
  useGetTaxonomyAttributesByIDQuery,
  useLazyGetTaxonomyAttributesByIDQuery,
  useGetTaxonomyProductsQuery,
  useLazyGetTaxonomyProductsQuery,
  useGetTaxonomyProductsByGTINQuery,
  useLazyGetTaxonomyProductsByGTINQuery,
} = productTaxonomiesApi;
