import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down-small.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import classnames from 'classnames';
import { upsertObjectInArray } from 'utils';

import { useListActionBar } from 'components/Shared/ListActionBar/hooks/useListActionBar';

import {
  Button,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
} from '@mui/material';

import styles from './Filter.module.scss';
import { EntityFilterType } from './types';

export interface MultiSelectFilterProps {
  showFilterIcon?: boolean;
  filterItem: EntityFilterType;
  onFilter: (values: string[]) => void;
}

export const MultiSelectFilter = ({
  onFilter,
  filterItem,
  showFilterIcon,
}: MultiSelectFilterProps) => {
  const { t } = useTranslation();
  const {
    selectedFilters,
    setSelectedFilters,
    setUpdatedFilters,
    updatedFilters,
  } = useListActionBar();

  const [checkboxSelectedItems, setCheckboxSelectedItems] = useState<string[]>(
    []
  );
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (filterItem?.activeFilterValues?.length) {
      setSelectedFilters([
        {
          field: filterItem.field,
          activeFilterValues: filterItem?.activeFilterValues,
        },
      ]);
      setCheckboxSelectedItems(
        filterItem?.activeFilterValues.map((x) => x.value)
      );
    }
  }, []);

  const selectedFilterValues =
    selectedFilters
      .filter((x) => x.field.value === filterItem?.field.value)[0]
      ?.activeFilterValues?.map((x) => x.value) || [];

  /**
   * This hook listens to changes the filterUpdated prop and invokes the `onFilter` callback.
   * The `filterUpdated` is only updated from within the ListActionBar context i.e. by changing the filter from the
   * dropdowns or by removing filters from the selected filter bar.
   * The invocation of `onFilter` should be skipped during component initialization, as this results in multiple
   * BE calls when multiple filters have initially set values.
   * After the `onFilter` callback is invoked the `filterUpdated` is set to its initial value i.e. undefined to
   * effectively trigger this hook in case the filter object reference remains unchanged.
   */
  useEffect(() => {
    if (updatedFilters !== undefined) {
      setCheckboxSelectedItems(selectedFilterValues);
      onFilter(selectedFilterValues);
      setUpdatedFilters(undefined);
    }
  }, [updatedFilters]);

  const onChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    setCheckboxSelectedItems(selectedValues);
  };

  const applyFilters = () => {
    const filters = upsertObjectInArray(
      [...selectedFilters],
      {
        field: filterItem.field,
        activeFilterValues: filterItem?.filterValues?.filter((x) =>
          checkboxSelectedItems.includes(x.value)
        ),
      },
      'field'
    );

    setSelectedFilters(filters);
    setUpdatedFilters(filters);
    setOpen(false);
  };

  return (
    <Stack direction="row" spacing={2} className={styles.filtering_wrapper}>
      {showFilterIcon && (
        <SvgIcon
          component={FilterIcon}
          className={styles.icon}
          viewBox={'0 0 32 32'}
        />
      )}
      <Stack direction="row" spacing={2}>
        <Stack
          key={filterItem?.field.value}
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Select
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={onChange}
            className={classnames(styles.filter_select_field, {
              [styles.opened]: open,
            })}
            IconComponent={ArrowDownIcon}
            multiple
            displayEmpty
            value={checkboxSelectedItems || []}
            renderValue={() => t(filterItem?.field.label)}
            MenuProps={{
              classes: {
                list: styles.filter_list,
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            <MenuItem value={''} disabled={true}>
              {t('filter_by')}
            </MenuItem>
            {filterItem?.filterValues?.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                disableRipple
                className={classnames([styles.filter_checkbox], {
                  [styles.selected]: checkboxSelectedItems?.includes(
                    item.value
                  ),
                })}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checkboxSelectedItems?.includes(item.value)}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}

            <Stack
              className={styles.filter_actions}
              spacing={1}
              direction="row"
            >
              <Button
                disableRipple
                color="secondary"
                variant="outlined"
                className={styles.filter_action_cancel}
                onClick={() => setOpen(false)}
              >
                {t('cancel')}
              </Button>
              <Button disableRipple variant="contained" onClick={applyFilters}>
                {t('confirm')}
              </Button>
            </Stack>
          </Select>
        </Stack>
      </Stack>
    </Stack>
  );
};
