import { useCallback, useState } from 'react';

import { UserPreferences } from 'shared/interfaces';

type useLocalStorageType<T> = [state: T, wrappedSetState: (value: T) => void];
const prefix = 'so1-';

export const useLocalStorage = <T>(
  key: keyof UserPreferences,
  initialValue: T
): useLocalStorageType<T> => {
  const [state, setState] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(prefix + key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const wrappedSetState = useCallback(
    (update) => {
      setState((currentState) => {
        const nextState =
          typeof update === 'function' ? update(currentState) : update;
        window.localStorage.setItem(prefix + key, JSON.stringify(nextState));
        return nextState;
      });
    },
    [key]
  );

  return [state, wrappedSetState];
};
