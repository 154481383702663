import { ReactElement, ReactNode } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/x.svg';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import { PageHeader } from '../PageHeader/PageHeader';
import styles from './DialogWrapper.module.scss';

interface DialogProps {
  className?: string;
  dialogActions: ReactNode;
  dialogContent: ReactNode;
  fullScreen?: boolean;
  handleClose: () => void;
  headerText: string;
  id?: string;
  open?: boolean;
  showCloseIcon?: boolean;
  subHeaderText?: string;
  width?: number | string;
}

export const DialogWrapper = ({
  className,
  dialogActions,
  dialogContent,
  fullScreen = false,
  handleClose,
  headerText,
  open = true,
  subHeaderText,
  showCloseIcon = true,
  width,
}: DialogProps): ReactElement => {
  return (
    <Dialog
      open={open}
      className={`${styles.dialog_wrapper} ${className ? className : ''}`}
      sx={{
        '& .MuiDialog-paper': {
          width: fullScreen ? 'auto' : width || '800',
          maxWidth: fullScreen ? 'auto' : '80vw',
          backgroundColor: (theme) => theme.palette.grey[50],
        },
      }}
      fullScreen={fullScreen}
      disablePortal={true}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={12} className={styles.title_container}>
            <PageHeader
              className={styles.title}
              headerText={headerText || ''}
              subHeaderText={subHeaderText || ''}
            />
            {showCloseIcon && (
              <IconButton onClick={handleClose} data-testid="close-dialog">
                <SvgIcon component={CloseIcon} viewBox={'0 0 32 32'} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      {dialogContent && (
        <DialogContent className={styles.dialog_content}>
          {dialogContent}
        </DialogContent>
      )}
      {dialogActions && (
        <DialogActions className={styles.dialog_actions}>
          {dialogActions}
        </DialogActions>
      )}
    </Dialog>
  );
};
