import { Grid, Typography } from '@mui/material';

import { AccountInfoStyle } from './AccountInfo.style';

type AccountInfoProps = {
  user: any;
};

export const AccountInfo = (props: AccountInfoProps) => {
  const { user } = props;
  return (
    <AccountInfoStyle>
      <Grid flexDirection="column">
        {user && (
          <Grid
            item
            sx={{
              marginBottom: (theme) => theme.spacing(1.5),
            }}
          >
            <Typography variant="body1">{user.email}</Typography>
          </Grid>
        )}
      </Grid>
    </AccountInfoStyle>
  );
};
