import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as ViewIcon } from 'assets/icons/eye.svg';
import classNames from 'classnames';

import {
  useGetTaxonomyAttributesByIDQuery,
  useGetTaxonomyProductsByGTINQuery,
} from 'store/api/endpoints/productTaxonomies';

import { Button } from '@mui/material';

import { PopperStep, SelectionBoxProps } from '../Rule';
import styles from '../Rule.module.scss';

interface Props extends SelectionBoxProps {
  productGtins: string[];
  search_query?: string;
  taxonomyId: string;
  productAttributes: string[];
  setProductsModalOpen: (open: boolean) => void;
}

export const SelectProducts = ({
  disabled,
  selected,
  hasError,
  readonly,
  taxonomyId,
  search_query,
  productGtins,
  productAttributes,
  setProductsModalOpen,
}: Props) => {
  const { t } = useTranslation();

  const textDividerElement = (
    <span className={styles.divider}> {t('or')} </span>
  );

  const [label, setLabel] = useState<ReactElement>();

  // get attribute name
  const { currentData: attribute } = useGetTaxonomyAttributesByIDQuery(
    {
      product_taxonomy_id: taxonomyId,
      attribute_ids: [productAttributes[0]],
    },
    {
      skip: !taxonomyId || !productAttributes || !productAttributes?.length,
    }
  );

  // get products name
  const { currentData: product } = useGetTaxonomyProductsByGTINQuery(
    {
      product_taxonomy_id: taxonomyId,
      gtins: [productGtins[0]],
    },
    {
      skip: !taxonomyId || !productGtins || !productGtins?.length,
    }
  );

  useEffect(() => {
    const labelElement = getSelectedAttributesLabel() ||
      getSelectedProductsLabel() || <>{t('add_products')}</>;
    setLabel(labelElement);
  }, [attribute, product, productGtins, productAttributes]);

  const getSelectedAttributesLabel = useCallback(() => {
    const attributeName = attribute?.attributes[0]?.name?.trim();

    if (!attributeName && !search_query) return;

    return (
      <>
        {search_query && (
          <>
            "{search_query}"{attributeName && textDividerElement}
          </>
        )}
        {attributeName}
        {productAttributes.length > 1 && (
          <>
            {textDividerElement}
            {productAttributes.length - 1} {t('other_attributes')}
          </>
        )}
      </>
    );
  }, [productAttributes, attribute, search_query]);

  const getSelectedProductsLabel = useCallback(() => {
    const productName = product?.products[0]?.product_name?.trim();

    if (!productName) return;

    return (
      <>
        {productName}
        {productGtins.length > 1 && (
          <>
            {textDividerElement}
            {productGtins.length - 1} {t('other_products')}
          </>
        )}
      </>
    );
  }, [productGtins, product]);

  return (
    <>
      <Button
        className={classNames(styles.block, {
          [styles.disabled]: disabled,
          [styles.selected]: selected,
          [styles.error]: hasError,
        })}
        disabled={disabled}
        id={PopperStep.PRODUCTS}
        onClick={(e) => {
          e.stopPropagation();
          setProductsModalOpen(true);
        }}
        endIcon={
          readonly ? (
            <ViewIcon className={styles.icon} />
          ) : (
            <EditIcon className={styles.icon} />
          )
        }
      >
        <span>{label}</span>
      </Button>
    </>
  );
};
