import { MutableRefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useElementOnScreen } from 'hooks';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { definitions, operations } from 'types/api';

import { Routes } from 'routes/Routes';

import { useStoreDispatch } from 'store';
import { useGetUniversesQuery } from 'store/api/endpoints/universes';
import { selectDraftCampaign } from 'store/modules/campaign/selectors';
import { updateDraft } from 'store/modules/campaign/slice';
import { selectAdvertiser } from 'store/modules/settings/selectors';

import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';
import { UniversesList } from 'components/Universes/UniversesList/UniversesList';

import { Button, CircularProgress, Stack } from '@mui/material';

import { UniverseListActionBar } from './UniverseListActionBar/UniverseListActionBar';
import styles from './UniverseSection.module.scss';

interface Props {
  retailerId?: string;
  onClose: () => void;
}
export const UniverseSelectionDialog = ({ onClose, retailerId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const advertiser = useSelector(selectAdvertiser);
  const draftCampaign = useSelector(selectDraftCampaign);

  const [page, setPage] = useState(DEFAULT_API_PARAMETERS.page);
  const [universeSearchTerm, setUniverseSearchTerm] = useState<string>('');
  const [isCreatingNewUniverse, setIsCreatingNewUniverse] =
    useState<boolean>(false);
  const [filter, setFilter] = useState<{
    builder_type?: string;
  }>({
    builder_type: undefined,
  });
  const [allUniverses, setAllUniverses] = useState<definitions['Universe'][]>(
    []
  );

  const {
    data: universeData,
    isLoading,
    isFetching,
  } = useGetUniversesQuery(
    {
      page,
      retailer_id: retailerId,
      country_code: draftCampaign?.country_code,
      search: universeSearchTerm,
      advertiser_id: [advertiser!.id],
      builder_type:
        filter?.builder_type as operations['UniverseService_GetAllUniverses']['parameters']['query']['builder_type'],
    },
    {
      skip: !retailerId || !advertiser?.id,
      refetchOnMountOrArgChange: true,
    }
  );

  const [containerRef, isVisible] = useElementOnScreen(
    {},
    allUniverses.length === universeData?.pagination?.results
  );

  useEffect(() => {
    if (isVisible) {
      setPage((previousPage: number) => previousPage + 1);
    }
  }, [isVisible]);

  useEffect(() => {
    if (universeData) {
      if (page > 0)
        setAllUniverses((prevUniverses) => [
          ...prevUniverses,
          ...(universeData?.universes || []),
        ]);
      else setAllUniverses(universeData?.universes || []);
    }
  }, [universeData]);

  return (
    <>
      <DialogWrapper
        width={800}
        headerText={t('select_a_universe')}
        handleClose={onClose}
        dialogContent={
          <>
            <div className={styles.action_bar_wrapper}>
              <UniverseListActionBar
                activeSearchTerm={universeSearchTerm}
                activeBuilderTypeFilter={filter.builder_type}
                onSearch={(searchTerm: string) => {
                  setPage(0);
                  setUniverseSearchTerm(searchTerm);
                }}
                onFilter={(filterName: string, filterValue?: string) => {
                  setPage(0);
                  setFilter({ ...filter, [filterName]: filterValue });
                }}
              />
            </div>

            {!isLoading && (
              <>
                {allUniverses?.length ? (
                  <>
                    <UniversesList
                      onItemClick={(universe) => {
                        dispatch(
                          updateDraft({
                            universe_id: universe.id,
                            universe: universe,
                          })
                        );
                        onClose();
                      }}
                      universes={allUniverses || []}
                      dialogMode={true}
                      itemClickMode="select"
                    />
                    <div ref={containerRef as MutableRefObject<null>}></div>
                  </>
                ) : (
                  <div className={styles.no_results_wrapper}>
                    <div>{t('no_universe_for_country')}</div>
                    {isCreatingNewUniverse ? (
                      <Button
                        className={styles.action_button}
                        variant="outlined"
                        onClick={() => setIsCreatingNewUniverse(false)}
                      >
                        {t('refresh')}
                      </Button>
                    ) : (
                      <Button
                        className={styles.action_button}
                        variant="contained"
                        href={`${Routes.UNIVERSES}/create`}
                        target="_blank"
                        onClick={() => setIsCreatingNewUniverse(true)}
                      >
                        {t('create_new_universe')}
                      </Button>
                    )}
                  </div>
                )}
              </>
            )}
            {isFetching && (
              <Stack justifySelf="center" alignItems="center">
                <CircularProgress />
              </Stack>
            )}
          </>
        }
        dialogActions={
          <Button onClick={onClose} variant="outlined">
            {t('cancel')}
          </Button>
        }
      />
    </>
  );
};
