import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormError, InputValidationMessage } from 'components/Shared/CustomMui';

import { Link } from '@mui/material';

import styles from './CampaignSectionWrapper.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  header: string;
  subHeader?: string;
  required?: boolean;
  error?: InputValidationMessage;
}

export const CampaignSectionWrapper = ({
  header,
  subHeader,
  children,
  required,
  error,
}: Props) => {
  const { t } = useTranslation();

  const LINE_HEIGHT_IN_PIXEL = 19;
  const LINES_TO_DISPLAYED = 2;
  const [showMore, setShowMore] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const [subHeaderClass, setSubHeaderClass] = useState<string>();
  const [subHeaderLines, setSubHeaderLines] = useState<number>(0);

  const calcNumberOfLines = () => {
    const subHeaderInnerHeight = ref.current?.clientHeight;
    /**
     * Get the inner height of an element in pixels and divide them by 19 (LINE_HEIGHT_IN_PIXEL: which is the
     * line-height of html body) to calculate the total number of lines displayed in sub header element.
     * So that we can restrict the number of line to 2 (LINES_TO_DISPLAYED) with css classname.
     */
    setSubHeaderLines(
      subHeaderInnerHeight ? subHeaderInnerHeight / LINE_HEIGHT_IN_PIXEL : 0
    );
  };

  useEffect(() => {
    calcNumberOfLines();
    /**
     * Classname to shorten the number of lines for subHeader has to be applied only
     * after we calculate the number of lines based on subHeader's initial inner height
     */
    setSubHeaderClass(styles.sub_header__content_show_less);
  }, []);

  /**
   * Toggle between show all and show less css classes for sub header element
   */
  const toggleShowMore = () => {
    if (!showMore) {
      setSubHeaderClass(styles.sub_header__content_show_all);
      setShowMore(!showMore);
    } else {
      setSubHeaderClass(styles.sub_header__content_show_less);
      setShowMore(!showMore);
    }
  };

  return (
    <div data-testid={header} className={styles.section}>
      <div className={styles.header}>
        {header}
        {required && <span className={styles.required}> *</span>}
      </div>
      {subHeader && (
        <div className={styles.sub_header}>
          <div ref={ref} className={subHeaderClass}>
            {subHeader}
          </div>
          {/* Only display show more button when sub header element has more than 2 lines */}
          {subHeaderLines > LINES_TO_DISPLAYED && (
            <Link
              className={styles.more}
              variant="body2"
              onClick={toggleShowMore}
            >
              {showMore ? t('show_less') : t('show_more')}
            </Link>
          )}
        </div>
      )}

      <>{children}</>
      {error && <FormError>{error}</FormError>}
    </div>
  );
};
