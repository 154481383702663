import { useTranslation } from 'react-i18next';

import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate.svg';

import { CampaignFormStatus } from 'hooks/useCampaignFormState';

import { Button, SvgIcon } from '@mui/material';

interface Props {
  formState: CampaignFormStatus;
  cloneCampaign: () => void;
}

export const CampaignOverviewActions = ({
  cloneCampaign,
  formState,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {formState.formActions.includes('clone') && (
        <Button
          size="small"
          variant="text"
          startIcon={
            <SvgIcon component={DuplicateIcon} viewBox={'0 0 32 32'} />
          }
          onClick={cloneCampaign}
        >
          {t('clone_campaign')}
        </Button>
      )}
    </>
  );
};
