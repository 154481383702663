import * as React from 'react';

export type CampaignGroupAccordionContextType = {
  numberOfTableItems: number;
  setNumberOfTableItems: (count: number) => void;
};

export const CampaignGroupAccordionContext =
  React.createContext<CampaignGroupAccordionContextType | null>(null);

export const CampaignGroupAccordionProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const [numberOfTableItems, setNumberOfTableItems] = React.useState<number>(0);

  return (
    <CampaignGroupAccordionContext.Provider
      value={{
        numberOfTableItems,
        setNumberOfTableItems,
      }}
    >
      {children}
    </CampaignGroupAccordionContext.Provider>
  );
};
