import { useTranslation } from 'react-i18next';

import { differenceInCalendarDays } from 'date-fns';
import { FormFieldType } from 'hooks';
import { ExtendedCampaign } from 'shared/interfaces/Campaign';

import { useStoreDispatch } from 'store';
import {
  setDraftTouchedFields,
  updateDraft,
} from 'store/modules/campaign/slice';
import { getCampaignValidationLimits } from 'store/modules/campaign/validations';

import { NumberInput } from 'components/Shared/CustomMui';

import { Grid } from '@mui/material';

import { CampaignSectionProps } from '../CampaignDetails';
import { CampaignSectionWrapper } from './CampaignSectionWrapper';

type Props = {
  campaign: ExtendedCampaign;
} & CampaignSectionProps;

export const Budget = ({ campaign, formState, validate }: Props) => {
  const {
    budget,
    target_daily_budget,
    advertiser_cpm_bid,
    advertiser_cpa_bid,
    advertiser_cpc_bid,
    used_budget_total_cumulative,
    campaign_start,
    campaign_end,
  } = campaign;

  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const campaignValidationLimits = getCampaignValidationLimits(campaign);

  const campaignBudgetLimit =
    Number(used_budget_total_cumulative || 0) +
    campaignValidationLimits.min_budget;

  const showBudgetSection = ['CPM', 'CPA', 'Budget'].some(
    (fieldType: string) =>
      !formState.hiddenFields.includes(fieldType as FormFieldType)
  );

  const showCPAField = !formState.hiddenFields.includes('CPA');

  const showCPMField = !formState.hiddenFields.includes('CPM');

  const showCPCField = !formState.hiddenFields.includes('CPC');

  const showTargetDailyBudgetField =
    !formState.hiddenFields.includes('TargetDailyBudget');

  let linearPacingTargetBudgetValue;
  if (campaign_start && campaign_end && budget) {
    const remainingBudget =
      Number(budget) - (Number(used_budget_total_cumulative) || 0);
    const remainingDays =
      differenceInCalendarDays(
        new Date(campaign_end),
        new Date(campaign_start)
      ) + 1; // campaign_start to campaign_end date inclusive

    const targetDailyBudget = remainingBudget / remainingDays;
    const targetDailyBudgetLimit = Math.max(
      targetDailyBudget,
      getCampaignValidationLimits(campaign).min_daily_budget
    );

    linearPacingTargetBudgetValue = t('recommended_target_daily_budget', {
      currencySymbol: campaign?.currency?.symbol,
      target_daily_budget: new Intl.NumberFormat('de-DE', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(targetDailyBudgetLimit),
    });
  }

  const getParsedFloatValue = (value: string): string => {
    return !!value ? parseFloat(value).toFixed(2) : '';
  };

  return (
    <>
      {showBudgetSection && (
        <CampaignSectionWrapper
          header={t('budget')}
          subHeader={t('subheader_campaign_budget')}
        >
          <Grid container rowSpacing={0} columnSpacing={{ xs: 0 }}>
            {!formState.hiddenFields.includes('Budget') && (
              <Grid item xs={6}>
                <NumberInput
                  id="budget"
                  label={t('budget')}
                  error={validate('budget', {
                    budget: campaignBudgetLimit,
                    currencySymbol: campaign?.currency?.symbol,
                  })}
                  onBlur={() => {
                    dispatch(setDraftTouchedFields('budget'));
                    dispatch(setDraftTouchedFields('target_daily_budget'));
                  }}
                  onChange={(e) =>
                    dispatch(
                      updateDraft({
                        budget: getParsedFloatValue(e.target.value),
                      })
                    )
                  }
                  prefix={campaign?.currency?.symbol}
                  value={budget}
                  disabled={formState.readonlyFields.includes('Budget')}
                  decimalScale={2}
                  required
                />
              </Grid>
            )}
            {showCPMField && (
              <Grid item xs={6}>
                <NumberInput
                  id="advertiser_cpm_bid"
                  label={t('cpm_bid')}
                  decimalScale={2}
                  error={validate('advertiser_cpm_bid', {
                    limit: campaignValidationLimits.min_cpm_bid,
                    currencySymbol: campaign?.currency?.symbol,
                  })}
                  onBlur={() =>
                    dispatch(setDraftTouchedFields('advertiser_cpm_bid'))
                  }
                  onChange={(e) =>
                    dispatch(
                      updateDraft({
                        advertiser_cpm_bid: getParsedFloatValue(e.target.value),
                      })
                    )
                  }
                  prefix={campaign?.currency?.symbol}
                  value={advertiser_cpm_bid}
                  disabled={formState.readonlyFields.includes('CPM')}
                  required
                />
              </Grid>
            )}
            {showCPAField && (
              <Grid item xs={6}>
                <NumberInput
                  id="advertiser_cpa_bid"
                  label={t('cpa_bid')}
                  decimalScale={2}
                  error={validate('advertiser_cpa_bid', {
                    limit: campaignValidationLimits.min_cpa_bid,
                    currencySymbol: campaign?.currency?.symbol,
                  })}
                  onBlur={() =>
                    dispatch(setDraftTouchedFields('advertiser_cpa_bid'))
                  }
                  onChange={(e) =>
                    dispatch(
                      updateDraft({
                        advertiser_cpa_bid: getParsedFloatValue(e.target.value),
                      })
                    )
                  }
                  prefix={campaign?.currency?.symbol}
                  value={advertiser_cpa_bid}
                  disabled={formState.readonlyFields.includes('CPA')}
                  required
                />
              </Grid>
            )}
            {showCPCField && (
              <Grid item xs={6}>
                <NumberInput
                  id="advertiser_cpc_bid"
                  label={t('cpc_bid')}
                  decimalScale={2}
                  error={validate('advertiser_cpc_bid', {
                    limit: campaignValidationLimits.min_cpc_bid,
                    currencySymbol: campaign?.currency?.symbol,
                  })}
                  onBlur={() =>
                    dispatch(setDraftTouchedFields('advertiser_cpc_bid'))
                  }
                  onChange={(e) =>
                    dispatch(
                      updateDraft({
                        advertiser_cpc_bid: getParsedFloatValue(e.target.value),
                      })
                    )
                  }
                  prefix={campaign?.currency?.symbol}
                  value={advertiser_cpc_bid}
                  disabled={formState.readonlyFields.includes('CPC')}
                  required
                />
              </Grid>
            )}
          </Grid>
          {showTargetDailyBudgetField && (
            <Grid container rowSpacing={0} columnSpacing={{ xs: 0 }}>
              <Grid item xs={6}>
                <NumberInput
                  id="target_daily_budget"
                  label={t('target_daily_budget')}
                  decimalScale={2}
                  error={validate('target_daily_budget', {
                    limit: campaignValidationLimits.min_daily_budget,
                    currencySymbol: campaign?.currency?.symbol,
                  })}
                  onBlur={() => {
                    dispatch(setDraftTouchedFields('target_daily_budget'));
                    dispatch(setDraftTouchedFields('budget'));
                  }}
                  onChange={(e) =>
                    dispatch(
                      updateDraft({
                        target_daily_budget: getParsedFloatValue(
                          e.target.value
                        ),
                      })
                    )
                  }
                  prefix={campaign?.currency?.symbol}
                  value={target_daily_budget}
                  disabled={formState.readonlyFields.includes(
                    'TargetDailyBudget'
                  )}
                  helper={linearPacingTargetBudgetValue}
                  required
                />
              </Grid>
            </Grid>
          )}
        </CampaignSectionWrapper>
      )}
    </>
  );
};
