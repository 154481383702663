import { FC, MutableRefObject, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { tkUniverseBuildType } from 'i18n/translationKeyMaps';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { UserRole } from 'types';
import { definitions } from 'types/api';

import { Routes } from 'routes/Routes';

import { useLazyGetUniversesQuery } from 'store/api/endpoints/universes';
import { selectAdvertiser } from 'store/modules/settings/selectors';
import { selectUser } from 'store/modules/user/selectors';

import { useElementOnScreen } from 'hooks/useElementVisible';

import { AccessResolver } from 'components/Shared/AccessResolver/AccessResolver';
import { PageHeader } from 'components/Shared/CustomMui';
import { EntitySelectedFilterType } from 'components/Shared/ListActionBar/components';
import LoadingIndicator from 'components/Shared/LoadingIndicator/LoadingIndicator';

import { Button, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import RemoveUniverse from './RemoveUniverse/RemoveUniverse';
import { UniverseListFilters } from './UniverseListFilters/UniverseListFilters';
import styles from './Universes.module.scss';
import { UniversesList } from './UniversesList/UniversesList';

export const Universes: FC = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [universeToBeRemoved, setUniverseToBeRemoved] = useState<
    null | definitions['Universe']
  >(null);

  const advertiser = useSelector(selectAdvertiser);
  const [searchParams, setSearchParams] = useSearchParams();

  const user = useSelector(selectUser);
  const [allUniverses, setAllUniverses] = useState<definitions['Universe'][]>(
    []
  );

  const [page, setPage] = useState(DEFAULT_API_PARAMETERS.page);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filters, setFilters] = useState<EntitySelectedFilterType[]>([
    {
      field: {
        label: t('retailer'),
        value: 'retailer_id',
      },
      activeFilterValues: searchParams
        .getAll('retailer_id')
        .map((retailerId) => ({
          label: retailerId,
          value: retailerId,
        })),
    },
    {
      field: {
        label: t('type'),
        value: 'type',
      },
      activeFilterValues: searchParams.getAll('type').map((builderType) => ({
        label: tkUniverseBuildType(
          builderType as definitions['UniverseBuildType']
        ),
        value: builderType,
      })),
    },
  ]);

  const [
    getUniversesPerPage,
    {
      data: universeData,
      isLoading: isUniversesLoading,
      isFetching: isUniversesFetching,
    },
  ] = useLazyGetUniversesQuery();

  const [containerRef, isVisible] = useElementOnScreen(
    {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    },
    !universeData?.universes?.length ||
      universeData?.pagination.results === allUniverses?.length
  );

  useEffect(() => {
    const fetchUniverses = async () => {
      if (user?.advertiser_id) {
        await getUniversesPerPage({
          search: searchTerm,
          retailer_id: filters?.find(
            (filter) => filter?.field?.value === 'retailer_id'
          )?.activeFilterValues?.[0]?.value,
          advertiser_id: [user?.advertiser_id],
          builder_type: filters
            .find((filter) => filter?.field?.value === 'type')
            ?.activeFilterValues?.map(
              (filter) => filter.value as definitions['UniverseBuildType']
            ),
          page,
        });
      }
    };

    fetchUniverses();
  }, [user?.advertiser_id, page, searchTerm, filters]);

  useEffect(() => {
    setAllUniverses([]);
  }, [user?.advertiser_id]);

  useEffect(() => {
    const setUniverses = async () => {
      if (universeData?.universes.length) {
        setAllUniverses(
          (previousUniverses: definitions['Universe'][]) =>
            page > 1
              ? previousUniverses.concat(universeData?.universes)
              : [...universeData?.universes] // This is needed in case of universe update. A change in object reference to trigger re-render
        );
      }
    };
    setUniverses();
  }, [universeData]);

  useEffect(() => {
    if (isUniversesLoading || isUniversesFetching) {
      return;
    }
    if (isVisible) {
      setPage((previousPage: number) => previousPage + 1);
    }
  }, [isVisible]);

  const onCreateNew = () => {
    navigate(`${Routes.UNIVERSES}/create`);
  };

  const onImportStaticUniverse = (): void => {
    navigate(`${Routes.UNIVERSES}/import`);
  };

  const getUniverses = (): definitions['Universe'][] => {
    if (searchTerm) {
      return allUniverses
        ? allUniverses?.filter((universe: definitions['Universe']) =>
            universe.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : [];
    }
    return allUniverses ? allUniverses : [];
  };

  const handleFilterChange = (selectedFilters: EntitySelectedFilterType[]) => {
    // update url search params
    const searchParams = new URLSearchParams();
    selectedFilters.forEach((filterValue) => {
      filterValue.activeFilterValues?.forEach((activeFilterValue) => {
        if (filterValue?.field?.value && activeFilterValue?.value)
          searchParams.append(filterValue.field.value, activeFilterValue.value);
      });
    });
    setSearchParams(searchParams);

    // reset filters
    if (selectedFilters.length === 0) {
      setFilters([]);
      return;
    }

    setPage(1);
    setFilters(selectedFilters);
  };

  return (
    <>
      <LoadingIndicator
        isAppLoading={isUniversesLoading || isUniversesFetching}
      />
      <PageHeader
        headerText={t('universes')}
        subHeaderText={t('subheader_universes')}
      />
      <UniverseListFilters
        activeSearchTerm={searchTerm}
        onSearch={(searchTerm: string) => {
          setPage(1);
          setSearchTerm(searchTerm);
        }}
        initialFilters={filters}
        onFilter={(filters: EntitySelectedFilterType[]) => {
          setAllUniverses([]);
          handleFilterChange(filters);
        }}
      />
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ marginBottom: '1rem' }}
      >
        <Typography variant={'h3'}>
          {t('results')}: {universeData?.pagination.results || 0}
        </Typography>
        <Stack direction="row" spacing={2}>
          <AccessResolver
            requiredRoles={[UserRole.KEY_ACCOUNT_MANAGER]}
            additionalCondition={advertiser?.allow_static_universes_upload}
          >
            <Button
              data-testid="import-button"
              size="small"
              onClick={onImportStaticUniverse}
              variant="outlined"
              color="secondary"
              startIcon={
                <UploadIcon
                  className={styles.button_start_icon}
                  viewBox={'0 0 32 32'}
                />
              }
            >
              {t('upload_universe')} (.csv)
            </Button>
          </AccessResolver>
          <Button size="small" onClick={onCreateNew} variant="contained">
            {`+ ${t('new_universe')}`}
          </Button>
        </Stack>
      </Grid>
      {user?.advertiser_id && (
        <>
          <UniversesList
            onItemClick={(universe) =>
              navigate(`${Routes.UNIVERSES}/${universe?.id}`)
            }
            onItemRemove={(universe) => setUniverseToBeRemoved(universe)}
            universes={getUniverses()}
            itemClickMode="edit"
          />
          {!!getUniverses()?.length &&
            !isUniversesLoading &&
            !isUniversesFetching && (
              <Grid
                item
                xs={12}
                className={styles.loading_container_ref}
                ref={containerRef as MutableRefObject<null>}
              />
            )}
        </>
      )}
      {!!universeToBeRemoved && (
        <RemoveUniverse
          universe={universeToBeRemoved}
          onClose={() => {
            setUniverseToBeRemoved(null);
            setPage(1);
          }}
        />
      )}
    </>
  );
};
