import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { format, utcToZonedTime } from 'date-fns-tz';
import { tkCampaignExecutionStatus } from 'i18n/translationKeyMaps';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { useQuery } from 'utils';

import { Routes } from 'routes/Routes';

import { useGetCampaignExecutionsQuery } from 'store/api/endpoints/campaignExecution';

import { PageHeader, Table } from 'components/Shared/CustomMui';

import Grid from '@mui/material/Grid';

import styles from './Executions.module.scss';

const Executions: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchQuery = useQuery();
  const page = Number(searchQuery.get('page') || DEFAULT_API_PARAMETERS.page);

  const { data: campaignExecutionsQueryData } = useGetCampaignExecutionsQuery({
    page,
    per_page: DEFAULT_API_PARAMETERS.per_page,
  });

  const tableColumns = [
    {
      field: 'createdAt',
      headerName: `${t('start_time')} (CET)`,
    },
    {
      field: 'execution_status',
      headerName: t('state'),
    },
    {
      field: 'lastEvent',
      headerName: `${t('last_event_time')} (CET)`,
    },
  ];

  const tableRows = campaignExecutionsQueryData?.results.map((execution) => {
    const { id, execution_status, last_event_time, created_at } = execution;
    return {
      id: id,
      createdAt: format(
        utcToZonedTime(created_at, 'Europe/Berlin'),
        'do MMM yyyy HH:mm',
        { timeZone: 'Europe/Berlin' }
      ),
      lastEvent: format(
        utcToZonedTime(last_event_time || created_at, 'Europe/Berlin'),
        'do MMM yyyy HH:mm',
        { timeZone: 'Europe/Berlin' }
      ),
      execution_status: t(tkCampaignExecutionStatus(execution_status)),
    };
  });

  const handlePageChange = (_e: MouseEvent, newPage: number) => {
    navigate(`${Routes.EXECUTIONS}?page=${newPage}`);
  };

  const handleRowClick = (id: string) => {
    navigate(`${Routes.EXECUTIONS}/${id}`);
  };

  return (
    <>
      <PageHeader headerText={t('executions')} subHeaderText={''} />
      <Grid className={styles.content_wrapper} container wrap="nowrap">
        <Table
          emptyState={<>{t('no_executions')}</>}
          columns={tableColumns}
          rows={tableRows || []}
          onRowClick={handleRowClick}
          minHeight={DEFAULT_API_PARAMETERS.per_page}
          pagination={{
            onPageChange: handlePageChange,
            page: campaignExecutionsQueryData?.query.page || page,
            per_page:
              campaignExecutionsQueryData?.query.per_page ||
              DEFAULT_API_PARAMETERS.per_page,
            results: campaignExecutionsQueryData?.query.results || 0,
          }}
        />
      </Grid>
    </>
  );
};

export default Executions;
