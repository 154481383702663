import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { tkUniverseBuildType } from 'i18n/translationKeyMaps';
import { definitions } from 'types/api';

import { useGetRetailersQuery } from 'store/api/endpoints/retailer';
import { selectUser } from 'store/modules/user/selectors';

import { ListActionBar } from 'components/Shared/ListActionBar/ListActionBar';
import {
  EntityFilterType,
  EntitySelectedFilterType,
} from 'components/Shared/ListActionBar/components/Filters/types';

import { Box } from '@mui/material';

import styles from './UniverseListFilters.module.scss';

interface UniverseListFiltersProps {
  activeSearchTerm: string;
  initialFilters?: EntitySelectedFilterType[];
  onSearch: (searchTerm: string) => void;
  onFilter: (filters: EntitySelectedFilterType[]) => void;
}

export const UniverseListFilters: FC<UniverseListFiltersProps> = (
  props: UniverseListFiltersProps
): ReactElement => {
  const { t } = useTranslation();
  const { activeSearchTerm, initialFilters, onFilter, onSearch } = props;
  const user = useSelector(selectUser);
  const { data: retailers = [] } = useGetRetailersQuery(undefined, {
    skip: !user?.advertiser_id,
    refetchOnMountOrArgChange: true,
  });

  const retailerIdFilter: EntityFilterType = {
    field: { label: t('retailer'), value: 'retailer_id' },
    filterValues: retailers.map((retailer: definitions['Retailer']) => ({
      label: retailer?.display_name,
      value: retailer?.id,
    })),
  };

  const universeTypeFilter: EntityFilterType = {
    field: {
      label: t('type'),
      value: 'type',
    },
    filterValues: [
      {
        label: tkUniverseBuildType('UNIVERSE_BUILD_TYPE_BUILDER'),
        value: 'UNIVERSE_BUILD_TYPE_BUILDER',
      },
      {
        label: tkUniverseBuildType('UNIVERSE_BUILD_TYPE_EXTERNAL'),
        value: 'UNIVERSE_BUILD_TYPE_EXTERNAL',
      },
      {
        label: tkUniverseBuildType('UNIVERSE_BUILD_TYPE_IMPORT'),
        value: 'UNIVERSE_BUILD_TYPE_IMPORT',
      },
    ],
  };

  const handleSearchTermChange = (term: string): void => {
    if (term !== activeSearchTerm) {
      onSearch(term);
    }
  };

  return (
    <Box className={styles.action_bar}>
      <ListActionBar
        initialFilters={initialFilters}
        onFiltersChanged={(filters) => {
          onFilter(filters);
        }}
      >
        <ListActionBar.Search
          onSearch={handleSearchTermChange}
        ></ListActionBar.Search>
        <ListActionBar.SingleSelectFilter
          showFilterIcon={true}
          filterItem={retailerIdFilter}
          onFilter={() => {}}
        ></ListActionBar.SingleSelectFilter>
        <ListActionBar.MultiSelectFilter
          filterItem={universeTypeFilter}
          onFilter={() => {}}
        ></ListActionBar.MultiSelectFilter>
      </ListActionBar>
    </Box>
  );
};
