import { Stack } from '@mui/material';

import styles from './CountryNameWithFlag.module.scss';

interface Props {
  countryName: string;
  countryCode?: string;
}

// fix the mismatch between some country codes and their flags
const overrideFlags: { [key: string]: string } = {
  HR: 'CR-1',
  CS: 'ES',
};

export const CountryNameWithFlag = ({
  countryCode,
  countryName,
  ...rest
}: Props) => {
  return (
    <Stack direction="row" spacing={1} alignItems="start">
      {countryCode && (
        <img
          src={`/img/flags/${overrideFlags[countryCode] || countryCode}.svg`}
          alt={countryCode}
          {...rest}
        />
      )}
      <span className={styles.country_name}>{countryName}</span>
    </Stack>
  );
};
