import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import classNames from 'classnames';
import { parseCSV } from 'utils/string';

import { FormError } from 'components/Shared/CustomMui';

import { Button, Chip, SvgIcon, Typography } from '@mui/material';

import styles from './StaticUniverseUploader.module.scss';

interface Props {
  onSuccessfulUpload: (shoppers: string[]) => void;
}

export const StaticUniverseUploader = ({ onSuccessfulUpload }: Props) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [fileName, setFileName] = useState('');

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader();
    reader.onerror = () => {
      setHasError(true);
      reader.abort();
    };
    reader.onload = () => {
      // Parse CSV file
      if (reader.result !== null) {
        const parsedData = parseCSV(reader.result as string);
        if (parsedData.length > 0) {
          setHasError(false);
        } else setHasError(true);
        onSuccessfulUpload(parsedData);
        setFileName(acceptedFiles[0].name);
      }
    };

    // read the file content
    if (acceptedFiles.length > 0) reader.readAsText(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv, application/vnd.ms-excel, text/csv',
  });
  const className = classNames(styles.upload_zone, {
    [styles.upload_zone_active]: isDragActive,
  });

  return (
    <>
      <div {...getRootProps({ className: className })}>
        <Typography variant="body1" className={styles.upload_zone_text}>
          {t('import_universe_upload_drag_and_drop')}
        </Typography>
        <input {...getInputProps()} />
        <Button onClick={() => {}} variant="outlined">
          <SvgIcon component={UploadIcon} viewBox={'0 0 36 36'} />
          {t('browse_files')}
        </Button>
        <Typography variant="caption" className={styles.upload_zone_hint}>
          {t('import_universe_upload_file_hint')}
        </Typography>
      </div>
      {hasError && <FormError>{t('unable_to_read_file')}</FormError>}
      {!!fileName && (
        <div className={styles.selected_file_details}>
          <Chip className={styles.selected_file_name_chip} label={fileName} />
          <span className={styles.success_text}>
            {t('count_added', { countValue: '1/1' })}
          </span>
        </div>
      )}
    </>
  );
};
