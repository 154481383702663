import { UserRole } from 'types';

import { RouteInfo } from 'routes/RouteInfo';
import { Routes } from 'routes/Routes';

import CampaignApproval from 'components/CampaignApproval/CampaignApproval';
import CampaignDetails from 'components/Campaigns/CampaignDetails/CampaignDetails';
import Execution from 'components/Executions/Execution';
import Executions from 'components/Executions/Executions';
import { Home } from 'components/Home/Home';
import Profile from 'components/Profile/Profile';
import Reporting from 'components/Reporting/Reporting';
import ErrorGeneral from 'components/Shared/Error/ErrorGeneral';
import { Imprint, PrivacyPolicy } from 'components/StaticPages';
import { UniverseDetailsPage } from 'components/Universes/UniverseDetailsPage';
import { Universes } from 'components/Universes/Universes';
import { UserManagement } from 'components/UserManagement/UserManagement';

import { CampaignGroupDetails } from '../components/CampaignGroups/CampaignGroupDetails/CampaignGroupDetails';
import { CampaignsAndGroupListingWrapper } from '../components/CampaignGroups/CampaignsAndGroupListing';
import { CookieNotice } from '../components/StaticPages/CookieNotice';

export const PrivateRoutes: Record<string, RouteInfo> = {
  ROOT: {
    path: Routes.ROOT,
    roles: [],
    element: <Home />,
    translationKey: 'home',
  },
  CAMPAIGNS: {
    path: Routes.CAMPAIGNS,
    roles: [
      UserRole.ADVERTISER_CAMPAIGN_MANAGER,
      UserRole.ADVERTISER_ADMIN,
      UserRole.KEY_ACCOUNT_MANAGER,
    ],
    element: <CampaignsAndGroupListingWrapper />,
    translationKey: 'campaigns',
  },
  CAMPAIGNS_OVERVIEW: {
    path: Routes.CAMPAIGNS_OVERVIEW,
    roles: [
      UserRole.APPROVAL_MANAGER,
      UserRole.SALESFORCE_MANAGER,
      UserRole.KEY_ACCOUNT_MANAGER,
    ],
    element: <CampaignApproval />,
    translationKey: 'campaign_approval',
  },
  CREATE_CAMPAIGN: {
    path: `${Routes.CAMPAIGNS}/create/:campaignType`,
    roles: [
      UserRole.ADVERTISER_CAMPAIGN_MANAGER,
      UserRole.ADVERTISER_ADMIN,
      UserRole.KEY_ACCOUNT_MANAGER,
    ],
    element: <CampaignDetails formType="create" />,
    translationKey: 'campaign_create',
  },
  EDIT_CAMPAIGN: {
    path: `${Routes.CAMPAIGNS}/:campaignId/edit`,
    roles: [
      UserRole.ADVERTISER_CAMPAIGN_MANAGER,
      UserRole.ADVERTISER_ADMIN,
      UserRole.KEY_ACCOUNT_MANAGER,
    ],
    element: <CampaignDetails formType="edit" />,
    translationKey: 'edit_campaign',
  },
  VALIDATE_CAMPAIGN: {
    path: `${Routes.CAMPAIGNS}/:campaignId/validate`,
    roles: [
      UserRole.APPROVAL_MANAGER,
      UserRole.KEY_ACCOUNT_MANAGER,
      UserRole.SALESFORCE_MANAGER,
    ],
    element: <CampaignDetails formType="validate" />,
    translationKey: 'validate_campaign',
  },
  VIEW_CAMPAIGN: {
    path: `${Routes.CAMPAIGNS}/:campaignId`,
    roles: [],
    element: <CampaignDetails formType="view" />,
    translationKey: 'view_campaign',
  },
  CREATE_CAMPAIGN_GROUP: {
    path: `${Routes.CAMPAIGN_GROUPS}/create`,
    roles: [
      UserRole.ADVERTISER_CAMPAIGN_MANAGER,
      UserRole.ADVERTISER_ADMIN,
      UserRole.KEY_ACCOUNT_MANAGER,
    ],
    element: <CampaignGroupDetails formType="create" />,
    translationKey: 'campaign_group_create',
  },
  EDIT_CAMPAIGN_GROUP: {
    path: `${Routes.CAMPAIGN_GROUPS}/edit/:campaignGroupId`,
    roles: [
      UserRole.ADVERTISER_CAMPAIGN_MANAGER,
      UserRole.ADVERTISER_ADMIN,
      UserRole.KEY_ACCOUNT_MANAGER,
    ],
    element: <CampaignGroupDetails formType="edit" />,
    translationKey: 'campaign_group_edit',
  },
  REPORTING: {
    path: Routes.REPORTING,
    roles: [
      UserRole.ADVERTISER_CAMPAIGN_MANAGER,
      UserRole.ADVERTISER_ADMIN,
      UserRole.KEY_ACCOUNT_MANAGER,
    ],
    element: <Reporting />,
    translationKey: 'reporting',
  },
  PROFILE: {
    path: Routes.PROFILE,
    roles: [
      UserRole.ADVERTISER_CAMPAIGN_MANAGER,
      UserRole.ADVERTISER_ADMIN,
      UserRole.KEY_ACCOUNT_MANAGER,
      UserRole.ADVERTISER_ADMIN,
      UserRole.APPROVAL_MANAGER,
      UserRole.SALESFORCE_MANAGER,
    ],
    element: <Profile />,
    translationKey: 'profile',
  },
  AUDIENCES: {
    path: Routes.AUDIENCES,
    roles: [
      UserRole.ADVERTISER_CAMPAIGN_MANAGER,
      UserRole.ADVERTISER_ADMIN,
      UserRole.KEY_ACCOUNT_MANAGER,
    ],
    element: <Universes />,
    translationKey: 'audiences',
  },
  EDIT_AUDIENCES: {
    path: `${Routes.AUDIENCES}/:id`,
    roles: [
      UserRole.ADVERTISER_CAMPAIGN_MANAGER,
      UserRole.ADVERTISER_ADMIN,
      UserRole.KEY_ACCOUNT_MANAGER,
    ],
    element: <UniverseDetailsPage />,
    translationKey: 'audiences',
  },
  EXECUTIONS: {
    path: Routes.EXECUTIONS,
    roles: [UserRole.STAFF],
    element: <Executions />,
    translationKey: 'executions',
  },
  EXECUTION: {
    path: `${Routes.EXECUTIONS}/:executionId`,
    roles: [UserRole.STAFF],
    element: <Execution />,
    translationKey: 'execution',
  },
  USERS: {
    path: `${Routes.USERS}`,
    roles: [UserRole.ADVERTISER_ADMIN],
    element: <UserManagement />,
    translationKey: 'users',
  },
  ERROR: {
    path: Routes.ERROR,
    roles: [], // open for all users
    translationKey: 'error',
    element: <ErrorGeneral />,
  },
  IMPRINT: {
    path: Routes.IMPRINT,
    roles: [],
    translationKey: 'imprint',
    element: <Imprint />,
  },
  PRIVACY_POLICY: {
    path: Routes.PRIVACY_POLICY,
    roles: [],
    translationKey: 'privacy_policy',
    element: <PrivacyPolicy />,
  },
  COOKIE_NOTICE: {
    path: Routes.COOKIE_NOTICE,
    roles: [],
    translationKey: 'cookie_notice',
    element: <CookieNotice />,
  },
};
