import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { DraftUniverse } from 'shared/interfaces';

import { Routes } from 'routes/Routes';

import { addNotification } from 'store/modules/notifications/actions';

import LoadingIndicator from 'components/Shared/LoadingIndicator/LoadingIndicator';

import EditUniverse from './EditUniverse/EditUniverse';
import { UniverseChangeLog } from './UniverseChangeLog/UniverseChangeLog';
import { useUniverseData } from './hooks/useUniverseData';

export const UniverseDetailsPage = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { universe, isLoading, saveUniverse, isSaving } = useUniverseData(id!);

  const handleSave = async (universeItem: DraftUniverse) => {
    const updatedUniverse = await saveUniverse(universeItem);
    dispatch(
      addNotification({
        text: !universeItem?.id ? t('universe_created') : t('universe_updated'),
        type: 'success',
      })
    );
    if (!universe?.id) {
      // This is the creation mode and post universe creation user should navigate to the universe details page
      navigate(
        universeItem?.build?.type === 'UNIVERSE_BUILD_TYPE_BUILDER'
          ? `${Routes.UNIVERSES}/${updatedUniverse?.id}`
          : Routes.UNIVERSES,
        {
          replace: true,
        }
      );
    }
  };

  return (
    <>
      <LoadingIndicator isAppLoading={isLoading} />
      {universe && !isLoading && (
        <EditUniverse
          draftUniverse={universe}
          onSave={handleSave}
          isLoading={isLoading}
          isSaving={isSaving}
        />
      )}
      {/*Don't show changelog for creation mode and for CSV uploaded universes*/}
      {universe?.build?.type !== 'UNIVERSE_BUILD_TYPE_IMPORT' &&
        !!universe?.id && <UniverseChangeLog universe={universe} />}
    </>
  );
};
