import { FC, useEffect, useMemo, useState } from 'react';

import { LayoutType } from 'shared/enums';

import { Divider, Stack } from '@mui/material';
import { Box } from '@mui/system';

import styles from './ListActionBar.module.scss';
import {
  AutoCompleteFilter,
  AutoCompleteFilterProps,
  EntitySelectedFilterType,
  Layouts,
  LayoutsProps,
  MultiSelectFilter,
  MultiSelectFilterProps,
  Search,
  SearchProps,
  SelectedFilterBar,
  SingleSelectFilter,
  SingleSelectFilterProps,
  Sort,
  SortProps,
} from './components';
import {
  SwitchFilter,
  SwitchFilterProps,
} from './components/Filters/SwitchFilter/SwitchFilter';
import { ListActionBarContext } from './hooks/useListActionBar';

interface ListActionComposition {
  Search: FC<SearchProps>;
  Sort: FC<SortProps>;
  MultiSelectFilter: FC<MultiSelectFilterProps>;
  SingleSelectFilter: FC<SingleSelectFilterProps>;
  AutoCompleteFilter: FC<AutoCompleteFilterProps>;
  SwitchFilter: FC<SwitchFilterProps>;
  Layouts: FC<LayoutsProps>;
}

interface Props {
  initialFilters?: EntitySelectedFilterType[];
  onFiltersChanged?: (filters: EntitySelectedFilterType[]) => void;
}

const ListActionBar: FC<Props> & ListActionComposition = (props) => {
  const { initialFilters, onFiltersChanged, ...rest } = props;
  const [selectedLayout, setSelectedLayout] = useState(LayoutType.Table);

  /* We are using below 2 filter state objects to manage different points of filter update triggers.
  When there are some preset filters sent from the parent component, the `apply filter callback`
  should not be invoked. So, here the selectedFilter state keeps track of the overall filters i.e.
  the ones coming from parent component and also the ones updated from within the `list action bar`
  and the `updatedFilters` only gets updated when the filter update is triggered from
  within the `List Action Bar`.
  */
  const [selectedFilters, setSelectedFilters] = useState<
    EntitySelectedFilterType[]
  >(props.initialFilters || []);

  const [updatedFilters, setUpdatedFilters] = useState<
    EntitySelectedFilterType[] | undefined
  >(props.initialFilters);

  useEffect(() => {
    if (updatedFilters !== undefined && props.onFiltersChanged)
      props.onFiltersChanged(selectedFilters);
  }, [selectedFilters]);

  const memoizedContextValue = useMemo(
    () => ({
      selectedLayout,
      setSelectedLayout,
      selectedFilters,
      setSelectedFilters,
      updatedFilters,
      setUpdatedFilters,
    }),
    [
      selectedLayout,
      setSelectedLayout,
      selectedFilters,
      setSelectedFilters,
      updatedFilters,
      setUpdatedFilters,
    ]
  );

  return (
    <ListActionBarContext.Provider value={memoizedContextValue}>
      <div {...rest}>
        <Box className={styles.action_bar} sx={{ py: 1, px: 2 }}>
          <>
            <Stack
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              spacing={2}
            >
              {props.children}
            </Stack>
            {selectedFilters.filter(
              (selected) => selected.activeFilterValues?.length !== 0
            ).length > 0 && (
              <>
                <Divider></Divider>
                <SelectedFilterBar />
              </>
            )}
          </>
        </Box>
      </div>
    </ListActionBarContext.Provider>
  );
};

ListActionBar.Sort = Sort;
ListActionBar.Search = Search;
ListActionBar.SingleSelectFilter = SingleSelectFilter;
ListActionBar.MultiSelectFilter = MultiSelectFilter;
ListActionBar.AutoCompleteFilter = AutoCompleteFilter;
ListActionBar.Layouts = Layouts;
ListActionBar.SwitchFilter = SwitchFilter;

export { ListActionBar };
