import { ReactNode } from 'react';

import { Alert, AlertColor } from '@mui/material';

import styles from './Alert.module.scss';

export type AlertProps = {
  text: ReactNode;
  severity: AlertColor;
};

const getAlertClassName = (severity: AlertColor): string => {
  const colorsMap: {
    [key in AlertColor]: string;
  } = {
    success: styles.success,
    warning: styles.warning,
    info: styles.info,
    error: styles.error,
  };

  return colorsMap[severity] || styles.info;
};

export const CustomAlert = ({ text, severity = 'info' }: AlertProps) => {
  return (
    <Alert
      className={`${styles.alert} ${getAlertClassName(severity)}`}
      variant="outlined"
      elevation={0}
      severity={severity}
    >
      <span className={styles.alert_text}>{text}</span>
    </Alert>
  );
};
