import React from 'react';

import {
  endOfDay,
  isSameDay,
  max,
  min,
  startOfDay,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { definitions } from 'types/api';
import isWithinIntervalUTC from 'utils/isWithinIntervalUTC';

declare global {
  interface Window {
    google: any;
  }
}

export function useLoadGoogleCharts(): boolean {
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.google.charts.load('current', {
      packages: ['corechart', 'line', 'controls'],
      language: 'de',
    });
    window.google.charts.setOnLoadCallback(() => {
      setLoaded(true);
    });
  }, []);

  return loaded;
}

export enum ChartColor {
  PURPLE = '#795ef0',
  ORANGE = '#feae01',
  DARK_ORANGE = '#fe6100',
  BLUE = '#648fff',
  GREEN = '#009292',
}

export enum TimePeriod {
  WEEK = '1W',
  MONTH = '1M',
  THREE_MONTHS = '3M',
  YEAR = '1Y',
}

export const getChartColor = (usedColors: ChartColor[]): ChartColor => {
  return Object.values(ChartColor).filter((c) => !usedColors.includes(c))[0];
};

export const mapTimePeriod = (
  timePeriod: TimePeriod,
  lastDataDate?: string
): { from: Date; to: Date } => {
  /*
  Since the reporting data from DPR only has dates and parsing the dates introduces issues because of daylight saving, we should always consider the start of the first date
  and the end of the last date to prevent any comparison surprises due to the time zone shift from the Daylight savings. +2:00 GMT to +1:00 GMT or vice versa e.g. PACMAN-1976
   */
  let fromDate;
  let toDate = subDays(new Date(), 1);

  if (lastDataDate) {
    toDate = endOfDay(new Date(lastDataDate));
  }
  fromDate = startOfDay(
    {
      [TimePeriod.WEEK]: subWeeks(toDate, 1),
      [TimePeriod.MONTH]: subMonths(toDate, 1),
      [TimePeriod.THREE_MONTHS]: subMonths(toDate, 3),
      [TimePeriod.YEAR]: subYears(toDate, 1),
    }[timePeriod]
  );

  return { from: fromDate, to: toDate };
};

export const formatData = (
  campaignAData: definitions['TimeSeriesValue'][],
  campaignBData: definitions['TimeSeriesValue'][],
  productUnavailabilityDurations: definitions['ProductUnavailabilityDuration'][]
): (number | Date | null)[][] => {
  /*
  Since the reporting data from DPR only has dates and parsing the dates introduces issues because of daylight saving, we should always consider the start of the first date
  and the end of the last date to prevent any comparison surprises due to the time zone shift from the Daylight savings. +2:00 GMT to +1:00 GMT or vice versa e.g. PACMAN-1976
   */
  const startDate = startOfDay(
    min(
      campaignAData
        .map((d) => new Date(d.date!))
        .concat(campaignBData.map((d) => new Date(d.date!)) || [])
    )
  );

  const endDate = endOfDay(
    max(
      campaignAData
        .map((d) => new Date(d.date!))
        .concat(campaignBData.map((d) => new Date(d.date!)) || [])
    )
  );

  const mappedData = [];
  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    const campaignAValue = campaignAData.find((entity) =>
      isSameDay(new Date(entity.date!), date)
    )?.value;
    const campaignBValue = campaignBData?.find((entity) =>
      isSameDay(new Date(entity.date!), date)
    )?.value;
    const productUnavailability = productUnavailabilityDurations.some(
      (interval) => {
        return isWithinIntervalUTC(
          date,
          new Date(interval.start!),
          new Date(interval.end!)
        );
      }
    );
    mappedData.push([
      new Date(date),
      campaignAValue ? Number(campaignAValue) : null,
      campaignBValue ? Number(campaignBValue) : null,
      productUnavailability ? Number(campaignAValue) : null,
    ]);
  }

  return mappedData;
};
