import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { tkCampaignState } from 'i18n/translationKeyMaps';
import { definitions } from 'types/api';

import { useGetCampaignsQuery } from 'store/api/endpoints/campaign';
import { useDeleteUniverseMutation } from 'store/api/endpoints/universes';

import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import styles from './RemoveUniverse.module.scss';

interface Props {
  universe: definitions['Universe'];
  onClose: () => void;
}

const RemoveUniverse: FC<Props> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();

  const { data: campaigns, isLoading: isGetCampaignsLoading } =
    useGetCampaignsQuery(
      {
        filter: { universe_ids: [props.universe.id] },
      },
      { skip: !props.universe.id }
    );

  const [deleteUniverse, { isLoading: isDeleting }] =
    useDeleteUniverseMutation();

  const handleUniverseRemove = useCallback(async () => {
    await deleteUniverse(props.universe.id);
    props.onClose();
  }, [props]);

  const hasRunningCampaignsAssigned = campaigns?.results.some(
    (campaign) => campaign.state === 'CAMPAIGN_STATE_RUNNING'
  );

  const { description, content } = useMemo(() => {
    if (isGetCampaignsLoading) {
      return {
        description: '',
        content: <CircularProgress />,
      };
    }

    if (campaigns?.results.length === 0) {
      return {
        description: t('delete_universe_description', {
          name: props.universe.name,
        }),
        content: t('delete_universe_no_running_campaigns_assigned'),
      };
    }

    return {
      description: '',
      content: (
        <>
          <div className={styles.description}>
            {hasRunningCampaignsAssigned
              ? t('delete_universe_forbidden', {
                  name: props.universe.name,
                })
              : t('delete_universe_no_running_campaigns_assigned')}
          </div>
          {campaigns?.results.map((campaign) => (
            <Grid key={campaign.id}>
              <Typography variant="subtitle1">{campaign.name}</Typography>
              <Typography variant="subtitle2">
                {`${t('state')}: ${tkCampaignState(campaign.state)}`}
              </Typography>
            </Grid>
          ))}
        </>
      ),
    };
  }, [t, campaigns?.results, props.universe, hasRunningCampaignsAssigned]);

  return (
    <DialogWrapper
      width={550}
      id="delete-universe-confirmation"
      headerText={t('delete_universe')}
      subHeaderText={description}
      handleClose={onClose}
      dialogContent={content}
      dialogActions={
        <>
          <Button onClick={onClose} variant="outlined">
            {t('cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            disabled={hasRunningCampaignsAssigned}
            loading={isDeleting || isGetCampaignsLoading}
            onClick={handleUniverseRemove}
          >
            {t('delete')}
          </LoadingButton>
        </>
      }
    />
  );
};

export default RemoveUniverse;
