import { MouseEvent } from 'react';

import { ExtendedChannel } from 'hooks';
import i18n from 'i18n';
import { tkCampaignType } from 'i18n/translationKeyMaps';
import { ExtendedCampaign } from 'shared/interfaces';
import { definitions } from 'types/api';
import { toDateTime } from 'utils/time';

import { Routes } from 'routes/Routes';

import { NumberFormat } from 'components/Shared';
import { CountryNameWithFlag } from 'components/Shared/CountryNameWithFlag/CountryNameWithFlag';
import {
  CampaignStateChip,
  Column,
  CustomIconButton,
} from 'components/Shared/CustomMui';

import { Tooltip } from '@mui/material';

interface Props {
  allAdvertisersSelected?: boolean;
  cloneClickHandler: (id: string) => void;
  isCurrentCampaignActive: (id: string) => boolean;
  networkAdvertisers?: definitions['Advertiser'][];
}

export const CampaignColumns = ({
  allAdvertisersSelected,
  cloneClickHandler,
  isCurrentCampaignActive,
  networkAdvertisers,
}: Props): Column[] => {
  return [
    {
      field: 'name',
      width: 250,
      headerName: i18n.t('name'),
      renderCell: (name) => (
        <Tooltip title={name || ''}>
          <span>{name}</span>
        </Tooltip>
      ),
    },
    {
      field: 'state',
      headerName: i18n.t('status'),
      width: 120,
      renderCell: (state) => <CampaignStateChip state={state} />,
    },
    {
      field: 'type',
      headerName: i18n.t('type'),
      width: 100,
      renderCell: (type) => tkCampaignType(type),
    },
    {
      field: 'country_code',
      headerName: i18n.t('country'),
      justify: 'flex-start',
      width: 80,
      renderCell: (country_code) => (
        <CountryNameWithFlag
          countryCode={country_code}
          countryName={country_code}
        />
      ),
    },
    {
      field: 'advertiser_id',
      headerName: i18n.t('advertiser_name'),
      renderCell: (id) => (
        <>
          {networkAdvertisers?.find((advertiser) => advertiser.id === id)
            ?.display_name || '-'}
        </>
      ),
    },
    {
      field: 'extended_channels',
      headerName: i18n.t('channels'),
      useRootObject: true,
      renderCell: (campaign) => {
        const allChannels = (
          campaign.extended_channels && campaign.extended_channels.length > 0
            ? campaign.extended_channels
            : []
        )
          .map((channel: ExtendedChannel) => channel.name)
          .join(', ');
        return allChannels ? (
          <Tooltip title={allChannels || ''}>
            <span>{allChannels}</span>
          </Tooltip>
        ) : (
          '-'
        );
      },
    },
    {
      field: 'campaign_group_name',
      headerName: i18n.t('campaign_group'),
      width: 250,
      renderCell: (campaign_group_name) => (
        <Tooltip title={campaign_group_name || '-'}>
          <span>{campaign_group_name || '-'}</span>
        </Tooltip>
      ),
    },
    {
      field: 'updated_at',
      headerName: i18n.t('last_modified'),
      width: 150,
      renderCell: (updated_at) => toDateTime(updated_at),
    },
    {
      field: 'campaign_start',
      headerName: i18n.t('campaign_start'),
      width: 120,
      renderCell: (campaign_start) => `${toDateTime(campaign_start, false)}`,
    },
    {
      field: 'campaign_end',
      headerName: i18n.t('campaign_end'),
      width: 120,
      renderCell: (campaign_end) => `${toDateTime(campaign_end, false)}` || '∞',
    },

    {
      field: 'bid',
      headerName: i18n.t('bid'),
      width: 150,
      useRootObject: true,
      renderCell: (extendedCampaign: ExtendedCampaign) => {
        const formattedBid = (
          <NumberFormat
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            displayType="text"
            prefix={extendedCampaign?.currency?.symbol}
            value={Number(extendedCampaign?.bid)}
          />
        );
        return (
          <Tooltip title={formattedBid}>
            <div>{formattedBid}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'budget',
      headerName: i18n.t('budget'),
      width: 150,
      useRootObject: true,
      renderCell: (extendedCampaign: ExtendedCampaign) => {
        const formattedBudget = (
          <NumberFormat
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            displayType="text"
            prefix={extendedCampaign?.currency?.symbol}
            value={Number(extendedCampaign?.budget)}
          />
        );
        return (
          <Tooltip title={formattedBudget}>
            <div>{formattedBudget}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'id',
      headerName: '',
      sticky: 'right',
      width: 135,
      useRootObject: true,
      renderCell: ({ id }: ExtendedCampaign) => (
        <>
          <CustomIconButton
            title={i18n.t('to_reporting')}
            icon="reporting"
            to={
              allAdvertisersSelected
                ? `${Routes.REPORTING}?campaign=${id}&all-advertisers=true`
                : `${Routes.REPORTING}?campaign=${id}`
            }
            disabled={!isCurrentCampaignActive(id)}
          />
          <CustomIconButton
            title={i18n.t('clone_campaign')}
            icon="duplicate"
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              cloneClickHandler(id);
            }}
          />
          <CustomIconButton
            title={i18n.t('edit_campaign')}
            icon="edit"
            to={`${Routes.CAMPAIGNS}/${id}/edit`}
          />
        </>
      ),
    },
  ];
};
