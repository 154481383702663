import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { UserRole } from 'types';
import { definitions } from 'types/api';

import { useGetRetailersQuery } from 'store/api/endpoints/retailer';
import { selectUser } from 'store/modules/user/selectors';

import Grid from '@mui/material/Grid';

import { UniverseItem } from '../UniverseItem/UniverseItem';

interface Props {
  onItemClick: (universe: definitions['Universe']) => void;
  onItemRemove?: (universe: definitions['Universe']) => void;
  universes: definitions['Universe'][];
  dialogMode?: boolean;
  itemClickMode?: 'edit' | 'select';
}

export const UniversesList: FC<Props> = (props: Props): ReactElement => {
  const user = useSelector(selectUser);
  const { data: retailers = [] } = useGetRetailersQuery(undefined, {
    skip: !user?.advertiser_id,
    refetchOnMountOrArgChange: true,
  });
  const { dialogMode, onItemClick, onItemRemove, universes, itemClickMode } =
    props;

  const isReadOnly = (universe: definitions['Universe']) => {
    return (
      (itemClickMode === 'edit' &&
        universe.build.type === 'UNIVERSE_BUILD_TYPE_EXTERNAL') ||
      (itemClickMode === 'edit' &&
        universe.build.type === 'UNIVERSE_BUILD_TYPE_IMPORT')
    );
  };

  const isDeletable = (universe: definitions['Universe']) => {
    return (
      user?.role === UserRole.KEY_ACCOUNT_MANAGER ||
      universe.build.type !== 'UNIVERSE_BUILD_TYPE_IMPORT'
    );
  };

  const handleClick = (universe: definitions['Universe']): void => {
    if (isReadOnly(universe)) {
      return;
    }
    return onItemClick(universe);
  };

  return (
    <Grid container spacing={4} data-testid="universe-list-container">
      {universes.map((universe: definitions['Universe'], index: number) => (
        <Grid item key={index} xs={12} sm={6} md={dialogMode ? 6 : 3}>
          <UniverseItem
            key={universe.id}
            retailers={retailers}
            onClick={handleClick}
            onRemove={onItemRemove}
            universe={universe}
            readonly={isReadOnly(universe)}
            deletable={isDeletable(universe)}
            mode={itemClickMode}
          />
        </Grid>
      ))}
    </Grid>
  );
};
