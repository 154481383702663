import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { tkUniverseBlockLogic } from 'i18n/translationKeyMaps';
import { definitions } from 'types/api';

import styles from 'components/Universes/Rule/Rule.module.scss';

import { Grid, Typography } from '@mui/material';

interface Props {
  logic: string;
  condition: definitions['BuildUniverseUserCondition'];
  onChange: (condition: definitions['BuildUniverseUserCondition']) => void;
}

export const RuleInclusivenessPopper = ({
  logic,
  condition,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography color="textSecondary" variant="body2">
        {t('actions')}
      </Typography>
      <Grid container>
        <Grid
          className={classNames(styles.block, {
            [styles.selected]:
              logic === 'BUILD_UNIVERSE_CONDITION_LOGIC_INCLUDE',
          })}
          onClick={() => {
            onChange({
              ...condition,
              logic: 'BUILD_UNIVERSE_CONDITION_LOGIC_INCLUDE',
            });
          }}
        >
          {t(tkUniverseBlockLogic('BUILD_UNIVERSE_CONDITION_LOGIC_INCLUDE'))}
        </Grid>
        <Grid
          className={classNames(styles.block, {
            [styles.selected]:
              logic === 'BUILD_UNIVERSE_CONDITION_LOGIC_EXCLUDE',
          })}
          onClick={() => {
            onChange({
              ...condition,
              logic: 'BUILD_UNIVERSE_CONDITION_LOGIC_EXCLUDE',
            });
          }}
        >
          {t(tkUniverseBlockLogic('BUILD_UNIVERSE_CONDITION_LOGIC_EXCLUDE'))}
        </Grid>
      </Grid>
    </div>
  );
};
