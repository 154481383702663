import { ACTIVE_CAMPAIGN_POSSIBLE_STATES } from '../shared/constants';
import { definitions } from './api';

export const isCampaignActive = (
  state: definitions['CampaignState']
): boolean => {
  return ACTIVE_CAMPAIGN_POSSIBLE_STATES.includes(state);
};

export const campaignTypeGuard = (
  type: string
): definitions['CampaignType'] => {
  switch (type.toLowerCase()) {
    case 'cpm':
      return 'CAMPAIGN_TYPE_IMPRESSION';
    case 'cpa':
      return 'CAMPAIGN_TYPE_CONVERSION';
    case 'cpc':
      return 'CAMPAIGN_TYPE_CLICK';
    default:
      return 'CAMPAIGN_TYPE_UNKNOWN';
  }
};
