import { MouseEvent } from 'react';

import { ExtendedChannel } from 'hooks';
import i18n from 'i18n';
import { tkCampaignType } from 'i18n/translationKeyMaps';
import { ExtendedCampaign } from 'shared/interfaces';
import { toDateTime } from 'utils/time';

import { Routes } from 'routes/Routes';

import { InlineBudgetInput } from 'components/CampaignGroups/CampaignGroupDetails/BudgetInput/InlineBudgetInput';
import { NumberFormat } from 'components/Shared';
import { CountryNameWithFlag } from 'components/Shared/CountryNameWithFlag/CountryNameWithFlag';
import {
  CampaignStateChip,
  Column,
  CustomIconButton,
} from 'components/Shared/CustomMui';

import { Tooltip } from '@mui/material';

interface Props {
  isCampaignGroupPage: boolean;
  touchedCampaignIds: string[];
  cloneClickHandler: (id: string) => void;

  isCurrentCampaignActive: (id: string) => boolean;
  onUnassignCampaign?: (campaignId: string) => void;
  setTouchedCampaignIds: (campaignIds: string[]) => void;
}

export const CampaignGroupColumns = ({
  cloneClickHandler,
  touchedCampaignIds,
  onUnassignCampaign,

  isCampaignGroupPage,
  setTouchedCampaignIds,
  isCurrentCampaignActive,
}: Props): Column[] => {
  return [
    {
      field: 'name',
      width: 250,
      headerName: i18n.t('name'),
      renderCell: (name) => (
        <Tooltip title={name}>
          <span>{name}</span>
        </Tooltip>
      ),
    },
    {
      field: 'state',
      headerName: i18n.t('status'),
      width: 120,
      renderCell: (state) => <CampaignStateChip state={state} />,
    },
    {
      field: 'type',
      headerName: i18n.t('type'),
      width: 100,
      renderCell: (type) => tkCampaignType(type),
    },
    {
      field: 'country_code',
      headerName: i18n.t('country'),
      justify: 'flex-start',
      width: 80,
      renderCell: (country_code) => (
        <CountryNameWithFlag
          countryCode={country_code}
          countryName={country_code}
        />
      ),
    },
    {
      field: 'extended_channels',
      headerName: i18n.t('channel'),
      renderCell: (extended_channels) => {
        const allChannels = (
          extended_channels && extended_channels.length > 0
            ? extended_channels
            : []
        )
          .map((channel: ExtendedChannel) => channel.name)
          .join(', ');
        return allChannels ? (
          <Tooltip title={allChannels}>
            <span>{allChannels}</span>
          </Tooltip>
        ) : (
          '-'
        );
      },
    },
    {
      field: 'updated_at',
      headerName: i18n.t('last_modified'),
      width: 150,
      renderCell: (updated_at) => {
        return toDateTime(updated_at);
      },
    },
    {
      field: 'campaign_start',
      headerName: i18n.t('campaign_start'),
      width: 120,
      renderCell: (campaign_start) => `${toDateTime(campaign_start, false)}`,
    },
    {
      field: 'campaign_end',
      headerName: i18n.t('campaign_end'),
      width: 120,
      renderCell: (campaign_end) => `${toDateTime(campaign_end, false)}` || '∞',
    },
    {
      field: 'bid',
      headerName: i18n.t('bid'),
      width: 90,
      useRootObject: true,
      renderCell: (extendedCampaign: ExtendedCampaign) => (
        <NumberFormat
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={2}
          displayType="text"
          prefix={extendedCampaign?.currency?.symbol}
          value={Number(extendedCampaign.bid)}
        />
      ),
    },
    {
      field: 'budget',
      headerName: i18n.t('budget'),
      width: 150,
      useRootObject: true,
      renderCell: (campaign: ExtendedCampaign) =>
        isCampaignGroupPage ? (
          <InlineBudgetInput
            campaign={campaign}
            campaignTouched={touchedCampaignIds.includes(campaign.id)}
            onBlur={() =>
              setTouchedCampaignIds([...touchedCampaignIds, campaign.id])
            }
          />
        ) : (
          <NumberFormat
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            displayType="text"
            prefix={campaign?.currency?.symbol}
            value={Number(campaign.budget)}
          />
        ),
    },
    {
      field: 'used_budget',
      headerName: i18n.t('used_budget'),
      width: 150,
      useRootObject: true,
      renderCell: (extendedCampaign: ExtendedCampaign) => (
        <NumberFormat
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={0}
          displayType="text"
          prefix={extendedCampaign?.currency?.symbol}
          value={Number(extendedCampaign?.used_budget_total_cumulative)}
        />
      ),
    },
    {
      field: 'id',
      headerName: '',
      width: isCampaignGroupPage ? 160 : 130,
      sticky: 'right',
      useRootObject: true,
      renderCell: (extendedCampaign: ExtendedCampaign) => {
        return (
          <>
            <CustomIconButton
              title={i18n.t('to_reporting')}
              to={`${Routes.REPORTING}?campaign=${extendedCampaign.id}`}
              disabled={!isCurrentCampaignActive(extendedCampaign.id)}
              icon="reporting"
            />
            <CustomIconButton
              title={i18n.t('clone_campaign')}
              icon="duplicate"
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                cloneClickHandler(extendedCampaign.id);
              }}
            />
            <CustomIconButton
              title={i18n.t('edit_campaign')}
              to={`${Routes.CAMPAIGNS}/${extendedCampaign.id}/edit`}
              icon="edit"
            />
            {isCampaignGroupPage && (
              <CustomIconButton
                title={i18n.t('remove_campaign_from_group')}
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  // Refetch campaign group after deleting a campaign from campaign group to sync with backend, which automatically fetches campaign list
                  onUnassignCampaign?.(extendedCampaign.id);
                }}
                disabled={
                  !['CAMPAIGN_STATE_DRAFT', 'CAMPAIGN_STATE_REJECTED'].includes(
                    extendedCampaign.state
                  )
                }
                icon="delete"
              />
            )}
          </>
        );
      },
    },
  ];
};
