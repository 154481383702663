import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  AsyncThunkAction,
  configureStore as rtkConfigureStore,
  unwrapResult,
} from '@reduxjs/toolkit';

import { errorHandlerMiddleware } from 'store/middlewares/errorHandler';

import { baseApi } from './api/baseApi';
import { combinedReducer, rootReducer } from './reducer';

const configureStore = (preloadedState = {} as RootState) => {
  return rtkConfigureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat([baseApi.middleware, errorHandlerMiddleware]);
    },
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  });
};

export default configureStore;

export type StoreDispatch = ReturnType<typeof configureStore>['dispatch'];

// Hook that can be reused to resolve types
export const useStoreDispatch = () => useDispatch<StoreDispatch>();

export const useStoreDispatchUnwrap = () => {
  // automatically unwrap - required to access response / thrown error
  const dispatch = useDispatch<StoreDispatch>();
  return useCallback(
    <R extends any>(asyncThunk: AsyncThunkAction<R, any, any>): Promise<R> =>
      dispatch(asyncThunk).then(unwrapResult),
    [dispatch]
  );
};

export type RootState = ReturnType<typeof combinedReducer>;
