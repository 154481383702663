import { createContext, useContext } from 'react';

import { LayoutType } from 'shared/enums';

import { EntitySelectedFilterType } from '../components';

interface ListActionBarProps {
  searchQuery?: string;

  selectedLayout: string;
  setSelectedLayout: (layout: LayoutType) => void;

  selectedFilters: EntitySelectedFilterType[];
  setSelectedFilters: (filters: EntitySelectedFilterType[]) => void;

  updatedFilters: EntitySelectedFilterType[] | undefined;
  setUpdatedFilters: (filters: EntitySelectedFilterType[] | undefined) => void;
}

export const ListActionBarContext = createContext<
  ReturnType<typeof useListActionBar>
>({} as any);

export const useListActionBar = (): ListActionBarProps => {
  const context = useContext(ListActionBarContext);
  if (!context) {
    throw new Error(
      'Child components of ListActionBar cannot be rendered outside the ListActionBar component!'
    );
  }
  return context;
};
