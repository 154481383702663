import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AuthConfig } from 'shared/interfaces';

import { setAuthConfig as setAuthConfigAction } from '../store/modules/settings/actions';

export const useGetEnvironment = (): {
  environment: string;
  authConfig: AuthConfig | undefined;
} => {
  const dispatch = useDispatch();
  const [environment, setEnvironment] = useState<string>('');
  const [authConfig, setAuthConfig] = useState<AuthConfig>();

  useEffect(() => {
    let mounted = true;

    const fetchConfigFile = async () => {
      const data = await (
        await fetch(`${process.env.PUBLIC_URL}/config/env_config.json`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
      ).json();
      if (mounted) {
        setEnvironment(data['env']);
        setAuthConfig(data['authConfig']);
        // Setting authConfig to store to access outside of components and hooks
        dispatch(setAuthConfigAction(data['authConfig']));
      }
    };

    fetchConfigFile();
    return () => {
      mounted = false;
    };
  }, []);

  return { environment, authConfig };
};
