import { Endpoints } from 'types';
import { definitions } from 'types/api';

import { baseApi } from '../baseApi';

const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['CSPExecution'],
});

// inject campaign predictions endpoints to the enhancedBaseApiWithTags
export const campaignSuccessPredictionApi =
  enhancedBaseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      getCSPByCampaignId: builder.query<
        definitions['CSPExecution'],
        { campaignId: string }
      >({
        query: ({ campaignId }) =>
          `${Endpoints.campaigns}/${campaignId}/success_predictions`,
        transformResponse: (response: {
          cspExecution: definitions['CSPExecution'][];
        }) => {
          // the first one is the most recent one
          return response.cspExecution[0];
        },
      }),
      getCSPByExecutionId: builder.query<
        definitions['CSPExecution'],
        { campaignId: string; executionId: string }
      >({
        query: ({ campaignId, executionId }) =>
          `${Endpoints.campaigns}/${campaignId}/success_predictions/${executionId}`,
        transformResponse: (response: {
          cspExecution: definitions['CSPExecution'];
        }) => {
          return response.cspExecution;
        },
        providesTags: ['CSPExecution'],
      }),
      createCSPExecution: builder.query<definitions['CSPExecution'], string>({
        query: (campaignId: string) => ({
          url: `${Endpoints.campaigns}/${campaignId}/success_predictions`,
          method: 'POST',
          body: { campaign: campaignId },
        }),
        transformResponse: (response: {
          cspExecution: definitions['CSPExecution'];
        }) => {
          return response.cspExecution;
        },
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetCSPByCampaignIdQuery,
  useGetCSPByExecutionIdQuery,
  useLazyCreateCSPExecutionQuery,
} = campaignSuccessPredictionApi;
