import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';

// set to midday local time then convert to ISO in UTC
export const localMiddayISO = (date: Date): string => {
  return formattedISOString(setHours(setMinutes(setSeconds(date, 0), 0), 12));
};

// set to local startday which is 2 hours after 00:00 local time then convert to ISO in UTC
export const localStartDayISO = (date: Date): string => {
  return formattedISOString(setHours(setMinutes(setSeconds(date, 0), 0), 2));
};

// set to local endday which is 23 hours after 00:00 local time then convert to ISO in UTC
export const localEndDayISO = (date: Date): string => {
  return formattedISOString(setHours(setMinutes(setSeconds(date, 0), 0), 23));
};

export const isValidDate = (d: null | Date) => {
  return d === null || (d instanceof Date && !isNaN(d.getTime()));
};

export const toDateTime = (
  date?: string | null,
  showTime: boolean = true,
  utcTimezone: boolean = false
) => {
  if (!date) return '';

  const dateFormat = showTime ? 'dd.MM.yyyy | HH:mm' : 'dd.MM.yyyy';

  if (utcTimezone) return formatInTimeZone(parseISO(date), 'UTC', dateFormat);

  return format(new Date(date), dateFormat);
};

export const formattedISOString = (date: Date) => {
  // to be aligned with API response, we need to remove milliseconds
  return date.toISOString().replace(/\.\d{3}Z/, 'Z');
};

const TODAY = new Date();
export const TOMORROW = new Date(TODAY);
TOMORROW.setDate(TOMORROW.getDate() + 1);
export const YESTERDAY = new Date(TODAY);
YESTERDAY.setDate(YESTERDAY.getDate() - 1);
